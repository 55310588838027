// This constant is equal to the scss $layout-min-width variable. They should have the same values.
export const LAYOUT_MIN_WIDTH = '992px';

// This constant is equal to the scss $section-margin variable. They should have the same values.;
export const SECTION_MARGIN = '24px';

export const TAB_MARGIN_BOTTOM = '0px';
export const SMALL_MODAL_WIDTH = '500px';
export const LARGE_MODAL_WIDTH = '992px';
export const XLARGE_MODAL_WIDTH = '1200px';
