import { Market } from '@shared/interfaces/market.interface';
import { Medium } from '@shared/interfaces/medium.interface';

export enum KpiSelectsEnum {
  Media = 'media',
  Market = 'market',
}

export interface KpiSelects {
  [KpiSelectsEnum.Media]: Medium[];
  [KpiSelectsEnum.Market]: Market[];
}
