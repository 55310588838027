export enum TableEnum {
  Row = 'row',
  Column = 'column',
  SplitBy = 'splitBy',
}

export interface Table {
  [TableEnum.Row]: Row;
  [TableEnum.Column]: Column;
  [TableEnum.SplitBy]: string;
}

export enum RowEnum {
  Headers = 'headers',
  Content = 'content',
}

export interface Row {
  [RowEnum.Headers]: string[];
  [RowEnum.Content]: string[];
}

export enum ColumnEnum {
  Headers = 'headers',
  Content = 'content',
}

export interface Column {
  [ColumnEnum.Headers]: string[];
  [ColumnEnum.Content]: string[];
}
