export const parseATagToText = (msg: string): string => {
  const regex = /<a.*href.*>/g;
  const textRegex = />([^<]+)</;
  const text = msg;
  const match = text.match(regex);

  return !!match ? text.match(textRegex)![1] : text;
};

export const parseATagToPath = (tagAsString: string): string | null => {
  const hrefRegex = /href="([^"]+)"/;
  const hrefMatch = tagAsString.match(hrefRegex);

  return !!hrefMatch ? hrefMatch![1] : null;
};
