export enum TokenErrorEnum {
  TokenExpired = 'token_expired',
  AuthenticationException = 'authentication_exception',
  TokenNotFound = 'token_not_found',
}

export enum AccessErrorEnum {
  InsufficientAccessRights = 'insuffient_access_rights',
}

export const TOKEN_ERROR = {
  [TokenErrorEnum.TokenExpired]: 'token_expired',
  [TokenErrorEnum.AuthenticationException]: 'authentication_exception',
  [TokenErrorEnum.TokenNotFound]: 'token_not_found',
};

export const ACCESS_ERROR = {
  [AccessErrorEnum.InsufficientAccessRights]: 'insuffient_access_rights',
};

export const Endpoints_Without_Token = ['api/refreshToken, api/rfp/2'];
