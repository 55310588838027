import { ClientFilterEnum, ClientFilters, Client, ClientEnum } from '@shared/interfaces/clients.interface';
import { Market, MarketEnum } from '@shared/interfaces/market.interface';

export const filterClientHelper = (clients: Client[], filters: ClientFilters): Client[] => {
  if (filters[ClientFilterEnum.Market]) {
    clients = clients.filter(
      (client: Client) =>
        client[ClientEnum.Markets].filter(
          (market: Market) => market[MarketEnum.Id] === filters[ClientFilterEnum.Market][MarketEnum.Id]
        ).length > 0
    );
  }

  if (filters[ClientFilterEnum.Parent]) {
    clients = clients.filter((client: Client) => {
      return client[ClientFilterEnum.Parent]
        ? client[ClientFilterEnum.Parent]![ClientEnum.Id] === filters[ClientFilterEnum.Parent][ClientEnum.Id]
        : false;
    });
  }

  if (typeof filters[ClientFilterEnum.Disabled] == 'boolean') {
    clients = clients.filter((client: Client) => client[ClientFilterEnum.Disabled] !== filters[ClientFilterEnum.Disabled]);
  }

  if (filters[ClientFilterEnum.SearchText]) {
    const searchText = filters[ClientFilterEnum.SearchText].toLowerCase();
    clients = clients.filter((client: Client) => {
      return (
        client[ClientFilterEnum.Name].toLowerCase().includes(searchText) ||
        client[ClientFilterEnum.Parent]![ClientEnum.Name].toLowerCase().includes(searchText)
      );
    });
  }

  return clients;
};
