import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ApplicationConf, ApplicationConfEnum } from '@shared/consts/application-conf.const';
import {
  Client,
  ClientEnum,
  ClientFilterEnum,
  ClientFilters,
  ClientFilterValues,
  ClientSelects,
} from '@shared/interfaces/clients.interface';
import { MarketEnum } from '@shared/interfaces/market.interface';
import { filter, first, Observable } from 'rxjs';
import { clientFilterForm } from './client-filter.const';

@Component({
  selector: 'app-client-filter',
  templateUrl: './client-filter.component.html',
  styleUrls: ['./client-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientFilterComponent implements OnInit {
  @Output() submitted = new EventEmitter<ClientFilters>();
  @Input() filterValuesData!: ClientFilterValues;
  @Input() selectsData$!: Observable<Partial<ClientSelects>>;
  @Input() clients$!: Observable<Client[]>;
  filteredParents: Client[] = [];

  public clientFilterForm = this.formBuilder.group(clientFilterForm);
  public ClientFilterEnum = ClientFilterEnum;

  public MarketEnum = MarketEnum;
  public ClientEnum = ClientEnum;

  public ApplicationConf = ApplicationConf;
  public ApplicationConfEnum = ApplicationConfEnum;

  constructor(public formBuilder: FormBuilder, private readonly changeDetecotr: ChangeDetectorRef) {}

  ngOnInit() {
    this.clients$
      .pipe(
        filter((clients: Client[]) => clients.length > 0),
        first()
      )
      .subscribe((clients: Client[]) => {
        this.filteredParents = [];
        clients.map((client: Client) => {
          if (
            client[ClientEnum.Parent] &&
            this.filteredParents.filter((parent: Client) => parent[ClientEnum.Id] === client[ClientEnum.Parent]![ClientEnum.Id])
              .length === 0
          ) {
            this.filteredParents.push(<Client>client[ClientEnum.Parent]);
          }
        });
        this.changeDetecotr.detectChanges();
      });
  }

  filter(): void {
    const filterValues: ClientFilters = this.clientFilterForm.getRawValue();
    this.submitted.emit(filterValues);
  }

  search(event: Event): void {
    event.preventDefault();
    this.filter();
  }

  clear(event: Event): void {
    event.preventDefault();
    this.clientFilterForm.reset();
    this.filter();
  }
}
