import { Directive, ElementRef, HostListener } from '@angular/core';
import { CharactersDisallowedKPI } from '@shared/consts/validator.const';

@Directive({
  selector: '[appEngineSpecialCharacters]',
})
export class EngineSpecialCharactersDirective {
  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    return new RegExp('^[^\\\\?$_=].*').test(event.key);
  }

  @HostListener('paste', ['$event'])
  blockPaste(event: ClipboardEvent) {
    event.preventDefault();
    this.validateCopyText(event);
  }

  validateCopyText(event: ClipboardEvent): void {
    this.el.nativeElement.value = event.clipboardData!.getData('text/plain').replace(CharactersDisallowedKPI, '');
  }
}
