import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  DragDropTransferItems,
  DragDropTransferItemsEnum,
  DragDropTableContainer,
  DragDropTransferOutputEnum,
} from '@shared/interfaces/drag-drop-for-tables.interface';

export interface DragDropTransferOutput<T> {
  builderItemList: T[];
  sheetGroup: T[];
  draggedItem: T;
  builderItemIndex: number;
  sheetGroupItemIndex: number;
}

export const dragDropDataContainer = <T>(data: CdkDragDrop<T[]>): DragDropTransferItems<T> => {
  const base = {
    [DragDropTransferItemsEnum.PrevContainer]: data.previousContainer.data,
    [DragDropTransferItemsEnum.CurrContainer]: data.container.data,
    [DragDropTransferItemsEnum.PrevIndex]: data.previousIndex,
    [DragDropTransferItemsEnum.CurrIndex]: data.currentIndex,
  };
  const [currContainerTitle, currContainerIndex] = data.container.id.split('-');
  const [prevContainerTitle, prevContainerIndex] = data.previousContainer.id.split('-');

  return {
    ...base,
    [DragDropTransferItemsEnum.CurrContainerId]: currContainerTitle,
    [DragDropTransferItemsEnum.PrevContainerId]: prevContainerTitle,
    [DragDropTransferItemsEnum.PrevContainerIndex]: prevContainerIndex ? +prevContainerIndex : undefined,
    [DragDropTransferItemsEnum.CurrContainerIndex]: currContainerIndex ? +currContainerIndex : undefined,
  };
};

export const transferBetweenSections = <T>(data: DragDropTransferItems<T>) => {
  if (data[DragDropTransferItemsEnum.CurrContainerId] === DragDropTableContainer.Worksheet) {
    return fromBuilderItemToGroup(data);
  } else if (data[DragDropTransferItemsEnum.CurrContainerId] === DragDropTableContainer.KpiList) {
    return fromGroupItemToBuilder(data);
  }
  return null;
};

export const transferInsideSection = <T>(data: DragDropTransferItems<T>) => {
  if (data[DragDropTransferItemsEnum.CurrContainerId] === DragDropTableContainer.Worksheet) {
    const items = data[DragDropTransferItemsEnum.CurrContainer];
    const prevIndex = data[DragDropTransferItemsEnum.PrevIndex];
    const currIndex = data[DragDropTransferItemsEnum.CurrIndex];
    const newOrderedItems = items.filter((col, index) => index !== prevIndex);

    newOrderedItems.splice(currIndex, 0, items[prevIndex]);

    return [...newOrderedItems];
  }

  return null;
};

export const fromBuilderItemToGroup = <T>(data: DragDropTransferItems<T>): DragDropTransferOutput<T> => {
  const builderContainer = [...data[DragDropTransferItemsEnum.PrevContainer]];
  const groupContainer = [...data[DragDropTransferItemsEnum.CurrContainer]];
  const builderItemIndex = data[DragDropTransferItemsEnum.PrevIndex];
  const groupItemIndex = data[DragDropTransferItemsEnum.CurrIndex];

  builderContainer.splice(builderItemIndex, 1);

  groupContainer.splice(groupItemIndex, 0, data.prevContainer[builderItemIndex]);

  return {
    [DragDropTransferOutputEnum.BuilderItemList]: [...builderContainer],
    [DragDropTransferOutputEnum.SheetGroup]: [...groupContainer],
    [DragDropTransferOutputEnum.DraggedItem]: data.prevContainer[builderItemIndex],
    [DragDropTransferOutputEnum.BuilderItemIndex]: builderItemIndex,
    [DragDropTransferOutputEnum.SheetGroupItemIndex]: groupItemIndex,
  };
};

export const fromGroupItemToBuilder = <T>(data: DragDropTransferItems<T>): DragDropTransferOutput<T> => {
  const groupContainer = [...data[DragDropTransferItemsEnum.PrevContainer]];
  const builderContainer = [...data[DragDropTransferItemsEnum.CurrContainer]];
  const groupItemIndex = data[DragDropTransferItemsEnum.PrevIndex];
  const builderItemIndex = data[DragDropTransferItemsEnum.CurrIndex];

  groupContainer.splice(groupItemIndex, 1);
  builderContainer.splice(builderItemIndex, 0, data.prevContainer[groupItemIndex]);

  return {
    [DragDropTransferOutputEnum.BuilderItemList]: [...builderContainer],
    [DragDropTransferOutputEnum.SheetGroup]: [...groupContainer],
    [DragDropTransferOutputEnum.DraggedItem]: data.prevContainer[groupItemIndex],
    [DragDropTransferOutputEnum.BuilderItemIndex]: builderItemIndex,
    [DragDropTransferOutputEnum.SheetGroupItemIndex]: groupItemIndex,
  };
};
