import { Client, ClientEnum, ClientForm } from '@shared/interfaces/clients.interface';
import { Market, MarketEnum } from '@shared/interfaces/market.interface';

const getClientFormInit = (): ClientForm => {
  return {
    [ClientEnum.Name]: '',
    [ClientEnum.Parent]: null!,
    [ClientEnum.Markets]: [],
  };
};

const prepareClientFromBackend = (client: Client): ClientForm => {
  const marketIds: number[] = [];
  client[ClientEnum.Markets].map((market: Market) => marketIds.push(market[MarketEnum.Id]));
  return <ClientForm>{
    [ClientEnum.Name]: client[ClientEnum.Name],
    [ClientEnum.Parent]: client[ClientEnum.Parent] ? client[ClientEnum.Parent]![ClientEnum.Id] : null,
    [ClientEnum.Markets]: marketIds,
  };
};

export { getClientFormInit, prepareClientFromBackend };
