import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject, withLatestFrom } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Medium, MediumEnum, MediumFilterEnum, MediumFilters, MediumSave } from '@shared/interfaces/medium.interface';
import { NotificationEnum, NotificationType } from '@shared/components/notification/notification.const';
import { NotificationService } from '@shared/components/notification/notification.facade';
import { filterMediumHelper } from '../../../logged-in/modules/admin/medium/medium.helpers';
import CustomError from '@shared/classes/CustomError.class';
import { map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomErrorEnum, CustomErrorType } from '@shared/interfaces/custom-error.interface';
import { MarketFacade } from '@shared/services/markets/market.facade';
import { MediumService } from '@shared/services';
import { MediumFilterValuesInit } from '../../../logged-in/modules/admin/medium/medium.const';

@Injectable()
export class MediaAdminFacade {
  private _media$ = new BehaviorSubject<Medium[]>([]);
  public readonly media$ = this._media$.asObservable();

  public readonly enabledMedia$ = this.media$.pipe(map((media) => media.filter((medium) => !medium[MediumEnum.Disabled])));
  private readonly markets$ = this.marketFacade.enabledMarkets$;

  private _errorMediumAdd$ = new Subject<CustomError | null>();
  public errorMediumAdd$ = this._errorMediumAdd$.asObservable();
  public filterData$ = combineLatest([this.markets$]).pipe(
    map(([markets]) => ({
      [MediumFilterEnum.Markets]: markets,
      [MediumFilterEnum.Disabled]: [],
    }))
  );
  private mediaAll: Medium[] = [];
  private mediumFilters: MediumFilters = MediumFilterValuesInit;

  constructor(
    private readonly mediumService: MediumService,
    private readonly marketFacade: MarketFacade,
    private readonly modalService: NzModalService,
    private readonly notificationService: NotificationService
  ) {}

  get currentValueMedium(): Medium[] {
    return this._media$.value;
  }

  get currentValueEnabledMedium(): Medium[] {
    return this._media$.value.filter((medium) => !medium[MediumEnum.Disabled]);
  }

  filterMedia(filters: MediumFilters): void {
    const filteredMedia: Medium[] = filterMediumHelper(this.mediaAll, filters);
    this._media$.next(filteredMedia);
  }

  edit(id: number, medium: MediumSave): void {
    this.mediumService.edit(id, medium).subscribe({
      next: (editedMedium: Medium): void => {
        this._media$.next(
          this._media$.value.map(
            (data: Medium): Medium => (data[MediumEnum.Id] === editedMedium[MediumEnum.Id] ? editedMedium : data)
          )
        );
        this.mediaAll = this.replaceMedium(this.mediaAll, editedMedium);
        this.notificationService.create({
          [NotificationEnum.Type]: NotificationType.Success,
          [NotificationEnum.TextType]: 'mediumEdit',
        });
        this.modalService.closeAll();
      },
      error: (error: HttpErrorResponse): void => {
        this._errorMediumAdd$.next(null);
      },
    });
  }

  save(media: MediumSave): void {
    this.mediumService.save(media).subscribe({
      next: (medium: Medium): void => {
        this.getAll();
        this.notificationService.create({
          [NotificationEnum.Type]: NotificationType.Success,
          [NotificationEnum.TextType]: 'mediumAdd',
        });
        this.modalService.closeAll();
      },
      error: (err: HttpErrorResponse): void => {
        let customError: CustomError;
        if (err.error.errorCode === 'medium_already_exists') {
          customError = new CustomError({
            [CustomErrorEnum.Message]: '',
            [CustomErrorEnum.MessageCode]: 'medium_already_exists',
            [CustomErrorEnum.Type]: CustomErrorType.Form,
          });
        } else {
          customError = new CustomError({
            [CustomErrorEnum.Message]: '',
            [CustomErrorEnum.MessageCode]: 'unexpected_error',
            [CustomErrorEnum.Type]: CustomErrorType.Default,
          });
        }
        this._errorMediumAdd$.next(customError);
      },
    });
  }

  public getAll(): void {
    this.mediumService.getAll().subscribe((media: Medium[]): void => {
      this.mediaAll = media;
      this.filterMedia(this.mediumFilters);
    });
  }

  public disable(id: Medium[MediumEnum.Id]): void {
    this.mediumService
      .disable(id)
      .pipe(
        withLatestFrom(this.media$),
        map(([changedMedium, mediums]) => {
          return [this.replaceMedium(mediums, changedMedium), this.replaceMedium(this.mediaAll, changedMedium)];
        })
      )
      .subscribe({
        next: ([mediums, mediumsAll]) => {
          this._media$.next(mediums);
          this.mediaAll = mediumsAll;
          this.notificationService.create({
            [NotificationEnum.Type]: NotificationType.Success,
            [NotificationEnum.TextType]: 'mediumDisable',
          });
        },
        error: (error: HttpErrorResponse) => this.showNotification(NotificationType.Error, 'mediumDisable'),
      });
  }

  public enable(id: Medium[MediumEnum.Id]): void {
    this.mediumService
      .enable(id)
      .pipe(
        withLatestFrom(this.media$),
        map(([changedMedium, mediums]) => {
          return [this.replaceMedium(mediums, changedMedium), this.replaceMedium(this.mediaAll, changedMedium)];
        })
      )
      .subscribe({
        next: ([mediums, mediumsAll]) => {
          this._media$.next(mediums);
          this.mediaAll = mediumsAll;
          this.notificationService.create({
            [NotificationEnum.Type]: NotificationType.Success,
            [NotificationEnum.TextType]: 'mediumEnable',
          });
        },
        error: (error: HttpErrorResponse) => this.showNotification(NotificationType.Error, 'mediumEnable'),
      });
  }

  public replaceMedium(mediums: Medium[], changedMedium: Medium): Medium[] {
    return mediums.map((medium: Medium) => (medium[MediumEnum.Id] === changedMedium[MediumEnum.Id] ? changedMedium : medium));
  }

  // save(market: MediumSave): void {
  //   this.mediumService.save(market).subscribe({
  //     next: (market: Medium) => this._mediums.next([...this._mediums.value, ...[market]]),
  //     error: (error: HttpErrorResponse) => {
  //       const customError = new CustomError({
  //         [CustomErrorEnum.Message]: '',
  //         [CustomErrorEnum.MessageCode]: 'medium_already_exists',
  //         [CustomErrorEnum.Type]: CustomErrorType.Form,
  //       });
  //       this._errorMediumAdd$.next(customError);
  //     },
  //   });
  // }

  // delete(id: number): void {
  //   this.mediumService.disable(id).subscribe({
  //     next: (market: Medium) =>
  //       this._mediums.next([...this._mediums.value.filter((item: Medium) => item[MediumEnum.Id] !== market[MediumEnum.Id])]),
  //     error: (error: HttpErrorResponse) => this.showNotification(NotificationType.Error, 'mediumDelete'),
  //   });
  // }

  showNotification(type: NotificationType, text: string): void {
    this.notificationService.create({ [NotificationEnum.Type]: type, [NotificationEnum.TextType]: text });
  }
}
