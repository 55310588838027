import { ProjectListPaginationEnum } from '@shared-project/modules/projects/interfaces/projects.interface';
import { OrganizationTypeEnum, AllProjectStatuses } from '@shared/interfaces';

export const projectsMock = [
  {
    id: 1,
    projectUuid: 'c5341933-e462-4e70-8b8c-3a66f6cec827',
    name: 'EMPTY FOR CLONING (DO NOT MODIFY)',
    status: AllProjectStatuses.GT_SETUP,
    year: 2022,
    evaluatedFrom: undefined,
    evaluatedTo: undefined,
    disabled: false,
    organization: { organizationId: 1, type: OrganizationTypeEnum.Stars, name: 'STARS', disabled: false, parent: null },
    market: { id: 31, name: 'Poland', code: 'PL', disabled: false, currencies: [] },
    rfp: {
      rfpId: 1,
      rfpUuid: '2b252765-7221-44b0-b232-a8e6199811ff',
      name: 'Dummy RFP',
      client: { organizationId: 4, type: 'CLIENT', name: 'Stars_Client', disabled: false, parent: null, markets: [] },
      userAccesses: [],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'liquibase',
    modifiedBy: 'test@webellian.com',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-22T12:36:02.616+00:00',
    userAccesses: [],
    agencyUserAccesses: [],
    responsibleUser: { userId: 1, email: 'test@webellian.com' },
    selectedMedia: [
      { mediumId: 1, name: 'TV' },
      { mediumId: 3, name: 'Radio' },
    ],
    version: null,
    readOnly: false,
  },
  {
    id: 2,
    projectUuid: '0eb2dede-05b9-4fc7-bb73-37be54420dbd',
    name: 'Jarek"s project - Prototype',
    status: 'AT_SETUP',
    year: '2020',
    evaluatedFrom: null,
    evaluatedTo: null,
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 31, name: 'Poland', code: 'PL', disabled: false },
    rfp: {
      rfpId: 1,
      rfpUuid: '2b252765-7221-44b0-b232-a8e6199811ff',
      name: 'Dummy RFP',
      client: { organizationId: 4, type: 'CLIENT', name: 'Stars_Client', disabled: false },
      userAccesses: [],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'liquibase',
    modifiedBy: 'r.lejman@webellian.com',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T14:06:09.896+00:00',
    userAccesses: [],
    agencyUserAccesses: [],
    responsibleUser: { userId: 1, email: 'test@webellian.com' },
    selectedMedia: [
      { mediumId: 1, name: 'TV' },
      { mediumId: 4, name: 'Online' },
    ],
    version: null,
    readOnly: false,
  },
  {
    id: 3,
    projectUuid: '83613a00-3994-41ea-b742-c3f546a96187',
    name: 'pusty, zarezerwowany',
    status: 'AT_SETUP',
    year: '2020',
    evaluatedFrom: null,
    evaluatedTo: null,
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 31, name: 'Poland', code: 'PL', disabled: false },
    rfp: {
      rfpId: 1,
      rfpUuid: '2b252765-7221-44b0-b232-a8e6199811ff',
      name: 'Dummy RFP',
      client: { organizationId: 4, type: 'CLIENT', name: 'Stars_Client', disabled: false },
      userAccesses: [],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'liquibase',
    modifiedBy: 'test@webellian.com',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-12T11:31:10.792+00:00',
    userAccesses: [],
    agencyUserAccesses: [],
    responsibleUser: { userId: 1, email: 'test@webellian.com' },
    selectedMedia: [
      { mediumId: 1, name: 'TV' },
      { mediumId: 4, name: 'Online' },
    ],
    version: null,
    readOnly: false,
  },
  {
    id: 4,
    projectUuid: '72489eb3-b367-417d-8e08-168a89e0bfe6',
    name: 'Jarek"s project - Prototype (p4)',
    status: 'AT_CALCULATION_IN_PROGRESS',
    year: '2022',
    evaluatedFrom: null,
    evaluatedTo: null,
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 31, name: 'Poland', code: 'PL', disabled: false },
    rfp: {
      rfpId: 1,
      rfpUuid: '2b252765-7221-44b0-b232-a8e6199811ff',
      name: 'Dummy RFP',
      client: { organizationId: 4, type: 'CLIENT', name: 'Stars_Client', disabled: false },
      userAccesses: [],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'liquibase',
    modifiedBy: 'test@webellian.com',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-17T09:50:59.548+00:00',
    userAccesses: [],
    agencyUserAccesses: [],
    responsibleUser: { userId: 1, email: 'test@webellian.com' },
    selectedMedia: [{ mediumId: 1, name: 'TV' }],
    version: null,
    readOnly: false,
  },
  {
    id: 5,
    projectUuid: 'd4453cbd-7526-4327-b925-666b839c1e98',
    name: 'pusty, zarezerwowany (2)',
    status: 'AT_SETUP',
    year: '2022',
    evaluatedFrom: null,
    evaluatedTo: null,
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 31, name: 'Poland', code: 'PL', disabled: false },
    rfp: {
      rfpId: 1,
      rfpUuid: '2b252765-7221-44b0-b232-a8e6199811ff',
      name: 'Dummy RFP',
      client: { organizationId: 4, type: 'CLIENT', name: 'Stars_Client', disabled: false },
      userAccesses: [],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'liquibase',
    modifiedBy: 'r.lejman@webellian.com',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-17T13:11:27.251+00:00',
    userAccesses: [],
    agencyUserAccesses: [],
    responsibleUser: { userId: 1, email: 'test@webellian.com' },
    selectedMedia: [{ mediumId: 1, name: 'TV' }],
    version: null,
    readOnly: false,
  },
  {
    id: 6,
    projectUuid: '5b431b21-62db-48aa-896f-84d7746fe804',
    name: 'Project_Molson_Romania_2022_20220921122722',
    status: 'AT_CALCULATION_SUCCESS',
    year: '2022',
    evaluatedFrom: null,
    evaluatedTo: null,
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 33, name: 'Romania', code: 'RO', disabled: false },
    rfp: {
      rfpId: 1,
      rfpUuid: '2b252765-7221-44b0-b232-a8e6199811ff',
      name: 'Dummy RFP',
      client: { organizationId: 4, type: 'CLIENT', name: 'Stars_Client', disabled: false },
      userAccesses: [],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'test@webellian.com',
    modifiedBy: 'o.wolska@webellian.com',
    createdDate: '2022-09-21T12:27:23.028+00:00',
    modifiedDate: '2023-02-14T11:21:14.546+00:00',
    userAccesses: [],
    agencyUserAccesses: [],
    responsibleUser: { userId: 1, email: 'test@webellian.com' },
    selectedMedia: [{ mediumId: 1, name: 'TV' }],
    version: null,
    readOnly: false,
  },
  {
    id: 7,
    projectUuid: 'e3b9951a-f318-4b4f-b4d8-0104d9ca54a8',
    name: 'Project_Molson_Romania_2022_20220916122206',
    status: 'AT_CALCULATION_SUCCESS',
    year: '2022',
    evaluatedFrom: null,
    evaluatedTo: null,
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 33, name: 'Romania', code: 'RO', disabled: false },
    rfp: {
      rfpId: 1,
      rfpUuid: '2b252765-7221-44b0-b232-a8e6199811ff',
      name: 'Dummy RFP',
      client: { organizationId: 4, type: 'CLIENT', name: 'Stars_Client', disabled: false },
      userAccesses: [],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'test@webellian.com',
    modifiedBy: 'test@webellian.com',
    createdDate: '2022-09-15T10:17:44.717+00:00',
    modifiedDate: '2023-02-13T00:39:03.257+00:00',
    userAccesses: [],
    agencyUserAccesses: [],
    responsibleUser: { userId: 1, email: 'test@webellian.com' },
    selectedMedia: [{ mediumId: 1, name: 'TV' }],
    version: null,
    readOnly: false,
  },
  {
    id: 8,
    projectUuid: 'b7f36d73-85da-48eb-b435-7a0b0b45d8fa',
    name: 'Project_Molson_Croatia_2022_20220916122206',
    status: 'AT_CALCULATION_SUCCESS',
    year: '2022',
    evaluatedFrom: null,
    evaluatedTo: null,
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 8, name: 'Croatia', code: 'CR', disabled: false },
    rfp: {
      rfpId: 1,
      rfpUuid: '2b252765-7221-44b0-b232-a8e6199811ff',
      name: 'Dummy RFP',
      client: { organizationId: 4, type: 'CLIENT', name: 'Stars_Client', disabled: false },
      userAccesses: [],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'test@webellian.com',
    modifiedBy: 'r.lejman@webellian.com',
    createdDate: '2022-09-15T10:17:46.231+00:00',
    modifiedDate: '2023-02-16T13:21:54.765+00:00',
    userAccesses: [],
    agencyUserAccesses: [],
    responsibleUser: { userId: 1, email: 'test@webellian.com' },
    selectedMedia: [{ mediumId: 1, name: 'TV' }],
    version: null,
    readOnly: false,
  },
  {
    id: 9,
    projectUuid: '4cf29e79-8aeb-4988-b72f-08a006724a51',
    name: 'STARS project - example Client',
    status: 'GT_EXPORT_FAILURE',
    year: '2020',
    evaluatedFrom: null,
    evaluatedTo: null,
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 31, name: 'Poland', code: 'PL', disabled: false },
    rfp: {
      rfpId: 1,
      rfpUuid: '2b252765-7221-44b0-b232-a8e6199811ff',
      name: 'Dummy RFP',
      client: { organizationId: 4, type: 'CLIENT', name: 'Stars_Client', disabled: false },
      userAccesses: [],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'liquibase',
    modifiedBy: 'r.lejman@webellian.com',
    createdDate: '2022-09-12T17:39:02.130+00:00',
    modifiedDate: '2023-02-13T08:25:33.348+00:00',
    userAccesses: [],
    agencyUserAccesses: [],
    responsibleUser: { userId: 1, email: 'test@webellian.com' },
    selectedMedia: [
      { mediumId: 1, name: 'TV' },
      { mediumId: 4, name: 'Online' },
    ],
    version: null,
    readOnly: false,
  },
  {
    id: 10,
    projectUuid: '8d0cba6a-f5f6-49f7-9888-9a656378c3c8',
    name: 'Project_Molson_Romania_2022_20221019104849',
    status: 'GT_EXPORT_FAILURE',
    year: '2022',
    evaluatedFrom: null,
    evaluatedTo: null,
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 33, name: 'Romania', code: 'RO', disabled: false },
    rfp: {
      rfpId: 1,
      rfpUuid: '2b252765-7221-44b0-b232-a8e6199811ff',
      name: 'Dummy RFP',
      client: { organizationId: 4, type: 'CLIENT', name: 'Stars_Client', disabled: false },
      userAccesses: [],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'test@webellian.com',
    modifiedBy: 'f.bezpalko@webellian.com',
    createdDate: '2022-10-19T10:48:49.343+00:00',
    modifiedDate: '2023-02-17T14:34:22.091+00:00',
    userAccesses: [],
    agencyUserAccesses: [],
    responsibleUser: { userId: 1, email: 'test@webellian.com' },
    selectedMedia: [{ mediumId: 1, name: 'TV' }],
    version: null,
    readOnly: false,
  },
  {
    id: 11,
    projectUuid: '942af16b-89d7-40c8-a299-fa96f5d9e1ce',
    name: 'STARS project - example Client (p4)',
    status: 'AT_CALCULATION_SUCCESS_VALIDATION_FAILURE',
    year: '2022',
    evaluatedFrom: null,
    evaluatedTo: null,
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 31, name: 'Poland', code: 'PL', disabled: false },
    rfp: {
      rfpId: 1,
      rfpUuid: '2b252765-7221-44b0-b232-a8e6199811ff',
      name: 'Dummy RFP',
      client: { organizationId: 4, type: 'CLIENT', name: 'Stars_Client', disabled: false },
      userAccesses: [],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'liquibase',
    modifiedBy: 'm.lanocha@webellian.com',
    createdDate: '2022-09-12T17:39:02.130+00:00',
    modifiedDate: '2023-02-20T09:58:28.490+00:00',
    userAccesses: [],
    agencyUserAccesses: [],
    responsibleUser: { userId: 1, email: 'test@webellian.com' },
    selectedMedia: [{ mediumId: 1, name: 'TV' }],
    version: null,
    readOnly: false,
  },
  {
    id: 12,
    projectUuid: 'fd396f20-a791-45ee-9afa-4fc015b93fbb',
    name: 'Project_Molson_Croatia_2022_20220921122726',
    status: 'GT_EXPORT_SUCCESS',
    year: '2022',
    evaluatedFrom: null,
    evaluatedTo: null,
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 8, name: 'Croatia', code: 'CR', disabled: false },
    rfp: {
      rfpId: 1,
      rfpUuid: '2b252765-7221-44b0-b232-a8e6199811ff',
      name: 'Dummy RFP',
      client: { organizationId: 4, type: 'CLIENT', name: 'Stars_Client', disabled: false },
      userAccesses: [],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'test@webellian.com',
    modifiedBy: 'test@webellian.com',
    createdDate: '2022-09-21T12:27:26.963+00:00',
    modifiedDate: '2022-11-15T17:59:43.920+00:00',
    userAccesses: [],
    agencyUserAccesses: [],
    responsibleUser: { userId: 1, email: 'test@webellian.com' },
    selectedMedia: [{ mediumId: 1, name: 'TV' }],
    version: null,
    readOnly: false,
  },
  {
    id: 15,
    projectUuid: '6a472c06-a29e-4719-8b4a-0ddb08fa7810',
    name: 'Automated Scenario 2: 2208',
    status: 'AT_SETUP',
    year: '2023',
    evaluatedFrom: null,
    evaluatedTo: null,
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 33, name: 'Romania', code: 'RO', disabled: false },
    rfp: {
      rfpId: 1,
      rfpUuid: '2b252765-7221-44b0-b232-a8e6199811ff',
      name: 'Dummy RFP',
      client: { organizationId: 4, type: 'CLIENT', name: 'Stars_Client', disabled: false },
      userAccesses: [],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-10T11:42:27.079+00:00',
    modifiedDate: '2023-02-10T11:43:41.755+00:00',
    userAccesses: [],
    agencyUserAccesses: [],
    responsibleUser: { userId: 18, email: 'iRobot' },
    selectedMedia: [{ mediumId: 1, name: 'TV' }],
    version: null,
    readOnly: false,
  },
  {
    id: 17,
    projectUuid: 'e51a902f-b480-4571-b31f-805dea329f89',
    name: 'Automated Scenario 4 GT: 951583',
    status: 'DIMENSION_SETUP',
    year: '2023',
    evaluatedFrom: null,
    evaluatedTo: null,
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 31, name: 'Poland', code: 'PL', disabled: false },
    rfp: {
      rfpId: 1,
      rfpUuid: '2b252765-7221-44b0-b232-a8e6199811ff',
      name: 'Dummy RFP',
      client: { organizationId: 4, type: 'CLIENT', name: 'Stars_Client', disabled: false },
      userAccesses: [],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-10T11:54:19.163+00:00',
    modifiedDate: '2023-02-10T11:54:37.324+00:00',
    userAccesses: [],
    agencyUserAccesses: [],
    responsibleUser: { userId: 18, email: 'iRobot' },
    selectedMedia: [{ mediumId: 1, name: 'TV' }],
    version: null,
    readOnly: false,
  },
  {
    id: 18,
    projectUuid: '2a672065-63f6-44e9-984f-25d04c2e6b46',
    name: 'Automated Scenario 1: 37968',
    status: 'AT_SETUP',
    year: '2023',
    evaluatedFrom: null,
    evaluatedTo: null,
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 31, name: 'Poland', code: 'PL', disabled: false },
    rfp: {
      rfpId: 1,
      rfpUuid: '2b252765-7221-44b0-b232-a8e6199811ff',
      name: 'Dummy RFP',
      client: { organizationId: 4, type: 'CLIENT', name: 'Stars_Client', disabled: false },
      userAccesses: [],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'iRobot',
    modifiedBy: 'o.wolska@webellian.com',
    createdDate: '2023-02-10T12:09:42.594+00:00',
    modifiedDate: '2023-02-23T20:11:21.513+00:00',
    userAccesses: [],
    agencyUserAccesses: [],
    responsibleUser: { userId: 18, email: 'iRobot' },
    selectedMedia: [{ mediumId: 1, name: 'TV' }],
    version: null,
    readOnly: false,
  },
  {
    id: 19,
    projectUuid: '825926ae-308a-4e85-ae82-2a77378f9f26',
    name: 'Automated Scenario 2: 112931',
    status: 'AT_SETUP',
    year: '2023',
    evaluatedFrom: null,
    evaluatedTo: null,
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 33, name: 'Romania', code: 'RO', disabled: false },
    rfp: {
      rfpId: 1,
      rfpUuid: '2b252765-7221-44b0-b232-a8e6199811ff',
      name: 'Dummy RFP',
      client: { organizationId: 4, type: 'CLIENT', name: 'Stars_Client', disabled: false },
      userAccesses: [],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-10T12:09:49.663+00:00',
    modifiedDate: '2023-02-10T12:10:33.528+00:00',
    userAccesses: [],
    agencyUserAccesses: [],
    responsibleUser: { userId: 18, email: 'iRobot' },
    selectedMedia: [{ mediumId: 1, name: 'TV' }],
    version: null,
    readOnly: false,
  },
  {
    id: 21,
    projectUuid: 'f17d7170-8f7e-4429-8316-b98dfcf256eb',
    name: 'Automated Scenario 4 GT: 39942',
    status: 'DIMENSION_SETUP',
    year: '2023',
    evaluatedFrom: null,
    evaluatedTo: null,
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 31, name: 'Poland', code: 'PL', disabled: false },
    rfp: {
      rfpId: 1,
      rfpUuid: '2b252765-7221-44b0-b232-a8e6199811ff',
      name: 'Dummy RFP',
      client: { organizationId: 4, type: 'CLIENT', name: 'Stars_Client', disabled: false },
      userAccesses: [],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-10T12:14:10.918+00:00',
    modifiedDate: '2023-02-10T12:14:22.992+00:00',
    userAccesses: [],
    agencyUserAccesses: [],
    responsibleUser: { userId: 18, email: 'iRobot' },
    selectedMedia: [{ mediumId: 1, name: 'TV' }],
    version: null,
    readOnly: false,
  },
  {
    id: 23,
    projectUuid: 'cc514fbf-dc6e-4b09-bc59-fd03f1bfa0b3',
    name: 'filip11',
    status: 'GT_SETUP',
    year: '2023',
    evaluatedFrom: '2023-02-01T16:00:24.203+00:00',
    evaluatedTo: '2023-03-08T16:00:24.203+00:00',
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 31, name: 'Poland', code: 'PL', disabled: false },
    rfp: {
      rfpId: 1,
      rfpUuid: '2b252765-7221-44b0-b232-a8e6199811ff',
      name: 'Dummy RFP',
      client: { organizationId: 4, type: 'CLIENT', name: 'Stars_Client', disabled: false },
      userAccesses: [],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'f.bezpalko@webellian.com',
    modifiedBy: 'f.bezpalko@webellian.com',
    createdDate: '2023-02-10T12:39:38.598+00:00',
    modifiedDate: '2023-02-23T12:43:31.587+00:00',
    userAccesses: [{ userId: 197, email: 'f.bezpalko+s@webellian.com' }],
    agencyUserAccesses: [{ userId: 20, email: 'example.agency@webellian.com' }],
    responsibleUser: { userId: 15, email: 'f.bezpalko@webellian.com' },
    selectedMedia: [{ mediumId: 1, name: 'TV' }],
    version: null,
    readOnly: false,
  },
  {
    id: 24,
    projectUuid: '613034ee-d011-4ea4-ac89-7e56a19fcab9',
    name: 'Auto Project 148627',
    status: 'AT_VALIDATION_SUCCESS',
    year: '2023',
    evaluatedFrom: null,
    evaluatedTo: null,
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 47, name: 'Auto Market 148627', code: 'Code 148627', disabled: false },
    rfp: {
      rfpId: 1,
      rfpUuid: '2b252765-7221-44b0-b232-a8e6199811ff',
      name: 'Dummy RFP',
      client: { organizationId: 4, type: 'CLIENT', name: 'Stars_Client', disabled: false },
      userAccesses: [],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'iRobot',
    modifiedBy: 'o.wolska@webellian.com',
    createdDate: '2023-02-10T12:42:25.042+00:00',
    modifiedDate: '2023-02-15T11:34:02.962+00:00',
    userAccesses: [],
    agencyUserAccesses: [],
    responsibleUser: { userId: 18, email: 'iRobot' },
    selectedMedia: [
      { mediumId: 1, name: 'TV' },
      { mediumId: 12, name: 'Auto_Medium_148627' },
    ],
    version: null,
    readOnly: false,
  },
  {
    id: 25,
    projectUuid: '37ceb846-a2c9-4969-a762-4b1929df7fb9',
    name: 'Auto Project 283995',
    status: 'DIMENSION_SETUP',
    year: '2023',
    evaluatedFrom: null,
    evaluatedTo: null,
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 48, name: 'Auto Market 283995', code: 'Code 283995', disabled: false },
    rfp: {
      rfpId: 1,
      rfpUuid: '2b252765-7221-44b0-b232-a8e6199811ff',
      name: 'Dummy RFP',
      client: { organizationId: 4, type: 'CLIENT', name: 'Stars_Client', disabled: false },
      userAccesses: [],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-10T12:45:43.249+00:00',
    modifiedDate: '2023-02-10T12:45:48.804+00:00',
    userAccesses: [],
    agencyUserAccesses: [],
    responsibleUser: { userId: 18, email: 'iRobot' },
    selectedMedia: [
      { mediumId: 1, name: 'TV' },
      { mediumId: 13, name: 'Auto_Medium_283995' },
    ],
    version: null,
    readOnly: false,
  },
  {
    id: 26,
    projectUuid: 'f2d3801a-968d-4028-b766-85ddb480dbbd',
    name: 'Auto Project 233126',
    status: 'AT_SHARED_WITH_AGENCY',
    year: '2023',
    evaluatedFrom: null,
    evaluatedTo: null,
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 49, name: 'Auto Market 233126', code: 'Code 233126', disabled: false },
    rfp: {
      rfpId: 1,
      rfpUuid: '2b252765-7221-44b0-b232-a8e6199811ff',
      name: 'Dummy RFP',
      client: { organizationId: 4, type: 'CLIENT', name: 'Stars_Client', disabled: false },
      userAccesses: [],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-10T12:48:10.760+00:00',
    modifiedDate: '2023-02-10T12:48:21.929+00:00',
    userAccesses: [],
    agencyUserAccesses: [],
    responsibleUser: { userId: 18, email: 'iRobot' },
    selectedMedia: [
      { mediumId: 1, name: 'TV' },
      { mediumId: 14, name: 'Auto_Medium_233126' },
    ],
    version: null,
    readOnly: false,
  },
  {
    id: 27,
    projectUuid: 'cc859f8c-f13b-4ea0-96da-1f245ad0d861',
    name: 'ValueTrack_R2D2_Poland_TV_Digital_OOH_Print_Cinema_2023_20230210',
    status: 'DIMENSION_SETUP',
    year: '2023',
    evaluatedFrom: null,
    evaluatedTo: null,
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 31, name: 'Poland', code: 'PL', disabled: false },
    rfp: {
      rfpId: 24,
      rfpUuid: 'b7c4f206-ccc9-432e-b19b-1ccffe30c782',
      name: 'nowe',
      client: { organizationId: 6, type: 'CLIENT', name: 'R2D2', disabled: false },
      userAccesses: [{ userId: 15, email: 'f.bezpalko@webellian.com' }],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'f.bezpalko@webellian.com',
    modifiedBy: 'j.zakoscielna@webellian.com',
    createdDate: '2023-02-10T12:49:01.872+00:00',
    modifiedDate: '2023-02-21T11:38:04.182+00:00',
    userAccesses: [{ userId: 15, email: 'f.bezpalko@webellian.com' }],
    agencyUserAccesses: [],
    responsibleUser: { userId: 15, email: 'f.bezpalko@webellian.com' },
    selectedMedia: [
      { mediumId: 1, name: 'TV' },
      { mediumId: 2, name: 'Digital' },
      {
        mediumId: 5,
        name: 'OOH',
      },
      { mediumId: 6, name: 'Print' },
      { mediumId: 7, name: 'Cinema' },
    ],
    version: null,
    readOnly: false,
  },
  {
    id: 28,
    projectUuid: 'fcea16e4-5d7e-4260-88dd-506b09d46293',
    name: 'ValueTrack_R2D2_Croatia_TV_Digital_OOH_Print_Cinema_2023_20230210',
    status: 'KPI_SETUP',
    year: '2023',
    evaluatedFrom: null,
    evaluatedTo: null,
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 8, name: 'Croatia', code: 'CR', disabled: false },
    rfp: {
      rfpId: 24,
      rfpUuid: 'b7c4f206-ccc9-432e-b19b-1ccffe30c782',
      name: 'nowe',
      client: { organizationId: 6, type: 'CLIENT', name: 'R2D2', disabled: false },
      userAccesses: [{ userId: 15, email: 'f.bezpalko@webellian.com' }],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'f.bezpalko@webellian.com',
    modifiedBy: 'f.bezpalko@webellian.com',
    createdDate: '2023-02-10T12:49:01.931+00:00',
    modifiedDate: '2023-02-10T12:49:01.931+00:00',
    userAccesses: [{ userId: 15, email: 'f.bezpalko@webellian.com' }],
    agencyUserAccesses: [],
    responsibleUser: { userId: 15, email: 'f.bezpalko@webellian.com' },
    selectedMedia: [
      { mediumId: 1, name: 'TV' },
      { mediumId: 2, name: 'Digital' },
      {
        mediumId: 5,
        name: 'OOH',
      },
      { mediumId: 6, name: 'Print' },
      { mediumId: 7, name: 'Cinema' },
    ],
    version: null,
    readOnly: false,
  },
  {
    id: 29,
    projectUuid: '289f85c5-648a-4ac6-a6f6-831c063a0ab7',
    name: 'ValueTrack_R2D2_Romania_TV_Digital_OOH_Print_Cinema_2023_20230210',
    status: 'KPI_SETUP',
    year: '2023',
    evaluatedFrom: null,
    evaluatedTo: null,
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 33, name: 'Romania', code: 'RO', disabled: false },
    rfp: {
      rfpId: 24,
      rfpUuid: 'b7c4f206-ccc9-432e-b19b-1ccffe30c782',
      name: 'nowe',
      client: { organizationId: 6, type: 'CLIENT', name: 'R2D2', disabled: false },
      userAccesses: [{ userId: 15, email: 'f.bezpalko@webellian.com' }],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'f.bezpalko@webellian.com',
    modifiedBy: 'f.bezpalko@webellian.com',
    createdDate: '2023-02-10T12:49:01.953+00:00',
    modifiedDate: '2023-02-10T12:49:01.953+00:00',
    userAccesses: [{ userId: 15, email: 'f.bezpalko@webellian.com' }],
    agencyUserAccesses: [],
    responsibleUser: { userId: 15, email: 'f.bezpalko@webellian.com' },
    selectedMedia: [
      { mediumId: 1, name: 'TV' },
      { mediumId: 2, name: 'Digital' },
      {
        mediumId: 5,
        name: 'OOH',
      },
      { mediumId: 6, name: 'Print' },
      { mediumId: 7, name: 'Cinema' },
    ],
    version: null,
    readOnly: false,
  },
  {
    id: 30,
    projectUuid: '95f8d71b-1f92-4e46-bd7a-4a41bbda3968',
    name: 'ValueTrack_R2D2_France_TV_Digital_OOH_Print_Cinema_2023_20230210',
    status: 'KPI_SETUP',
    year: '2023',
    evaluatedFrom: '2023-02-22T09:17:12.240+00:00',
    evaluatedTo: '2023-03-24T09:17:12.240+00:00',
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 13, name: 'France', code: 'FR', disabled: false },
    rfp: {
      rfpId: 24,
      rfpUuid: 'b7c4f206-ccc9-432e-b19b-1ccffe30c782',
      name: 'nowe',
      client: { organizationId: 6, type: 'CLIENT', name: 'R2D2', disabled: false },
      userAccesses: [{ userId: 15, email: 'f.bezpalko@webellian.com' }],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'f.bezpalko@webellian.com',
    modifiedBy: 'test@webellian.com',
    createdDate: '2023-02-10T12:49:01.977+00:00',
    modifiedDate: '2023-02-20T09:27:13.373+00:00',
    userAccesses: [{ userId: 15, email: 'f.bezpalko@webellian.com' }],
    agencyUserAccesses: [{ userId: 31, email: 'f.bezpalko+agency@webellian.com' }],
    responsibleUser: { userId: 15, email: 'f.bezpalko@webellian.com' },
    selectedMedia: [
      { mediumId: 1, name: 'TV' },
      { mediumId: 2, name: 'Digital' },
      {
        mediumId: 5,
        name: 'OOH',
      },
      { mediumId: 6, name: 'Print' },
      { mediumId: 7, name: 'Cinema' },
    ],
    version: null,
    readOnly: false,
  },
  {
    id: 35,
    projectUuid: 'b99d2d9c-0c2f-40bc-b08c-ef71fa519ab1',
    name: 'ValueTrack_R2D2_Poland_TV_Digital_2024_20230210',
    status: 'KPI_SETUP',
    year: '2024',
    evaluatedFrom: null,
    evaluatedTo: null,
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 31, name: 'Poland', code: 'PL', disabled: false },
    rfp: {
      rfpId: 10,
      rfpUuid: 'a0ca1fff-98e8-44e2-a9c5-4ad65a0d4abe',
      name: 'Automated Scenario 5: 690066',
      client: { organizationId: 6, type: 'CLIENT', name: 'R2D2', disabled: false },
      userAccesses: [{ userId: 18, email: 'iRobot' }],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'iRobot',
    modifiedBy: 'm.gapys+stars@webellian.com',
    createdDate: '2023-02-10T13:03:15.719+00:00',
    modifiedDate: '2023-02-13T18:55:34.953+00:00',
    userAccesses: [{ userId: 18, email: 'iRobot' }],
    agencyUserAccesses: [],
    responsibleUser: { userId: 18, email: 'iRobot' },
    selectedMedia: [
      { mediumId: 1, name: 'TV' },
      { mediumId: 2, name: 'Digital' },
    ],
    version: null,
    readOnly: false,
  },
  {
    id: 36,
    projectUuid: '162cf14e-1e8a-43f3-b152-2145c54a4211',
    name: 'ValueTrack_R2D2_Romania_TV_Digital_2024_20230210',
    status: 'KPI_SETUP',
    year: '2024',
    evaluatedFrom: null,
    evaluatedTo: null,
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 33, name: 'Romania', code: 'RO', disabled: false },
    rfp: {
      rfpId: 10,
      rfpUuid: 'a0ca1fff-98e8-44e2-a9c5-4ad65a0d4abe',
      name: 'Automated Scenario 5: 690066',
      client: { organizationId: 6, type: 'CLIENT', name: 'R2D2', disabled: false },
      userAccesses: [{ userId: 18, email: 'iRobot' }],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'iRobot',
    modifiedBy: 'o.wolska@webellian.com',
    createdDate: '2023-02-10T13:03:15.793+00:00',
    modifiedDate: '2023-02-22T07:40:37.284+00:00',
    userAccesses: [{ userId: 18, email: 'iRobot' }],
    agencyUserAccesses: [],
    responsibleUser: { userId: 18, email: 'iRobot' },
    selectedMedia: [
      { mediumId: 1, name: 'TV' },
      { mediumId: 2, name: 'Digital' },
    ],
    version: null,
    readOnly: false,
  },
  {
    id: 38,
    projectUuid: '98aa80a9-496c-406f-88da-242e5f3de278',
    name: 'ValueTrack_R2D2_Poland_TV_Radio_2024_20230210',
    status: 'KPI_SETUP',
    year: '2024',
    evaluatedFrom: null,
    evaluatedTo: null,
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 31, name: 'Poland', code: 'PL', disabled: false },
    rfp: {
      rfpId: 14,
      rfpUuid: '13717eb2-4d03-44d2-9c00-ebb0f035f2e3',
      name: 'Automated Scenario 5: 201913',
      client: { organizationId: 6, type: 'CLIENT', name: 'R2D2', disabled: false },
      userAccesses: [{ userId: 18, email: 'iRobot' }],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'm.lanocha@webellian.com',
    modifiedBy: 'm.lanocha@webellian.com',
    createdDate: '2023-02-10T13:17:56.909+00:00',
    modifiedDate: '2023-02-10T13:17:56.909+00:00',
    userAccesses: [{ userId: 18, email: 'iRobot' }],
    agencyUserAccesses: [],
    responsibleUser: { userId: 16, email: 'm.lanocha@webellian.com' },
    selectedMedia: [
      { mediumId: 1, name: 'TV' },
      { mediumId: 3, name: 'Radio' },
    ],
    version: null,
    readOnly: false,
  },
  {
    id: 39,
    projectUuid: '03d3660c-9eb1-4ebc-8cd3-3156da468359',
    name: 'ValueTrack_R2D2_Romania_TV_Radio_2024_20230210',
    status: 'KPI_SETUP',
    year: '2024',
    evaluatedFrom: '2023-02-22T13:46:48.945+00:00',
    evaluatedTo: '2023-03-22T13:46:48.945+00:00',
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 33, name: 'Romania', code: 'RO', disabled: false },
    rfp: {
      rfpId: 14,
      rfpUuid: '13717eb2-4d03-44d2-9c00-ebb0f035f2e3',
      name: 'Automated Scenario 5: 201913',
      client: { organizationId: 6, type: 'CLIENT', name: 'R2D2', disabled: false },
      userAccesses: [{ userId: 18, email: 'iRobot' }],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'm.lanocha@webellian.com',
    modifiedBy: 'm.gapys@webellian.com',
    createdDate: '2023-02-10T13:17:56.978+00:00',
    modifiedDate: '2023-02-20T13:46:53.642+00:00',
    userAccesses: [{ userId: 18, email: 'iRobot' }],
    agencyUserAccesses: [{ userId: 31, email: 'f.bezpalko+agency@webellian.com' }],
    responsibleUser: { userId: 16, email: 'm.lanocha@webellian.com' },
    selectedMedia: [
      { mediumId: 1, name: 'TV' },
      { mediumId: 3, name: 'Radio' },
    ],
    version: null,
    readOnly: false,
  },
  {
    id: 47,
    projectUuid: '91728fdd-9477-4742-b485-dca9b246143f',
    name: 'ValueTrack_R2D2_Auto Org Client 702061_Poland_TV_Radio_2024_20230210',
    status: 'DIMENSION_SETUP',
    year: '2024',
    evaluatedFrom: null,
    evaluatedTo: null,
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 31, name: 'Poland', code: 'PL', disabled: false },
    rfp: {
      rfpId: 37,
      rfpUuid: '7942db86-61b8-408d-b0ce-01cf11f04e56',
      name: 'Automated Scenario 5: 702061',
      client: { organizationId: 53, type: 'CLIENT', name: 'Auto Org Client 702061', disabled: true },
      userAccesses: [{ userId: 18, email: 'iRobot' }],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'iRobot',
    modifiedBy: 'f.bezpalko@webellian.com',
    createdDate: '2023-02-10T14:39:23.722+00:00',
    modifiedDate: '2023-02-16T18:33:27.111+00:00',
    userAccesses: [{ userId: 18, email: 'iRobot' }],
    agencyUserAccesses: [],
    responsibleUser: { userId: 18, email: 'iRobot' },
    selectedMedia: [
      { mediumId: 1, name: 'TV' },
      { mediumId: 3, name: 'Radio' },
    ],
    version: null,
    readOnly: false,
  },
  {
    id: 48,
    projectUuid: 'bf8e786c-9223-4c43-b6af-55a42f74768b',
    name: 'ValueTrack_R2D2_Auto Org Client 702061_Romania_TV_Radio_2024_20230210',
    status: 'AT_SETUP',
    year: '2024',
    evaluatedFrom: null,
    evaluatedTo: null,
    disabled: false,
    organization: { organizationId: 1, type: 'STARS', name: 'STARS', disabled: false },
    market: { id: 33, name: 'Romania', code: 'RO', disabled: false },
    rfp: {
      rfpId: 37,
      rfpUuid: '7942db86-61b8-408d-b0ce-01cf11f04e56',
      name: 'Automated Scenario 5: 702061',
      client: { organizationId: 53, type: 'CLIENT', name: 'Auto Org Client 702061', disabled: true },
      userAccesses: [{ userId: 18, email: 'iRobot' }],
      finished: true,
      projectCreated: true,
      clientNotified: true,
      readOnly: false,
    },
    createdBy: 'iRobot',
    modifiedBy: 'j.zakoscielna@webellian.com',
    createdDate: '2023-02-10T14:39:23.747+00:00',
    modifiedDate: '2023-02-24T12:34:23.214+00:00',
    userAccesses: [{ userId: 18, email: 'iRobot' }],
    agencyUserAccesses: [],
    responsibleUser: { userId: 18, email: 'iRobot' },
    selectedMedia: [
      { mediumId: 1, name: 'TV' },
      { mediumId: 3, name: 'Radio' },
    ],
    version: null,
    readOnly: false,
  },
];

export const paginationMocks = {
  [ProjectListPaginationEnum.Pageable]: {},
  [ProjectListPaginationEnum.PageNumber]: 1,
  [ProjectListPaginationEnum.Number]: 1,
  [ProjectListPaginationEnum.PageSize]: 5,
  [ProjectListPaginationEnum.TotalElements]: 10,
  [ProjectListPaginationEnum.TotalPages]: 2,
  [ProjectListPaginationEnum.Direction]: 'ASC',
  [ProjectListPaginationEnum.SortProperty]: 'name',
  [ProjectListPaginationEnum.Content]: projectsMock,
};
