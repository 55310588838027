import { FormGroup } from '@angular/forms';
import { AuditData } from '@shared/interfaces/audit-data.interface';

export class ToolsService {
  static validForm(formGroup: FormGroup) {
    if (typeof formGroup?.controls !== 'object') {
      formGroup?.markAsDirty();
      formGroup?.updateValueAndValidity({ onlySelf: true });
    } else {
      Object.values(formGroup?.controls).forEach((control) => {
        if ((control as FormGroup)?.controls) {
          this.validForm(control as FormGroup);
        } else if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  static deleteAuditFields<T extends (Record<string, any> & Partial<AuditData>)[]>(data: T) {
    return data.map(({ createdBy, modifiedBy, createdDate, modifiedDate, ...rest }) => {
      for (let prop in rest) {
        if (Array.isArray(rest[prop])) {
          rest[prop] = ToolsService.deleteAuditFields(rest[prop]);
        }
      }
      return rest;
    });
  }
}
