import { Market, MarketEnum, MarketFilterEnum, MarketFilters } from '@shared/interfaces/market.interface';

export const filterMarketHelper = (markets: Market[], filters: MarketFilters): Market[] => {
  if (typeof filters[MarketFilterEnum.Disabled] === 'boolean') {
    markets = markets.filter((market: Market): boolean => filters[MarketFilterEnum.Disabled] === market[MarketEnum.Disabled]);
  }
  if (filters[MarketFilterEnum.SearchText]) {
    const searchText: string = filters[MarketFilterEnum.SearchText].toLowerCase();
    markets = markets.filter((market: Market) => market[MarketEnum.Name].toLowerCase().includes(searchText));
  }
  return markets;
};
