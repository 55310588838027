export enum CommonError {
  UnexpectedError = 'unexpectedError',
  AccessDenied = 'projectAccessDenied',
  ProjectNotFound = 'projectNotFound',
}

export enum CommonBackendError {
  ProjectNotFound = 'project_not_found',
  AccessDenied = 'access_denied',
}
