import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class RestApiService {
  constructor(private httpClient: HttpClient) {}

  get(path: string = '', params: {} = [], apiUrl: string = 'apiUrl'): Observable<any> {
    const url: string = apiUrl === 'apiUrl' ? environment.apiUrl : environment.workspaceUrl;
    return this.httpClient.get(`${url}/${path}`, params);
  }

  post(path: string = '', body: {} = [], options: {} = [], apiUrl: string = 'apiUrl'): Observable<any> {
    const url: string = apiUrl === 'apiUrl' ? environment.apiUrl : environment.workspaceUrl;
    return this.httpClient.post(`${url}/${path}`, body, options);
  }

  put(path: string = '', body: {} = [], apiUrl: string = 'apiUrl'): Observable<any> {
    const url: string = apiUrl === 'apiUrl' ? environment.apiUrl : environment.workspaceUrl;
    return this.httpClient.put(`${url}/${path}`, body);
  }

  delete(path: string = '', body: {} = [], apiUrl: string = 'apiUrl'): Observable<any> {
    const url: string = apiUrl === 'apiUrl' ? environment.apiUrl : environment.workspaceUrl;
    return this.httpClient.delete(`${url}/${path}`, body);
  }
}
