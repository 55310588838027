import { Market } from '@shared/interfaces/market.interface';

export enum MediumEnum {
  Id = 'id',
  Name = 'name',
  Markets = 'markets',
  Disabled = 'disabled',
  CreatedBy = 'createdBy',
  CreatedDate = 'createdDate',
  ModifiedBy = 'modifiedBy',
  ModifiedDate = 'modifiedDate',
}

export interface Medium extends MediumSave {
  [MediumEnum.Id]: number;
  // TODO mark as required
  [MediumEnum.Disabled]?: boolean;
  [MediumEnum.CreatedBy]?: string;
  [MediumEnum.CreatedDate]?: string;
  [MediumEnum.ModifiedBy]?: string;
  [MediumEnum.ModifiedDate]?: string;
}

export interface MediumSave {
  [MediumEnum.Name]: string;
  [MediumEnum.Markets]?: Market[];
}

//export type MediumName = MediumEnum.Name;

export interface MediaDictionary {
  [key: string]: Medium[];
}

export enum MediumFilterEnum {
  SearchText = 'searchText',
  Markets = 'markets',
  Disabled = 'disabled',
}

export interface MediumFilters {
  [MediumFilterEnum.SearchText]: string;
  [MediumFilterEnum.Markets]: Market;
  [MediumFilterEnum.Disabled]: boolean;
}

export interface MediumFiltersValues {
  [MediumFilterEnum.Markets]: Market[];
  [MediumFilterEnum.Disabled]: boolean[];
}
