import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from '@shared/services/spinner/spinner.service';
import { UserAuthEnum, UserObjectEnum } from '@auth/interfaces/user.interface';
import { UntilDestroy } from '@ngneat/until-destroy';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { NetworkService } from '@shared/services/network/network.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  isLoading = false;
  UserEnum = UserAuthEnum;
  UserObjectEnum = UserObjectEnum;

  onlineEvent!: Observable<Event>;
  offlineEvent!: Observable<Event>;
  subscriptions: Subscription[] = [];
  isOffline$ = this.networkService.isOffline$;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly networkService: NetworkService,
    private readonly spinnerService: SpinnerService,
    private readonly translate: TranslateService
  ) {
    translate.addLangs(['en']);
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngOnInit(): void {
    this.spinnerService.isLoading$.subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;
      this.changeDetector.detectChanges();
    });

    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.push(this.onlineEvent.subscribe((e) => this.networkService.change(false)));
    this.subscriptions.push(this.offlineEvent.subscribe((e) => this.networkService.change(true)));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
