import { Market } from '@shared/interfaces';
import { Dimension, DimensionEnum } from '../../../../../value-track/projects/project/modules/kpi/interfaces/dimension.interface';
import { EnumValue } from './enum-values.interface';
import { RelatedDimension } from './filtered-relations.interface';
import { SelectedMedia } from '@shared-project/modules/kpis/interfaces/selected-medium.interface';

export enum DsSetupEnum {
  Columns = 'columns',
  SplitAsSheet = 'splitAsSheet',
  Rows = 'rows',
  SplitBy = 'splitBy',
  SuggestedList = 'suggestedList',
  MultiDimensionalFilter = 'multiDimensionalFilter',
}

export enum DsSectionEnum {
  Id = 'id',
  Name = 'name',
  FilteredRelations = 'filteredRelations',
  EnumValues = 'branches',
  Type = 'type',
  FromProject = 'fromProject',
}

export enum ProjectDimensionEnum {
  Uuid = 'dimensionUuid',
}

export interface DimensionSetup {
  [DsSetupEnum.Columns]: DsSection[];
  [DsSetupEnum.Rows]: DsSection[];
  [DsSetupEnum.SplitBy]: DsSection[];
  [DsSetupEnum.SplitAsSheet]: DsSection[];
  [DsSetupEnum.MultiDimensionalFilter]: MultiDimensionalFilters | null;
}

export interface DsSectionsModel {
  [DsSetupEnum.Columns]: DsSection[];
  [DsSetupEnum.Rows]: DsSection[];
  [DsSetupEnum.SplitBy]: DsSection[];
  [DsSetupEnum.SplitAsSheet]: DsSection[];
}

export type DsSections = Exclude<DsSetupEnum, DsSetupEnum.SuggestedList | DsSetupEnum.MultiDimensionalFilter>;
export interface DsSection {
  [DsSectionEnum.Name]: string;
  [DsSectionEnum.FilteredRelations]?: RelatedDimension;
  [DsSectionEnum.EnumValues]: EnumValue[];
  [DsSectionEnum.Type]: Dimension[DimensionEnum.Type];
  [DsSectionEnum.FromProject]: boolean;
}
export interface MultiDimensionalFilters {
  [key: string]: string[];
}
export interface MultiDimensionalRows {
  [key: string]: string;
}

export enum DSTrackingItems {
  setup = 'setup',
}

export interface ProjectDimension {
  [ProjectDimensionEnum.Uuid]: string;
  [DimensionEnum.Branches]: EnumValue[];
  [DimensionEnum.Description]: Dimension[DimensionEnum.Description];
  [DimensionEnum.Name]: Dimension[DimensionEnum.Name];
  [DimensionEnum.ParentDimension]: Dimension[DimensionEnum.ParentDimension];
  [DimensionEnum.Type]: Dimension[DimensionEnum.Type];
}

export interface DimensionModalParams {
  market: Market | undefined;
  selectedMedia: SelectedMedia[];
  allDimensions: Dimension[];
  projectDim: ProjectDimension[];
  projectDimensions: (DsSection | ProjectDimension)[];
}

export type EditProjectDimension = ProjectDimension & { oldDimensionName?: string };
export type EditDimension = Dimension & { oldDimensionName: string };

export interface EditDimensions {
  dimension: ProjectDimension;
  sectionName: Exclude<DsSetupEnum, DsSetupEnum.MultiDimensionalFilter>;
  oldDimensionName?: string;
  dictDimension?: Dimension;
}

export interface HandleDimensionResult {
  dimension: ProjectDimension;
  fromProject: boolean;
  sectionName: Exclude<DsSetupEnum, DsSetupEnum.MultiDimensionalFilter>;
  oldDimensionName: string;
  dictDimension?: Dimension;
  sectionDimensions?: DsSection[];
  index?: number;
}
