import { Injectable } from '@angular/core';
import { RfpData, RfpSave, RfpTemplateSave } from '@shared/interfaces/rfp.interface';
import { RestApiService } from '@shared/services/rest-api/rest-api.service';
import { Observable } from 'rxjs';

import { Project } from '@shared/interfaces/project.interface';
import { RfpMatrix } from 'src/app/logged-in/modules/value-track/rfp';
import {
  CreatePitchingProjectFromRfpData,
  RfpPitchingMatrix,
} from '../../../logged-in/modules/value-track/rfp/create-project-from-rfp-pitching/create-project-from-rfp-pitching.interface';
import { CreateProjectFromRfpData } from '../../../logged-in/modules/value-track/rfp/shared/rfp.interfaces';

@Injectable({
  providedIn: 'root',
})
export class RfpService {
  constructor(private readonly restApiService: RestApiService) {}

  getRfp(id: number): Observable<RfpData> {
    return this.restApiService.get(`rfp/${id}`);
  }

  getRfps(): Observable<RfpData[]> {
    return this.restApiService.get(`rfp/`);
  }

  getRfpTemplates(): Observable<RfpTemplateSave[]> {
    return this.restApiService.get(`rfp/template`);
  }

  createRfp(payload: RfpSave): Observable<RfpData> {
    return this.restApiService.post(`rfp/`, payload);
  }

  createRfpTemplate(payload: RfpTemplateSave): Observable<RfpTemplateSave> {
    return this.restApiService.post('rfp/template', payload);
  }

  editRfp(id: number, payload: RfpSave): Observable<RfpData> {
    return this.restApiService.put(`rfp/${id}`, payload);
  }

  editRfpTemplate(id: number, payload: RfpSave): Observable<RfpTemplateSave> {
    return this.restApiService.put(`rfp/template/${id}`, payload);
  }

  notifyClients(id: number): Observable<RfpTemplateSave> {
    return this.restApiService.post(`rfp/${id}/clients/notify`);
  }

  createProjectsFromRfp(
    id: number,
    payload: CreateProjectFromRfpData[] | CreatePitchingProjectFromRfpData[]
  ): Observable<Project[]> {
    return this.restApiService.post(`rfp/${id}/generateProjects`, payload);
  }

  getRfpMatrixData(rfpId: number): Observable<RfpMatrix> {
    return this.restApiService.get(`organization/matrix/${rfpId}`);
  }

  getPitchingRfpMatrixData(rfpId: number): Observable<RfpPitchingMatrix> {
    return this.restApiService.get(`organization/market/matrix/${rfpId}`);
  }
}
