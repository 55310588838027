import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-expandable-tags',
  templateUrl: './expandable-tags.component.html',
  styleUrls: ['./expandable-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandableTagsComponent {
  @Input() tags: string[] = [];
  @Input() color: string = 'red';
  @Input() limit: number = 5;

  constructor() {}

  showMore(event: Event): void {
    event.preventDefault();
    this.limit = this.tags.length;
  }
}
