<form
  class="section-container bg-white"
  id="kpi-filter-container"
  [formGroup]="kpiFilterForm"
  (ngSubmit)="filter()"
  nz-row
  nzAlign="middle"
  nzJustify="space-between"
>
  <div nz-col [nzSpan]="18" class="pl-2">
    <div nz-row [nzGutter]="[4, 0]" *ngIf="filterValuesData">
      <ng-container *ngIf="selectsData$ | async as selects">
        <div nz-col>
          <nz-select
            class="filter-select"
            nzShowSearch
            nzAllowClear
            (ngModelChange)="filter()"
            [formControlName]="KpiFilterEnum.Market"
            [id]="KpiFilterEnum.Market"
            [nzPlaceHolder]="'LOGGED_IN.FILTERS.bar.markets' | translate"
          >
            <nz-option
              *ngFor="let markets of selects[KpiSelectsEnum.Market]"
              [nzValue]="markets"
              [nzLabel]="markets[MarketEnum.Name]"
            ></nz-option>
          </nz-select>
        </div>

        <div nz-col>
          <nz-select
            class="filter-select"
            nzShowSearch
            nzAllowClear
            (ngModelChange)="filter()"
            [formControlName]="KpiFilterEnum.Media"
            [id]="KpiFilterEnum.Media"
            [nzPlaceHolder]="'LOGGED_IN.FILTERS.bar.media' | translate"
          >
            <nz-option
              *ngFor="let market of selects[KpiSelectsEnum.Media]"
              [nzValue]="market"
              [nzLabel]="market[MediumEnum.Name]"
            >
            </nz-option>
          </nz-select>
        </div>
      </ng-container>

      <div nz-col>
        <nz-select
          class="filter-select"
          nzAllowClear
          (ngModelChange)="filter()"
          [formControlName]="KpiFilterEnum.Formats"
          [id]="KpiFilterEnum.Formats"
          [nzPlaceHolder]="'LOGGED_IN.FILTERS.bar.format' | translate"
        >
          <nz-option
            *ngFor="let formats of filterValuesData[KpiFilterEnum.Formats]"
            [nzValue]="formats"
            [nzLabel]="formats"
          ></nz-option>
        </nz-select>
      </div>
      <div nz-col *ngIf="!kpiWaiting">
        <nz-select
          class="filter-select"
          nzAllowClear
          [id]="KpiFilterEnum.Disabled"
          (ngModelChange)="filter()"
          [formControlName]="KpiFilterEnum.Disabled"
          [nzPlaceHolder]="'LOGGED_IN.FILTERS.bar.status' | translate"
        >
          <nz-option [nzLabel]="'LOGGED_IN.FILTERS.bar.active' | translate" [nzValue]="false"></nz-option>
          <nz-option [nzLabel]="'LOGGED_IN.FILTERS.bar.disabled' | translate" [nzValue]="true"></nz-option>
        </nz-select>
      </div>

      <div class="nz-col ml-5">
        <nz-input-group nzSearch [nzAddOnAfter]="suffixSearchButton">
          <input
            class="search-input"
            [formControlName]="KpiFilterEnum.SearchText"
            [id]="KpiFilterEnum.SearchText"
            nz-input
            [disabled]="true"
            [placeholder]="'LOGGED_IN.FILTERS.bar.search' | translate"
            [maxLength]="ApplicationConf[ApplicationConfEnum.DefaultInputLength]"
          />
        </nz-input-group>
        <ng-template #suffixSearchButton>
          <button nz-button nzSearch id="kpi-filter-button-search" (click)="search($event)">
            <i nz-icon nzType="search"></i>
          </button>
        </ng-template>
      </div>
    </div>
  </div>
  <div nz-col class="pr-2">
    <button nz-button nzType="default" type="button" id="kpi-filter-button-clear" (click)="clear($event)">
      <i nz-icon nzType="clear" nzTheme="outline"></i>
      {{ "LOGGED_IN.FILTERS.bar.clearFilters" | translate }}
    </button>
  </div>
</form>
