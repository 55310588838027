import { Component, Input } from '@angular/core';
import { TooltipPlacement, TooltipTriggerMode } from './tooltip-wraper.interface';

@Component({
  selector: 'app-tooltip-wraper',
  templateUrl: './tooltip-wraper.component.html',
  styleUrls: ['./tooltip-wraper.component.scss'],
})
export class TooltipWraperComponent {
  @Input() text!: string;
  @Input() disabled = false;
  @Input() trigger = TooltipTriggerMode.Hover;
  @Input() placement = TooltipPlacement.Top;
  constructor() {}
}
