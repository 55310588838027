import { ModalConfirmationComponent } from '@shared/components/modal-confirmation/modal-confirmation.component';
import { LAYOUT_MIN_WIDTH, SMALL_MODAL_WIDTH } from '@shared/consts/consts';
import { ClientAddComponent } from './client-add/client-add.component';
import { ClientDeleteComponent } from './client-delete/client-delete.component';

export const CreateClientModalConf = {
  nzWidth: LAYOUT_MIN_WIDTH,
  nzFooter: null,
  nzCentered: true,
  nzContent: ClientAddComponent,
};

/*export const DisableClientModalConf = {
  nzWidth: SMALL_MODAL_WIDTH,
  nzCentered: true,
  nzContent: ClientDeleteComponent,
};*/

export const DisableClientModalConf = {
  nzWidth: SMALL_MODAL_WIDTH,
  nzCentered: true,
  nzContent: ModalConfirmationComponent,
  nzFooter: null,
  nzComponentParams: {
    type: 'disableClient',
  },
};

export const EnableClientModalConf = {
  nzWidth: SMALL_MODAL_WIDTH,
  nzCentered: true,
  nzContent: ModalConfirmationComponent,
  nzFooter: null,
  nzComponentParams: {
    type: 'enableClient',
  },
};
