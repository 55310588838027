<div *nzModalTitle>
  <div nz-row nzJustify="center">
    <h3>
      <i nz-icon nzType="delete" class="icon"></i>
      {{ "CLIENT.DISABLE.title" | translate }}
      {{ name }}
    </h3>
  </div>
</div>

{{ "CLIENT.DISABLE.content" | translate }}

<div *nzModalFooter>
  <button nz-button nzType="default" type="button" id="client-delete-cancel" (click)="cancel()">
    <i nz-icon nzType="close" nzTheme="outline"></i>
    {{ "COMMON.cancel" | translate }}
  </button>
  <button nz-button nzDanger nzType="primary" id="client-delete-disable" (click)="disableClient()">
    <i nz-icon nzType="check" nzTheme="outline"></i>
    {{ "COMMON.disable" | translate }}
  </button>
</div>
