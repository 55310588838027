import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { NgzorroModule } from '../ngzorro/ngzorro.module';
import { TranslateModule } from '@ngx-translate/core';
import { ContentHeaderComponent } from '@shared/components';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotificationComponent } from '@shared/components';
import { SortPipe } from './pipes/filter.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { DeveloperComponent } from '@shared/components';
import { MarketFacade } from '@shared/services/markets/market.facade';
import { ModalConfirmationComponent } from '@shared/components';
import { RemoveUnderscorePipe } from './pipes/removeUnderscore.pipe';
import { MediaAdminFacade } from '@shared/services/medium/medium.facade';
import { TooltipWraperComponent } from '@shared/components';
import { ErrorModalComponent } from '@shared/components';
import { TrimInputValueDirective } from './directives/trim-input-values.directive';
import { UnderConstructionComponent } from '@shared/components';
import { OrganizationTypePipe } from '@shared/pipes/organization-type.pipe';
import { ClickOutsideDirective } from './directives/click-outside.directive';

import { TruncatePipe } from './pipes/truncate.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { AsPipe } from '@shared/pipes/as.pipe';
import { OrganizationByMarketPipe } from '@shared/pipes/organization-by-market.pipe';
import { AsFormArrayPipe } from '@shared/pipes/asFormGroup.pipe';
import { BranchesFormArrayComponent } from '@shared/components/branches-form-array/branches-form-array.component';
import { EngineSpecialCharactersDirective } from './directives/engine-special-charackets.directive';
import { ElementInactiveDirective } from './directives/element-inactive.directive';
import { ExpandableTagsComponent } from '@shared/components/expandable-tags/expandable-tags.component';
import { ToStringArraysPipe } from '@shared/pipes/to-string-arrays.pipe';
import { NotAllowedByRoleDirective } from './directives/allowed-by-role.directive';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { TableRowIndexComponent } from '@shared/components';
import { SafePipe } from '@shared/pipes/safe.pipe';
import { InputBooleanComponent } from './components/input-boolean/input-boolean.component';
import { CheckCloseIconComponent } from './components/check-close-icon/check-close-icon.component';
import { ClientRfpOpenQuestionComponent } from '@shared/components/client-rfp-open-question/client-rfp-open-question.component';
import { ModalAddUsersComponent } from '@shared/components/modal-add-users/modal-add-users.component';
import { PreventEnterDirective } from './directives/prevent-enter.directive';
import { KpiFilterComponent } from '../logged-in/modules/admin/kpi/shared/kpi-filter/kpi-filter.component';
import { PercentagePipe } from './pipes/percentage.pipe';

@NgModule({
  declarations: [
    ClickOutsideDirective,
    ContentHeaderComponent,
    NotificationComponent,
    TruncatePipe,
    SortPipe,
    OrderByPipe,
    PercentagePipe,
    NotAllowedByRoleDirective,
    OrganizationByMarketPipe,
    AsPipe,
    AsFormArrayPipe,
    SearchPipe,
    SafePipe,
    RemoveUnderscorePipe,
    OrganizationTypePipe,
    DeveloperComponent,
    ModalConfirmationComponent,
    TooltipWraperComponent,
    ErrorModalComponent,
    TrimInputValueDirective,
    TimeAgoPipe,
    UnderConstructionComponent,
    BranchesFormArrayComponent,
    EngineSpecialCharactersDirective,
    ElementInactiveDirective,
    KpiFilterComponent,
    ExpandableTagsComponent,
    ToStringArraysPipe,
    TableRowIndexComponent,
    InputBooleanComponent,
    ModalAddUsersComponent,
    CheckCloseIconComponent,
    ClientRfpOpenQuestionComponent,
    PreventEnterDirective,
  ],
  imports: [CommonModule, NgzorroModule, ReactiveFormsModule, SharedRoutingModule, TranslateModule, FormsModule, DragDropModule],
  exports: [
    NgzorroModule,
    DragDropModule,
    TranslateModule,
    ContentHeaderComponent,
    NotAllowedByRoleDirective,
    ClickOutsideDirective,
    OrderByPipe,
    OrganizationByMarketPipe,
    AsPipe,
    TimeAgoPipe,
    PercentagePipe,
    AsFormArrayPipe,
    TooltipWraperComponent,
    TruncatePipe,
    TrimInputValueDirective,
    NotificationComponent,
    SafePipe,
    SortPipe,
    RemoveUnderscorePipe,
    OrganizationTypePipe,
    SearchPipe,
    KpiFilterComponent,
    BranchesFormArrayComponent,
    EngineSpecialCharactersDirective,
    ElementInactiveDirective,
    ExpandableTagsComponent,
    ToStringArraysPipe,
    TableRowIndexComponent,
    InputBooleanComponent,
    CheckCloseIconComponent,
    ClientRfpOpenQuestionComponent,
    ModalAddUsersComponent,
    PreventEnterDirective,
  ],
  providers: [MarketFacade, MediaAdminFacade],
})
export class SharedModule {}
