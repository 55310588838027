import {
  CalculationsTypes,
  SlidingScaleType,
} from '../logged-in/modules/value-track/projects/project/modules/agency/components/table/interfaces/calculations-raw.interface';
import { DataFormats } from '@shared/interfaces';
import { AllProjectStatuses, FileExtension } from '@shared/interfaces';
import { GtColor } from '@shared-project/modules/guarantee/interface/gt-colors.interface';
import { ColumnType } from '../logged-in/modules/value-track/projects/project/modules/agency/components/table/interfaces/columns.interface';
import { Color } from '../logged-in/modules/value-track/projects/project/modules/agency/components/table/interfaces/configuration.interface';

export const dataTypeFormats = [
  {
    label: DataFormats.Text,
    example: 'abc',
  },
  {
    label: DataFormats.Boolean,
    example: 'true/false',
  },
  {
    label: DataFormats.Date,
    example: 'YYYY-MM-DD',
  },
  {
    label: DataFormats.Time,
    example: 'H:M:S',
  },
  {
    label: DataFormats.Money,
    example: '120$',
  },
  {
    label: DataFormats.Index,
    example: '0',
  },
  {
    label: DataFormats.Numeric,
    example: '12.44',
  },
  {
    label: DataFormats.Integer,
    example: '17',
  },
  {
    label: DataFormats.Percentage,
    example: '%',
  },
];

export const config = {
  atColors: {
    [Color.Black]: '000000',
    [Color.Gray]: 'd8d8d8',
    [Color.Blue]: '1f497d',
    [Color.White]: 'FFFFFF',
    [Color.Viola]: 'bf819e',
    [Color.Orange]: 'f79646',
    [Color.Purple]: '8064a2',
  },
  gtColors: {
    [GtColor.Black]: '000000',
    [GtColor.Gray]: 'd8d8d8',
    [GtColor.Gray2]: 'b2b2b2',
    [GtColor.LightGray]: 'f2f2f2',
    [GtColor.DarkGray]: '808080',
    [GtColor.DarkGray2]: '909090',
    [GtColor.Blue]: '1f497d',
    [GtColor.White]: 'FFFFFF',
    [GtColor.Viola]: 'bf819e',
    [GtColor.Orange]: 'f79646',
    [GtColor.Purple]: '8064a2',
  },
  projectAllowedFormatsToUpload: [
    {
      file: 'image',
      types: ['.png', '.jpeg', '.jpg'],
    },
    {
      file: 'excel',
      types: ['.xlsx'],
    },
    {
      file: 'powerpoint',
      types: ['.pptx', '.ppt'],
    },
  ],
  fileExtensions: {
    [FileExtension.PNG]: 'image/png',
    [FileExtension.JPEG]: 'image/jpeg',
    [FileExtension.XLSX]: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    [FileExtension.PPTX]: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    [FileExtension.PPT]: 'application/vnd.ms-powerpoint',
  },
  kpiSetupStagesOrder: [AllProjectStatuses.KPI_SETUP, AllProjectStatuses.AT_SETUP] as const,
  formats: {
    dataTypes: dataTypeFormats,
  },
  agency: {
    validators: {
      rangesMaxLength: 4,
    },
    calculations: [
      { value: CalculationsTypes.Autopopulated, label: 'Autopopulated' },
      { value: CalculationsTypes.Formula, label: 'Formula' },
      { value: CalculationsTypes.LookupTable, label: 'Lookup Table' },
      { value: CalculationsTypes.SlidingScale, label: 'Sliding Scale' },
      { value: CalculationsTypes.Vlookup, label: 'Vlookup' },
    ],
    columnTypes: [ColumnType.AgencyData, ColumnType.UsersData, ColumnType.Calculations],
    slidingScaleTypes: [
      { value: SlidingScaleType.Linear, label: 'Linear' },
      { value: SlidingScaleType.Lower, label: 'Lower' },
      { value: SlidingScaleType.Higher, label: 'Higher' },
    ],
  },

  guarantee: {
    kpiGroup: {
      paddingValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      truncate: 16,
    },
    sheetDetails: {
      signature: {
        box: 'hereby I confirm above information',
        header: `date, signature of person(s) who represent the Agency and Company's stamp`,
      },
    },
  },
};
