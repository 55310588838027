export const rfpsListMock = [{
  'rfpId': 1,
  'rfpUuid': '2b252765-7221-44b0-b232-a8e6199811ff',
  'name': 'Dummy RFP',
  'client': {'organizationId': 4, 'type': 'CLIENT', 'name': 'Stars_Client', 'disabled': false},
  'userAccesses': [],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 2,
  'rfpUuid': '874bdada-5e85-4193-af01-800483c092b0',
  'name': 'Automated Scenario 5: 32518',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 3,
  'rfpUuid': '4133c097-85d2-4fdc-863a-87bcb24f2036',
  'name': 'Automated Scenario 5: 7410',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 4,
  'rfpUuid': '92987ddb-b3a8-4579-99a4-7bdb153a7634',
  'name': 'Automated Scenario 5: 804129',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 5,
  'rfpUuid': '8147f536-5796-4b21-8ae2-71662fb92af9',
  'name': 'Automated Scenario 5: 600423',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 6,
  'rfpUuid': 'ae32daa5-6c0c-4a7c-8f3b-7168a6079428',
  'name': 'Automated Scenario 5: 843066',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 7,
  'rfpUuid': '29571d28-a20a-47eb-adc5-7037e15145a1',
  'name': 'Automated Scenario 5: 482771',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 8,
  'rfpUuid': '6ceb5676-796a-4443-8ce4-363dba339cc2',
  'name': 'Automated Scenario 5: 552294',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 9,
  'rfpUuid': '1a3f948a-3c04-4270-9271-4df83442f6a0',
  'name': 'Automated Scenario 5: 274413',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 10,
  'rfpUuid': 'a0ca1fff-98e8-44e2-a9c5-4ad65a0d4abe',
  'name': 'Automated Scenario 5: 690066',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 11,
  'rfpUuid': 'daa84813-b7d8-466b-be0c-02ee4d735a95',
  'name': 'Automated Scenario 5: 488413',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 12,
  'rfpUuid': '3c46dc1a-80fc-4582-9b35-20ebed49e7f1',
  'name': 'Automated Scenario 5: 403930',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 13,
  'rfpUuid': '4387a176-eb79-4d5c-b5fd-5cdf11b6fb77',
  'name': 'Automated Scenario 5: 53501',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 14,
  'rfpUuid': '13717eb2-4d03-44d2-9c00-ebb0f035f2e3',
  'name': 'Automated Scenario 5: 201913',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 15,
  'rfpUuid': 'c2a15012-6652-4d7c-986c-baa80531c832',
  'name': 'Automated Scenario 5: 784919',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 16,
  'rfpUuid': '51771699-0f15-4b0b-bd6a-c2d4bd3e7a5d',
  'name': 'Automated Scenario 5: 912814',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 17,
  'rfpUuid': '3be529ca-0431-45e8-aa9f-609d5e9c1fee',
  'name': 'Automated Scenario 5: 864088',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 18,
  'rfpUuid': '562e1c5c-77df-4e44-9f5e-3c2f059d726c',
  'name': 'Automated Scenario 5: 981132',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 19,
  'rfpUuid': '748781c3-00e9-4323-b7b0-f0f558e44d0c',
  'name': 'Automated Scenario 5: 553095',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 20,
  'rfpUuid': 'fa80994b-68a6-400b-a105-92994690c7a3',
  'name': 'Automated Scenario 5: 282611',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 21,
  'rfpUuid': '46a669bc-a3dc-4422-98ed-bd7755feceeb',
  'name': 'Automated Scenario 5: 234872',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 22,
  'rfpUuid': '0fd97d4b-08b7-4536-9d80-984a7bbb5e92',
  'name': 'Automated Scenario 5: 990664',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 23,
  'rfpUuid': '27cd5005-2a25-4412-8217-6da2e41364ce',
  'name': 'nwoe',
  'client': {'organizationId': 4, 'type': 'CLIENT', 'name': 'Stars_Client', 'disabled': false},
  'userAccesses': [{'userId': 15, 'email': 'f.bezpalko@webellian.com'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 24,
  'rfpUuid': 'b7c4f206-ccc9-432e-b19b-1ccffe30c782',
  'name': 'nowe',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 15, 'email': 'f.bezpalko@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 25,
  'rfpUuid': '62d5ece8-ce3c-41b8-8c6d-7e60c2d680af',
  'name': 'Automated Scenario 5: 463316',
  'client': {'organizationId': 16, 'type': 'CLIENT', 'name': 'Auto Org Client 463316', 'disabled': true},
  'userAccesses': [],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 26,
  'rfpUuid': '07e722da-26b3-49a3-96d6-fa066cd1d64f',
  'name': 'Automated Scenario 5: 167',
  'client': {'organizationId': 18, 'type': 'CLIENT', 'name': 'Auto Org Client 167', 'disabled': true},
  'userAccesses': [],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 37,
  'rfpUuid': '7942db86-61b8-408d-b0ce-01cf11f04e56',
  'name': 'Automated Scenario 5: 702061',
  'client': {'organizationId': 53, 'type': 'CLIENT', 'name': 'Auto Org Client 702061', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 38,
  'rfpUuid': 'fd5c8028-79de-4344-9366-fc2503b0ef2c',
  'name': 'Automated Scenario 5: 972286',
  'client': {'organizationId': 56, 'type': 'CLIENT', 'name': 'Auto Org Client 972286', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 39,
  'rfpUuid': 'e1d647ad-47ea-4b73-83d4-dc4d0c7bb1c7',
  'name': 'Automated Scenario 5: 99896',
  'client': {'organizationId': 59, 'type': 'CLIENT', 'name': 'Auto Org Client 99896', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 40,
  'rfpUuid': '0e9cfc96-8bdf-42e9-b9b4-2afbb001a8de',
  'name': 'Automated Scenario 5: 400535',
  'client': {'organizationId': 62, 'type': 'CLIENT', 'name': 'Auto Org Client 400535', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 41,
  'rfpUuid': '2717069f-f454-4111-b1b5-69a3f6c8b052',
  'name': 'Automated Scenario 5: 499298',
  'client': {'organizationId': 65, 'type': 'CLIENT', 'name': 'Auto Org Client 499298', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 42,
  'rfpUuid': '2fa3f6e3-2b43-47c7-a687-32e129fa9ba2',
  'name': 'Automated Scenario 5: 744988',
  'client': {'organizationId': 68, 'type': 'CLIENT', 'name': 'Auto Org Client 744988', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 43,
  'rfpUuid': '5e021990-2bcb-4ec7-8620-2405bd723adb',
  'name': 'Automated Scenario 5: 313543',
  'client': {'organizationId': 72, 'type': 'CLIENT', 'name': 'Auto Org Client 313543', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 44,
  'rfpUuid': '84fd3bc4-57b0-4133-9de4-3be947788481',
  'name': 'RFP Scenario 1: 637474',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 45,
  'rfpUuid': '32dd24d3-660f-4a41-af10-8a6aaea85b17',
  'name': 'RFP Scenario 1: 357767',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 46,
  'rfpUuid': '9338a098-219f-4a4c-8401-4af4544c5309',
  'name': 'RFP Scenario 1: 102537',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 47,
  'rfpUuid': '747a5552-9b50-408a-bcbf-dbf71e82dd87',
  'name': 'RFP Scenario 1: 285840',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 48,
  'rfpUuid': '7eafc502-d8d1-4483-ac12-69a33b29f16d',
  'name': 'RFP Scenario 1: 12544',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 49,
  'rfpUuid': 'c2b0c0e4-4228-4d5b-a836-644921d04cd2',
  'name': 'RFP Scenario 1: 914581',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 50,
  'rfpUuid': 'c687ad06-465f-4ec9-acb4-03837eadd9c5',
  'name': 'RFP Scenario 1: 64656',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 51,
  'rfpUuid': '017a6c82-092f-4dad-a22b-aadad80d9367',
  'name': 'RFP Scenario 1: 108789',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 52,
  'rfpUuid': '60401a0c-693b-4bfd-b7ff-d92a45afa0ef',
  'name': 'RFP Scenario 1: 917301',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 53,
  'rfpUuid': '6d80100e-5f86-4a68-a162-77c9f0e4739a',
  'name': 'RFP Scenario 1: 883153',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 54,
  'rfpUuid': '03ea6623-6505-492d-a5d1-5182b2c3437a',
  'name': 'RFP Scenario 1: 94291',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 55,
  'rfpUuid': '19e07d8d-1719-4746-93be-3247e44e40a7',
  'name': 'RFP Scenario 1: 509155',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 56,
  'rfpUuid': '809b6807-2996-4f47-afe6-a99d8cb69ae8',
  'name': 'RFP Scenario 1: 847835',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 57,
  'rfpUuid': 'c3d2d3ee-fcc0-4ae6-8d16-ddcd5cbc2083',
  'name': 'RFP Scenario 1: 240742',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 58,
  'rfpUuid': '0bac21ff-08ed-460e-977a-f2a0217be246',
  'name': 'RFP Scenario 1: 820504',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 59,
  'rfpUuid': 'd3033adc-571e-4d60-8af4-15d508056e4b',
  'name': 'RFP Scenario 1: 479971',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 60,
  'rfpUuid': '483d16fa-fce4-45f2-acfe-69ab09579edf',
  'name': 'RFP Scenario 1: 908913',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 61,
  'rfpUuid': '199b6263-760e-4232-9401-06b648b89758',
  'name': 'RFP Scenario 1: 561271',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 62,
  'rfpUuid': '53eee5e0-84d6-4f99-bca9-a0b007cf5503',
  'name': 'RFP Scenario 1: 461029',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 63,
  'rfpUuid': '7ac593ed-3278-4def-bb5c-48506b0d96d6',
  'name': 'RFP Scenario 1: 778081',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 64,
  'rfpUuid': '5fb73d2f-dd4d-47b0-9fbf-6bdfd15b4a47',
  'name': 'RFP Scenario 1: 679220',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 65,
  'rfpUuid': 'c83a8a1e-80b7-4b7c-a399-4ec46461061c',
  'name': 'RFP Scenario 1: 277014',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 66,
  'rfpUuid': 'b5df8f93-10e1-416c-9809-7a6a223fee27',
  'name': 'RFP Scenario 1: 791754',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 67,
  'rfpUuid': '57ee8eff-f300-4baa-b4da-54f4c1bfbfcd',
  'name': 'RFP Scenario 1: 289731',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 68,
  'rfpUuid': '195aa83e-e64b-47b1-a80d-d9c490947c38',
  'name': 'RFP Scenario 1: 582409',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 69,
  'rfpUuid': '4ce9bab8-6ac1-4402-8b49-0b493ae12aa5',
  'name': 'RFP Scenario 1: 400134',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 70,
  'rfpUuid': '51b4e81c-421d-4f7e-9376-5b1f793c6602',
  'name': 'RFP Scenario 1: 840475',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 71,
  'rfpUuid': 'd4b5d1cf-f45b-4a7e-91e7-74b3d80307a0',
  'name': 'RFP Scenario 1: 619066',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 72,
  'rfpUuid': '58292980-4644-4430-8f39-bd6bd818a31a',
  'name': 'RFP Scenario 1: 506033',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 73,
  'rfpUuid': '9d21f230-5e28-4253-8551-edff0bb1835b',
  'name': 'RFP Scenario 1: 45599',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 74,
  'rfpUuid': '26d51a47-dcb7-404c-8ba5-54d13f5e05a0',
  'name': 'RFP Scenario 1: 963247',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 75,
  'rfpUuid': '67f0b9ac-873d-4143-8bf6-f0eed1216b2d',
  'name': 'RFP Scenario 1: 790008',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 76,
  'rfpUuid': '80c4c0bb-23c1-4a9e-97e5-1ccd12e991eb',
  'name': 'RFP Scenario 1: 118050',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 77,
  'rfpUuid': '6fb100cb-e5e9-4097-b7eb-23d1aacd61d6',
  'name': 'RFP Scenario 1: 322957',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 78,
  'rfpUuid': 'e5f2fa24-ed6e-4b90-8d9f-9237575fa70c',
  'name': 'test',
  'client': {'organizationId': 4, 'type': 'CLIENT', 'name': 'Stars_Client', 'disabled': false},
  'userAccesses': [{'userId': 15, 'email': 'f.bezpalko@webellian.com'}, {
    'userId': 26,
    'email': 'f.bezpalko+staruser@webellian.com'
  }],
  'projectCreated': false,
  'finished': false,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 79,
  'rfpUuid': '5690c8e5-cc8f-400d-b442-61be59c0180b',
  'name': 'aaaa',
  'client': {'organizationId': 79, 'type': 'CLIENT', 'name': 'filipCLIENT', 'disabled': true},
  'userAccesses': [{'userId': 15, 'email': 'f.bezpalko@webellian.com'}, {
    'userId': 26,
    'email': 'f.bezpalko+staruser@webellian.com'
  }],
  'projectCreated': false,
  'finished': false,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 80,
  'rfpUuid': 'a21393cf-fdfb-44a0-a27d-f07378a51e37',
  'name': 'fdfds',
  'client': {'organizationId': 79, 'type': 'CLIENT', 'name': 'filipCLIENT', 'disabled': true},
  'userAccesses': [{'userId': 15, 'email': 'f.bezpalko@webellian.com'}, {
    'userId': 26,
    'email': 'f.bezpalko+staruser@webellian.com'
  }],
  'projectCreated': false,
  'finished': false,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 81,
  'rfpUuid': 'cd603c55-3ed0-48da-a097-46e18f3116f6',
  'name': 'ads',
  'client': {'organizationId': 79, 'type': 'CLIENT', 'name': 'filipCLIENT', 'disabled': true},
  'userAccesses': [{'userId': 15, 'email': 'f.bezpalko@webellian.com'}, {
    'userId': 26,
    'email': 'f.bezpalko+staruser@webellian.com'
  }],
  'projectCreated': false,
  'finished': false,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 82,
  'rfpUuid': 'c1cf9d07-5657-49f3-8921-fcb69978b09e',
  'name': 'asdasdas',
  'client': {'organizationId': 79, 'type': 'CLIENT', 'name': 'filipCLIENT', 'disabled': true},
  'userAccesses': [{'userId': 15, 'email': 'f.bezpalko@webellian.com'}, {
    'userId': 26,
    'email': 'f.bezpalko+staruser@webellian.com'
  }],
  'projectCreated': false,
  'finished': false,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 83,
  'rfpUuid': '1778288e-151c-4f2b-8612-bd666eabb196',
  'name': 'Automated Scenario 5: 927199',
  'client': {'organizationId': 87, 'type': 'CLIENT', 'name': 'Auto Org Client 927199', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 84,
  'rfpUuid': 'ae795af6-1504-4524-a87d-282787e4fb04',
  'name': 'RFP Scenario 1: 554984',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 85,
  'rfpUuid': 'b782a7ab-fe1e-461c-af3d-84ba7d5049d5',
  'name': 'RFP Scenario 2: 42093',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 86,
  'rfpUuid': 'bafbb273-0bf0-4b8f-b6c7-f16aeeb4a29b',
  'name': 'RFP Scenario 3: 557932',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 87,
  'rfpUuid': '06764589-3e1b-48c9-a073-95d7b886bb52',
  'name': 'RFP Scenario 3: 917557',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 88,
  'rfpUuid': '57f558c6-cc62-4231-ba34-a07d6bd14e85',
  'name': 'RFP Scenario 4 GT: 110114',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 89,
  'rfpUuid': '73209bef-936d-4d55-87a3-99983ffc4502',
  'name': 'Auto RFP: 494047',
  'client': {'organizationId': 89, 'type': 'CLIENT', 'name': 'Auto Org Client 494047', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 90,
  'rfpUuid': '32073c58-d798-40c1-980e-83d0774a9d4d',
  'name': 'Auto RFP: 903657',
  'client': {'organizationId': 91, 'type': 'CLIENT', 'name': 'Auto Org Client 903657', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 91,
  'rfpUuid': 'f68702b4-993e-44fc-9aef-fa2cd9d4fdf9',
  'name': 'Auto RFP: 295055',
  'client': {'organizationId': 93, 'type': 'CLIENT', 'name': 'Auto Org Client 295055', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 92,
  'rfpUuid': '3f240c7f-428e-400e-9103-8f9703c8c172',
  'name': 'Auto RFP: 25697',
  'client': {'organizationId': 95, 'type': 'CLIENT', 'name': 'Auto Org Client 25697', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 93,
  'rfpUuid': '792cf879-f564-4ef4-8a78-f19a9615b9ee',
  'name': 'asdasdasa',
  'client': {'organizationId': 79, 'type': 'CLIENT', 'name': 'filipCLIENT', 'disabled': true},
  'userAccesses': [{'userId': 15, 'email': 'f.bezpalko@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 94,
  'rfpUuid': '9fce80d8-3ff2-43b9-aedf-7cbdaadaa80e',
  'name': 'filip1',
  'client': {'organizationId': 79, 'type': 'CLIENT', 'name': 'filipCLIENT', 'disabled': true},
  'userAccesses': [{'userId': 15, 'email': 'f.bezpalko@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 95,
  'rfpUuid': '0411e76d-5161-4842-ad60-314952dc1a9a',
  'name': 'Automated Scenario 5: 469771',
  'client': {'organizationId': 103, 'type': 'CLIENT', 'name': 'Auto Org Client 469771', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 96,
  'rfpUuid': '6a94ff73-199e-45c1-b31d-1e6316c6920a',
  'name': 'Automated Scenario 5: 501239',
  'client': {'organizationId': 110, 'type': 'CLIENT', 'name': 'Auto Org Client 501239', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 97,
  'rfpUuid': '09a2e6a4-3ad8-436d-bb86-4e94cd18c61c',
  'name': 'Automated Scenario 5: 731970',
  'client': {'organizationId': 117, 'type': 'CLIENT', 'name': 'Auto Org Client 731970', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 98,
  'rfpUuid': '2c73196c-008f-4b73-b02e-0e29af0ac777',
  'name': 'Automated Scenario 5: 21632',
  'client': {'organizationId': 124, 'type': 'CLIENT', 'name': 'Auto Org Client 21632', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 103,
  'rfpUuid': '221c0e1c-558b-4807-9d57-77ae6d0f7b38',
  'name': 'RFP Scenario 2: 563083',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 104,
  'rfpUuid': 'c02a0092-0802-46f5-945d-54acf281f1ff',
  'name': 'RFP Scenario 2: 645252',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 105,
  'rfpUuid': '0d39fb50-db72-45aa-9b2c-6a6e44d67d16',
  'name': 'RFP Scenario 2: 196292',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 106,
  'rfpUuid': '012a6e68-4f91-413a-b6d0-30b5c9bb026a',
  'name': 'RFP Scenario 2: 625973',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 107,
  'rfpUuid': '3d82b1c4-30f2-4e61-ad23-3901b1d6a59a',
  'name': 'RFP Scenario 2: 375349',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 108,
  'rfpUuid': 'eb8f65e0-98fe-438b-b0bc-f69939b185a8',
  'name': 'RFP Scenario 2: 197986',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 109,
  'rfpUuid': '139077c0-5973-42d4-a3d7-660968c7205c',
  'name': 'RFP Scenario 2: 433130',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 110,
  'rfpUuid': 'e7982816-8079-489e-9c61-ff950d3a3c95',
  'name': 'RFP Scenario 2: 68114',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 111,
  'rfpUuid': '727752d0-c3ce-4d79-89aa-bf96390bcedb',
  'name': 'RFP Scenario 2: 969173',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 112,
  'rfpUuid': '54599ae0-0abc-480c-95db-9171c2b0e01a',
  'name': 'RFP Scenario 2: 525578',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 113,
  'rfpUuid': '8f68b289-e485-4ba1-95da-78d8a60972f6',
  'name': 'RFP Scenario 2: 656500',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 114,
  'rfpUuid': 'b505e409-a5c8-4566-8864-4b5206a3d49b',
  'name': 'RFP Scenario 2: 101893',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 115,
  'rfpUuid': '91fa2490-71e4-4ff3-a45b-aa957049a4b6',
  'name': 'RFP Scenario 1: 597392',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 116,
  'rfpUuid': 'b9255fda-a863-4c6a-af9f-d8e028e9bde8',
  'name': 'RFP Scenario 1: 664688',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 117,
  'rfpUuid': '81f02370-f653-4260-9ea5-b678579ba37e',
  'name': 'RFP Scenario 1: 591817',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 118,
  'rfpUuid': 'ad729e51-5b47-4f63-b171-2887d6dd685e',
  'name': 'RFP Scenario 2: 529510',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 119,
  'rfpUuid': '27aa89e1-f8e2-4962-ab2f-b67204cdc6f2',
  'name': 'RFP Scenario 3: 158318',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 120,
  'rfpUuid': 'b8d6d844-9082-492b-9b02-bdc4b7ea1927',
  'name': 'RFP Scenario 4 GT: 159455',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 121,
  'rfpUuid': 'c0ab54dd-7f23-423c-b132-9c6a13d0851c',
  'name': 'RFP Scenario 4 GT: 511182',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 122,
  'rfpUuid': '426328b8-7c24-4776-8954-bf988032f5c4',
  'name': 'RFP Scenario 1: 407532',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 123,
  'rfpUuid': 'dc9f54d6-ea18-42aa-b502-b734ed560ace',
  'name': 'Auto RFP: 648096',
  'client': {'organizationId': 156, 'type': 'CLIENT', 'name': 'Auto Org Client 648096', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 124,
  'rfpUuid': '5a10eddb-5d50-4b2b-a783-a0ef9f189178',
  'name': 'Auto RFP: 234644',
  'client': {'organizationId': 158, 'type': 'CLIENT', 'name': 'Auto Org Client 234644', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 125,
  'rfpUuid': '5ae5f2dc-7fba-43af-8b1c-ea54cceb9b58',
  'name': 'uygugvuy',
  'client': {'organizationId': 79, 'type': 'CLIENT', 'name': 'filipCLIENT', 'disabled': true},
  'userAccesses': [{'userId': 15, 'email': 'f.bezpalko@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 126,
  'rfpUuid': 'bee326fa-dc2e-412a-8040-79f82061473a',
  'name': 'hfghfd',
  'client': {'organizationId': 79, 'type': 'CLIENT', 'name': 'filipCLIENT', 'disabled': true},
  'userAccesses': [{'userId': 15, 'email': 'f.bezpalko@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 127,
  'rfpUuid': '2cdad379-33f5-4b65-b9db-eb9d59606f83',
  'name': 'SN449',
  'client': {'organizationId': 4, 'type': 'CLIENT', 'name': 'Stars_Client', 'disabled': false},
  'userAccesses': [{'userId': 10, 'email': 'o.wolska@webellian.com'}, {'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 128,
  'rfpUuid': 'ae68a5f5-f93b-41b0-9ccb-6436f548a25d',
  'name': 'filip10',
  'client': {'organizationId': 79, 'type': 'CLIENT', 'name': 'filipCLIENT', 'disabled': true},
  'userAccesses': [{'userId': 15, 'email': 'f.bezpalko@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 129,
  'rfpUuid': '563775de-072f-41fc-8e92-9a5390363f3a',
  'name': 'RFP From North California XX',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 6, 'email': 'r.lejman@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 130,
  'rfpUuid': 'd0d145ad-d64e-4218-a628-c9abc5089d3d',
  'name': 'SN',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 131,
  'rfpUuid': '8d34aae1-62e8-4b9c-805d-246739fe3732',
  'name': 'RFP Scenario 1: 589676',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 132,
  'rfpUuid': '5e107eef-3af7-4436-9de7-d0da88d58c04',
  'name': 'RFP Scenario 1: 157838',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 133,
  'rfpUuid': '6d23e3a5-b4c4-4f11-937a-47ab771720a7',
  'name': 'RFP Scenario 1: 986539',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 134,
  'rfpUuid': 'f6ea8235-35e0-48e5-828b-8c566ad6e49f',
  'name': 'RFP Scenario 1: 492493',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 135,
  'rfpUuid': 'ac6d0a9c-d959-4ebe-87c4-1f991423dff2',
  'name': 'Automated Scenario 5: 672118',
  'client': {'organizationId': 168, 'type': 'CLIENT', 'name': 'Auto Org Client 672118', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 136,
  'rfpUuid': 'fca5fbcd-0e4f-4835-b11a-57388f7fc8dd',
  'name': 'Auto RFP: 597700',
  'client': {'organizationId': 175, 'type': 'CLIENT', 'name': 'Auto Org Client 597700', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 137,
  'rfpUuid': 'f4e767cc-5992-4cf4-968a-781df6e28634',
  'name': 'Auto RFP: 236360',
  'client': {'organizationId': 177, 'type': 'CLIENT', 'name': 'Auto Org Client 236360', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 138,
  'rfpUuid': 'f6622142-936a-4c1b-a169-f3887b1b1da8',
  'name': 'Etioppia',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 8, 'email': 'j.zakoscielna@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 139,
  'rfpUuid': 'b607aadc-95d7-43fa-92a5-ff6dc623aedd',
  'name': 'Auto RFP: 397149',
  'client': {'organizationId': 179, 'type': 'CLIENT', 'name': 'Auto Org Client 397149', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 140,
  'rfpUuid': 'f8334f41-bc6a-43f6-83b0-77bd3653aea1',
  'name': 'Auto RFP: 513395',
  'client': {'organizationId': 181, 'type': 'CLIENT', 'name': 'Auto Org Client 513395', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 141,
  'rfpUuid': '13e82698-1904-4ce6-afba-ff752f89d026',
  'name': 'Auto RFP: 324241',
  'client': {'organizationId': 183, 'type': 'CLIENT', 'name': 'Auto Org Client 324241', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 142,
  'rfpUuid': 'e7941e42-a817-4d2a-8a28-3e13081c5f2f',
  'name': 'Auto RFP: 46025',
  'client': {'organizationId': 185, 'type': 'CLIENT', 'name': 'Auto Org Client 46025', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 143,
  'rfpUuid': 'd169d2be-50a5-46de-8828-1c2c4fb46531',
  'name': 'RFP Scenario 1: 154052',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 144,
  'rfpUuid': '10038afb-7130-4fb7-894a-f35fb7160ab5',
  'name': 'RFP Scenario 2: 880216',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 145,
  'rfpUuid': 'eeb060d4-a81c-42d1-8120-bfed72ba0845',
  'name': 'RFP Scenario 3: 859582',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 146,
  'rfpUuid': '1a426b79-671d-46b0-933d-3317b94e123d',
  'name': 'RFP Scenario 4 GT: 583787',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 147,
  'rfpUuid': 'cf3f8ec9-d81e-409a-b62e-93383fa21c98',
  'name': 'Automated Scenario 5: 282063',
  'client': {'organizationId': 189, 'type': 'CLIENT', 'name': 'Auto Org Client 282063', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 148,
  'rfpUuid': '3a43b72d-cf50-4a38-a62c-7e2d118f41bd',
  'name': 'RFP Scenario 4 GT: 989804',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 149,
  'rfpUuid': '1cee2226-ff74-49d2-9427-6cbbcfb79839',
  'name': 'RFP Scenario 4 GT: 14558',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 150,
  'rfpUuid': '7ddf4960-4c20-45ec-ba21-30b0658240e5',
  'name': 'RFP Scenario 4 GT: 191834',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 151,
  'rfpUuid': 'b2c7b4a2-16ff-421d-be1d-aeb771577523',
  'name': 'RFP Scenario 4 GT: 424760',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 152,
  'rfpUuid': '243f1017-2083-4ac3-ba01-3ca64789e5f4',
  'name': 'RFP Scenario 4 GT: 717683',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 153,
  'rfpUuid': '745e9b0e-7426-4403-bdf0-2128840260cf',
  'name': 'Auto RFP: 737598',
  'client': {'organizationId': 190, 'type': 'CLIENT', 'name': 'Auto Org Client 737598', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 154,
  'rfpUuid': 'd9cd83ba-9489-43dc-8846-ba1a8a499ed3',
  'name': 'Auto RFP: 677886',
  'client': {'organizationId': 192, 'type': 'CLIENT', 'name': 'Auto Org Client 677886', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 155,
  'rfpUuid': 'be33301f-47b0-4517-a108-adfd80af06d1',
  'name': 'Auto RFP: 717375',
  'client': {'organizationId': 196, 'type': 'CLIENT', 'name': 'Auto Org Client 717375', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 156,
  'rfpUuid': '9f96c06a-b7a1-467b-b032-842f967e33ed',
  'name': 'newrfp[',
  'client': {'organizationId': 79, 'type': 'CLIENT', 'name': 'filipCLIENT', 'disabled': true},
  'userAccesses': [{'userId': 15, 'email': 'f.bezpalko@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 157,
  'rfpUuid': 'd3867b07-76d1-4d4b-b305-15cff1b86a62',
  'name': 'test_20',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 3, 'email': 'm.gapys@webellian.com'}],
  'projectCreated': false,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 158,
  'rfpUuid': 'a60337d1-c094-4338-aa3d-1c1b4b30b206',
  'name': 'test_22',
  'client': {'organizationId': 5, 'type': 'CLIENT', 'name': 'Webellian_Client', 'disabled': false},
  'userAccesses': [{'userId': 3, 'email': 'm.gapys@webellian.com'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 159,
  'rfpUuid': 'fefe1023-dff6-4b66-abb2-6c6b8df70470',
  'name': 'partnertest',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 238, 'email': 'e.sankowski+partner@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 160,
  'rfpUuid': 'f8673b59-21ab-4ae0-9b8c-9cfb606dbef7',
  'name': 'asdasd',
  'client': {'organizationId': 72, 'type': 'CLIENT', 'name': 'Auto Org Client 313543', 'disabled': true},
  'userAccesses': [{'userId': 238, 'email': 'e.sankowski+partner@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 161,
  'rfpUuid': '79f50e2c-80c2-4727-aa23-0bee7c4756e4',
  'name': 'Web client 1',
  'client': {'organizationId': 79, 'type': 'CLIENT', 'name': 'filipCLIENT', 'disabled': true},
  'userAccesses': [{'userId': 238, 'email': 'e.sankowski+partner@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 162,
  'rfpUuid': '9c80902b-924a-4b5c-9b1f-03801907a166',
  'name': 'RFP Scenario 1: 69560',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 163,
  'rfpUuid': '46f4c7b8-9108-4657-8357-7ba0379cd971',
  'name': 'RFP Scenario 2: 836490',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 164,
  'rfpUuid': 'a145d01f-e637-4ab5-b6a7-7e01e447f9de',
  'name': 'RFP Scenario 3: 339423',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 165,
  'rfpUuid': '551ff2a4-6571-47de-8c97-ad9fb3355fec',
  'name': 'RFP Scenario 4 GT: 767554',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 166,
  'rfpUuid': 'd6c02f27-9450-4286-a80e-9b5e7be65f41',
  'name': 'Automated Scenario 5: 260955',
  'client': {'organizationId': 203, 'type': 'CLIENT', 'name': 'Auto Org Client 260955', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 167,
  'rfpUuid': '9ac2dad5-e00c-429c-be40-f8572a13de33',
  'name': 'Auto RFP: 869296',
  'client': {'organizationId': 207, 'type': 'CLIENT', 'name': 'Auto Org Client 869296', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 168,
  'rfpUuid': '8812968a-f4f0-40bb-a694-e9449c4a1829',
  'name': 'RFP Scenario 1: 812869',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 169,
  'rfpUuid': 'ddfa41b3-d494-4ccf-a7ee-09131b9a0cf1',
  'name': 'RFP Scenario 2: 712036',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 170,
  'rfpUuid': 'abad8345-ab96-40a1-98f2-bad59d5a75c1',
  'name': 'RFP Scenario 3: 677602',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 171,
  'rfpUuid': 'd7699de9-acc4-4cf2-a411-3ec94116a815',
  'name': 'RFP Scenario 4 GT: 836149',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 172,
  'rfpUuid': '6386aa43-eeb9-43e8-bbed-5516d527f671',
  'name': 'RFP Scenario 1: 376115',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 173,
  'rfpUuid': '195357cd-fdac-4dfe-9930-73f6f3c435a6',
  'name': 'RFP Scenario 1: 707637',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 174,
  'rfpUuid': '83ac35e0-efb8-4612-ad53-4d94005e5d66',
  'name': 'RFP Scenario 1: 92501',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 175,
  'rfpUuid': '2d32b712-0751-4500-afbf-45fa551eb8aa',
  'name': 'Auto RFP: 631381',
  'client': {'organizationId': 210, 'type': 'CLIENT', 'name': 'Auto Org Client 631381', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 176,
  'rfpUuid': '67ed9078-7793-4887-b8cd-a2ffb433a5c4',
  'name': 'RFP Scenario 1: 538662',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 177,
  'rfpUuid': '3a4c979f-c4c5-423a-9617-efe6e5289735',
  'name': 'adassda',
  'client': {'organizationId': 79, 'type': 'CLIENT', 'name': 'filipCLIENT', 'disabled': true},
  'userAccesses': [{'userId': 85, 'email': 'f.bezpalko+partner@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 180,
  'rfpUuid': 'ed101510-be4d-43b7-9243-54384bd1968b',
  'name': 'RFP Scenario 1: 67767',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 181,
  'rfpUuid': '595e5e81-0e02-488d-84fd-78750d6e0ea4',
  'name': 'RFP Scenario 1: 261874',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 182,
  'rfpUuid': 'a9592374-766a-4cf2-85c4-006197c6391e',
  'name': 'RFP Scenario 2: 888468',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 183,
  'rfpUuid': 'fd04b117-3d72-4000-a418-aa0fa2c67626',
  'name': 'Auto RFP: 406559',
  'client': {'organizationId': 215, 'type': 'CLIENT', 'name': 'Auto Org Client 406559', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 184,
  'rfpUuid': 'a58cbd9f-9692-462b-afda-84f59cf42485',
  'name': 'RFP Scenario 1: 203086',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 185,
  'rfpUuid': '2bb0f441-c964-4a9b-b0e7-dc5292209fa7',
  'name': 'RFP Scenario 2: 240697',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 186,
  'rfpUuid': 'c0ce4479-204b-47ab-bf9f-b1263bcf0618',
  'name': 'RFP Scenario 3: 458655',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 187,
  'rfpUuid': 'acdcee5e-2dcc-46b6-884a-33d97808e836',
  'name': 'RFP Scenario 4 GT: 284744',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 188,
  'rfpUuid': '6f2e5b21-7fb3-4138-acbd-387fcca10a5f',
  'name': 'Automated Scenario 5: 182170',
  'client': {'organizationId': 219, 'type': 'CLIENT', 'name': 'Auto Org Client 182170', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 189,
  'rfpUuid': 'b4cf4ad4-420f-4511-8476-48c1bb3f3de6',
  'name': 'Laurent\'s RFP',
  'client': {'organizationId': 4, 'type': 'CLIENT', 'name': 'Stars_Client', 'disabled': false},
  'userAccesses': [{'userId': 1, 'email': 'test@webellian.com'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 190,
  'rfpUuid': '67acad0c-413f-4d49-8579-b77fbc414f31',
  'name': 'GTExportError',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 191,
  'rfpUuid': '5e79a5cf-078c-42cb-911b-3a58a9d749b4',
  'name': 'ddd',
  'client': {'organizationId': 4, 'type': 'CLIENT', 'name': 'Stars_Client', 'disabled': false},
  'userAccesses': [{'userId': 1, 'email': 'test@webellian.com'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 192,
  'rfpUuid': '8fbe7173-b2f8-40cd-b542-5b3e4f23f211',
  'name': 'ggg',
  'client': {'organizationId': 4, 'type': 'CLIENT', 'name': 'Stars_Client', 'disabled': false},
  'userAccesses': [{'userId': 1, 'email': 'test@webellian.com'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 193,
  'rfpUuid': '7492ae5d-6542-491f-b0ce-42f94ef2ba65',
  'name': 'kkkkkkk',
  'client': {'organizationId': 4, 'type': 'CLIENT', 'name': 'Stars_Client', 'disabled': false},
  'userAccesses': [{'userId': 1, 'email': 'test@webellian.com'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 194,
  'rfpUuid': 'f96e61df-b365-4774-b369-35f242ec7cbf',
  'name': 'ooooooooooooo',
  'client': {'organizationId': 4, 'type': 'CLIENT', 'name': 'Stars_Client', 'disabled': false},
  'userAccesses': [{'userId': 1, 'email': 'test@webellian.com'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 195,
  'rfpUuid': '5d5cb2ad-c197-44d5-8fb8-e54212f07134',
  'name': 'ppp',
  'client': {'organizationId': 4, 'type': 'CLIENT', 'name': 'Stars_Client', 'disabled': false},
  'userAccesses': [{'userId': 1, 'email': 'test@webellian.com'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 196,
  'rfpUuid': '88a0a397-bb60-4bf4-8696-2096d767de91',
  'name': 'adada',
  'client': {'organizationId': 79, 'type': 'CLIENT', 'name': 'filipCLIENT', 'disabled': true},
  'userAccesses': [{'userId': 15, 'email': 'f.bezpalko@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 197,
  'rfpUuid': '7f9346a1-7b27-4cfc-a1ae-b99e70241d80',
  'name': 'Dxzwew',
  'client': {'organizationId': 79, 'type': 'CLIENT', 'name': 'filipCLIENT', 'disabled': true},
  'userAccesses': [{'userId': 15, 'email': 'f.bezpalko@webellian.com'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 200,
  'rfpUuid': 'a45750bf-f639-44dc-b275-9d9710c2aebd',
  'name': 'rfp piatkowe pierwsze',
  'client': {'organizationId': 323, 'type': 'CLIENT', 'name': 'Aska-Client-1-ORG', 'disabled': false},
  'userAccesses': [{'userId': 8, 'email': 'j.zakoscielna@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 201,
  'rfpUuid': '71122edb-0e43-4772-a342-5c034e06522a',
  'name': 'piatkowe 2',
  'client': {'organizationId': 323, 'type': 'CLIENT', 'name': 'Aska-Client-1-ORG', 'disabled': false},
  'userAccesses': [{'userId': 8, 'email': 'j.zakoscielna@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 202,
  'rfpUuid': '9e89e742-da10-48b1-a57e-98092d1e87a1',
  'name': 'Futro',
  'client': {'organizationId': 323, 'type': 'CLIENT', 'name': 'Aska-Client-1-ORG', 'disabled': false},
  'userAccesses': [{'userId': 8, 'email': 'j.zakoscielna@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 203,
  'rfpUuid': '887744a1-2939-4ad8-914a-e25246428c29',
  'name': '111111111111aa',
  'client': {'organizationId': 4, 'type': 'CLIENT', 'name': 'Stars_Client', 'disabled': false},
  'userAccesses': [{'userId': 15, 'email': 'f.bezpalko@webellian.com'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 204,
  'rfpUuid': '74d19354-89aa-4257-a0a9-79ce12218fc1',
  'name': 'dasdasdas',
  'client': {'organizationId': 4, 'type': 'CLIENT', 'name': 'Stars_Client', 'disabled': false},
  'userAccesses': [{'userId': 15, 'email': 'f.bezpalko@webellian.com'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 205,
  'rfpUuid': 'f37996c8-ed5a-41fa-a6c4-017ff97ac104',
  'name': 'test1',
  'client': {'organizationId': 79, 'type': 'CLIENT', 'name': 'filipCLIENT', 'disabled': true},
  'userAccesses': [{'userId': 15, 'email': 'f.bezpalko@webellian.com'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 206,
  'rfpUuid': '495aa057-65ea-436b-944f-697499b3e1f9',
  'name': 'Taśma',
  'client': {'organizationId': 323, 'type': 'CLIENT', 'name': 'Aska-Client-1-ORG', 'disabled': false},
  'userAccesses': [{'userId': 8, 'email': 'j.zakoscielna@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 207,
  'rfpUuid': 'a8d69438-0932-40da-a04c-71bf8c613a3f',
  'name': 'nowyfilip',
  'client': {'organizationId': 79, 'type': 'CLIENT', 'name': 'filipCLIENT', 'disabled': true},
  'userAccesses': [{'userId': 15, 'email': 'f.bezpalko@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 208,
  'rfpUuid': '5e7437f6-f80f-4771-8b09-765c6e96d44b',
  'name': 'nowyfilip2',
  'client': {'organizationId': 79, 'type': 'CLIENT', 'name': 'filipCLIENT', 'disabled': true},
  'userAccesses': [{'userId': 15, 'email': 'f.bezpalko@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 209,
  'rfpUuid': '42c1a258-a0ba-4273-b7c7-08071f420807',
  'name': 'RFP Scenario 1: 221660',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 210,
  'rfpUuid': '0f1d9107-f858-4ba4-a360-c2967b015b3c',
  'name': 'RFP Scenario 1: 840441',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 211,
  'rfpUuid': 'c6900bd4-4264-46a4-b7c2-1ddb65fe5d10',
  'name': 'RFP Scenario 1: 824129',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 212,
  'rfpUuid': 'ab640b1d-88cf-4b14-927f-01f7e8c5604b',
  'name': 'RFP Scenario 1: 202666',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 213,
  'rfpUuid': '05734d7e-f2a3-4fdf-b1f0-9e10ae79a621',
  'name': 'RFP Scenario 1: 800613',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 214,
  'rfpUuid': 'acf70c42-17b8-407e-9dca-1eb386f91045',
  'name': 'RazDwaTrzy',
  'client': {'organizationId': 4, 'type': 'CLIENT', 'name': 'Stars_Client', 'disabled': false},
  'userAccesses': [{'userId': 14, 'email': 'r.zaniewski@webellian.com'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 215,
  'rfpUuid': '6867bea7-0f86-4e55-9055-76649c17f82c',
  'name': 'Katar',
  'client': {'organizationId': 323, 'type': 'CLIENT', 'name': 'Aska-Client-1-ORG', 'disabled': false},
  'userAccesses': [{'userId': 8, 'email': 'j.zakoscielna@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 216,
  'rfpUuid': 'd5b4e307-77e1-4ea8-acce-62f96ec05be9',
  'name': 'FourFiveSix',
  'client': {'organizationId': 4, 'type': 'CLIENT', 'name': 'Stars_Client', 'disabled': false},
  'userAccesses': [{'userId': 14, 'email': 'r.zaniewski@webellian.com'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 217,
  'rfpUuid': '2c688347-9870-4ff5-9d7e-edfd5e006f40',
  'name': 'Auto RFP: 874622',
  'client': {'organizationId': 338, 'type': 'CLIENT', 'name': 'Auto Org Client 874622', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 218,
  'rfpUuid': 'f750c873-c4d6-4d5c-8ce0-b3d02bf049c4',
  'name': 'Test',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 219,
  'rfpUuid': '5bc07c11-9291-4d26-ae21-b97240d86da3',
  'name': 'Auto RFP: 401770',
  'client': {'organizationId': 345, 'type': 'CLIENT', 'name': 'Auto Org Client 401770', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 220,
  'rfpUuid': '072b7080-59dc-43f7-b04a-6e4e4e870af4',
  'name': 'RFP Scenario 1: 186116',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 221,
  'rfpUuid': '479937f0-6dd4-4ac2-9384-d3b797a4a849',
  'name': 'RFP Scenario 2: 96542',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 222,
  'rfpUuid': '995434a1-daf5-4290-9a83-cc1bb6296da3',
  'name': 'RFP Scenario 3: 313111',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 223,
  'rfpUuid': 'b84b7558-c5d4-4f66-b01b-2f301204cd0e',
  'name': 'Auto RFP: 710023',
  'client': {'organizationId': 350, 'type': 'CLIENT', 'name': 'Auto Org Client 710023', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 224,
  'rfpUuid': '4228adf7-0890-4928-8902-3ea8c614fa36',
  'name': 'RFP Scenario 4 GT: 287911',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 225,
  'rfpUuid': 'ae1b9368-8858-4c83-bde4-a99ab5ba26ea',
  'name': 'RFP Scenario 3: 222731',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 226,
  'rfpUuid': 'a67c9780-d4e9-40d7-9337-fb4f9fd7e529',
  'name': 'RFP Scenario 4 GT: 457490',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 227,
  'rfpUuid': 'bcd14e48-5ee5-419a-9fa8-02f4a9e2791e',
  'name': 'Automated Scenario 5: 845920',
  'client': {'organizationId': 354, 'type': 'CLIENT', 'name': 'Auto Org Client 845920', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 228,
  'rfpUuid': 'af5714a6-c451-4dbd-ac31-4be430a0a94d',
  'name': 'Automated Scenario 5: 444052',
  'client': {'organizationId': 357, 'type': 'CLIENT', 'name': 'Auto Org Client 444052', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 229,
  'rfpUuid': '5421db40-756c-4b15-aeaa-873f88b6182d',
  'name': 'filipnowy',
  'client': {'organizationId': 79, 'type': 'CLIENT', 'name': 'filipCLIENT', 'disabled': true},
  'userAccesses': [{'userId': 15, 'email': 'f.bezpalko@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 230,
  'rfpUuid': '78aa64c8-119c-46fb-92a9-77510b8fd3d3',
  'name': 'RfpName_4523',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 231,
  'rfpUuid': '6039f9de-8ac6-4019-be5f-dec3267eafa1',
  'name': 'RfpName_29454',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 232,
  'rfpUuid': 'dfa34e46-ebb5-4dcb-ad4c-a5b4371832e5',
  'name': 'RfpName_4638',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 233,
  'rfpUuid': '765f5470-6bbf-4497-8573-b00972c7cc88',
  'name': 'RfpName_1526',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 234,
  'rfpUuid': '481a0840-9fb4-4252-b43f-893515c2aed0',
  'name': 'RfpName_3049',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 235,
  'rfpUuid': '22c3ebd2-09d8-485b-9067-ee87f8d2aebc',
  'name': 'RfpName_8183',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': false,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 236,
  'rfpUuid': 'f789f144-8dee-426d-9330-4aaf10e9aed0',
  'name': 'RfpName_4037',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': false,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 237,
  'rfpUuid': '73f1df37-2963-4d71-a554-5a938becac71',
  'name': 'RfpName_2282',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': false,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 238,
  'rfpUuid': '213b0045-5e00-416b-9ba7-77e4562fbb7e',
  'name': 'RfpName_5136',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': false,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 239,
  'rfpUuid': '72723c47-d91a-4caf-8dfb-523156835c76',
  'name': 'Auto RFP: 561185',
  'client': {'organizationId': 364, 'type': 'CLIENT', 'name': 'Auto Org Client 561185', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 240,
  'rfpUuid': '4480303b-d8f5-4c76-9292-3612560c86d3',
  'name': 'RFP Scenario 1: 688822',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 241,
  'rfpUuid': '4f87213b-b1ca-4451-b638-87b845e49b88',
  'name': 'RFP Scenario 2: 223129',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 242,
  'rfpUuid': 'd728013c-15eb-420a-bee3-0f3cccb2b103',
  'name': 'RfpName_2264',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': false,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 243,
  'rfpUuid': '727326e1-c854-4cf0-af72-d31ad7424883',
  'name': 'RfpName_5765',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': false,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 244,
  'rfpUuid': 'aa6f75e6-4c56-4991-bdc5-aae1bd7d27fb',
  'name': 'RfpName_9203',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 245,
  'rfpUuid': '95102bd1-8b59-4877-ae79-143bf1857168',
  'name': 'RfpName_2021',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 246,
  'rfpUuid': '61a218d2-0f62-460c-9920-5fc40f177b59',
  'name': 'RfpName_1094',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 247,
  'rfpUuid': 'ea35071d-46db-475c-9c14-6dbf553560c0',
  'name': 'RfpName_9665',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 248,
  'rfpUuid': 'a6032c63-3a2f-48c7-8f02-9aba25755e57',
  'name': 'RfpName_8515',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 249,
  'rfpUuid': '52dfe9b4-7bf6-4d93-9332-7e6fa1d90c0f',
  'name': 'RFP Scenario 3: 904698',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 250,
  'rfpUuid': '743dc16a-07e9-4111-b8ab-b5c4c3ab1551',
  'name': 'Auto RFP: 359385',
  'client': {'organizationId': 370, 'type': 'CLIENT', 'name': 'Auto Org Client 359385', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 251,
  'rfpUuid': 'edb87f81-d534-4e02-b638-adcab8bdbb9e',
  'name': 'RFP Scenario 1: 647633',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 252,
  'rfpUuid': 'd679999e-fd36-4243-bb02-ac84b5c5d7b4',
  'name': 'RFP Scenario 2: 494502',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 253,
  'rfpUuid': '2e55cff8-84b7-4f9c-b4b8-677bd83a302f',
  'name': 'RFP Scenario 3: 977627',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 254,
  'rfpUuid': '78751c55-b2b7-496d-bb21-fbab532c32a1',
  'name': 'RFP Scenario 4 GT: 4428',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 255,
  'rfpUuid': '7d5f0c66-09c4-4399-a228-a9e59c23713f',
  'name': 'Automated Scenario 5: 80692',
  'client': {'organizationId': 374, 'type': 'CLIENT', 'name': 'Auto Org Client 80692', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 256,
  'rfpUuid': '8624ee79-cef1-4111-bf10-24e59e7bd0d2',
  'name': 'RfpName_3510',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 257,
  'rfpUuid': '3c281d72-a5dd-42b9-a643-1838ac1eb0a5',
  'name': 'Auto RFP: 817002',
  'client': {'organizationId': 378, 'type': 'CLIENT', 'name': 'Auto Org Client 817002', 'disabled': true},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 258,
  'rfpUuid': '8c4c21ca-517a-4d54-8ac6-194819dd8278',
  'name': 'RFP Scenario 1: 414855',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 259,
  'rfpUuid': '9424d36f-2c3c-452f-8b17-98b89241f999',
  'name': 'RFP Scenario 2: 408996',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 260,
  'rfpUuid': '2dc1c3cb-b039-403a-8510-315e12259c8c',
  'name': 'RFP Scenario 3: 35537',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 261,
  'rfpUuid': '134e81d9-e827-490d-bf54-6d5ce35f7d62',
  'name': 'RFP Scenario 4 GT: 683587',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 262,
  'rfpUuid': '3b90eac5-ee2e-4a16-87bc-4e2de9ef8175',
  'name': 'Automated Scenario 5: 650085',
  'client': {'organizationId': 382, 'type': 'CLIENT', 'name': 'Auto Org Client 650085', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 263,
  'rfpUuid': '03fe6805-852b-427e-b92c-b118c05547f4',
  'name': 'Auto RFP: 690162',
  'client': {'organizationId': 386, 'type': 'CLIENT', 'name': 'Auto Org Client 690162', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 264,
  'rfpUuid': '53f558b4-5ea6-4385-be81-725d78dac73b',
  'name': 'RFP Scenario 1: 410561',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 265,
  'rfpUuid': '4de5ca1c-824c-4dc3-a9e7-2fb75f9be767',
  'name': 'RFP Scenario 2: 594631',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 266,
  'rfpUuid': '63500cc8-582a-417f-84c2-37cc73464567',
  'name': 'RFP Scenario 4 GT: 385352',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 267,
  'rfpUuid': 'c55df2b9-16b2-4d5a-bab4-82f1faa551e3',
  'name': 'Automated Scenario 5: 443686',
  'client': {'organizationId': 390, 'type': 'CLIENT', 'name': 'Auto Org Client 443686', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 268,
  'rfpUuid': '1b97b8fc-3f55-4ffe-b16e-5eb63046cc16',
  'name': 'Auto RFP: 674655',
  'client': {'organizationId': 398, 'type': 'CLIENT', 'name': 'Auto Org Client 674655', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 269,
  'rfpUuid': '2ff4bf90-594a-45c2-b8d1-176bc3407e15',
  'name': 'RFP Scenario 1: 201231',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 270,
  'rfpUuid': 'c406d408-f40d-4480-bdf6-ae6460712250',
  'name': 'Auto RFP: 553456',
  'client': {'organizationId': 403, 'type': 'CLIENT', 'name': 'Auto Org Client 553456', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': false,
  'finished': false,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 271,
  'rfpUuid': 'fb328539-e34f-4388-a9d7-4656611fb8a6',
  'name': 'RFP Scenario 1: 820042',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 272,
  'rfpUuid': 'f41af713-379a-4403-989e-25d0133c8d98',
  'name': 'RFP Scenario 2: 948342',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 273,
  'rfpUuid': '151d0692-9df2-4771-b897-d0623071213b',
  'name': 'RFP Scenario 3: 413142',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 274,
  'rfpUuid': 'c00bbfce-73b3-4221-accf-da66e4f81578',
  'name': 'RFP Scenario 4 GT: 603188',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 275,
  'rfpUuid': '4bd9a32c-7bdc-4175-9056-2d2e7ac20047',
  'name': 'Partner1RFP',
  'client': {'organizationId': 323, 'type': 'CLIENT', 'name': 'Aska-Client-1-ORG', 'disabled': false},
  'userAccesses': [{'userId': 411, 'email': 'j.wojtas+Partner1@webellian.com'}, {
    'userId': 412,
    'email': 'j.wojtas+Partner2@webellian.com'
  }],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 276,
  'rfpUuid': '422e03bf-0e0c-4cf9-871c-a210320f8bcb',
  'name': 'RfpName_2298',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 277,
  'rfpUuid': '8e1cee01-8218-4a8c-b575-17d933823594',
  'name': 'RfpName_9041',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 278,
  'rfpUuid': '795ca2d1-eac0-4cf2-99d5-d25a0f143343',
  'name': 'RfpName_2156',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 279,
  'rfpUuid': '4e777edd-31dc-462a-a34d-3b7f196c0200',
  'name': 'RfpName_5418',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 280,
  'rfpUuid': 'c8004fd1-e573-4466-a540-efc4a9545bf1',
  'name': 'RfpName_5454',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 281,
  'rfpUuid': 'c0a9290f-4b51-4d27-b04a-89fef9042ddd',
  'name': 'RfpName_7087',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 282,
  'rfpUuid': 'a1221875-68c0-420b-a9c9-27324f05211e',
  'name': 'STARTS_RFP_1',
  'client': {'organizationId': 323, 'type': 'CLIENT', 'name': 'Aska-Client-1-ORG', 'disabled': false},
  'userAccesses': [{'userId': 416, 'email': 'j.wojtas+Star_User1@webellian.com'}, {
    'userId': 417,
    'email': 'j.wojtas+Star_User2@webellian.com'
  }],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 283,
  'rfpUuid': '768ad0dd-9fb6-4213-bf00-477642f75455',
  'name': 'Partner_rFP_2',
  'client': {'organizationId': 323, 'type': 'CLIENT', 'name': 'Aska-Client-1-ORG', 'disabled': false},
  'userAccesses': [{'userId': 411, 'email': 'j.wojtas+Partner1@webellian.com'}, {
    'userId': 412,
    'email': 'j.wojtas+Partner2@webellian.com'
  }],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 284,
  'rfpUuid': 'a8d811fd-3dfb-42c4-a38a-0cef8c0944db',
  'name': 'RfpName_2680',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 285,
  'rfpUuid': '7d685d56-37d4-4589-af59-a2745c006515',
  'name': 'qwertyuiop',
  'client': {'organizationId': 323, 'type': 'CLIENT', 'name': 'Aska-Client-1-ORG', 'disabled': false},
  'userAccesses': [{'userId': 8, 'email': 'j.zakoscielna@webellian.com'}, {
    'userId': 417,
    'email': 'j.wojtas+Star_User2@webellian.com'
  }],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 286,
  'rfpUuid': '1bffaad3-4cee-4aa5-b7da-3fc2e75f87aa',
  'name': 'RfpName_7979',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 287,
  'rfpUuid': '5b218137-be65-4dd6-ba30-c17b90509389',
  'name': 'RfpName_4494',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 288,
  'rfpUuid': '1d9280d6-da4f-40b7-adaf-b474e312d3d5',
  'name': 'RfpName_2659',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 289,
  'rfpUuid': '48aa268c-61be-46b4-b2c5-d3a70429b764',
  'name': 'RfpName_8840',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 290,
  'rfpUuid': '8a5a627a-6cd5-4fe4-ac80-3d14d1552c1e',
  'name': 'RfpName_8044',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 291,
  'rfpUuid': '71c6a78d-e953-4231-9ea6-d865d1482a11',
  'name': '00000000000000000000000',
  'client': {'organizationId': 323, 'type': 'CLIENT', 'name': 'Aska-Client-1-ORG', 'disabled': false},
  'userAccesses': [{'userId': 8, 'email': 'j.zakoscielna@webellian.com'}, {
    'userId': 416,
    'email': 'j.wojtas+Star_User1@webellian.com'
  }],
  'projectCreated': false,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 292,
  'rfpUuid': '729836b0-2bd7-4efb-abf9-0a8238a470e9',
  'name': 'RfpName_5253',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 293,
  'rfpUuid': '061e240f-d90a-47a8-9673-dfa70d667d43',
  'name': 'RfpName_7125',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 294,
  'rfpUuid': '94b22c14-fbc9-41ee-9cf1-827c4581ee7d',
  'name': 'RfpName_5227',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 295,
  'rfpUuid': 'd1d4d955-dfbe-40f3-9e76-f6ec0a5bce2a',
  'name': 'TRAMPOLINA',
  'client': {'organizationId': 323, 'type': 'CLIENT', 'name': 'Aska-Client-1-ORG', 'disabled': false},
  'userAccesses': [{'userId': 411, 'email': 'j.wojtas+Partner1@webellian.com'}, {
    'userId': 412,
    'email': 'j.wojtas+Partner2@webellian.com'
  }],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 296,
  'rfpUuid': '1dd54579-a48e-4398-924e-bd54ddfdd290',
  'name': 'Auto RFP: 271597',
  'client': {'organizationId': 413, 'type': 'CLIENT', 'name': 'Auto Org Client 271597', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 297,
  'rfpUuid': '99707c6a-9328-4e70-8236-d777e308c003',
  'name': 'RFP Scenario 1: 793387',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 298,
  'rfpUuid': '053d0522-e347-4580-871b-88dccf880492',
  'name': 'RFP Scenario 2: 187456',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 299,
  'rfpUuid': '4985b953-f321-4c68-bf06-a4422359f12e',
  'name': 'RFP Scenario 3: 758288',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 300,
  'rfpUuid': '19d4d93d-cf8f-491e-b896-326bb9ae3781',
  'name': 'RFP Scenario 4 GT: 353393',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 301,
  'rfpUuid': '3e0cf913-7ba5-4261-b46b-531dc58d24b0',
  'name': 'Automated Scenario 5: 879010',
  'client': {'organizationId': 417, 'type': 'CLIENT', 'name': 'Auto Org Client 879010', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 302,
  'rfpUuid': 'de051c41-7f9a-4697-9066-10b89daec186',
  'name': 'RfpName_9964',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 303,
  'rfpUuid': '7ab40456-d2a0-4987-a7ae-6032329b3d95',
  'name': 'Test388',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 304,
  'rfpUuid': '95dfe072-5145-4413-a6fa-095419bef0f4',
  'name': 'RfpName_6585',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 305,
  'rfpUuid': '8070e838-391e-41c9-a13f-59c6c719627d',
  'name': 'RfpName_1183',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 306,
  'rfpUuid': '8f68ee92-21f3-41b1-a885-881876a0967a',
  'name': 'RfpName_7105',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 307,
  'rfpUuid': '1ddd429c-8204-49ad-bb2f-8522febcc89b',
  'name': 'RfpName_7809',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 308,
  'rfpUuid': '2fc7b849-c2f8-405c-802f-6ba852bb0483',
  'name': 'RfpName_1969',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 309,
  'rfpUuid': '6cd7652e-5688-4eac-841f-ea2d63e3970d',
  'name': 'RfpName_1316',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 310,
  'rfpUuid': '3781f56a-7391-4fea-b37b-fd9a36d7a996',
  'name': 'RfpName_2462',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 311,
  'rfpUuid': '99b15499-b84e-40b5-9dcd-24bb734ca604',
  'name': 'RfpName_6731',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 312,
  'rfpUuid': '02d40fd3-ed8f-4bb1-8f24-2c5033115fe0',
  'name': 'RfpName_2733',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 313,
  'rfpUuid': '0b48f5f4-a7a3-479e-b540-8b7164fa9a31',
  'name': 'RfpName_4264',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 314,
  'rfpUuid': 'b3726d59-d163-4faa-9f46-7f5d9b4bebbe',
  'name': 'RfpName_3213',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 315,
  'rfpUuid': '592ab81e-6578-4b14-b4fa-222a9ea0a98c',
  'name': 'RfpName_7373',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 316,
  'rfpUuid': '4ade7be3-cec7-4c75-aa93-b231cc63a2bc',
  'name': 'RfpName_4525',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 317,
  'rfpUuid': 'bbb52e6e-00e6-4ce3-a0fc-4021be7b916c',
  'name': '-',
  'client': {'organizationId': 323, 'type': 'CLIENT', 'name': 'Aska-Client-1-ORG', 'disabled': false},
  'userAccesses': [{'userId': 8, 'email': 'j.zakoscielna@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 318,
  'rfpUuid': '77252d81-2c9f-4af3-81c1-fb33aecbdaa0',
  'name': 'RfpName_8015',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 319,
  'rfpUuid': '800e1e48-4c5f-4a08-938b-df5b06a11a91',
  'name': 'RfpName_4601',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 320,
  'rfpUuid': '593ade34-6650-461c-9f16-e28353976d56',
  'name': 'RFP Scenario 1: 168082',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 321,
  'rfpUuid': '3771612e-5c76-45ce-95d4-b408047a41c2',
  'name': 'RFP Scenario 3: 351785',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 322,
  'rfpUuid': '5ea8ca2f-caa5-4dc0-9a11-b0ae5394d74b',
  'name': 'Automated Scenario 5: 265684',
  'client': {'organizationId': 424, 'type': 'CLIENT', 'name': 'Auto Org Client 265684', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 323,
  'rfpUuid': 'e810e5cf-5569-45ff-bce5-5499cd59f47e',
  'name': 'RfpName_5890',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 324,
  'rfpUuid': 'd8cbb8ce-b990-493b-a77b-89790649fbbc',
  'name': 'Auto RFP: 968376',
  'client': {'organizationId': 428, 'type': 'CLIENT', 'name': 'Auto Org Client 968376', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 325,
  'rfpUuid': 'dc0766d3-4ab8-4f49-a898-9d55fe7d94c1',
  'name': 'RFP Scenario 1: 642052',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 326,
  'rfpUuid': 'fd283032-ae3f-44cc-9816-cc2bec9f30cd',
  'name': 'RFP Scenario 2: 835923',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 327,
  'rfpUuid': '314bfd0d-b66b-4d3e-a98b-8d36f13df3c3',
  'name': 'RFP Scenario 3: 980254',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 328,
  'rfpUuid': 'bc257df8-264e-4375-8b3e-07c398a89f63',
  'name': 'RFP Scenario 4 GT: 168936',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 329,
  'rfpUuid': '54e74e57-66b7-471d-bd41-355b9771957c',
  'name': 'Automated Scenario 5: 722912',
  'client': {'organizationId': 432, 'type': 'CLIENT', 'name': 'Auto Org Client 722912', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 330,
  'rfpUuid': '34331dfb-b696-4ea4-ac20-cbf93ec62376',
  'name': 'Auto RFP: 889684',
  'client': {'organizationId': 436, 'type': 'CLIENT', 'name': 'Auto Org Client 889684', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 331,
  'rfpUuid': '0f3e2a61-a742-47a5-b51c-3a0843af7f12',
  'name': 'RFP Scenario 1: 440824',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 332,
  'rfpUuid': '6d035208-4881-4856-a652-ca7bbfa13316',
  'name': 'RFP Scenario 2: 588462',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 333,
  'rfpUuid': '9b4862d0-dca1-4d9f-affb-fac3975eb10e',
  'name': 'RFP Scenario 3: 841565',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 334,
  'rfpUuid': '0f397702-b42b-481a-8033-608376de9880',
  'name': 'RFP Scenario 4 GT: 56771',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 335,
  'rfpUuid': '75640fea-d8e2-4e94-834b-bab8902705a0',
  'name': 'Automated Scenario 5: 512026',
  'client': {'organizationId': 440, 'type': 'CLIENT', 'name': 'Auto Org Client 512026', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 336,
  'rfpUuid': 'ad87faca-25f8-42ec-88d3-3dd6175a1bb8',
  'name': 'Auto RFP: 582105',
  'client': {'organizationId': 444, 'type': 'CLIENT', 'name': 'Auto Org Client 582105', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 337,
  'rfpUuid': '8234ed88-c39d-43c4-b1f6-d60ca548b7bd',
  'name': 'RFP Scenario 1: 197943',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 338,
  'rfpUuid': 'acff1cc7-69af-4b2d-aabc-9106a360775f',
  'name': 'RFP Scenario 2: 283938',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 339,
  'rfpUuid': '3a09f642-c087-47f9-b344-540a6471d84b',
  'name': 'RFP Scenario 3: 211055',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 340,
  'rfpUuid': '525699b4-892a-48f1-bd3b-0a81748ce20e',
  'name': 'RFP Scenario 4 GT: 947760',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 341,
  'rfpUuid': '49aec384-2c13-45db-8a9e-ef76e5ef6667',
  'name': 'Automated Scenario 5: 881486',
  'client': {'organizationId': 448, 'type': 'CLIENT', 'name': 'Auto Org Client 881486', 'disabled': false},
  'userAccesses': [{'userId': 18, 'email': 'iRobot'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 342,
  'rfpUuid': '8f5ba920-2468-4e0f-8ca2-be83582a3e0e',
  'name': 'RfpName_2662',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 343,
  'rfpUuid': 'bd215e86-5ddd-49f0-b63a-efa7afaa5ee1',
  'name': 'RfpName_7700',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 344,
  'rfpUuid': '6cbdaa89-38e7-4693-bd32-eacb82890936',
  'name': 'testSeb',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 345,
  'rfpUuid': '546b6da6-ae32-4a89-8d21-3df221870be1',
  'name': 'ATExport',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 346,
  'rfpUuid': '54629cb3-bbb8-4d7d-93bc-9eac03a0857b',
  'name': 'RfpName_4195',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 347,
  'rfpUuid': '8e268604-4814-4ad9-8d6a-4d2736bb227a',
  'name': 'RfpName_6820',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 348,
  'rfpUuid': '3e05e628-7b97-4d48-b85d-4dadb260afc1',
  'name': '000000',
  'client': {'organizationId': 323, 'type': 'CLIENT', 'name': 'Aska-Client-1-ORG', 'disabled': false},
  'userAccesses': [{'userId': 8, 'email': 'j.zakoscielna@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 349,
  'rfpUuid': '25aee654-3858-4a92-838a-eac3aa8d878e',
  'name': 'RfpName_1453',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 350,
  'rfpUuid': 'a5c718c3-24cd-47ac-8bc4-16dff1286eed',
  'name': '681-testPartner',
  'client': {'organizationId': 323, 'type': 'CLIENT', 'name': 'Aska-Client-1-ORG', 'disabled': false},
  'userAccesses': [{'userId': 411, 'email': 'j.wojtas+Partner1@webellian.com'}, {
    'userId': 412,
    'email': 'j.wojtas+Partner2@webellian.com'
  }],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 351,
  'rfpUuid': '05b4d9c0-7aa5-4497-a1d8-3b8a2d18ecc3',
  'name': 'RfpName_4232',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 352,
  'rfpUuid': 'bae7c0e2-a8fd-4ffb-a929-69bdd125d50f',
  'name': 'RfpName_2601',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 353,
  'rfpUuid': '5c1bb965-c559-4cf5-a1d0-4971462b7c38',
  'name': 'RfpName_7338',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 354,
  'rfpUuid': '1f6d1844-e0a3-4a95-9ace-dbb3df14ff20',
  'name': 'RfpName_3054',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 355,
  'rfpUuid': '87b3e950-3aef-4c04-bd8a-dcf57174208e',
  'name': 'RfpName_8176',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 356,
  'rfpUuid': '022c8825-46c7-400a-82b3-8163aa00540a',
  'name': 'RfpName_1393',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}, {
  'rfpId': 357,
  'rfpUuid': '90d362a5-adeb-4315-9e6c-a1c325208877',
  'name': 'klops',
  'client': {'organizationId': 323, 'type': 'CLIENT', 'name': 'Aska-Client-1-ORG', 'disabled': false},
  'userAccesses': [{'userId': 411, 'email': 'j.wojtas+Partner1@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': true,
  'readOnly': false
}, {
  'rfpId': 358,
  'rfpUuid': '879e0b8c-55f1-49fb-87e2-af5a1eed85d8',
  'name': 'RfpName_3126',
  'client': {'organizationId': 6, 'type': 'CLIENT', 'name': 'R2D2', 'disabled': false},
  'userAccesses': [{'userId': 19, 'email': 's.lubiecki@webellian.com'}],
  'projectCreated': true,
  'finished': true,
  'clientNotified': false,
  'readOnly': false
}];
