export const marketsListMock = [
  {
    id: 1,
    name: 'Albania',
    code: 'AL',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 2,
    name: 'Andorra',
    code: 'AN',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 3,
    name: 'Austria',
    code: 'AU',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 4,
    name: 'Belarus',
    code: 'BY',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 2, name: 'USD', fullName: 'Dolar' }],
  },
  {
    id: 5,
    name: 'Belgium',
    code: 'BE',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 6,
    name: 'Bosnia and Herzegovina',
    code: 'BH',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 7,
    name: 'Bulgaria',
    code: 'BG',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 8,
    name: 'Croatia',
    code: 'CR',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 9,
    name: 'Czech Republic',
    code: 'CZ',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 10,
    name: 'Denmark',
    code: 'DK',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 11,
    name: 'Estonia',
    code: 'EE',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 12,
    name: 'Finland',
    code: 'FI',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 13,
    name: 'France',
    code: 'FR',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 14,
    name: 'Germany',
    code: 'GE',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 15,
    name: 'Greece',
    code: 'GR',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 16,
    name: 'Hungary',
    code: 'HU',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 17,
    name: 'Iceland',
    code: 'IC',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 18,
    name: 'Ireland',
    code: 'IR',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 19,
    name: 'Italy',
    code: 'IT',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 20,
    name: 'Latvia',
    code: 'LA',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 21,
    name: 'Liechtenstein',
    code: 'LI',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 22,
    name: 'Lithuania',
    code: 'LT',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 23,
    name: 'Luxembourg',
    code: 'LU',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 24,
    name: 'Malta',
    code: 'MA',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 25,
    name: 'Moldova',
    code: 'MD',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 26,
    name: 'Monaco',
    code: 'MO',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 27,
    name: 'Montenegro',
    code: 'ME',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 28,
    name: 'Netherlands',
    code: 'NH',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 29,
    name: 'North Macedonia',
    code: 'NM',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 30,
    name: 'Norway',
    code: 'NO',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 31,
    name: 'Poland',
    code: 'PL',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [
      { id: 3, name: 'PLN', fullName: 'Złoty' },
      { id: 2, name: 'USD', fullName: 'Dolar' },
      {
        id: 4,
        name: 'GBA',
        fullName: 'British Pound',
      },
      { id: 1, name: 'EUR', fullName: 'Euro' },
    ],
  },
  {
    id: 32,
    name: 'Portugal',
    code: 'PT',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 33,
    name: 'Romania',
    code: 'RO',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 34,
    name: 'Russia',
    code: 'RU',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 2, name: 'USD', fullName: 'Dolar' }],
  },
  {
    id: 35,
    name: 'San Marino',
    code: 'SM',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 36,
    name: 'Serbia',
    code: 'SR',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 37,
    name: 'Slovakia',
    code: 'SK',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 38,
    name: 'Slovenia',
    code: 'SI',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 39,
    name: 'Spain',
    code: 'SP',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 40,
    name: 'Sweden',
    code: 'SE',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 41,
    name: 'Switzerland',
    code: 'CH',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 42,
    name: 'Ukraine',
    code: 'UA',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 43,
    name: 'United Kingdom',
    code: 'UK',
    disabled: false,
    createdBy: 'liquibase',
    modifiedBy: 'liquibase',
    createdDate: '2023-02-10T11:35:24.542+00:00',
    modifiedDate: '2023-02-10T11:35:24.542+00:00',
    currencies: [{ id: 4, name: 'GBA', fullName: 'British Pound' }],
  },
  {
    id: 44,
    name: 'Auto Market 587201+e',
    code: 'Code 587201+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-10T11:39:14.746+00:00',
    modifiedDate: '2023-02-10T11:39:17.767+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 45,
    name: 'Auto Market 158245Auto Market 158245+e',
    code: 'Code 158245+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-10T12:06:28.949+00:00',
    modifiedDate: '2023-02-10T12:06:30.863+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 46,
    name: 'Auto Market 33023Auto Market 33023+e',
    code: 'Code 33023+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-10T12:16:34.092+00:00',
    modifiedDate: '2023-02-10T12:16:35.958+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 47,
    name: 'Auto Market 148627',
    code: 'Code 148627',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-10T12:41:57.128+00:00',
    modifiedDate: '2023-02-10T12:41:57.128+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 48,
    name: 'Auto Market 283995',
    code: 'Code 283995',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-10T12:45:16.104+00:00',
    modifiedDate: '2023-02-10T12:45:16.104+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 49,
    name: 'Auto Market 233126',
    code: 'Code 233126',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-10T12:47:43.882+00:00',
    modifiedDate: '2023-02-10T12:47:43.882+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 50,
    name: 'Auto Market 632607',
    code: 'Code 632607',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-10T12:49:59.682+00:00',
    modifiedDate: '2023-02-10T12:49:59.682+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 51,
    name: 'Auto Market 803272',
    code: 'Code 803272',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-10T14:16:23.540+00:00',
    modifiedDate: '2023-02-10T14:16:23.540+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 52,
    name: 'Auto Market 433678+e',
    code: 'Code 433678+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-13T09:24:02.268+00:00',
    modifiedDate: '2023-02-13T09:24:04.941+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 53,
    name: 'Auto Market 548579Auto Market 548579+e',
    code: 'Code 548579+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-13T18:47:54.969+00:00',
    modifiedDate: '2023-02-13T18:47:57.711+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 54,
    name: 'Auto Market 418274',
    code: 'Code 418274',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-13T18:50:12.088+00:00',
    modifiedDate: '2023-02-13T18:50:12.088+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 55,
    name: 'Auto Market 343571',
    code: 'Code 343571',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-14T09:30:45.427+00:00',
    modifiedDate: '2023-02-14T09:30:45.427+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 56,
    name: 'Auto Market 269629+e',
    code: 'Code 269629+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-14T10:29:24.356+00:00',
    modifiedDate: '2023-02-14T10:29:28.886+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 57,
    name: 'Auto Market 77700',
    code: 'Code 77700',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-14T10:32:01.541+00:00',
    modifiedDate: '2023-02-14T10:32:01.541+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 58,
    name: 'Auto Market 959836',
    code: 'Code 959836',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-14T12:01:24.755+00:00',
    modifiedDate: '2023-02-14T12:01:24.755+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 59,
    name: 'Auto Market 494047',
    code: 'Code 494047',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-14T13:17:53.351+00:00',
    modifiedDate: '2023-02-14T13:17:53.351+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 60,
    name: 'Auto Market 903657',
    code: 'Code 903657',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-14T13:20:07.232+00:00',
    modifiedDate: '2023-02-14T13:20:07.232+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 61,
    name: 'Auto Market 295055',
    code: 'Code 295055',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-14T13:36:06.477+00:00',
    modifiedDate: '2023-02-14T13:36:06.477+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 62,
    name: 'Auto Market 25697',
    code: 'Code 25697',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-14T13:38:16.659+00:00',
    modifiedDate: '2023-02-14T13:38:16.659+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 63,
    name: 'Auto Market 609057+e',
    code: 'Code 609057+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-14T14:36:32.476+00:00',
    modifiedDate: '2023-02-14T14:36:36.459+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 64,
    name: 'Auto Market 211725',
    code: 'Code 211725',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-14T14:38:40.070+00:00',
    modifiedDate: '2023-02-14T14:38:40.070+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 65,
    name: 'Auto Market 617234+e',
    code: 'Code 617234+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-14T14:57:12.618+00:00',
    modifiedDate: '2023-02-14T14:57:14.592+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 66,
    name: 'Auto Market 856598',
    code: 'Code 856598',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-14T14:59:02.840+00:00',
    modifiedDate: '2023-02-14T14:59:02.840+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 67,
    name: 'Auto Market 585387',
    code: 'Code 585387',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-14T16:25:19.591+00:00',
    modifiedDate: '2023-02-14T16:25:19.591+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 68,
    name: 'Auto Market 579986Auto Market 579986+e',
    code: 'Code 579986+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-15T07:10:19.319+00:00',
    modifiedDate: '2023-02-15T07:10:21.368+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 69,
    name: 'Auto Market 705869',
    code: 'Code 705869',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-15T07:11:38.951+00:00',
    modifiedDate: '2023-02-15T07:11:38.951+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 70,
    name: 'Auto Market 630929Auto Market 630929+e',
    code: 'Code 630929+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-15T07:18:41.327+00:00',
    modifiedDate: '2023-02-15T07:18:43.388+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 71,
    name: 'Auto Market 250738',
    code: 'Code 250738',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-15T07:20:00.900+00:00',
    modifiedDate: '2023-02-15T07:20:00.900+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 72,
    name: 'Auto Market 634920+e',
    code: 'Code 634920+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-15T07:26:18.371+00:00',
    modifiedDate: '2023-02-15T07:26:20.630+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 73,
    name: 'Auto Market 573992Auto Market 573992+e',
    code: 'Code 573992+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-15T13:08:44.557+00:00',
    modifiedDate: '2023-02-15T13:08:46.537+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 74,
    name: 'Auto Market 648096',
    code: 'Code 648096',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-16T12:46:10.967+00:00',
    modifiedDate: '2023-02-16T12:46:10.967+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 75,
    name: 'Auto Market 234644',
    code: 'Code 234644',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-16T12:49:04.677+00:00',
    modifiedDate: '2023-02-16T12:49:04.677+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 76,
    name: 'Auto Market 864390',
    code: 'Code 864390',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-16T13:58:34.033+00:00',
    modifiedDate: '2023-02-16T13:58:34.033+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 77,
    name: 'Auto Market 135121+e',
    code: 'Code 135121+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-20T10:13:24.858+00:00',
    modifiedDate: '2023-02-20T10:13:26.628+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 78,
    name: 'Auto Market 206787+e',
    code: 'Code 206787+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-20T11:38:58.569+00:00',
    modifiedDate: '2023-02-20T11:39:00.406+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 79,
    name: 'Auto Market 597700',
    code: 'Code 597700',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-20T11:40:24.587+00:00',
    modifiedDate: '2023-02-20T11:40:24.587+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 80,
    name: 'Auto Market 236360',
    code: 'Code 236360',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-20T11:43:08.794+00:00',
    modifiedDate: '2023-02-20T11:43:08.794+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 81,
    name: 'Auto Market 397149',
    code: 'Code 397149',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-20T11:54:18.159+00:00',
    modifiedDate: '2023-02-20T11:54:18.159+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 82,
    name: 'Auto Market 513395',
    code: 'Code 513395',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-20T12:04:58.836+00:00',
    modifiedDate: '2023-02-20T12:04:58.836+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 83,
    name: 'Auto Market 324241',
    code: 'Code 324241',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-20T12:06:39.646+00:00',
    modifiedDate: '2023-02-20T12:06:39.646+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 84,
    name: 'Auto Market 46025',
    code: 'Code 46025',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-20T12:08:44.649+00:00',
    modifiedDate: '2023-02-20T12:08:44.649+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 85,
    name: 'Auto Market 737598',
    code: 'Code 737598',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-20T12:39:29.095+00:00',
    modifiedDate: '2023-02-20T12:39:29.095+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 86,
    name: 'Auto Market 677886',
    code: 'Code 677886',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-20T13:42:38.692+00:00',
    modifiedDate: '2023-02-20T13:42:38.692+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 87,
    name: 'Auto Market 39267',
    code: 'Code 39267',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-20T13:45:59.607+00:00',
    modifiedDate: '2023-02-20T13:45:59.607+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 88,
    name: 'Auto Market 825388',
    code: 'Code 825388',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-20T13:49:23.932+00:00',
    modifiedDate: '2023-02-20T13:49:23.932+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 89,
    name: 'Auto Market 717375',
    code: 'Code 717375',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-20T13:49:41.210+00:00',
    modifiedDate: '2023-02-20T13:49:41.210+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 90,
    name: 'Auto Market 833014+e',
    code: 'Code 8330',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-21T11:10:16.598+00:00',
    modifiedDate: '2023-02-21T11:10:18.380+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 91,
    name: 'Auto Market 993790',
    code: 'Code 993790',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-21T11:11:37.038+00:00',
    modifiedDate: '2023-02-21T11:11:37.038+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 92,
    name: 'Auto Market 869296',
    code: 'Code 869296',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-21T11:20:17.081+00:00',
    modifiedDate: '2023-02-21T11:20:17.081+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 93,
    name: 'Auto Market 631381',
    code: 'Code 631381',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-21T11:28:28.025+00:00',
    modifiedDate: '2023-02-21T11:28:28.025+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 94,
    name: 'Auto Market 969928+e',
    code: 'Code 969928+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-21T13:02:30.925+00:00',
    modifiedDate: '2023-02-21T13:02:32.891+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 95,
    name: 'Auto Market 406559',
    code: 'Code 406559',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-21T13:03:48.766+00:00',
    modifiedDate: '2023-02-21T13:03:48.766+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 96,
    name: 'albania',
    code: 'aa',
    disabled: false,
    createdBy: 'f.bezpalko@webellian.com',
    modifiedBy: 'f.bezpalko@webellian.com',
    createdDate: '2023-02-22T21:03:36.852+00:00',
    modifiedDate: '2023-02-22T21:03:36.852+00:00',
    currencies: [{ id: 4, name: 'GBA', fullName: 'British Pound' }],
  },
  {
    id: 97,
    name: 'albania2',
    code: 'albania2',
    disabled: false,
    createdBy: 'f.bezpalko@webellian.com',
    modifiedBy: 'f.bezpalko@webellian.com',
    createdDate: '2023-02-24T17:16:49.088+00:00',
    modifiedDate: '2023-02-24T17:16:49.088+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 98,
    name: 'albania3',
    code: 'aaa',
    disabled: false,
    createdBy: 'f.bezpalko@webellian.com',
    modifiedBy: 'f.bezpalko@webellian.com',
    createdDate: '2023-02-24T17:17:04.502+00:00',
    modifiedDate: '2023-02-24T17:17:04.502+00:00',
    currencies: [{ id: 1, name: 'EUR', fullName: 'Euro' }],
  },
  {
    id: 99,
    name: 'Auto Market 188374+e',
    code: 'Code 188374+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-27T12:41:16.745+00:00',
    modifiedDate: '2023-02-27T12:41:20.620+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 100,
    name: 'Auto Market 779948+e',
    code: 'Code 779948+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-27T13:55:55.958+00:00',
    modifiedDate: '2023-02-27T13:55:59.779+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 101,
    name: 'Auto Market 669444+e',
    code: 'Code 669444+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-27T13:56:21.607+00:00',
    modifiedDate: '2023-02-27T13:56:24.484+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 102,
    name: 'Auto Market 738238+e',
    code: 'Code 738238+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-28T07:31:58.516+00:00',
    modifiedDate: '2023-02-28T07:32:01.525+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 103,
    name: 'Auto Market 76080+e',
    code: 'Code 76080+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-28T10:24:01.983+00:00',
    modifiedDate: '2023-02-28T10:24:04.040+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 104,
    name: 'Auto Market 874622',
    code: 'Code 874622',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-28T10:26:59.304+00:00',
    modifiedDate: '2023-02-28T10:26:59.304+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 105,
    name: 'Auto Market 487347+e',
    code: 'Code 487347+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-28T10:36:21.422+00:00',
    modifiedDate: '2023-02-28T10:36:23.263+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 106,
    name: 'Auto Market 486066+e',
    code: 'Code 486066+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-28T10:50:11.947+00:00',
    modifiedDate: '2023-02-28T10:50:15.311+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 107,
    name: 'Auto Market 401770',
    code: 'Code 401770',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-28T10:52:02.014+00:00',
    modifiedDate: '2023-02-28T10:52:02.014+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 108,
    name: 'Auto Market 661175+e',
    code: 'Code 661175+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-28T10:54:30.378+00:00',
    modifiedDate: '2023-02-28T10:54:32.341+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 109,
    name: 'Auto Market 710023',
    code: 'Code 710023',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-28T10:55:48.698+00:00',
    modifiedDate: '2023-02-28T10:55:48.698+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 110,
    name: 'Auto Market 575672+e',
    code: 'Code 575672+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-28T12:52:26.545+00:00',
    modifiedDate: '2023-02-28T12:52:28.706+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 111,
    name: 'Auto Market 663684+e',
    code: 'Code 663684+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-28T12:57:22.105+00:00',
    modifiedDate: '2023-02-28T12:57:24.203+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 112,
    name: 'Auto Market 561185',
    code: 'Code 561185',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-28T12:58:43.085+00:00',
    modifiedDate: '2023-02-28T12:58:43.085+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 113,
    name: 'Auto Market 315697',
    code: 'Code 315697',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-28T15:32:58.278+00:00',
    modifiedDate: '2023-02-28T15:32:58.278+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 114,
    name: 'Auto Market 359385',
    code: 'Code 359385',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-02-28T15:35:06.309+00:00',
    modifiedDate: '2023-02-28T15:35:06.309+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 115,
    name: 'Auto Market 4737+e',
    code: 'Code 4737+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-03-01T08:01:09.693+00:00',
    modifiedDate: '2023-03-01T08:01:12.563+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 116,
    name: 'Auto Market 817002',
    code: 'Code 817002',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-03-01T08:03:11.362+00:00',
    modifiedDate: '2023-03-01T08:03:11.362+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 117,
    name: 'Auto Market 896472+e',
    code: 'Code 896472+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-03-01T08:18:55.018+00:00',
    modifiedDate: '2023-03-01T08:18:59.267+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 118,
    name: 'Auto Market 690162',
    code: 'Code 690162',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-03-01T08:21:06.202+00:00',
    modifiedDate: '2023-03-01T08:21:06.202+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 119,
    name: 'Auto Market 993140+e',
    code: 'Code 993140+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-03-01T08:41:59.297+00:00',
    modifiedDate: '2023-03-01T08:42:03.300+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 120,
    name: 'Auto Market 470367',
    code: 'Code 470367',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-03-01T08:42:09.895+00:00',
    modifiedDate: '2023-03-01T08:42:09.895+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 121,
    name: 'Auto Market 473771+e',
    code: 'Code 473771+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-03-01T09:03:55.694+00:00',
    modifiedDate: '2023-03-01T09:03:58.477+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 122,
    name: 'Auto Market 674655',
    code: 'Code 674655',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-03-01T09:06:05.913+00:00',
    modifiedDate: '2023-03-01T09:06:05.913+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 123,
    name: 'Auto Market 38766+e',
    code: 'Code 38766+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-03-01T09:08:59.029+00:00',
    modifiedDate: '2023-03-01T09:09:02.297+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 124,
    name: 'Auto Market 519846+e',
    code: 'Code 519846+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-03-01T09:16:14.426+00:00',
    modifiedDate: '2023-03-01T09:16:19.173+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 125,
    name: 'Auto Market 553456',
    code: 'Code 553456',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-03-01T09:19:12.553+00:00',
    modifiedDate: '2023-03-01T09:19:12.553+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 126,
    name: 'Auto Market 11698+e',
    code: 'Code 11698+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-03-01T12:07:02.332+00:00',
    modifiedDate: '2023-03-01T12:07:05.608+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 127,
    name: 'Auto Market 207001+e',
    code: 'Code 207001+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-03-01T16:42:20.376+00:00',
    modifiedDate: '2023-03-01T16:42:23.713+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 128,
    name: 'Auto Market 271597',
    code: 'Code 271597',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-03-01T16:44:19.360+00:00',
    modifiedDate: '2023-03-01T16:44:19.360+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 129,
    name: 'Auto Market 475450+e',
    code: 'Code 475450+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-03-02T08:50:53.141+00:00',
    modifiedDate: '2023-03-02T08:50:55.294+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 130,
    name: 'Auto Market 112872+e',
    code: 'Code 112872+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-03-02T08:58:44.966+00:00',
    modifiedDate: '2023-03-02T08:58:47.629+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 131,
    name: 'Auto Market 784769',
    code: 'Code 784769',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-03-02T09:00:11.410+00:00',
    modifiedDate: '2023-03-02T09:00:11.410+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 132,
    name: 'Auto Market 550405+e',
    code: 'Code 550405+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-03-02T09:19:51.120+00:00',
    modifiedDate: '2023-03-02T09:19:53.167+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 133,
    name: 'Auto Market 968376',
    code: 'Code 968376',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-03-02T09:21:12.152+00:00',
    modifiedDate: '2023-03-02T09:21:12.152+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 134,
    name: 'Auto Market 523422+e',
    code: 'Code 523422+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-03-02T10:00:22.929+00:00',
    modifiedDate: '2023-03-02T10:00:26.757+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 135,
    name: 'Auto Market 889684',
    code: 'Code 889684',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-03-02T10:02:38.056+00:00',
    modifiedDate: '2023-03-02T10:02:38.056+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 136,
    name: 'Auto Market 267050+e',
    code: 'Code 267050+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-03-02T10:10:06.779+00:00',
    modifiedDate: '2023-03-02T10:10:08.869+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 137,
    name: 'Auto Market 582105',
    code: 'Code 582105',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-03-02T10:11:33.515+00:00',
    modifiedDate: '2023-03-02T10:11:33.515+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 138,
    name: 'Auto Market 391484+e',
    code: 'Code 391484+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-03-02T11:36:04.369+00:00',
    modifiedDate: '2023-03-02T11:36:06.562+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
  {
    id: 139,
    name: 'Auto Market 375769+e',
    code: 'Code 375769+e',
    disabled: false,
    createdBy: 'iRobot',
    modifiedBy: 'iRobot',
    createdDate: '2023-03-02T11:50:50.449+00:00',
    modifiedDate: '2023-03-02T11:50:54.448+00:00',
    currencies: [{ id: 3, name: 'PLN', fullName: 'Złoty' }],
  },
];
