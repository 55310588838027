<div *nzModalTitle>
  <div nz-row nzJustify="center">
    <h3>
      <span nz-icon nzType="edit" nzTheme="outline"></span>
      {{ "LOGGED_IN.REPORT.editWorkspaceUsers.title" | translate }}
    </h3>
  </div>
</div>

<div nz-row nzJustify="center">
  <div [nzSpan]="12" nz-col>
    <form nz-form [formGroup]="usersForm" [nzAutoTips]="autoTips">
      <nz-form-item>
        <nz-form-label [nzSpan]="8" [nzFor]="ProjectData.USER_ACCESSES">
          {{ "RFP.ADD.assignedUsers" | translate }}
        </nz-form-label>
        <nz-form-control [nzSpan]="14">
          <nz-select
            nzShowSearch
            nzAllowClear
            nzMode="multiple"
            [formControlName]="ProjectData.USER_ACCESSES"
            [id]="ProjectData.USER_ACCESSES"
            [nzPlaceHolder]="'RFP.ADD.placeholders.assignedUsers' | translate"
          >
            <nz-option *ngFor="let user of users" [nzValue]="user[UserEnum.Id]" [nzLabel]="user[UserEnum.Email]"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</div>

<div nz-row class="mt-4" nzJustify="end">
  <button nz-button id="modal-confirmation-button-cancel" class="mr-2" (click)="cancel($event)">
    <i nz-icon nzType="close" nzTheme="outline"></i>
    {{ "COMMON.cancel" | translate }}
  </button>
  <button (click)="submit($event)" nz-button nzType="primary" class="ml-2">
    <i nz-icon nzType="check" nzTheme="outline"></i>
    {{ "COMMON.save" | translate }}
  </button>
</div>
