import { AbstractControl, FormGroup } from '@angular/forms';
import { hasBackendErrorPrefix } from './hasBackendErrorPrefix';

export const displayFormErrors = (form: FormGroup): void => {
  Object.values(form.controls).forEach((control: AbstractControl) => {
    checkControl(control);
  });
};

export const checkControl = (control: AbstractControl): void => {
  const [firstError] = Object.keys(control.errors || {});
  if (control.invalid && !hasBackendErrorPrefix(firstError)) {
    control.markAsDirty();
    control.updateValueAndValidity({ onlySelf: true });
  }
};
