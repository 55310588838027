<div>
  <nz-result
    nzTitle="Dev error"
    nzStatus="error"
    nzSubTitle="Please check the following information before trying to perform the action again."
  >
    <div nz-result-content class="error-details">
      <div class="desc">
        <h4 nz-title>The action taken has the following error:</h4>
        <ng-container *ngIf="isObject(data[ErrorModalEnum.Detail]); else onlyErrorText">
          <section *ngFor="let detail of data[ErrorModalEnum.Detail]; let index = index">
            <p><i nz-icon nzType="warning" nzTheme="outline"></i> {{ index }}</p>
            <div class="section-container">
              <p>
                <strong> Location: </strong>
                <span *ngFor="let location of detail[ErrorModalEnum.Loc]; let i = index">
                  {{ location }}
                  {{ i === detail[ErrorModalEnum.Loc].length - 1 ? "" : "-" }}
                </span>
              </p>

              <p nz-paragraph><strong> Message: </strong> {{ detail[ErrorModalEnum.Msg] }}</p>
              <p nz-paragraph><strong> Type: </strong> {{ detail[ErrorModalEnum.Type] }}</p>
            </div>
          </section>
        </ng-container>
        <ng-template #onlyErrorText> {{ data[ErrorModalEnum.Detail] }} </ng-template>
        <div class="mt-2" *ngIf="isObject(data[ErrorModalEnum.Error]) && data[ErrorModalEnum.Error]">
          {{ data[ErrorModalEnum.Error][ErrorModalEnum.Detail] }}
        </div>
      </div>
    </div>
  </nz-result>
</div>
<div *nzModalFooter>
  <button nz-button (click)="back()">{{ "COMMON.back" | translate }}</button>
</div>
