<nz-page-header [nzTitle]="'CLIENT.title' | translate" [nzGhost]="false">
  <nz-page-header-extra>
    <button nz-button nzType="primary" (click)="createClient()">
      <i nz-icon nzType="plus" nzTheme="outline"></i>{{ "CLIENT.CREATE.create" | translate }}
    </button>
  </nz-page-header-extra>
</nz-page-header>

<app-client-filter
  (submitted)="search($event)"
  [filterValuesData]="clientFiltersValue"
  [selectsData$]="selectsData$"
  [clients$]="clients$"
></app-client-filter>

<div class="section-container">
  <ng-container *ngIf="clients$ | async as clients">
    <nz-table
      #table
      [nzData]="clients"
      [nzLoading]="pending"
      nzSize="middle"
      nzShowPagination
      nzShowSizeChanger
      [nzPageSize]="ApplicationConf[ApplicationConfEnum.DefaultPageSize]"
      (nzCurrentPageDataChange)="currentPageDataChange($event)"
    >
      <thead>
        <tr>
          <th [nzColumnKey]="ClientEnum.Name" nzShowSort [nzSortFn]="sortName">
            {{ "CLIENT.table.column.clientName" | translate }}
          </th>
          <th [nzColumnKey]="ClientEnum.Parent" nzShowSort [nzSortFn]="sortParent">
            {{ "CLIENT.table.column.parent" | translate }}
          </th>
          <th [nzColumnKey]="ClientEnum.Markets">
            {{ "CLIENT.table.column.markets" | translate }}
          </th>
          <th [nzColumnKey]="ClientEnum.Disabled" nzAlign="center">
            {{ "CLIENT.table.column.active" | translate }}
          </th>
          <th nzColumnKey="action" nzWidth="10rem" nzAlign="center">
            {{ "COMMON.options" | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of table.data">
          <td>{{ data[ClientEnum.Name] }}</td>
          <td>{{ data[ClientEnum.Parent] ? data[ClientEnum.Parent]![ClientEnum.Name] : "" }}</td>
          <td>
            <nz-tag [nzColor]="'blue'" *ngFor="let market of data[ClientEnum.Markets]">
              {{ market[MarketEnum.Name] }}
            </nz-tag>
          </td>
          <td nzAlign="center">
            <i
              nz-icon
              [nzType]="data[ClientEnum.Disabled] ? 'close' : 'check'"
              [ngClass]="data[ClientEnum.Disabled] ? 'color-red' : 'color-green'"
              nzTheme="outline"
            ></i>
          </td>
          <td>
            <span
              *ngIf="data[ClientEnum.Disabled]"
              class="icon-action"
              nz-typography
              nzType="success"
              [nzTooltipTitle]="'COMMON.enable' | translate"
              nzTooltipPlacement="top"
              nz-button
              nz-tooltip
              (click)="enableClient($event, data[ClientEnum.Id])"
              ><i nz-icon nzType="check-circle" nzTheme="outline"></i
            ></span>
            <span
              *ngIf="!data[ClientEnum.Disabled]"
              class="icon-action"
              nz-typography
              nzType="danger"
              [nzTooltipTitle]="'COMMON.disable' | translate"
              nzTooltipPlacement="top"
              nz-button
              nz-tooltip
              (click)="disableClient($event, data[ClientEnum.Id])"
              ><i nz-icon nzType="close-circle" nzTheme="outline"></i
            ></span>
            <button
              nz-button
              nzType="default"
              nzSize="small"
              (click)="editClient(data)"
              [nz-tooltip]="'COMMON.edit' | translate"
              nzShape="circle"
            >
              <i nz-icon nzType="edit"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </ng-container>
</div>
