import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toStringArrays',
})
export class ToStringArraysPipe implements PipeTransform {
  transform(array: unknown[] | undefined, field: string): any {
    if (!array) {
      return [];
    }
    return array.map((item: any) => item[field]);
  }
}
