import { Injectable } from '@angular/core';
import { BehaviorSubject, mergeMap, Observable, Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { RestApiService } from '@shared/services/rest-api/rest-api.service';
import { UserLogin } from '@auth/components/login/login.interface';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import CustomError from '@shared/classes/CustomError.class';
import { CustomErrorEnum, CustomErrorType } from '@shared/interfaces/custom-error.interface';
import { Vault } from '@ultimate/vault';
import {
  LoginResponse,
  LoginResponseEnum,
  RoleTypeEnum,
  UserAuth,
  UserAuthEnum,
  UserObject,
  UserObjectEnum,
} from '@auth/interfaces/user.interface';
import { AccountActivation } from '@auth/components/activate/activate.interface';
import { Router } from '@angular/router';
import { ForgotPasswordInterface } from '@auth/components/forgot-password/forgot-password.interface';
import { ResetPassword } from '@auth/components/reset-password/reset-password.interface';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _user$ = new BehaviorSubject<UserObject | null>(null);
  public user$ = this._user$.asObservable();
  public isLoggedIn$ = this.user$.pipe(map((user) => !!user));
  private _errorLogin$ = new Subject<CustomError | null>();
  public errorLogin$ = this._errorLogin$.asObservable();
  private _errorLogout$ = new Subject<HttpErrorResponse | null>();
  public errorLogout$ = this._errorLogout$.asObservable();

  public userRole$ = this.user$.pipe(map((user) => user?.[UserObjectEnum.UserData].role));

  constructor(
    private readonly restApiService: RestApiService,
    private readonly router: Router,
    private readonly translateService: TranslateService
  ) {}

  get currentUserValue(): UserObject | CustomError | null {
    return this._user$.value;
  }

  public isLoggedIn(): boolean {
    return !!this.currentUserValue;
  }

  public isPartnerAccount(): boolean {
    const currentUser = <UserObject>this.currentUserValue;
    return currentUser[UserObjectEnum.UserData]?.[UserAuthEnum.Role] === RoleTypeEnum.PartnerUser;
  }

  public isClientAccount(): boolean {
    const currentUser = <UserObject>this.currentUserValue;
    return currentUser[UserObjectEnum.UserData]?.[UserAuthEnum.Role] === RoleTypeEnum.ClientUser;
  }

  public isAgencyAccount(): boolean {
    const currentUser = <UserObject>this.currentUserValue;
    return currentUser[UserObjectEnum.UserData]?.[UserAuthEnum.Role] === RoleTypeEnum.AgencyUser;
  }

  public isAdminAccount(): boolean {
    const currentUser = <UserObject>this.currentUserValue;
    return currentUser[UserObjectEnum.UserData]?.[UserAuthEnum.Role] === RoleTypeEnum.Admin;
  }

  public isUserOrAdminAccount(): boolean {
    const currentUser = <UserObject>this.currentUserValue;
    const userData: UserAuth = currentUser[UserObjectEnum.UserData];
    if (!userData) {
      return false;
    }
    return (
      userData[UserAuthEnum.Role] === RoleTypeEnum.StarsUser ||
      userData[UserAuthEnum.Role] === RoleTypeEnum.PartnerUser ||
      userData[UserAuthEnum.Role] === RoleTypeEnum.Admin
    );
  }

  public login(payload: UserLogin) {
    const localStorage: Vault = new Vault({ type: 'local' });
    let loginResponse: LoginResponse = null!;
    this.restApiService
      .post('login', payload)
      .pipe(
        mergeMap((response: LoginResponse) => {
          localStorage.set<string>(LoginResponseEnum.AccessToken, response[LoginResponseEnum.AccessToken]);
          localStorage.set<string>(LoginResponseEnum.RefreshToken, response[LoginResponseEnum.RefreshToken]);
          loginResponse = response;
          return this.getUserData();
        })
      )
      .subscribe({
        next: (user: UserAuth) => {
          localStorage.set<UserAuth>(UserObjectEnum.UserData, user);
          this._user$.next({
            [UserObjectEnum.LoginResponse]: loginResponse,
            [UserObjectEnum.UserData]: user,
          });
        },
        error: (error: HttpErrorResponse) => {
          let customError: CustomError;
          switch (error.error.errorCode) {
            case 'password_too_old': {
              customError = new CustomError({
                [CustomErrorEnum.Message]: error.error.additionalErrorMessage,
                [CustomErrorEnum.MessageCode]: 'password_too_old',
                [CustomErrorEnum.Type]: CustomErrorType.Form,
              });
              break;
            }
            case 'user_first_login': {
              customError = new CustomError({
                [CustomErrorEnum.Message]: error.error.additionalErrorMessage,
                [CustomErrorEnum.MessageCode]: 'user_first_login',
                [CustomErrorEnum.Type]: CustomErrorType.Form,
              });
              break;
            }
            case 'user_is_blocked': {
              customError = new CustomError({
                [CustomErrorEnum.Message]: error.error.additionalErrorMessage,
                [CustomErrorEnum.MessageCode]: 'user_is_blocked',
                [CustomErrorEnum.Type]: CustomErrorType.Form,
              });
              break;
            }
            case 'user_is_disabled': {
              customError = new CustomError({
                [CustomErrorEnum.Message]: error.error.additionalErrorMessage,
                [CustomErrorEnum.MessageCode]: 'user_is_disabled',
                [CustomErrorEnum.Type]: CustomErrorType.Form,
              });
              break;
            }
            case 'user_is_not_active': {
              customError = new CustomError({
                [CustomErrorEnum.Message]: error.error.additionalErrorMessage,
                [CustomErrorEnum.MessageCode]: 'user_is_not_active',
                [CustomErrorEnum.Type]: CustomErrorType.Form,
              });
              break;
            }
            case 'organization_is_disabled': {
              customError = new CustomError({
                [CustomErrorEnum.Message]: error.error.additionalErrorMessage,
                [CustomErrorEnum.MessageCode]: 'organization_is_disabled',
                [CustomErrorEnum.Type]: CustomErrorType.Form,
              });
              break;
            }
            case 'authentication_exception': {
              customError = new CustomError({
                [CustomErrorEnum.Message]: error.error.additionalErrorMessage,
                [CustomErrorEnum.MessageCode]: 'authentication_exception',
                [CustomErrorEnum.Type]: CustomErrorType.Form,
              });
              break;
            }
            case 'authentication_error': {
              customError = new CustomError({
                [CustomErrorEnum.Message]: error.error.additionalErrorMessage,
                [CustomErrorEnum.MessageCode]: 'authentication_exception',
                [CustomErrorEnum.Type]: CustomErrorType.Form,
              });
              break;
            }
            default: {
              customError = new CustomError({
                [CustomErrorEnum.Message]: this.translateService.instant('NOTIFICATION.error.unexpectedError.description'),
                [CustomErrorEnum.MessageCode]: 'unexpected_exception',
                [CustomErrorEnum.Type]: CustomErrorType.Default,
              });
              break;
            }
          }

          this._errorLogin$.next(customError);
        },
      });
  }

  public activate(payload: AccountActivation, uuid: string): Observable<any> {
    return this.restApiService.post('users/activate/' + uuid, payload);
  }

  public resetPassword(payload: ResetPassword, uuid: string): Observable<any> {
    return this.restApiService.post('users/resetPassword/' + uuid, payload);
  }

  public getUuidCode(email: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.restApiService.post('users/generateUuid', email, { headers, responseType: 'text' });
  }

  public updateUserData(user: UserAuth): void {
    const localStorage = new Vault({ type: 'local' });
    this.user$.pipe(first()).subscribe((userObject) => {
      localStorage.set<UserAuth>(UserObjectEnum.UserData, user);
      this._user$.next({
        ...userObject!,
        [UserObjectEnum.UserData]: user,
      });
    });
  }

  public setUserValue(user: UserObject): void {
    this._user$.next(user);
  }

  remindPassword(payload: ForgotPasswordInterface) {
    return this.restApiService.post('users/generatePasswordResetEmail', payload);
  }

  logout(): void {
    this.deleteUserFromStorage();
    this._user$.next(null);
    // @TODO currently we are removing token only on the front-end, but in the future we should do something also on the back-end
    // return this.restApiService.post('auth/logOut').subscribe({
    //   next: (data: any) => {
    //     this.deleteUserFromStorage();
    //   },
    //   error: (error: HttpErrorResponse) => {
    //     this.deleteUserFromStorage();
    //     this._errorLogout$.next(error);
    //   },
    // });
  }

  logoutWithoutRequest(): void {
    this._user$.next(null!);
    this.deleteUserFromStorage();
    let currentUrl = '';
    if (!this.router.url.includes('login')) {
      if (this.router.url !== currentUrl) {
        currentUrl = this.router.url;
      }
      this.router.navigate(['/'], { queryParams: { returnUrl: currentUrl } });
    }
  }

  private getUserData(): Observable<any> {
    return this.restApiService.get('users/me');
  }

  private deleteUserFromStorage(): void {
    localStorage.removeItem(LoginResponseEnum.AccessToken);
    localStorage.removeItem(LoginResponseEnum.RefreshToken);
    localStorage.removeItem(UserObjectEnum.UserData);
  }
}
