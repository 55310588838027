import { Injectable } from '@angular/core';
import { NzNotificationDataOptions } from 'ng-zorro-antd/notification';
import { Subject } from 'rxjs';
import { Notification, NotificationEnum, NotificationType } from './notification.const';
import { ApplicationConf, ApplicationConfEnum } from '@shared/consts/application-conf.const';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private _notification$ = new Subject<NzNotificationDataOptions<{}>>();
  public notification$ = this._notification$.asObservable();

  constructor() {}

  create(notification: Notification): void {
    notification[NotificationEnum.Options] = {
      ...this.getDefaultOptions(notification[NotificationEnum.Type]),
      ...notification[NotificationEnum.Options],
    };
    this._notification$.next(NotificationService.mapToNzNotification(notification));
  }

  private static mapToNzNotification({ options, ...data }: Notification): NzNotificationDataOptions<{}> {
    return {
      nzData: data,
      nzCloseIcon: options?.closeIcon,
      nzKey: options?.key,
      nzStyle: options?.style,
      nzClass: options?.class,
      nzDuration: options?.duration,
      nzPauseOnHover: options?.pauseOnHover,
      nzAnimate: options?.animate,
    };
  }

  getDefaultOptions(type: NotificationType) {
    return {
      duration:
        type === NotificationType.Error
          ? ApplicationConf[ApplicationConfEnum.NotificationErrorDurationDefaultTime]
          : ApplicationConf[ApplicationConfEnum.NotificationDurationDefaultTime],
    };
  }
}
