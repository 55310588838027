import { QuadraFileType } from '@shared-project/modules/files-manager/files-manager.interface';
import { QuadraFlowStatuses } from '@shared/interfaces/quadra-file.interface';

export const quadraFilesMock = [
  {
    fileId: 0,
    filename: 'POOL file',
    createdDate: '2023-11-08T11:00:32.641Z',
    modifiedDate: '2023-11-08T11:00:32.641Z',
    status: QuadraFlowStatuses.TODO,
    type: QuadraFileType.POOL,
    createdBy: 'Fabio',
    modifiedBy: 'Merkury',
  },
  {
    fileId: 1,
    filename: 'QAD FILE',
    createdDate: '2023-11-08T11:00:32.641Z',
    modifiedDate: '2023-11-08T11:00:32.641Z',
    flowState: QuadraFlowStatuses.TODO,
    type: QuadraFileType.QAD,
    createdBy: 'Fabio',
    modifiedBy: 'Merkury',
  },
  {
    fileId: 2,
    filename: 'QCT FILE',
    createdDate: '2023-11-08T11:00:32.641Z',
    modifiedDate: '2023-11-08T11:00:32.641Z',
    flowState: QuadraFlowStatuses.TODO,
    type: QuadraFileType.QCT,
    createdBy: 'Fabio',
    modifiedBy: 'Merkury',
  },
  {
    fileId: '',
    filename: 'QD File',
    createdDate: '2023-11-08T11:00:32.641Z',
    modifiedDate: '2023-11-08T11:00:32.641Z',
    flowState: QuadraFlowStatuses.TODO,
    type: QuadraFileType.QD,
    createdBy: 'Fabio',
    modifiedBy: 'Merkury',
  },
  {
    fileId: 4,
    filename: 'QAD TEMPLATE',
    createdDate: '2023-11-08T11:00:32.641Z',
    modifiedDate: '2023-11-08T11:00:32.641Z',
    flowState: undefined,
    type: QuadraFileType.QAD_TEMPLATE,
    createdBy: 'Fabio',
    modifiedBy: 'Merkury',
  },
  {
    fileId: 5,
    filename: 'QCT TEMPLATE',
    createdDate: '2023-11-08T11:00:32.641Z',
    modifiedDate: '2023-11-08T11:00:32.641Z',
    flowState: undefined,
    type: QuadraFileType.QCT_TEMPLATE,
    createdBy: 'Fabio',
    modifiedBy: 'Merkury',
  },
];
