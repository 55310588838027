import { DataFormats } from '@shared/interfaces/data-formats.interface';
import { CalculationsTypes } from 'src/app/logged-in/modules/value-track/projects/project/modules/agency/components/table/interfaces/calculations-raw.interface';
import { Dimension, DimensionEnum } from '../../../../kpi/interfaces/dimension.interface';
import { SelectedCalculation } from './calculation.interface';
import { ColumnValidators, ColumnValidatorsEnum } from './validators.interface';
import { Worksheet, WorksheetEnum } from './worksheet.interface';

// Raw

export enum ColumnEnum {
  GenericType = 'genericType',
  ColumnName = 'columnName',
  ColumnUid = 'columnUid',
  CalculationsType = 'calculationsType',
  ColumnIndex = 'columnIndex',
  ColumnType = 'columnType',
  Ranges = 'valueRange',
  CalculatedColumn = 'equalityColumnName',
  DataType = 'dataType',
  Format = 'format',
  ColumnWidth = 'columnWidth',
  DimensionNames = 'dimensionNames',
  EnumValues = 'enumValues',
  IsRequired = 'isRequired',
  Calculations = 'calculations',
  RelatedDimensions = 'relatedDimension',
}

export enum ColumnGenericType {
  Custom = 'custom',
  Kpi = 'kpi',
  Dimension = 'dimension',
  DefaultColumn = 'defaultColumn',
  ProjectDimension = 'projectDimension',
  NotIndexableDefaultColumn = 'notIndexabledefaultColumn',
}

export interface Column {
  genericType: ColumnGenericType;
  columnUid: string;
  columnName: string;
  columnIndex: number | null;
  // dimension: Dimension[];
  dimensionNames: Dimension[DimensionEnum.Name][];
  columnType: ColumnType | null;
  dataType: DataFormats | null;
  format?: string;
  columnWidth: number;
  enumValues: string[];
  isRequired: boolean;
  [ColumnEnum.Ranges]: ColumnValidators[ColumnValidatorsEnum.Range] | null;
  [ColumnEnum.CalculatedColumn]: ColumnValidators[ColumnValidatorsEnum.CalculatedColumn] | null;
  calculations: Partial<SelectedCalculation> | null;
  calculationsType?: CalculationsTypes;
  columnValue?: string;
}

export enum ColumnType {
  Calculations = 'Calculation',
  AgencyData = 'AgencyData',
  UsersData = 'StarsData',
}

export enum ColumnPrefix {
  CustomColumn = 'C',
  Kpi = 'K',
  Dimension = 'D',
  DefaultColumn = 'DC',
  ProjectDimension = 'PD',
}

// Helpers

export type DeleteColumnData = Pick<Column, ColumnEnum.ColumnUid | ColumnEnum.GenericType> &
  Record<'index', number> &
  Partial<Pick<Worksheet, WorksheetEnum.SheetUid>>;

export type EditColumnName = Pick<Column, ColumnEnum.ColumnName> & Record<'index', number>;

export type EditColumnData = Record<'column', Column> & Record<WorksheetEnum.SheetUid, string>;

export interface ResetedColumn {
  selectedColumn: Column;
  medium: string;
  worksheet: Worksheet;
}

export enum SelectedColumnEnum {
  Index = `index`,
  Item = `item`,
  ParentWorksheet = `parentWorksheet`,
}

export interface SelectedColumn {
  [SelectedColumnEnum.Index]: number;
  [SelectedColumnEnum.Item]: Column;
  [SelectedColumnEnum.ParentWorksheet]?: Worksheet;
}
