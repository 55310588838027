export enum ScoringLayoutEnum {
  RFP_NAME = 'rfpName',
  RFP_ID = 'rfpId',
  PROJECT_NAME = 'projectName',
  PROJECT_ID = 'projectId',
  GT_USER_DATA_PATH_IN = 'gtUserDataPathIn',
  SCORING_DATA_PATH_IN = 'scoringDataPathIn',
  SCORING_DATA_PATH_OUT = 'scoringDataPathOut',
  REQUEST_UUID = 'requestUuid',
  AGENCY_GT_LIST = 'agency_gt_list',
  KPI_WEIGHT = 'kpi_weight',
  STRATEGY_SCORES_LIST = 'strategy_scores_list',
}

export enum KPIWeightEnum {
  NAME = 'name',
  WEIGHT = 'weight',
  CHILD_LIST = 'childList',
  ENTRY_TYPE = 'entryType',
  CHOICE = 'choice',
}

export interface ScoringLayout {
  [ScoringLayoutEnum.RFP_NAME]: string | null;
  [ScoringLayoutEnum.RFP_ID]: string | null;
  [ScoringLayoutEnum.PROJECT_NAME]: string | null;
  [ScoringLayoutEnum.PROJECT_ID]: string | null;
  [ScoringLayoutEnum.GT_USER_DATA_PATH_IN]: string | null;
  [ScoringLayoutEnum.SCORING_DATA_PATH_IN]: string | null;
  [ScoringLayoutEnum.SCORING_DATA_PATH_OUT]: string | null;
  [ScoringLayoutEnum.REQUEST_UUID]: string | null;
  [ScoringLayoutEnum.AGENCY_GT_LIST]: string | null;
  [ScoringLayoutEnum.KPI_WEIGHT]: KPIWeight;
  [ScoringLayoutEnum.STRATEGY_SCORES_LIST]: Score.Data[] | null;
}

export namespace Score {
  export enum DataEnum {
    StrategyCategory = 'strategyCategory',
    StrategyTaskName = 'strategyTaskName',
    AgencyId = 'agencyId',
    AgencyName = 'agencyName',
    Score = 'score',
    Id = 'id',
  }

  export interface Data {
    [DataEnum.StrategyCategory]: string;
    [DataEnum.StrategyTaskName]: string;
    [DataEnum.AgencyId]: number | null;
    [DataEnum.AgencyName]: string | null;
    [DataEnum.Score]: number | null;
    [DataEnum.Id]: string;
  }
}

export enum NodeType {
  KPI = 'KPI',
  MEDIUM = 'MEDIUM',
  PROJECT = 'PROJECT',
  DIMENSION = 'DIMENSION',
  BRANCH = 'STRATEGY',
  SUB_BRANCH = 'TASK',
  ENUM_VALUE = 'BRANCH',
  COST = 'COST',
  DIMENSION_LIST = 'DIMENSION_LIST',
}
export interface KPIWeight {
  [KPIWeightEnum.NAME]: string;
  [KPIWeightEnum.WEIGHT]: number | null;
  [KPIWeightEnum.CHOICE]: ChoiceField;
  [KPIWeightEnum.ENTRY_TYPE]: NodeType;
  [KPIWeightEnum.CHILD_LIST]: KPIWeight[];
}

export type ChoiceField = number | '' | null;
