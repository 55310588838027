import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DefaultFieldsWithOtherEnum } from '@shared/interfaces';

@Component({
  selector: 'input-boolean',
  templateUrl: './input-boolean.component.html',
  styleUrls: ['./input-boolean.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputBooleanComponent {
  @Input() formGroup!: any;
  @Input() nameOfFormControl: string = '';
  @Input() label: string = '';
  @Input() editMode: boolean = false;

  constructor() {}

  protected readonly DefaultFieldsWithOtherEnum = DefaultFieldsWithOtherEnum;
}
