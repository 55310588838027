import { Organization } from '@shared/interfaces/organization.interface';
import { ProjectTypeEnum } from '@shared/interfaces/project.interface';

export enum RfpFilterEnum {
  Name = 'name',
  Client = 'client',
  Type = 'type',
  Filled = 'filled',
  Status = 'status',
  Users = 'users',
  ProjectsCreated = 'projectsCreated',
  SearchText = 'searchText',
}

export type RfpFilters = {
  [RfpFilterEnum.Name]: string;
  [RfpFilterEnum.Client]: Organization;
  [RfpFilterEnum.Type]: ProjectTypeEnum;
  [RfpFilterEnum.Filled]: boolean;
  [RfpFilterEnum.ProjectsCreated]: boolean;
  [RfpFilterEnum.SearchText]: string;
};
