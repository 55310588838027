export enum DataFormats {
  Text = 'text',
  Boolean = 'boolean',
  Date = 'date',
  Time = 'time',
  Money = 'money',
  Numeric = 'numeric',
  Index = 'index',
  Integer = 'integer',
  Percentage = 'percentage',
}

export const DateFormatNumeric: DataFormats[] = [DataFormats.Index, DataFormats.Integer, DataFormats.Numeric, DataFormats.Money];
