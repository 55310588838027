import { QuadraFileType } from '@shared-project/modules/files-manager/files-manager.interface';
import { EngineProcessObjectEnum, EngineProcessObject } from './project.interface';

export enum QuadraFlowStatuses {
  TODO = 'TODO',
  DATA_READY = 'DATA_READY',
  DATA_VERIFIED = 'DATA_VERIFIED',
  IN_QUADRA_MACRO = 'IN_QUADRA_MACRO',
  SKIPPED = 'SKIPPED',
}

export enum QuadraFileEnum {
  FileName = 'filename',
  CreatedDate = 'createdDate',
  UploadedBy = 'uploadedBy',
  ModifiedDate = 'modifiedDate',
  FlowState = 'status',
  Type = 'type',
  ModifiedBy = 'modifiedBy',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  FileId = 'fileId',
  LastExecutionStatus = 'lastExecutionStatus',
}

export interface QuadraFile {
  [QuadraFileEnum.CreatedDate]: string;
  [QuadraFileEnum.ModifiedDate]: string;
  [QuadraFileEnum.FileName]: string;
  [QuadraFileEnum.FileId]: number;

  [QuadraFileEnum.Type]: QuadraFileType;
  [QuadraFileEnum.CreatedBy]: string;
  [QuadraFileEnum.ModifiedBy]: string;
  [QuadraFileEnum.UpdatedBy]: string;
  [QuadraFileEnum.FlowState]: QuadraFlowStatuses;
}

export type QuadraResult = QuadraFile & Pick<EngineProcessObject, EngineProcessObjectEnum.CalculationResult>;
