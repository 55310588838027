<div [formGroup]="formName">
  <nz-form-item>
    <nz-form-label [nzOffset]="4" [nzSpan]="24" [nzFor]="index.toString()">
      {{ question[QuestionEnum.Content] }}
    </nz-form-label>
    <nz-form-control [nzOffset]="4" [nzSpan]="24">
      <textarea
        [id]="index"
        [formControlName]="question[QuestionEnum.Content]"
        [ngClass]="disabled ? 'disabled' : ''"
        [readonly]="disabled"
        rows="2"
      >
      </textarea>
    </nz-form-control>
  </nz-form-item>
</div>
