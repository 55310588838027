import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, Subject, withLatestFrom } from 'rxjs';
import { Market, MarketEnum, MarketFilters, MarketSave } from '@shared/interfaces/market.interface';
import CustomError from '@shared/classes/CustomError.class';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomErrorEnum, CustomErrorType } from '@shared/interfaces/custom-error.interface';
import { NotificationEnum, NotificationType } from '@shared/components/notification/notification.const';
import { NotificationService } from '@shared/components/notification/notification.facade';
import { NzModalService } from 'ng-zorro-antd/modal';
import { filterMarketHelper } from 'src/app/logged-in/modules/admin/market/market.helper';
import { Currency } from '@shared/interfaces/currency.interface';
import { MarketService } from '@shared/services/markets/market.service';
import { MarketFilterValuesInit } from '../../../logged-in/modules/admin/market/market-form.const';

@Injectable()
export class MarketFacade {
  private _markets: BehaviorSubject<Market[]> = new BehaviorSubject<Market[]>([]);
  public readonly markets$: Observable<Market[]> = this._markets.asObservable();
  public readonly enabledMarkets$: Observable<Market[]> = this.markets$.pipe(
    map((markets: Market[]) => markets.filter((market: Market): boolean => !market[MarketEnum.Disabled]))
  );
  private _errorMarketAdd$: Subject<CustomError | null> = new Subject<CustomError | null>();
  public errorMarketAdd$: Observable<CustomError | null> = this._errorMarketAdd$.asObservable();
  private marketsAll: Market[] = [];
  private marketFilters: MarketFilters = MarketFilterValuesInit;

  constructor(
    private readonly marketService: MarketService,
    private readonly notificationService: NotificationService,
    private readonly modalService: NzModalService
  ) {}

  get currentValueMarkets(): Market[] {
    return this._markets.value;
  }

  get currentValueEnabledMarkets(): Market[] {
    return this._markets.value.filter((market: Market) => !market[MarketEnum.Disabled]);
  }

  getAll(): void {
    this.marketService.getAll().subscribe((markets: Market[]): void => {
      this.marketsAll = markets;
      this.filterMarkets(this.marketFilters);
    });
  }

  public getCurrencies(): Observable<Currency[]> {
    return this.marketService.getCurrencies();
  }

  filterMarkets(filters: MarketFilters): void {
    const filteredMedia: Market[] = filterMarketHelper(this.marketsAll, filters);
    this._markets.next(filteredMedia);
  }

  edit(id: number, market: MarketSave): void {
    this.marketService.edit(id, market).subscribe({
      next: (editedMarket: Market): void => {
        const marketValues: Market[] = this._markets.value.map((data) =>
          data[MarketEnum.Id] === editedMarket[MarketEnum.Id] ? editedMarket : data
        );
        this._markets.next([...marketValues]);
        this.marketsAll = this.replaceMarket(this.marketsAll, editedMarket);
        this.notificationService.create({
          [NotificationEnum.Type]: NotificationType.Success,
          [NotificationEnum.TextType]: 'marketEdit',
        });
        this.modalService.closeAll();
      },
      error: (err: HttpErrorResponse): void => {
        this.errorSwitch(err.error.errorCode);
      },
    });
  }

  save(market: MarketSave): void {
    this.marketService.save(market).subscribe({
      next: (market: Market) => {
        this.getAll();
        this.notificationService.create({
          [NotificationEnum.Type]: NotificationType.Success,
          [NotificationEnum.TextType]: 'marketAdd',
        });
        this.modalService.closeAll();
      },
      error: (err: HttpErrorResponse) => {
        this.errorSwitch(err.error.errorCode);
      },
    });
  }

  errorSwitch(err: string) {
    let customError: CustomError;
    switch (err) {
      case 'market_already_exists': {
        customError = new CustomError({
          [CustomErrorEnum.Message]: '',
          [CustomErrorEnum.MessageCode]: 'market_already_exists',
          [CustomErrorEnum.Type]: CustomErrorType.Form,
        });
        break;
      }
      case 'market_name_already_exists': {
        customError = new CustomError({
          [CustomErrorEnum.Message]: '',
          [CustomErrorEnum.MessageCode]: 'market_name_already_exists',
          [CustomErrorEnum.Type]: CustomErrorType.Form,
        });
        break;
      }
      case 'market_code_already_exists': {
        customError = new CustomError({
          [CustomErrorEnum.Message]: '',
          [CustomErrorEnum.MessageCode]: 'market_code_already_exists',
          [CustomErrorEnum.Type]: CustomErrorType.Form,
        });
        break;
      }
      default: {
        customError = new CustomError({
          [CustomErrorEnum.Message]: '',
          [CustomErrorEnum.MessageCode]: 'unexpected_error',
          [CustomErrorEnum.Type]: CustomErrorType.Default,
        });
      }
    }
    return this._errorMarketAdd$.next(customError);
  }

  disable(id: number): void {
    this.marketService
      .disable(id)
      .pipe(
        withLatestFrom(this.markets$),
        map(([changedMarket, markets]) => {
          return [this.replaceMarket(markets, changedMarket), this.replaceMarket(this.marketsAll, changedMarket)];
        })
      )
      .subscribe({
        next: ([markets, marketsAll]) => {
          this._markets.next(markets);
          this.marketsAll = marketsAll;
          this.notificationService.create({
            [NotificationEnum.Type]: NotificationType.Success,
            [NotificationEnum.TextType]: 'marketDisable',
          });
        },
        error: (error: HttpErrorResponse) => this.showNotification(NotificationType.Error, 'marketDisable'),
      });
  }

  enable(id: number): void {
    this.marketService
      .enable(id)
      .pipe(
        withLatestFrom(this.markets$),
        map(([changedMarket, markets]) => {
          return [this.replaceMarket(markets, changedMarket), this.replaceMarket(this.marketsAll, changedMarket)];
        })
      )
      .subscribe({
        next: ([markets, marketsAll]) => {
          this._markets.next(markets);
          this.marketsAll = marketsAll;
          this.notificationService.create({
            [NotificationEnum.Type]: NotificationType.Success,
            [NotificationEnum.TextType]: 'marketEnable',
          });
        },
        error: (error: HttpErrorResponse) => this.showNotification(NotificationType.Error, 'marketEnable'),
      });
  }

  showNotification(type: NotificationType, text: string) {
    this.notificationService.create({ [NotificationEnum.Type]: type, [NotificationEnum.TextType]: text });
  }

  private replaceMarket(markets: Market[], replacedMarket: Market): Market[] {
    return markets.map((market) => (market[MarketEnum.Id] === replacedMarket[MarketEnum.Id] ? replacedMarket : market));
  }
}
