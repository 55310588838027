<div class="container">
  <div class="mt-5 mb-5">
    <h2>Page under construction</h2>
  </div>
  <div>
    <img src="./assets/images/under_construction.png" alt="Under construction" />
  </div>
  <div class="mt-5">
    <button nz-button nzType="primary" [routerLink]="'/value-track/projects'">
      <span nz-icon nzType="left"></span>
      Back
    </button>
  </div>
</div>
