import { Market } from '@shared/interfaces/market.interface';
import { Medium } from './medium.interface';
import { Organization } from './organization.interface';
import { User } from '@shared/interfaces/user.interface';
import { Rfp } from './rfp.interface';
import { Client } from '@shared/interfaces/clients.interface';
import { AdLayout } from 'src/app/logged-in/modules/value-track/projects/project/modules/agency/components/table/interfaces/layout.interface';
import { KpiSetup } from '@shared-project/modules/kpis/interfaces/kpi-setup.interface';
import { PitchingFile } from './pitching-file.interface';
import { FileType } from '@shared-project/modules/files-manager/files-manager.interface';
import { Stage } from './stages.interface';
import { ScoringLayout } from './scoring-layout.interface';
import { QuadraFile } from './quadra-file.interface';
import { GtLayout } from '@shared-project/modules/guarantee/interface/gt-layout.interface';

export enum ProjectData {
  NAME = 'name',
  PROJECT = 'project',
  ORGANIZATION = 'organization',
  CLIENT = 'client',
  YEAR = 'year',
  STATUS = 'status',
  MARKET = 'market',
  SELECTED_MEDIA = 'selectedMedia',
  EVALUATED_FROM = 'evaluatedFrom',
  EVALUATED_TO = 'evaluatedTo',
  MODIFIED_DATE = 'modifiedDate',
  MODIFIED_BY = 'modifiedBy',
  CREATED_DATE = 'createdDate',
  CREATED_BY = 'createdBy',
  ID = 'id',
  RFP = 'rfp',
  CONTENT = 'content',
  DISABLED = 'disabled',
  CLIENT_ID = 'clientId',
  MARKET_ID = 'marketId',
  CURRENCIES = 'currencies',
  RFP_IP = 'rfpId',
  AGENCIES = 'agencies',
  AGENCY_ID = 'agencyId',
  MEDIA_ID = 'mediaId',
  PROJECT_FILES = 'projectFiles',
  REPORT_FILES = 'reportFiles',
  READONLY = 'readOnly',
  PERIOD = 'period',
  USER_ACCESSES = 'userAccesses',
  RESPONSIBLE_USER = 'responsibleUser',
  RESPONSIBLE_USER_ID = 'responsibleUserId',
  AGENCY_USERS = 'agencyUserAccesses',
  PROJECT_STATUS_PERCENT = 'projectStatusPercent',
  INVOICE = 'invoiceNumber',
  GT_IMPORTED = 'gtImported',
  HAS_AGENCY_USERS = 'hasAgencyUsers',
  AT_FILE_PROMOTED = 'atFilePromoted',
  TYPE = 'type',
  PROJECT_TYPE = 'projectType',
  PITCHING_FILES = 'pitchingFile',
  QUADRA_FILES = 'quadraFiles',
  STAGES = 'stages',
  CURRENT_STAGE_NUMBER = 'currentStageNumber',
  NOTIFIED_AT_DOWNLOADED_BY_AGENCY = 'notifiedAtDownloadedByAgency',
  NOTIFIED_AT_SHARED_WITH_AGENCY = 'notifiedAtSharedWithAgency',
  NOTIFIED_GT_SHARED_WITH_AGENCY = 'notifiedGtSharedWithAgency',
}

export enum ProjectColumnsForSearchEnum {
  NAME = 'name',
  ORGANIZATION = 'organization',
  CLIENT = 'clientName',
  YEAR = 'year',
  STATUS = 'status',
  MARKET = 'marketName',
  RFP = 'rfpName',
  RESPONSIBLE_USER = 'responsibleUserEmail',
  MODIFIED_DATE = 'modifiedDate',
  CREATED_DATE = 'createdDate',
}

export interface ProjectColumnsForSearch {
  [ProjectColumnsForSearchEnum.NAME]: string;
  [ProjectColumnsForSearchEnum.ORGANIZATION]: string;
  [ProjectColumnsForSearchEnum.CLIENT]: string;
  [ProjectColumnsForSearchEnum.YEAR]: string;
  [ProjectColumnsForSearchEnum.STATUS]: string;
  [ProjectColumnsForSearchEnum.RESPONSIBLE_USER]: string;
  [ProjectColumnsForSearchEnum.MARKET]: string;
  [ProjectColumnsForSearchEnum.MODIFIED_DATE]: string;
  [ProjectColumnsForSearchEnum.CREATED_DATE]: string;
}

export enum ProjectContentEnum {
  KPI_SETUP = 'kpiSetup',
  AD_LAYOUT = 'adLayout',
  GT_LAYOUT = 'gtLayout',
  AD_PROCESS = 'adProcess',
  VALIDATED_FILES = 'validatedFiles',
  ENGINE_PROCESS = 'engineProcess',
  SCORING_LAYOUT = 'scoringLayout',
}

export interface Project {
  [ProjectData.ID]: number;
  [ProjectData.TYPE]: ProjectTypeEnum; //Field only for a project data
  [ProjectData.PROJECT_TYPE]: ProjectTypeEnum; //Field only for projects list
  [ProjectData.NAME]: string;
  [ProjectData.PROJECT]: string;
  [ProjectData.ORGANIZATION]: Organization;
  [ProjectData.CLIENT]: Organization;
  [ProjectData.YEAR]: number;
  [ProjectData.STATUS]: AllProjectStatuses;
  [ProjectData.DISABLED]: boolean;
  [ProjectData.MARKET]: Market;
  [ProjectData.SELECTED_MEDIA]: Medium[];
  [ProjectData.CURRENCIES]: string[];
  [ProjectData.MODIFIED_DATE]?: number;
  [ProjectData.MODIFIED_BY]?: string;
  [ProjectData.CREATED_DATE]?: number;
  [ProjectData.PROJECT_FILES]?: ProjectFile[];
  [ProjectData.REPORT_FILES]?: ReportFile[];
  [ProjectData.CREATED_BY]?: string;
  [ProjectData.EVALUATED_FROM]?: string;
  [ProjectData.RFP]: Rfp;
  [ProjectData.AGENCY_ID]: number;
  [ProjectData.AGENCIES]?: Organization[];
  [ProjectData.EVALUATED_TO]?: string;
  [ProjectData.CONTENT]: {
    [ProjectContentEnum.KPI_SETUP]: KpiSetup;
    [ProjectContentEnum.AD_LAYOUT]?: AdLayout;
    [ProjectContentEnum.AD_PROCESS]?: AdProcess;
    [ProjectContentEnum.GT_LAYOUT]?: GtLayout;
    [ProjectContentEnum.VALIDATED_FILES]: AdProcess[];
    [ProjectContentEnum.ENGINE_PROCESS]: EngineProcess;
    [ProjectContentEnum.SCORING_LAYOUT]: ScoringLayout;
  };
  [ProjectData.READONLY]?: boolean;
  [ProjectData.USER_ACCESSES]?: User[] | number[];
  [ProjectData.AGENCY_USERS]?: User[];
  [ProjectData.RESPONSIBLE_USER]: User;
  [ProjectData.CURRENT_STAGE_NUMBER]: number;
  [ProjectData.PROJECT_STATUS_PERCENT]?: number;
  [ProjectData.INVOICE]?: number;
  [ProjectData.GT_IMPORTED]?: boolean;
  [ProjectData.HAS_AGENCY_USERS]?: boolean;
  [ProjectData.AT_FILE_PROMOTED]?: string;
  // * PITCHING
  [ProjectData.CURRENT_STAGE_NUMBER]: number;
  [ProjectData.PITCHING_FILES]: PitchingFile[];
  [ProjectData.QUADRA_FILES]: QuadraFile[];
  [ProjectData.STAGES]: Stage[];
  [ProjectData.NOTIFIED_AT_DOWNLOADED_BY_AGENCY]: boolean;
  [ProjectData.NOTIFIED_AT_SHARED_WITH_AGENCY]: boolean;
  [ProjectData.NOTIFIED_GT_SHARED_WITH_AGENCY]: boolean;
}

export interface EditProject {
  [ProjectData.ID]?: number;
  [ProjectData.NAME]?: string;
  [ProjectData.YEAR]?: number;
  [ProjectData.STATUS]?: AllProjectStatuses;
  [ProjectData.MARKET_ID]?: number;
  [ProjectData.CLIENT_ID]?: number;
  [ProjectData.RFP_IP]?: number;
  [ProjectData.AGENCY_ID]?: number;
  [ProjectData.MEDIA_ID]?: number[];
  [ProjectData.CURRENCIES]?: string[];
  [ProjectData.USER_ACCESSES]?: User[] | number[];
  [ProjectData.AGENCY_USERS]?: User[] | number[];
  [ProjectData.RESPONSIBLE_USER]?: User | number;
  [ProjectData.RESPONSIBLE_USER_ID]?: User | number;
  [ProjectData.READONLY]?: boolean;
  [ProjectData.EVALUATED_FROM]?: string | null;
  [ProjectData.EVALUATED_TO]?: string | null;
  [ProjectData.INVOICE]?: number;
}

export enum ProjectTemplateEnum {
  Mediums = 'mediums',
  Name = 'name',
  ProjectId = 'projectId',
  Type = 'type',
}

export interface ProjectTemplate {
  [ProjectTemplateEnum.Mediums]: Medium[];
  [ProjectTemplateEnum.Name]: string;
  [ProjectTemplateEnum.ProjectId]: number;
  [ProjectTemplateEnum.Type]: ProjectTypeEnum;
}
export enum AllProjectStatuses {
  KPI_SETUP = 'KPI_SETUP',
  DIMENSION_SETUP = 'DIMENSION_SETUP',
  GT_SETUP = 'GT_SETUP',
  GT_EXPORT_SUCCESS = 'GT_EXPORT_SUCCESS',
  GT_EXPORT_FAILURE = 'GT_EXPORT_FAILURE',
  GT_EXPORT_IN_PROGRESS = 'GT_EXPORT_IN_PROGRESS',
  GT_IMPORT_SUCCESS = 'GT_IMPORT_SUCCESS',
  GT_IMPORT_FAILURE = 'GT_IMPORT_FAILURE',
  GT_IMPORT_IN_PROGRESS = 'GT_IMPORT_IN_PROGRESS',

  AT_SETUP = 'AT_SETUP',
  AT_GENERATED = 'AT_GENERATED',
  AT_APPROVED = 'AT_APPROVED',
  AT_SHARED_WITH_AGENCY = 'AT_SHARED_WITH_AGENCY',
  AT_DOWNLOADED_BY_AGENCY = 'AT_DOWNLOADED_BY_AGENCY',
  AT_VALIDATION_IN_PROGRESS = 'AT_VALIDATION_IN_PROGRESS',
  AT_VALIDATION_SUCCESS = 'AT_VALIDATION_SUCCESS',
  AT_VALIDATION_FAILURE = 'AT_VALIDATION_FAILURE',
  AT_CALCULATION_IN_PROGRESS = 'AT_CALCULATION_IN_PROGRESS',
  AT_CALCULATION_FAILURE = 'AT_CALCULATION_FAILURE',
  AT_CALCULATION_SUCCESS = 'AT_CALCULATION_SUCCESS',
  AT_CALCULATION_SUCCESS_VALIDATION_FAILURE = 'AT_CALCULATION_SUCCESS_VALIDATION_FAILURE',
  AT_CALCULATION_FAILURE_VALIDATION_SUCCESS = 'AT_CALCULATION_FAILURE_VALIDATION_SUCCESS',

  REPORT_SHARED_WITH_AGENCY = 'REPORT_SHARED_WITH_AGENCY',
  REPORT_CREATED = 'REPORT_CREATED',
  REPORTING = 'REPORTING',

  CREATED = 'CREATED',
  SHARED_WITH_AGENCY = 'SHARED_WITH_AGENCY',
  AGENCY_UPLOADED = 'AGENCY_UPLOADED',
  DATA_READY = 'DATA_READY',
  CALCULATED = 'CALCULATED',
  REPORT_UPLOADED = 'REPORT_UPLOADED',

  OPEN = 'OPEN',
  SCORING = 'SCORING',
  CLOSED = 'CLOSED',
}

export enum ValueTrackStatuses {
  KPI_SETUP = 'KPI_SETUP',
  DIMENSION_SETUP = 'DIMENSION_SETUP',
  GT_SETUP = 'GT_SETUP',
  GT_EXPORT_SUCCESS = 'GT_EXPORT_SUCCESS',
  GT_EXPORT_FAILURE = 'GT_EXPORT_FAILURE',
  GT_EXPORT_IN_PROGRESS = 'GT_EXPORT_IN_PROGRESS',
  GT_IMPORT_SUCCESS = 'GT_IMPORT_SUCCESS',
  GT_IMPORT_FAILURE = 'GT_IMPORT_FAILURE',
  GT_IMPORT_IN_PROGRESS = 'GT_IMPORT_IN_PROGRESS',

  AT_SETUP = 'AT_SETUP',
  AT_GENERATED = 'AT_GENERATED',
  AT_APPROVED = 'AT_APPROVED',
  AT_SHARED_WITH_AGENCY = 'AT_SHARED_WITH_AGENCY',
  AT_DOWNLOADED_BY_AGENCY = 'AT_DOWNLOADED_BY_AGENCY',
  AT_VALIDATION_IN_PROGRESS = 'AT_VALIDATION_IN_PROGRESS',
  AT_VALIDATION_SUCCESS = 'AT_VALIDATION_SUCCESS',
  AT_VALIDATION_FAILURE = 'AT_VALIDATION_FAILURE',
  AT_CALCULATION_IN_PROGRESS = 'AT_CALCULATION_IN_PROGRESS',
  AT_CALCULATION_FAILURE = 'AT_CALCULATION_FAILURE',
  AT_CALCULATION_SUCCESS = 'AT_CALCULATION_SUCCESS',
  AT_CALCULATION_SUCCESS_VALIDATION_FAILURE = 'AT_CALCULATION_SUCCESS_VALIDATION_FAILURE',
  AT_CALCULATION_FAILURE_VALIDATION_SUCCESS = 'AT_CALCULATION_FAILURE_VALIDATION_SUCCESS',

  REPORT_SHARED_WITH_AGENCY = 'REPORT_SHARED_WITH_AGENCY',
  REPORT_CREATED = 'REPORT_CREATED',
  REPORTING = 'REPORTING',
  CLOSED = 'CLOSED',
}

export enum PitchingStatuses {
  DIMENSION_SETUP = 'DIMENSION_SETUP',
  KPI_SETUP = 'KPI_SETUP',
  GT_SETUP = 'GT_SETUP',
  GT_EXPORT_SUCCESS = 'GT_EXPORT_SUCCESS',
  OPEN = 'OPEN',
  SCORING = 'SCORING',
  REPORT_CREATED = 'REPORT_CREATED',
  REPORTING = 'REPORTING',
  CLOSED = 'CLOSED',
}
export enum QuadraStatuses {
  CREATED = 'CREATED',
  SHARED_WITH_AGENCY = 'SHARED_WITH_AGENCY',
  AGENCY_UPLOADED = 'AGENCY_UPLOADED',
  DATA_READY = 'DATA_READY',
  CALCULATED = 'CALCULATED',
  REPORT_UPLOADED = 'REPORT_UPLOADED',
  CLOSED = 'CLOSED',
}

export type ProjectStatuses = ValueTrackStatuses | PitchingStatuses | QuadraStatuses;

export enum ProjectTypeEnum {
  ValueTrack = 'VALUE_TRACK',
  Pitching = 'PITCHING',
  Quadra = 'QUADRA',
}
export type ProjectFilters = {
  [ProjectFiltersEnum.MarketId]: Market[];
  [ProjectFiltersEnum.Year]: number[];
  [ProjectFiltersEnum.Clients]: Client[];
  [ProjectFiltersEnum.Rfp]: Rfp[];
  [ProjectFiltersEnum.Status]: ProjectStatuses[];
  [ProjectFiltersEnum.SearchText]: string;
};

export type ProjectSelectsWrapper = {
  [ProjectSelectsWrapperEnum.Projects]?: Project[];
  [ProjectSelectsWrapperEnum.Markets]: Market[];
  [ProjectSelectsWrapperEnum.Year]: number[];
  [ProjectSelectsWrapperEnum.Rfp]?: Rfp[];
  [ProjectSelectsWrapperEnum.Clients]: Client[];
  [ProjectSelectsWrapperEnum.Status]: AllProjectStatuses[];
  [ProjectSelectsWrapperEnum.SearchText]: string;
};

export enum ProjectSelectsWrapperEnum {
  Projects = 'projects',
  Markets = 'markets',
  Year = 'year',
  Clients = 'clients',
  Status = 'status',
  Rfp = 'rfp',
  SearchText = 'searchText',
}

export enum ProjectFiltersEnum {
  MarketId = 'marketId',
  Year = 'year',
  Clients = 'clients',
  Status = 'status',
  Rfp = 'rfpId',
  SearchText = 'name',
}

export enum FileEnum {
  FileName = 'filename',
  UploadDate = 'uploadDate',
  UploadedBy = 'user',
  ProjectFileType = 'projectFileType',
  Extension = 'extension',
  AccessibleByAgency = 'accessibleByAgency',
  GtImported = 'gtImported',
  ReportFileId = 'reportFileId',
  Approved = 'approved',
  DisabledForSending = 'disabledForSending',
}

export enum FileReportEnum {
  AgencyNotified = 'agencyNotified',
  AgencyApproved = 'agencyApproved',
  ClientNotified = 'isClientNotified',
  Approved = 'approved',
  AtFileApproved = 'atFileApproved',
  CreatedBy = 'createdBy',
  CreatedDate = 'createdDate',
  DisabledForSending = 'disabledForSending',
  Filename = 'filename',
  ModifiedBy = 'modifiedBy',
  ModifiedDate = 'modifiedDate',
  RelatedProject = 'relatedProject',
  ReportFileId = 'reportFileId',
  UploadedByUser = 'uploadedByUser',
  RejectedByAgency = 'rejectedByAgency',
  RejectAgencyUser = 'rejectAgencyUser',
}

export interface ReportFile {
  [FileReportEnum.AgencyNotified]: boolean;
  [FileReportEnum.AgencyApproved]: boolean;
  [FileReportEnum.RejectedByAgency]: boolean;
  [FileReportEnum.ClientNotified]: boolean;
  [FileReportEnum.Approved]: boolean;
  [FileReportEnum.CreatedBy]: string;
  [FileReportEnum.CreatedDate]: string;
  [FileReportEnum.DisabledForSending]: boolean;
  [FileReportEnum.Filename]: string;
  [FileReportEnum.ModifiedBy]: string;
  [FileReportEnum.ModifiedDate]: string;
  [FileReportEnum.RelatedProject]: number;
  [FileReportEnum.ReportFileId]: number;
  [FileReportEnum.UploadedByUser]: User;
}

export enum FileExtension {
  PNG = 'png',
  JPEG = 'jpeg',
  XLSX = 'xlsx',
  PPTX = 'pptx',
  PPT = 'ppt',
}

export interface ProjectFile {
  [FileEnum.FileName]: string;
  [FileEnum.UploadDate]: string;
  [FileEnum.UploadedBy]: string;
  [FileReportEnum.AgencyApproved]: boolean;
  [FileReportEnum.AtFileApproved]: boolean;
  [FileReportEnum.Approved]: boolean;
  [FileEnum.Extension]: FileExtension;
  [FileEnum.ProjectFileType]: FileType;
  [FileEnum.AccessibleByAgency]: boolean;
  [FileEnum.GtImported]: boolean;
}

export enum AdProcessEnum {
  FileName = 'filename',
  ValidationResult = 'validationResult',
  CalculationResult = 'calculationResult',
  CalculationVersion = 'calculationVersion',
  ValidationResultSuccess = 'validationResultSuccess',
  CalculationInProgress = 'calculationInProgress',
}

export interface AdProcessUI {
  [AdProcessEnum.CalculationInProgress]?: boolean;
}

export interface AdProcess extends AdProcessUI {
  [AdProcessEnum.FileName]: string;
  [AdProcessEnum.ValidationResult]: string;
  [AdProcessEnum.CalculationResult]: string;
  [AdProcessEnum.ValidationResultSuccess]: boolean;
  [AdProcessEnum.CalculationVersion]: number;
}

export enum EngineProcessEnum {
  AdCalculationProcess = 'adCalculationProcess',
  AdValidationProcess = 'adValidationProcess',
  GtExportProcess = 'gtExportProcess',
  GtImportProcess = 'gtImportProcess',
  // * Pitching
  GtValidationProcess = 'gtValidationProcess',
  GtScoringValidationProcess = 'gtScoringValidationProcess',
  GtScoringCalculationProcess = 'gtScoringCalculationProcess',
  QuadraCalculationProcess = 'quadraCalculationProcess',
}

export interface EngineProcess {
  [EngineProcessEnum.AdCalculationProcess]: EngineProcessObject;
  [EngineProcessEnum.AdValidationProcess]: EngineProcessObject;
  [EngineProcessEnum.GtExportProcess]: EngineProcessObject;
  [EngineProcessEnum.GtImportProcess]: EngineProcessObject;
  // * Pitching
  [EngineProcessEnum.GtValidationProcess]: EngineProcessObject;
  [EngineProcessEnum.GtScoringValidationProcess]: EngineProcessObject;
  [EngineProcessEnum.GtScoringCalculationProcess]: EngineProcessObject;
  // * Quadra
  [EngineProcessEnum.QuadraCalculationProcess]: EngineProcessObject;
}
export enum EngineProcessObjectEnum {
  Filename = 'filename',
  ImportResult = 'importResult',
  ProcessPercentage = 'processPercentage',
  ValidationResultSuccess = 'validationResultSuccess',
  ValidationResult = 'validationResult',
  CalculationResultSuccess = 'calculationResultSuccess',
  CalculationResult = 'calculationResult',
}

export interface EngineProcessObject {
  [EngineProcessObjectEnum.Filename]: string;
  [EngineProcessObjectEnum.ProcessPercentage]: number;
  [EngineProcessObjectEnum.ValidationResultSuccess]: boolean;
  [EngineProcessObjectEnum.ValidationResult]: string;
  [EngineProcessObjectEnum.ImportResult]: string;
  [EngineProcessObjectEnum.CalculationResultSuccess]: boolean;
  [EngineProcessObjectEnum.CalculationResult]: string;
}
