import { Market, MarketEnum } from '@shared/interfaces/market.interface';
import { Medium, MediumEnum, MediumFilterEnum, MediumFilters } from '@shared/interfaces/medium.interface';

export const filterMediumHelper = (media: Medium[], filters: MediumFilters): Medium[] => {
  if (typeof filters[MediumFilterEnum.Disabled] === 'boolean') {
    media = media.filter((medium: Medium) => filters[MediumFilterEnum.Disabled] === medium[MediumEnum.Disabled]);
  }

  if (filters[MediumFilterEnum.Markets]) {
    media = media.filter(
      (medium: Medium) =>
        medium[MediumFilterEnum.Markets]!.filter(
          (market: Market) => market[MarketEnum.Id] === filters[MediumFilterEnum.Markets][MarketEnum.Id]
        ).length > 0
    );
  }

  if (filters[MediumFilterEnum.SearchText]) {
    const searchText = filters[MediumFilterEnum.SearchText].toLowerCase();
    media = media.filter((medium: Medium) => {
      return medium[MarketEnum.Name].toLowerCase().includes(searchText);
    });
  }

  return media;
};
