import { UploadMode } from './components/upload-file/uploadFile.interface';

export enum FileExtensions {
  XLSX = 'xlsx',
  XLS = 'xls',
  PNG = 'png',
}

export enum GetFilesTypes {
  QUADRA = 'quadra',
  PITCHING = 'pitching',
  VALUE_TRACK = 'valueTrack',
  GT_VALIDATED = 'gtValidated',
  AT_VALIDATED = 'atValidated',
}
export enum FileType {
  AT_TEMPLATE = 'AT_TEMPLATE',
  AT_VALIDATED = 'AT_VALIDATED',
  AT_CALCULATED = 'AT_CALCULATED',
  AT_FOR_APPROVAL = 'AT_FOR_APPROVAL',
  GT_EXPORT = 'GT_EXPORT',
  GT_IMPORT = 'GT_IMPORT',
  REPORT = 'REPORT',
  AGENCY_REPORT = 'AGENCY_REPORT',
  LOGO = 'LOGO',
  ALL = 'all',
  // * PITCHING TABs
  GT_VALIDATED = 'GT_VALIDATED',
  GT_UPLOADED = 'GT_UPLOADED',
  GT_USER_DATA = 'GT_USER_DATA',
  GT_TEMPLATE = 'GT_EXPORT',
  GT_MERGED = 'GT_MERGED',
  GT_AGENCY_FEEDBACK = 'GT_AGENCY_FEEDBACK',
  ADDITIONAL_FILES = 'ADDITIONAL',
  GT_SCORING = 'GT_SCORING',
  FLOW = 'FLOW',
  TEMPLATES = 'TEMPLATES',
  QUADRA = 'QUADRA',
}

export enum QuadraFileType {
  QD = 'QD',
  QCT = 'QCT',
  QAD = 'QAD',
  POOL = 'POOL',
  QAD_TEMPLATE = 'QAD_TEMPLATE',
  QCT_TEMPLATE = 'QCT_TEMPLATE',
}

export enum PitchingFileType {
  GT_VALIDATED = 'GT_VALIDATED',
  GT_UPLOADED = 'GT_UPLOADED',
  GT_USER_DATA = 'GT_USER_DATA',
  GT_TEMPLATE = 'GT_EXPORT',
  GT_MERGED = 'GT_MERGED',
  ADDITIONAL_FILES = 'ADDITIONAL_FILES',
  GT_AGENCY_FEEDBACK = 'GT_AGENCY_FEEDBACK',
  GT_SCORING = 'GT_SCORING',
}

export enum UploadFileParamsEnum {
  Type = 'type',
}
export enum UploadPitchingFileParamsEnum {
  Type = 'type',
  AgencyId = 'agencyId',
  ProjectId = 'projectId',
}
export enum UploadQuadraFileParamsEnum {
  Type = 'type',
}
export interface UploadFileParams {
  type: FileType;
}
export interface UploadPitchingFileParams {
  [UploadPitchingFileParamsEnum.Type]: FileType;
  [UploadPitchingFileParamsEnum.AgencyId]?: number;
  [UploadPitchingFileParamsEnum.ProjectId]: number;
}

export type UploadQuadraFiles = UploadMode.POOL_UPLOAD | UploadMode.QAD_UPLOAD | UploadMode.QCT_UPLOAD | UploadMode.QD_UPLOAD;
export interface UploadQuadraFileParams {
  [UploadQuadraFileParamsEnum.Type]: UploadQuadraFiles;
}
