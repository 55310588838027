<div class="center" nz-row>
  <button nz-button nzType="primary" (click)="getApiDocs($event)">
    <i nz-icon nzType="book" nzTheme="outline"></i>
    API Docs
  </button>

  <button nz-button nzType="dashed" (click)="getApiReportingDocs($event)">
    <i nz-icon nzType="book" nzTheme="outline"></i>
    API Reporting Docs
  </button>
</div>
