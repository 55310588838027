import { Injectable } from '@angular/core';
import { RoleTypeEnum } from '@auth/interfaces/user.interface';
import { AuthService } from '@auth/services/auth/auth.service';
import { map } from 'rxjs';

@Injectable()
export class UserRolesService {
  public userRole$ = this.authService.userRole$;
  public isAdminRole$ = this.userRole$.pipe(map((role) => role === RoleTypeEnum.Admin));
  public hasAgencyRole$ = this.userRole$.pipe(map((role) => role === RoleTypeEnum.AgencyUser));
  public hasNotAgencyRole$ = this.hasAgencyRole$.pipe(map((hasAgencyRole) => hasAgencyRole === false));
  constructor(private readonly authService: AuthService) {}
}
