import { AuditData } from '@shared/interfaces/audit-data.interface';
import { Market } from '@shared/interfaces/market.interface';
import { Medium } from '@shared/interfaces/medium.interface';

export interface Organization extends Partial<AuditData> {
  [OrganizationEnum.Id]: number;
  [OrganizationEnum.Name]: string;
  [OrganizationEnum.Type]: OrganizationType;
  [OrganizationEnum.Disabled]: boolean;
  [OrganizationEnum.Parent]: Organization;
  [OrganizationEnum.Markets]: Market[];
  [OrganizationEnum.Media]: Medium[];
}

export enum OrganizationEnum {
  Id = 'organizationId',
  Name = 'name',
  Type = 'type',
  Disabled = 'disabled',
  Markets = 'markets',
  Parent = 'parent',
  ParentId = 'parentId',
  Media = 'media',
}

export enum OrganizationTypeEnum {
  Agency = 'AGENCY',
  Partner = 'PARTNER',
  Stars = 'STARS',
  Client = 'CLIENT',
}

export interface OrganizationAdd {
  [OrganizationEnum.Name]: string;
  [OrganizationEnum.Type]: OrganizationType;
  [OrganizationEnum.Markets]: number[];
  [OrganizationEnum.ParentId]?: number;
  [OrganizationEnum.Media]?: number[];
}

export type OrganizationType =
  | OrganizationTypeEnum.Agency
  | OrganizationTypeEnum.Partner
  | OrganizationTypeEnum.Stars
  | OrganizationTypeEnum.Client;

export type OrganizationForm = {
  [OrganizationEnum.Name]: string;
  [OrganizationEnum.Type]: OrganizationType | '';
  [OrganizationEnum.Markets]: number[];
  [OrganizationEnum.Media]: number[];
  [OrganizationEnum.ParentId]?: number;
};

export enum OrganizationFilterEnum {
  Name = 'name',
  Type = 'type',
  Markets = 'markets',
  Disabled = 'disabled',
  SearchText = 'searchText',
}

export type OrganizationFilters = {
  [OrganizationFilterEnum.Type]: string;
  [OrganizationFilterEnum.Markets]: Market;
  [OrganizationFilterEnum.Disabled]: boolean;
  [OrganizationFilterEnum.SearchText]: string;
};

export type OrganizationFiltersValue = {
  [OrganizationFilterEnum.Type]: OrganizationType[];
  [OrganizationFilterEnum.Disabled]: boolean[];
  [OrganizationFilterEnum.Markets]: Market[];
};
