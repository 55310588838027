import { Client, ClientEnum } from '@shared/interfaces/clients.interface';
import { AllProjectStatuses } from '@shared/interfaces/project.interface';
import { projectMock } from 'src/app/logged-in/modules/value-track/projects/project/mocks/project.mock';
import { of } from 'rxjs';
import { marketsListMock } from '@shared/mocks/markets-list.mock';
import { rfpsListMock } from '@shared/mocks/rfps-list.mock';
import { paginationMocks } from '../../../logged-in/modules/value-track/projects/project/mocks/projects.mock';

export const clients: Client[] = [
  {
    [ClientEnum.Id]: 1,
    [ClientEnum.Disabled]: false,
    [ClientEnum.Name]: 'Stars',
    [ClientEnum.Parent]: null,
    [ClientEnum.Markets]: [],
  },
  {
    [ClientEnum.Id]: 2,
    [ClientEnum.Disabled]: false,
    [ClientEnum.Name]: 'Zara',
    [ClientEnum.Parent]: null,
    [ClientEnum.Markets]: [],
  },
  {
    [ClientEnum.Id]: 3,
    [ClientEnum.Disabled]: false,
    [ClientEnum.Name]: 'TVP',
    [ClientEnum.Parent]: null,
    [ClientEnum.Markets]: [],
  },
  {
    [ClientEnum.Id]: 4,
    [ClientEnum.Disabled]: false,
    [ClientEnum.Name]: 'Porsche',
    [ClientEnum.Parent]: null,
    [ClientEnum.Markets]: [],
  },
];

export const year: number[] = [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022];

export const countries: string[] = ['Poland', 'USA', 'Germany', 'New Zeland', 'Spain', 'Portugal'];

export const projectServiceMock = {
  getProjects() {
    return of(paginationMocks);
  },

  getProject(id: number) {
    return of(projectMock);
  },

  getYears(): number[] {
    return year;
  },

  updateProjectContent() {
    return of(projectMock);
  },
  getProjectForEdit() {
    return of(projectMock);
  },
  setProject() {
    return of(projectMock);
  },
  uploadFile() {
    return of(projectMock);
  },
  validateAt() {
    return of(projectMock);
  },
  generateAt() {
    return of(projectMock);
  },
  calculateAt() {
    return of(projectMock);
  },
  getCurrencies() {
    return of([]);
  },
  downloadFile() {
    return of(null);
  },
  editProjectPermissions() {
    return of(null);
  },

  getStatuses() {
    return of(projectStatusesMock);
  },
  saveProject() {
    return of(projectMock);
  },
  copyProject() {
    return of(projectMock);
  },
  getClients() {
    return of([]); // Organization[]
  },
  deleteProject() {
    return of(projectMock);
  },
  exportGT() {
    return of(projectMock);
  },
  importGT() {
    return of(projectMock);
  },
  getMarkets() {
    return of(marketsListMock);
  },
  getRfps() {
    return of(rfpsListMock); // rfpData[]
  },
};

export const projectStatusesMock = [
  AllProjectStatuses.KPI_SETUP,
  AllProjectStatuses.DIMENSION_SETUP,
  AllProjectStatuses.AT_CALCULATION_FAILURE,
  AllProjectStatuses.AT_CALCULATION_FAILURE_VALIDATION_SUCCESS,
  AllProjectStatuses.AT_CALCULATION_IN_PROGRESS,
  AllProjectStatuses.AT_CALCULATION_SUCCESS,
  AllProjectStatuses.AT_CALCULATION_SUCCESS_VALIDATION_FAILURE,
  AllProjectStatuses.AT_DOWNLOADED_BY_AGENCY,
  AllProjectStatuses.AT_GENERATED,
  AllProjectStatuses.AT_SETUP,
  AllProjectStatuses.AT_SHARED_WITH_AGENCY,
  AllProjectStatuses.AT_VALIDATION_FAILURE,
  AllProjectStatuses.AT_VALIDATION_IN_PROGRESS,
  AllProjectStatuses.AT_VALIDATION_SUCCESS,
  AllProjectStatuses.GT_EXPORT_FAILURE,
  AllProjectStatuses.GT_EXPORT_SUCCESS,
  AllProjectStatuses.GT_SETUP,
  AllProjectStatuses.REPORT_CREATED,
  AllProjectStatuses.REPORT_SHARED_WITH_AGENCY,
  AllProjectStatuses.REPORTING,
  // ProjectStatuses.DONE,
];
