import { DataFormats } from '@shared/interfaces/data-formats.interface';
import { SelectedMediaEnum } from '@shared/interfaces/kpi-setup.interface';

export const selectedMediumMock = {
  [SelectedMediaEnum.MediumId]: 1,
  [SelectedMediaEnum.Name]: 'TV',
  [SelectedMediaEnum.SelectedKpis]: [
    {
      id: 4,
      name: 'Investment Net Net',
      description: 'For MVP demo, do not modify',
      formats: ['money'] as DataFormats[],
      dimensionSetup: {
        columns: [],
        rows: [],
        splitBy: [],
        splitAsSheet: [],
      },
      referenceKpis: [],
      relatedKpisByColumn: ['Budget variation index'],
      relatedKpisByRows: ['Budget variation index'],
    },
    {
      id: 2,
      name: 'Budget variation index',
      description: 'For MVP demo, do not modify',
      formats: ['index'] as DataFormats[],
      dimensionSetup: {
        columns: [],
        rows: [],
        splitBy: [],
        splitAsSheet: [],
      },
      referenceKpis: [],
      relatedKpisByColumn: ['Investment Net Net'],
      relatedKpisByRows: ['Investment Net Net'],
    },
    {
      id: 5,
      name: 'Premium Position Index',
      description: 'For MVP demo, do not modify',
      formats: ['index'] as DataFormats[],
      dimensionSetup: {
        columns: [],
        rows: [],
        splitBy: [],
        splitAsSheet: [],
      },
      referenceKpis: [],
      relatedKpisByColumn: [],
      relatedKpisByRows: [],
    },
    {
      id: 7,
      name: 'Prime Time Reference',
      description: 'For MVP demo, do not modify',
      formats: ['numeric'] as DataFormats[],
      dimensionSetup: {
        columns: [],
        rows: [],
        splitBy: [],
        splitAsSheet: [],
      },
      referenceKpis: [],
      relatedKpisByColumn: [],
      relatedKpisByRows: [],
    },
  ],
  [SelectedMediaEnum.SuggestedKpis]: [
    {
      id: 2,
      name: 'Budget variation index',
      description: 'For MVP demo, do not modify',
      disabled: false,
      formats: ['index'] as DataFormats[],
      markets: [
        {
          id: 31,
          name: 'Poland',
          code: 'PL',
          disabled: false,
        },
      ],
      media: [
        {
          id: 1,
          name: 'TV',
          disabled: false,
        },
      ],
      createdBy: 'liquibase',
      modifiedBy: 'liquibase',
      createdDate: '2023-02-10T11:35:24.542+00:00',
      modifiedDate: '2023-02-10T11:35:24.542+00:00',
      waitingForApproval: false,
      isDisabled: true,
    },
    {
      id: 3,
      name: 'CPP 30" contract',
      description: 'For MVP demo, do not modify',
      disabled: false,
      formats: ['numeric'],
      markets: [
        {
          id: 31,
          name: 'Poland',
          code: 'PL',
          disabled: false,
        },
      ],
      media: [
        {
          id: 1,
          name: 'TV',
          disabled: false,
        },
      ],
      createdBy: 'liquibase',
      modifiedBy: 'liquibase',
      createdDate: '2023-02-10T11:35:24.542+00:00',
      modifiedDate: '2023-02-10T11:35:24.542+00:00',
      waitingForApproval: false,
      isDisabled: false,
    },
    {
      id: 55,
      name: 'Cristof',
      description: 'XXX',
      disabled: false,
      formats: ['text'],
      markets: [
        {
          id: 31,
          name: 'Poland',
          code: 'PL',
          disabled: false,
        },
      ],
      media: [
        {
          id: 1,
          name: 'TV',
          disabled: false,
        },
      ],
      createdBy: 'r.lejman@webellian.com',
      modifiedBy: 'r.lejman@webellian.com',
      createdDate: '2023-02-15T11:28:19.734+00:00',
      modifiedDate: '2023-02-15T11:28:19.734+00:00',
      waitingForApproval: false,
      isDisabled: false,
    },
    {
      id: 4,
      name: 'Investment Net Net',
      description: 'For MVP demo, do not modify',
      disabled: false,
      formats: ['money'],
      markets: [
        {
          id: 31,
          name: 'Poland',
          code: 'PL',
          disabled: false,
        },
      ],
      media: [
        {
          id: 1,
          name: 'TV',
          disabled: false,
        },
      ],
      createdBy: 'liquibase',
      modifiedBy: 'liquibase',
      createdDate: '2023-02-10T11:35:24.542+00:00',
      modifiedDate: '2023-02-10T11:35:24.542+00:00',
      waitingForApproval: false,
      isDisabled: true,
    },
    {
      id: 5,
      name: 'Premium Position Index',
      description: 'For MVP demo, do not modify',
      disabled: false,
      formats: ['index'] as DataFormats[],
      markets: [
        {
          id: 31,
          name: 'Poland',
          code: 'PL',
          disabled: false,
        },
      ],
      media: [
        {
          id: 1,
          name: 'TV',
          disabled: false,
        },
      ],
      createdBy: 'liquibase',
      modifiedBy: 'liquibase',
      createdDate: '2023-02-10T11:35:24.542+00:00',
      modifiedDate: '2023-02-10T11:35:24.542+00:00',
      waitingForApproval: false,
      isDisabled: true,
    },
    {
      id: 6,
      name: 'Premium Position Reference',
      description: 'For MVP demo, do not modify',
      disabled: false,
      formats: ['numeric'],
      markets: [
        {
          id: 31,
          name: 'Poland',
          code: 'PL',
          disabled: false,
        },
      ],
      media: [
        {
          id: 1,
          name: 'TV',
          disabled: false,
        },
      ],
      createdBy: 'liquibase',
      modifiedBy: 'liquibase',
      createdDate: '2023-02-10T11:35:24.542+00:00',
      modifiedDate: '2023-02-10T11:35:24.542+00:00',
      waitingForApproval: false,
      isDisabled: false,
    },
    {
      id: 1,
      name: 'Prime Time Index',
      description: 'For MVP demo, do not modify',
      disabled: false,
      formats: ['index'] as DataFormats[],
      markets: [
        {
          id: 31,
          name: 'Poland',
          code: 'PL',
          disabled: false,
        },
      ],
      media: [
        {
          id: 1,
          name: 'TV',
          disabled: false,
        },
      ],
      createdBy: 'liquibase',
      modifiedBy: 'liquibase',
      createdDate: '2023-02-10T11:35:24.542+00:00',
      modifiedDate: '2023-02-10T11:35:24.542+00:00',
      waitingForApproval: false,
      isDisabled: false,
    },
    {
      id: 7,
      name: 'Prime Time Reference',
      description: 'For MVP demo, do not modify',
      disabled: false,
      formats: ['numeric'],
      markets: [
        {
          id: 31,
          name: 'Poland',
          code: 'PL',
          disabled: false,
        },
      ],
      media: [
        {
          id: 1,
          name: 'TV',
          disabled: false,
        },
      ],
      createdBy: 'liquibase',
      modifiedBy: 'liquibase',
      createdDate: '2023-02-10T11:35:24.542+00:00',
      modifiedDate: '2023-02-10T11:35:24.542+00:00',
      waitingForApproval: false,
      isDisabled: true,
    },
  ],
};
