import { Organization, OrganizationEnum } from '@shared/interfaces';

export enum LayoutHeaderEnum {
  Agency = 'agency',
  Country = 'country',
  Currency = 'currency',
  Client = 'client',
  EvaluationPeriod = 'evaluationPeriod',
  Logo = 'logo',
}
export interface LayoutHeader {
  [LayoutHeaderEnum.Client]: Organization[OrganizationEnum.Name];
  [LayoutHeaderEnum.Agency]: string | null;
  [LayoutHeaderEnum.Country]: string;
  [LayoutHeaderEnum.Currency]: string | null;
  [LayoutHeaderEnum.EvaluationPeriod]: number;
  [LayoutHeaderEnum.Logo]: Logo;
}

export type LayoutHeaderSave = Pick<LayoutHeader, LayoutHeaderEnum.Logo>;

export enum LogoEnum {
  ClientLogo = 'clientLogo',
  CompanyLogo = 'companyLogo',
}

export interface Logo {
  [LogoEnum.ClientLogo]: LogoDetails;
  [LogoEnum.CompanyLogo]: LogoDetails;
}

export enum LogoDetailsEnum {
  LogoS3Path = 'logoS3Path',
  LogoName = 'logoName',
  LogoColumn = 'logoColumn',
}
export interface LogoDetails {
  [LogoDetailsEnum.LogoS3Path]?: string;
  [LogoDetailsEnum.LogoName]: string;
  [LogoDetailsEnum.LogoColumn]: string;
}
export enum ColorsEnum {
  AgencyDataColor = 'agencydataColor',
  UsersDataColor = 'starsdataColor',
  CalculationColor = 'calculationColor',
  ValidationColor = 'validationColor',
  ColumnTypeColor = 'columnTypeColor',
}
export interface Colors {
  [ColorsEnum.AgencyDataColor]: ColorDetails;
  [ColorsEnum.UsersDataColor]: ColorDetails;
  [ColorsEnum.CalculationColor]: ColorDetails;
  [ColorsEnum.ValidationColor]: ColorDetails;
  [ColorsEnum.ColumnTypeColor]: ColorDetails;
}

export enum ColorDetailsEnum {
  Fill = 'fill',
  Text = 'text',
}
export interface ColorDetails {
  [ColorDetailsEnum.Fill]: string;
  [ColorDetailsEnum.Text]: string;
}

export enum SheetHeaderEnum {
  RequestedData = 'requestedData',
  Instruction = 'instruction',
}
export interface SheetHeader {
  [SheetHeaderEnum.RequestedData]: string;
  [SheetHeaderEnum.Instruction]: string;
}

export enum ATConfigurationFormEnum {
  AgencyDataColorFill = 'agencyDataColorFill',
  AgencyDataColorText = 'agencyDataColorText',
  CalculationColorFill = 'calculationColorFill',
  CalculationColorText = 'calculationColorText',
  ColumnTypeColorFill = 'columnTypeColorFill',
  ColumnTypeColorText = 'columnTypeColorText',
  UsersDataColorFill = 'starsDataColorFill',
  UsersDataColorText = 'starsDataColorText',
  ValidationColorFill = 'validationColorFill',
  ValidationColorText = 'validationColorText',
}

export enum Color {
  Black = 'black',
  White = 'white',
  Orange = 'orange',
  Purple = 'purple',
  Gray = 'gray',
  Blue = 'blue',
  Viola = 'viola',
}
export interface ATConfigurationForm {
  [LogoEnum.ClientLogo]: string;
  [LogoEnum.CompanyLogo]: string;
  [ColorsEnum.AgencyDataColor]: {
    [ColorDetailsEnum.Fill]: string;
    [ColorDetailsEnum.Text]: string;
  };
  [ColorsEnum.CalculationColor]: {
    [ColorDetailsEnum.Fill]: string;
    [ColorDetailsEnum.Text]: string;
  };
  [ColorsEnum.ColumnTypeColor]: {
    [ColorDetailsEnum.Fill]: string;
    [ColorDetailsEnum.Text]: string;
  };
  [ColorsEnum.UsersDataColor]: {
    [ColorDetailsEnum.Fill]: string;
    [ColorDetailsEnum.Text]: string;
  };
  [ColorsEnum.ValidationColor]: {
    [ColorDetailsEnum.Fill]: string;
    [ColorDetailsEnum.Text]: string;
  };
}
