import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ApplicationConf, ApplicationConfEnum } from '@shared/consts/application-conf.const';

export function dateRangeValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: keyof any | boolean } | null => {
    const min = new Date(ApplicationConf[ApplicationConfEnum.MinRangeDatePicker]);
    const max = new Date(ApplicationConf[ApplicationConfEnum.MaxRangeDatePicker]);

    if (!control.value) {
      return null;
    }

    const start = new Date(control.value[0]);
    const end = new Date(control.value[1]);
    if (start.getTime() < min.getTime() || end.getTime() > max.getTime()) {
      return { dateRange: true };
    }
    return null;
  };
}
