<ng-container [formGroup]="form">
  <div nz-row formArrayName="branches" *ngIf="dimensionType$ | async as dimensionType">
    <nz-form-label [nzSpan]="4" [nzRequired]="true">
      {{ "PROJECT.CREATE_DIMENSION.branching" | translate }}
    </nz-form-label>
    <div
      nz-col
      [nzSpan]="16"
      class="container"
      id="branches-form-container"
      cdkDropList
      [cdkDropListData]="branches.controls"
      (cdkDropListDropped)="drop($event)"
    >
      <div *ngFor="let branch of branches.controls; let branchIndex = index" [cdkDragData]="branch" cdkDrag>
        <ng-container [formGroupName]="branchIndex">
          <nz-form-item nzGutter="16" class="mb-2">
            <nz-form-control [nzSpan]="20" [nzOffset]="0">
              <nz-input-group
                class="branches__input"
                nzSearch
                [nzAddOnAfter]="branches.controls.length > 1 && dimensionType !== DataFormats.Boolean ? removeIconButton : ''"
              >
                <ng-container [ngSwitch]="dimensionType">
                  <ng-container *ngSwitchCase="DataFormats.Index">
                    <nz-input-number
                      (keydown.enter)="addBranch($event)"
                      [formControlName]="BranchFormEnum.Name"
                      [id]="BranchFormEnum.Name"
                      [nzDisabled]="readonlyMode"
                    ></nz-input-number>
                  </ng-container>
                  <ng-container *ngSwitchCase="DataFormats.Integer">
                    <nz-input-number
                      (keydown.enter)="addBranch($event)"
                      [formControlName]="BranchFormEnum.Name"
                      [id]="BranchFormEnum.Name"
                      [nzDisabled]="readonlyMode"
                    ></nz-input-number>
                  </ng-container>
                  <ng-container *ngSwitchCase="DataFormats.Numeric">
                    <nz-input-number
                      (keydown.enter)="addBranch($event)"
                      [formControlName]="BranchFormEnum.Name"
                      [id]="BranchFormEnum.Name"
                      [nzStep]="0.1"
                      [nzDisabled]="readonlyMode"
                    ></nz-input-number>
                  </ng-container>
                  <ng-container *ngSwitchCase="DataFormats.Money">
                    <nz-input-number
                      (keydown.enter)="addBranch($event)"
                      [nzFormatter]="formatterDollar"
                      [formControlName]="BranchFormEnum.Name"
                      [id]="BranchFormEnum.Name"
                      [nzDisabled]="readonlyMode"
                    ></nz-input-number>
                  </ng-container>
                  <ng-container *ngSwitchCase="DataFormats.Percentage">
                    <nz-input-number
                      (keydown.enter)="addBranch($event)"
                      [nzStep]="0.1"
                      [nzMax]="1"
                      [nzMin]="0"
                      [formControlName]="BranchFormEnum.Name"
                      [id]="BranchFormEnum.Name"
                      [nzDisabled]="readonlyMode"
                    ></nz-input-number>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <input
                      nz-input
                      [formControlName]="BranchFormEnum.Name"
                      [id]="BranchFormEnum.Name"
                      [placeholder]="'PROJECT.CREATE_DIMENSION.placeholders.branchTitle' | translate"
                      (keydown.enter)="addBranch($event)"
                      [disabled]="readonlyMode"
                    />
                  </ng-container>
                </ng-container>
              </nz-input-group>
              <ng-template #removeIconButton>
                <button
                  class="button-input"
                  id="branches-form-button-remove"
                  nz-button
                  nzSearch
                  type="button"
                  *ngIf="branches.controls.length > 1"
                  (click)="removeBranch(branchIndex)"
                  [disabled]="readonlyMode"
                >
                  <i nz-icon nzType="delete" nzTheme="outline"></i>
                </button>
              </ng-template>
            </nz-form-control>
            <nz-form-control *ngIf="parentValue" [nzSpan]="20" [nzOffset]="0">
              <nz-input-group class="patent-branches__input">
                <nz-select
                  [formControlName]="BranchFormEnum.ParentBranch"
                  [id]="BranchFormEnum.ParentBranch"
                  [nzPlaceHolder]="'PROJECT.CREATE_DIMENSION.placeholders.parentBranchName' | translate"
                  [nzDisabled]="readonlyMode"
                >
                  <nz-option
                    *ngFor="let option of parentValue[DimensionEnum.Branches]"
                    [nzLabel]="option[BranchFormEnum.Name]"
                    [nzValue]="option[BranchFormEnum.Name]"
                  >
                  </nz-option>
                </nz-select>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </ng-container>
      </div>
      <app-tooltip-wraper
        [text]="'For the BOOLEAN dimension type, there are two predefined, read-only fields'"
        [disabled]="dimensionType !== DataFormats.Boolean"
      >
        <button
          id="branches-form-button-add-branch"
          nz-button
          nzType="primary"
          type="button"
          class="mt-3"
          nzGhost
          (click)="addBranch($event)"
          [disabled]="dimensionType === DataFormats.Boolean || readonlyMode"
        >
          <i nz-icon nzType="plus"></i>
          {{ "PROJECT.CREATE_DIMENSION.addField" | translate }}
        </button>
      </app-tooltip-wraper>
    </div>
  </div>
</ng-container>
