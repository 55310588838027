import { HttpErrorResponse } from '@angular/common/http';
import CustomError from '@shared/classes/CustomError.class';
import { CustomErrorEnum, CustomErrorType } from '@shared/interfaces/custom-error.interface';

export const handleSaveUpdateError = (error: HttpErrorResponse): CustomError => {
  if (error.error && error.error.errorCode === 'rfp_already_exists') {
    return new CustomError({
      [CustomErrorEnum.Message]: '',
      [CustomErrorEnum.MessageCode]: 'rfp_already_exists',
      [CustomErrorEnum.Type]: CustomErrorType.Form,
    });
  } else if (error.error && error.error.errorCode === 'rfp_template_already_exists') {
    return new CustomError({
      [CustomErrorEnum.Message]: '',
      [CustomErrorEnum.MessageCode]: 'rfp_already_exists',
      [CustomErrorEnum.Type]: CustomErrorType.Form,
    });
  } else {
    return new CustomError({
      [CustomErrorEnum.Message]: '',
      [CustomErrorEnum.MessageCode]: '',
      [CustomErrorEnum.Type]: CustomErrorType.Default,
    });
  }
};
