import { LAYOUT_MIN_WIDTH } from '@shared/consts/consts';
import { MediumAddComponent } from './medium-add/medium-add.component';
import { ModalConfirmationComponent } from '@shared/components/modal-confirmation/modal-confirmation.component';
import { MediumFilterEnum, MediumFilters } from '@shared/interfaces';
import { Validators } from '@angular/forms';
import { ApplicationConf, ApplicationConfEnum } from '@shared/consts/application-conf.const';

export const AddMediumModalConf = {
  nzWidth: LAYOUT_MIN_WIDTH,
  nzCentered: true,
  nzContent: MediumAddComponent,
  nzFooter: null,
};

export const DeleteMediumModalConf = {
  nzWidth: LAYOUT_MIN_WIDTH,
  nzCentered: true,
  nzContent: ModalConfirmationComponent,
  nzFooter: null,
  nzComponentParams: {
    type: 'deleteMedium',
  },
};
export const DisableMediumModalConf = {
  nzWidth: LAYOUT_MIN_WIDTH,
  nzCentered: true,
  nzContent: ModalConfirmationComponent,
  nzFooter: null,
  nzComponentParams: {
    type: 'disableMedium',
  },
};
export const EnableMediumModalConf = {
  nzWidth: LAYOUT_MIN_WIDTH,
  nzCentered: true,
  nzContent: ModalConfirmationComponent,
  nzFooter: null,
  nzComponentParams: {
    type: 'enableMedium',
  },
};

export const MediumFieldsInitValues: MediumFilters = {
  [MediumFilterEnum.SearchText]: '',
  [MediumFilterEnum.Markets]: null!,
  [MediumFilterEnum.Disabled]: false,
};

export const MediumFilterFormInit = {
  [MediumFilterEnum.SearchText]: [
    MediumFieldsInitValues[MediumFilterEnum.SearchText],
    Validators.maxLength(ApplicationConf[ApplicationConfEnum.DefaultInputLength]),
  ],
  [MediumFilterEnum.Markets]: [MediumFieldsInitValues[MediumFilterEnum.Markets]],
  [MediumFilterEnum.Disabled]: [MediumFieldsInitValues[MediumFilterEnum.Disabled]],
};

export const MediumFilterValuesInit: MediumFilters = {
  [MediumFilterEnum.SearchText]: MediumFieldsInitValues[MediumFilterEnum.SearchText],
  [MediumFilterEnum.Disabled]: MediumFieldsInitValues[MediumFilterEnum.Disabled],
  [MediumFilterEnum.Markets]: MediumFieldsInitValues[MediumFilterEnum.Markets],
};
