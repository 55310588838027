<ng-template #template let-data="data">
  <div class="ant-notification-notice-content">
    <div class="ant-notification-notice-with-icon">
      <span class="ant-notification-notice-icon" [ngSwitch]="data[NotificationEnum.Type]">
        <i *ngSwitchCase="NotificationType.Success" class="icon--success" nz-icon nzType="check-circle" nzTheme="outline"></i>
        <i *ngSwitchCase="NotificationType.Info" class="icon--info" nz-icon nzType="info-circle" nzTheme="outline"></i>
        <i
          *ngSwitchCase="NotificationType.Warning"
          class="icon--warning"
          nz-icon
          nzType="exclamation-circle"
          nzTheme="outline"
        ></i>
        <i *ngSwitchCase="NotificationType.Error" class="icon--error" nz-icon nzType="close-circle" nzTheme="outline"></i>
      </span>

      <ng-container *ngIf="data[NotificationEnum.IgnoreTranslation]; else notificationWithTranslation">
        <div class="ant-notification-notice-message">
          {{ data[NotificationEnum.Type] | uppercase }}
        </div>
        <div class="ant-notification-notice-description">
          {{ data[NotificationEnum.TextType] }}
        </div>
      </ng-container>
      <ng-template #notificationWithTranslation>
        <div class="ant-notification-notice-message">
          {{ "NOTIFICATION." + data[NotificationEnum.Type] + "." + data[NotificationEnum.TextType] + "." + "title" | translate }}
        </div>
        <div class="ant-notification-notice-description">
          {{
            "NOTIFICATION." + data[NotificationEnum.Type] + "." + data[NotificationEnum.TextType] + "." + "description"
              | translate
          }}
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>
