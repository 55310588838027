import { Injectable } from '@angular/core';
import { Market, MarketEnum } from '@shared/interfaces/market.interface';
import { Medium, MediumEnum } from '@shared/interfaces/medium.interface';
import { Observable } from 'rxjs';
import {
  DefaultColumn,
  DefaultColumnEnum,
  DefaultColumnForm,
  Reindex,
  DefaultColumnFilters,
} from 'src/app/logged-in/modules/admin/default-columns/default-columns.interface';
import { RestApiService } from '../rest-api/rest-api.service';

@Injectable({
  providedIn: 'root',
})
export class DefaultAtColumnsService {
  private _path = 'defaultcolumns';
  constructor(private readonly restApiService: RestApiService) {}

  public get(id: Medium[MediumEnum.Id]): Observable<DefaultColumn> {
    return this.restApiService.get(`${this._path}/${id}/`);
  }
  public getMediumDefaultColumns(mediumId: Medium[MediumEnum.Id]): Observable<DefaultColumn[]> {
    return this.restApiService.get(`${this._path}/medium/${mediumId}/`);
  }

  public getDefaultColumnsByMediumMarket(queryParams: DefaultColumnFilters): Observable<DefaultColumn[]> {
    return this.restApiService.get(`${this._path}/`, { params: queryParams });
  }

  public getIndexedDefaultColumnsForMarketMedium(
    marketId: Market[MarketEnum.Id],
    mediumId: Medium[MediumEnum.Id]
  ): Observable<DefaultColumn[]> {
    return this.restApiService.get(`${this._path}/market/${marketId}/medium/${mediumId}/`);
  }

  public getNotIndexedDefaultColumns(marketId: Market[MarketEnum.Id]): Observable<DefaultColumn[]> {
    return this.restApiService.get(`${this._path}/other/${marketId}`);
  }
  public getAll(): Observable<DefaultColumn[]> {
    return this.restApiService.get(`${this._path}/`);
  }
  public save(column: DefaultColumnForm): Observable<DefaultColumn> {
    return this.restApiService.post(`${this._path}/`, column);
  }

  public saveOrder(reindexData: Reindex): Observable<DefaultColumn[]> {
    return this.restApiService.post(`${this._path}/reindex`, reindexData);
  }

  public update(id: DefaultColumn[DefaultColumnEnum.DefaultColumnId], column: DefaultColumnForm): Observable<DefaultColumn> {
    return this.restApiService.put(`${this._path}/${id}`, column);
  }
  public disable(id: DefaultColumn[DefaultColumnEnum.DefaultColumnId]): Observable<DefaultColumn> {
    return this.restApiService.post(`${this._path}/disable/${id}`);
  }
  public delete(id: DefaultColumn[DefaultColumnEnum.DefaultColumnId]): Observable<DefaultColumn> {
    return this.restApiService.delete(`${this._path}/${id}`);
  }
  public enable(id: DefaultColumn[DefaultColumnEnum.DefaultColumnId]): Observable<DefaultColumn> {
    return this.restApiService.post(`${this._path}/enable/${id}`);
  }
}
