import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ClientRfpData } from '../../../client/interfaces/client-rfp.interface';
import { Question, QuestionEnum, RfpEnum, RfpTemplateFieldEnum } from '@shared/interfaces';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-client-rfp-open-question',
  templateUrl: './client-rfp-open-question.component.html',
  styleUrls: ['./client-rfp-open-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientRfpOpenQuestionComponent {
  @Input() formName!: FormGroup;
  @Input() disabled: boolean = false;
  @Input() index!: number;
  @Input() rfpData!: ClientRfpData;
  @Input() question: Question = null!;

  constructor() {}

  readonly RfpEnum = RfpEnum;
  readonly RfpTemplateFieldEnum = RfpTemplateFieldEnum;
  readonly QuestionEnum = QuestionEnum;
}
