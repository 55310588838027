import { Market } from '@shared/interfaces/market.interface';

export enum ClientEnum {
  Id = 'clientId',
  Name = 'name',
  Disabled = 'disabled',
  Parent = 'parent',
  Markets = 'markets',
  ParentId = 'parentId',
}

export interface Client {
  [ClientEnum.Id]: number;
  [ClientEnum.Name]: string;
  [ClientEnum.Disabled]: boolean;
  [ClientEnum.Parent]: Client | null;
  [ClientEnum.Markets]: Market[];
}

export interface ClientAdd {
  [ClientEnum.Name]: string;
  [ClientEnum.Markets]: number[];
  [ClientEnum.ParentId]: number;
}

export interface ClientForm {
  [ClientEnum.Name]: string;
  [ClientEnum.Parent]: number;
  [ClientEnum.Markets]: number[];
}

export type ClientFilters = {
  [ClientFilterEnum.Name]: string;
  [ClientFilterEnum.Parent]: Client;
  [ClientFilterEnum.Market]: Market;
  [ClientFilterEnum.Disabled]: boolean;
  [ClientFilterEnum.SearchText]: string;
};

export enum ClientFilterEnum {
  Name = 'name',
  Parent = 'parent',
  Market = 'market',
  Disabled = 'disabled',
  SearchText = 'searchText',
}

export type ClientFilterValues = {
  [ClientFilterEnum.Disabled]: boolean[];
};

export enum ClientSelectsEnum {
  Market = 'market',
}

export interface ClientSelects {
  [ClientSelectsEnum.Market]: Market[];
}
