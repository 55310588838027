import { UserAuth, UserAuthEnum } from '@auth/interfaces/user.interface';

export interface NotificationMessage {
  message: string;
  recipients: string[]; //TODO AFTER BE CHANGES number[]   (id's)
}

export enum NotificationResponseEmum {
  CreatedBy = 'createdBy',
  CreatedDate = 'createdDate',
  IsRead = 'isRead',
  Message = 'message',
  ModifiedBy = 'modifiedBy',
  ModifiedDate = 'modifiedDate',
  NotificationId = 'notificationId',
  NotificationType = 'notificationType',
}

export interface NotificationResponse {
  [NotificationResponseEmum.CreatedBy]: string;
  [NotificationResponseEmum.CreatedDate]: Date;
  [NotificationResponseEmum.IsRead]: boolean;
  [NotificationResponseEmum.Message]: string;
  [NotificationResponseEmum.ModifiedBy]: string;
  [NotificationResponseEmum.ModifiedDate]: Date;
  [NotificationResponseEmum.NotificationId]: number;
  [NotificationResponseEmum.NotificationType]: string;
}

export type UserNotificationsDetails = Pick<
  NotificationResponse,
  | NotificationResponseEmum.IsRead
  | NotificationResponseEmum.ModifiedBy
  | NotificationResponseEmum.ModifiedDate
  | NotificationResponseEmum.Message
  | NotificationResponseEmum.NotificationId
>;

export type NotificationsSettings = Pick<UserAuth, UserAuthEnum.SystemNotificationOn | UserAuthEnum.EmailNotificationOn>;

