<div *nzModalTitle>
  <div nz-row nzJustify="center">
    <h3 id="modal-confirmation-title">
      <ng-container [ngSwitch]="status">
        <span *ngSwitchCase="'success'" class="success" nz-icon nzType="check-circle" nzTheme="outline"></span>
        <span *ngSwitchCase="'warning'" class="warning" nz-icon nzType="warning" nzTheme="outline"></span>
        <i *ngSwitchDefault nz-icon nzType="close" nzTheme="outline"></i>
      </ng-container>
      {{ "MODAL.confirmation." + type + ".title" | translate }}
    </h3>
  </div>
</div>

<ng-container>
  <div nz-row nzJustify="center" class="modal-content">
    <span
      *ngIf="descriptionParam; else descriptionTextTemp"
      [innerHTML]="'MODAL.confirmation.' + type + '.description' | translate: { param: descriptionParam }"
    >
    </span>
    <ng-template #descriptionTextTemp>
      <span>{{ "MODAL.confirmation." + type + ".description" | translate }}</span>
    </ng-template>
  </div>
</ng-container>

<div nz-row nzJustify="end">
  <nz-space class="footer mt-4" nz-row>
    <button *nzSpaceItem nz-button id="modal-confirmation-button-cancel" (click)="cancel($event)">
      <i nz-icon nzType="close" nzTheme="outline"></i>
      {{ "COMMON.cancel" | translate }}
    </button>
    <button
      *nzSpaceItem
      type="submit"
      nz-button
      id="modal-confirmation-button-confirm"
      nzType="primary"
      (click)="confirm($event)"
    >
      <i nz-icon nzType="check" nzTheme="outline"></i>
      {{ "COMMON.confirm" | translate }}
    </button>
  </nz-space>
</div>
