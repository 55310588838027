import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BE_ERROR_ORIGIN_PREFIX } from '@shared/classes/CustomError.class';

@Injectable({
  providedIn: 'root',
})
export class DefaultValidatorsService {
  constructor(private readonly translateService: TranslateService) {}

  get(): Record<string, Record<string, string>> {
    const autoTips: Record<string, Record<string, string>> = {
      default: {
        required: this.translateService.instant('COMMON.VALIDATORS.required'),
        maxlength: this.translateService.instant('COMMON.VALIDATORS.maxlength'),
        [`${BE_ERROR_ORIGIN_PREFIX}alreadyExists`]: this.translateService.instant('COMMON.VALIDATORS.alreadyExists'),
        alreadyExists: this.translateService.instant('COMMON.VALIDATORS.alreadyExists'),
      },
    };
    return autoTips;
  }
}
