import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApplicationConf, ApplicationConfEnum } from '@shared/consts/application-conf.const';
import { distinctUntilChanged, interval, retry, startWith, switchMap } from 'rxjs';
import { NotificationMessagesService } from '@shared/services';

@UntilDestroy()
@Injectable()
export class TrackUserNotificationsService {
  public notifications$ = interval(ApplicationConf[ApplicationConfEnum.DefaultNotificationIntervalTime]).pipe(
    startWith(-1),
    switchMap(() => this.usersNotification.getAllNotifications().pipe(untilDestroyed(this), retry(5))),
    untilDestroyed(this),
    distinctUntilChanged((prev, curr) => prev.length === curr.length)
  );

  constructor(private readonly usersNotification: NotificationMessagesService) {}
  // * OLD WAY OF THE IMPLEMENTATION
  // public start$(systemNotificationOn: boolean) {
  //   return systemNotificationOn
  //     ? interval(ApplicationConf[ApplicationConfEnum.DefaultNotificationIntervalTime]).pipe(
  //         startWith(-1),
  //         tap(console.log),
  //         switchMap(() => this.usersNotification.getAllNotifications().pipe(untilDestroyed(this), retry(5))),
  //         untilDestroyed(this),
  //         distinctUntilChanged((prev, curr) => prev.length === curr.length),
  //         filter((notifications) => notifications.length > 0)
  //       )
  //     : EMPTY;
  // }
}
