import { DataFormats } from '@shared/interfaces/data-formats.interface';
import { Validators } from '@angular/forms';
import { ApplicationConf, ApplicationConfEnum } from '@shared/consts/application-conf.const';
import { KpiFilterEnum, KpiFilterValues, KpiFilters } from '@shared-project/modules/kpis/interfaces/kpi-setup-handlers.interface';

export const KpiFiltersValue: KpiFilterValues = {
  [KpiFilterEnum.Formats]: [
    DataFormats.Text,
    DataFormats.Boolean,
    DataFormats.Date,
    DataFormats.Time,
    DataFormats.Money,
    DataFormats.Numeric,
    DataFormats.Index,
    DataFormats.Integer,
    DataFormats.Percentage,
  ],
};

export const KpiFilterForm = {
  [KpiFilterEnum.Market]: [],
  [KpiFilterEnum.Media]: [],
  [KpiFilterEnum.Formats]: [],
  [KpiFilterEnum.Disabled]: [],
  [KpiFilterEnum.SearchText]: ['', Validators.maxLength(ApplicationConf[ApplicationConfEnum.DefaultInputLength])],
};

export const KpiFieldsInitValues: KpiFilters = {
  [KpiFilterEnum.Name]: '',
  [KpiFilterEnum.Description]: '',
  [KpiFilterEnum.Disabled]: false,
  [KpiFilterEnum.Market]: null!,
  [KpiFilterEnum.Media]: null!,
  [KpiFilterEnum.Formats]: '',
  [KpiFilterEnum.SearchText]: '',
};

export const KpiFilterFormInit = {
  [KpiFilterEnum.Name]: [KpiFieldsInitValues[KpiFilterEnum.Name]],
  [KpiFilterEnum.Description]: [KpiFieldsInitValues[KpiFilterEnum.Description]],
  [KpiFilterEnum.Market]: [KpiFieldsInitValues[KpiFilterEnum.Market]],
  [KpiFilterEnum.Media]: [KpiFieldsInitValues[KpiFilterEnum.Media]],
  [KpiFilterEnum.Disabled]: [KpiFieldsInitValues[KpiFilterEnum.Disabled]],
  [KpiFilterEnum.Formats]: [KpiFieldsInitValues[KpiFilterEnum.Formats]],
  [KpiFilterEnum.SearchText]: [
    KpiFieldsInitValues[KpiFilterEnum.SearchText],
    Validators.maxLength(ApplicationConf[ApplicationConfEnum.DefaultInputLength]),
  ],
};

export const KpiFilterValuesInit: KpiFilters = {
  [KpiFilterEnum.Name]: KpiFieldsInitValues[KpiFilterEnum.Name],
  [KpiFilterEnum.Description]: KpiFieldsInitValues[KpiFilterEnum.Description],
  [KpiFilterEnum.Market]: KpiFieldsInitValues[KpiFilterEnum.Market],
  [KpiFilterEnum.Media]: KpiFieldsInitValues[KpiFilterEnum.Media],
  [KpiFilterEnum.Disabled]: KpiFieldsInitValues[KpiFilterEnum.Disabled],
  [KpiFilterEnum.Formats]: KpiFieldsInitValues[KpiFilterEnum.Formats],
  [KpiFilterEnum.SearchText]: KpiFieldsInitValues[KpiFilterEnum.SearchText],
};
