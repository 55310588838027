import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CanComponentDeactivate } from '@shared/interfaces/canComponentDeactive.interface';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CanDeactive implements CanDeactivate<CanComponentDeactivate> {
  constructor(private modal: NzModalService) {}
  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot | undefined
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return component.canDeactivate().pipe(
      switchMap((canDeactive) => (canDeactive ? of(true) : this.afterModalClose$())),
      map((value) => {
        if (!value) {
          this.modal.closeAll();
        }
        return !!value;
      })
    );
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  afterModalClose$() {
    const modal = this.modal.create({
      nzTitle: 'Are you sure?',
      nzContent: 'After exiting, the data will not be saved',
      nzOnOk: () => true,
    });

    return modal.afterClose;
  }
}
