import { DsSetupEnum } from '@shared-project/modules/dimensions/setup/interfaces/dimension-setup.interface';

export enum ApplicationConfEnum {
  HeaderName = 'headerName',
  NotificationErrorDurationDefaultTime = 'NotificationErrorDurationDefaultTime',
  NotificationDurationDefaultTime = 'notificationDurationDefaultTime',

  DefaultDateFormat = 'defaultDateFormat',
  DefaultDateTimeFormat = 'defaultDateTimeFormat',
  DefaultDateTimeFormatWithSeconds = 'defaultDateTimeFormatWithSeconds',

  // TABLE
  DefaultPageSize = 'defaultPageSize',
  DragAndDropSpeed = 'dragAndDropSpeed',

  // FORM
  DefaultInputLength = 'defaultInputLength',
  DebounceRequestTimeFromInput = 'debounceRequestTimeFromInput',
  DefaultTextareaLength = 'defaultTextareaLength',
  MaxRangeDatePicker = 'maxRangeDatePicker',
  MinRangeDatePicker = 'minRangeDatePicker',
  DefaultNotificationIntervalTime = 'defaultNotificationIntervalTime',
  DefaultCalculateCheckIntervalTime = 'defaultCalculateCheckIntervalTime',
  LoaderSkipHeaderKey = 'loaderSkipHeaderKey',
  DimensionSetupSectionsOrder = 'dimensionSetupSectionsOrder',
  DefaultMinYear = 'defaultMinYear',
  DefaultMaxYear = 'defaultMaxYear',
  PasswordSpecialCharacters = 'passwordSpecialCharacters',
  PasswordValidator = 'passwordValidator',
}

const PasswordSpecialCharacters: string = '#?!@$%^&*-';
export const ApplicationConf = {
  [ApplicationConfEnum.HeaderName]: 'Netscore',
  [ApplicationConfEnum.NotificationDurationDefaultTime]: 8000,
  [ApplicationConfEnum.NotificationErrorDurationDefaultTime]: 20000,
  [ApplicationConfEnum.DefaultCalculateCheckIntervalTime]: 3000,

  [ApplicationConfEnum.DefaultDateFormat]: 'dd/MM/yyyy',
  [ApplicationConfEnum.DefaultDateTimeFormat]: 'dd/MM/yyyy HH:mm',
  [ApplicationConfEnum.DefaultDateTimeFormatWithSeconds]: 'dd/MM/yyyy HH:mm:ss',

  // TABLE
  [ApplicationConfEnum.DefaultPageSize]: 30,
  [ApplicationConfEnum.DragAndDropSpeed]: 16,
  [ApplicationConfEnum.DimensionSetupSectionsOrder]: [
    DsSetupEnum.SplitBy,
    DsSetupEnum.Rows,
    DsSetupEnum.Columns,
    DsSetupEnum.SplitAsSheet,
  ] as const,

  // FORM
  [ApplicationConfEnum.DebounceRequestTimeFromInput]: 400,
  [ApplicationConfEnum.DefaultInputLength]: 100,
  [ApplicationConfEnum.DefaultNotificationIntervalTime]: 60000,
  [ApplicationConfEnum.LoaderSkipHeaderKey]: 'skip-spinner',
  [ApplicationConfEnum.DefaultTextareaLength]: 200,
  [ApplicationConfEnum.MaxRangeDatePicker]: '9999-12-31', // values base on backend validation
  [ApplicationConfEnum.MinRangeDatePicker]: '0000-01-01', // values base on backend validation
  [ApplicationConfEnum.DefaultMinYear]: 2010,
  [ApplicationConfEnum.DefaultMaxYear]: 2050,
  [ApplicationConfEnum.PasswordSpecialCharacters]: PasswordSpecialCharacters,
  [ApplicationConfEnum.PasswordValidator]: `^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[${PasswordSpecialCharacters}]).{8,}`,
};
