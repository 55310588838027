<form
  class="section-container bg-white"
  [formGroup]="clientFilterForm"
  (ngSubmit)="filter()"
  nz-row
  nzAlign="middle"
  nzJustify="space-between"
>
  <div nz-col [nzSpan]="18" class="pl-2">
    <div nz-row [nzGutter]="[4, 0]" *ngIf="filterValuesData">
      <ng-container *ngIf="selectsData$ | async as selects">
        <div nz-col>
          <nz-select
            class="filter-select"
            (ngModelChange)="filter()"
            [id]="ClientFilterEnum.Parent"
            [formControlName]="ClientFilterEnum.Parent"
            [nzPlaceHolder]="'LOGGED_IN.FILTERS.bar.parent' | translate"
          >
            <nz-option
              *ngFor="let filteredParent of filteredParents"
              [nzValue]="filteredParent"
              [nzLabel]="filteredParent[ClientEnum.Name]"
            ></nz-option>
          </nz-select>
        </div>

        <div nz-col>
          <nz-select
            class="filter-select"
            (ngModelChange)="filter()"
            [id]="ClientFilterEnum.Market"
            [formControlName]="ClientFilterEnum.Market"
            [nzPlaceHolder]="'LOGGED_IN.FILTERS.bar.markets' | translate"
          >
            <nz-option
              *ngFor="let markets of selects[ClientFilterEnum.Market]"
              [nzValue]="markets"
              [nzLabel]="markets[MarketEnum.Name]"
            ></nz-option>
          </nz-select>
        </div>
      </ng-container>

      <div nz-col>
        <nz-select
          class="filter-select"
          (ngModelChange)="filter()"
          [id]="ClientFilterEnum.Disabled"
          [formControlName]="ClientFilterEnum.Disabled"
          [nzPlaceHolder]="'LOGGED_IN.FILTERS.bar.active' | translate"
        >
          <nz-option
            *ngFor="let disabled of filterValuesData[ClientFilterEnum.Disabled]"
            [nzValue]="disabled"
            [nzLabel]="'COMMON.' + disabled.toString() | translate"
          ></nz-option>
        </nz-select>
      </div>

      <div class="nz-col ml-5">
        <nz-input-group nzSearch [nzAddOnAfter]="suffixSearchButton">
          <input
            class="search-input"
            [id]="ClientFilterEnum.SearchText"
            [formControlName]="ClientFilterEnum.SearchText"
            nz-input
            [disabled]="true"
            [placeholder]="'LOGGED_IN.FILTERS.bar.search' | translate"
            [maxLength]="ApplicationConf[ApplicationConfEnum.DefaultInputLength]"
          />
        </nz-input-group>
        <ng-template #suffixSearchButton>
          <button nz-button nzSearch (click)="search($event)"><i nz-icon nzType="search"></i></button>
        </ng-template>
      </div>
    </div>
  </div>
  <div nz-col class="pr-2">
    <button nz-button nzType="default" type="button" (click)="clear($event)">
      <i nz-icon nzType="clear" nzTheme="outline"></i>
      {{ "LOGGED_IN.FILTERS.bar.clearFilters" | translate }}
    </button>
  </div>
</form>
