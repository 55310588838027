import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  Market,
  RfpData,
  PitchingStatuses,
  ProjectTypeEnum,
  ProjectStatuses,
  ValueTrackStatuses,
  QuadraStatuses,
} from '@shared/interfaces';
import { RestApiService } from '@shared/services/rest-api/rest-api.service';
import { AuthService } from '@auth/services/auth/auth.service';
import { getYearsRange } from '@shared/functions/get-years-range';

@Injectable({
  providedIn: 'root',
})
export class ProjectsFilterService {
  constructor(private readonly authService: AuthService, private readonly restApiService: RestApiService) {}

  public getYears(): Observable<number[]> {
    return of(getYearsRange());
  }

  public getMarkets(): Observable<Market[]> {
    return this.authService.isAgencyAccount() ? of([]) : this.restApiService.get('markets/');
  }

  public getRfps(): Observable<RfpData[]> {
    return this.authService.isAgencyAccount() ? of([]) : this.restApiService.get('rfp/');
  }

  public getStatuses(type = ProjectTypeEnum.ValueTrack): Observable<ProjectStatuses[]> {
    return this.authService.isAgencyAccount() ? of([]) : of(Object.values(this.assignStatuses(type)));
  }

  private assignStatuses(projectType: ProjectTypeEnum) {
    switch (projectType) {
      case ProjectTypeEnum.Quadra:
        return QuadraStatuses;
      case ProjectTypeEnum.Pitching:
        return PitchingStatuses;
      default:
        return ValueTrackStatuses;
    }
  }
}
