export enum DragDropTableContainer {
  KpiList = 'itemList',
  DimensionList = 'dimensionList',
  DefaultColumnList = 'defaultColumnList',
  ProjectDimension = 'projectDimension',
  Worksheet = 'worksheet',
}

export enum DragDropTransferItemsEnum {
  PrevContainer = 'prevContainer',
  CurrContainer = 'currContainer',
  PrevIndex = 'prevIndex',
  CurrIndex = 'currIndex',
  CurrContainerId = 'currContainerId',
  PrevContainerId = 'prevContainerId',
  PrevContainerIndex = 'prevContainerIndex',
  CurrContainerIndex = 'currContainerIndex',
}

export interface DragDropTransferItems<T> {
  [DragDropTransferItemsEnum.PrevContainer]: T[];
  [DragDropTransferItemsEnum.CurrContainer]: T[];
  [DragDropTransferItemsEnum.PrevIndex]: number;
  [DragDropTransferItemsEnum.CurrIndex]: number;
  [DragDropTransferItemsEnum.CurrContainerId]: string;
  [DragDropTransferItemsEnum.PrevContainerId]?: string;
  [DragDropTransferItemsEnum.PrevContainerIndex]?: number;
  [DragDropTransferItemsEnum.CurrContainerIndex]?: number;
}

export enum DragDropTransferOutputEnum {
  BuilderItemList = 'builderItemList',
  SheetGroup = 'sheetGroup',
  DraggedItem = 'draggedItem',
  BuilderItemIndex = 'builderItemIndex',
  SheetGroupItemIndex = 'sheetGroupItemIndex',
}
