import { ApplicationConf, ApplicationConfEnum } from '@shared/consts/application-conf.const';

export const getYearsRange = (): number[] => {
  let startFrom = ApplicationConf[ApplicationConfEnum.DefaultMinYear];
  const finishYear = ApplicationConf[ApplicationConfEnum.DefaultMaxYear];
  const years: number[] = [];
  while (startFrom <= finishYear) {
    years.push(startFrom);
    startFrom++;
  }
  return years;
};
