import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NzTableComponent } from 'ng-zorro-antd/table';

@Component({
  selector: 'app-table-row-index',
  template: '{{ (table.nzPageIndex - 1) * table.nzPageSize + index + 1 }}',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableRowIndexComponent {
  @Input() table!: NzTableComponent<any>;
  @Input() index: number = 0;
  constructor() {}
}
