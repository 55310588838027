import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Medium, MediumEnum, MediumSave } from '@shared/interfaces/medium.interface';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MediaAdminFacade } from '@shared/services/medium/medium.facade';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { MarketFacade } from '@shared/services/markets/market.facade';
import { Market, MarketEnum } from '@shared/interfaces/market.interface';
import { DefaultValidatorsService } from '@shared/services/default-validators/default-validators.service';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CustomErrorEnum, CustomErrorType } from '@shared/interfaces/custom-error.interface';
import { NotificationEnum, NotificationType } from '@shared/components/notification/notification.const';
import { NotificationService } from '@shared/components/notification/notification.facade';
import { ApplicationConf, ApplicationConfEnum } from '@shared/consts/application-conf.const';
import { BE_ERROR_ORIGIN_PREFIX } from '@shared/classes/CustomError.class';
import { displayFormErrors } from '@shared/index';

@UntilDestroy()
@Component({
  selector: 'app-medium-add',
  templateUrl: './medium-add.component.html',
  styleUrls: ['./medium-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediumAddComponent implements OnInit {
  @Input() set editModeData(data: Medium) {
    this._editModeData = data;
    this.isEditMode = true;
    if (!!data) {
      this.mediumForm.get(MediumEnum.Name)?.disable();
      this.title = this.translateService.instant('ADMIN.MEDIUM.edit');
    }
  }
  private _editModeData!: Medium;
  public get editModeData(): Medium {
    return this._editModeData;
  }
  public isEditMode = false;

  public title = this.translateService.instant('ADMIN.MEDIUM.add');

  markets$ = this.marketFacade.markets$;
  MediumEnum = MediumEnum;
  MarketEnum = MarketEnum;
  autoTips!: Record<string, Record<string, string>>;
  mediumForm = new FormGroup({
    [MediumEnum.Name]: new FormControl('', [
      Validators.required,
      Validators.maxLength(ApplicationConf[ApplicationConfEnum.DefaultInputLength]),
    ]),
    [MediumEnum.Markets]: new FormControl([], [Validators.required]),
  });
  public errorMediumAdd$ = this.mediumFacade.errorMediumAdd$;

  constructor(
    private readonly translateService: TranslateService,
    private readonly defaultValidatorsService: DefaultValidatorsService,
    private readonly marketFacade: MarketFacade,
    private readonly mediumFacade: MediaAdminFacade,
    private readonly modal: NzModalRef,
    private readonly notificationService: NotificationService
  ) {
    this.autoTips = this.defaultValidatorsService.get();
  }
  ngOnInit(): void {
    this.handleErrors();
    if (!!this.editModeData) {
      this.mediumForm.patchValue({
        ...this.editModeData,
        [MediumEnum.Markets]: this.editModeData[MediumEnum.Markets]?.map((data) => data[MarketEnum.Id]),
      });
    }
  }

  save(event: Event): void {
    event.preventDefault();
    if (this.mediumForm.valid) {
      !!this.editModeData
        ? this.mediumFacade.edit(this.editModeData[MediumEnum.Id], {
            [MediumEnum.Name]: this.mediumForm.controls[MediumEnum.Name].value,
            [MediumEnum.Markets]: this.mediumForm.controls[MediumEnum.Markets].value,
          })
        : this.mediumFacade.save(<MediumSave>{
            [MediumEnum.Name]: this.mediumForm.controls[MediumEnum.Name].value,
            [MediumEnum.Markets]: this.mediumForm.controls[MediumEnum.Markets].value,
          });
    } else {
      displayFormErrors(this.mediumForm);
    }
  }

  handleErrors(): void {
    this.errorMediumAdd$.pipe(untilDestroyed(this)).subscribe({
      next: (error) => {
        if (error && error[CustomErrorEnum.Type] === CustomErrorType.Form) {
          if (error[CustomErrorEnum.MessageCode] === 'medium_already_exists') {
            this.mediumForm.controls[MediumEnum.Name].setErrors({
              [`${BE_ERROR_ORIGIN_PREFIX}alreadyExists`]: true,
            });
          }
        } else {
          this.showNotification(NotificationType.Error);
        }
      },
    });
  }

  showNotification(type: NotificationType) {
    this.notificationService.create({
      [NotificationEnum.Type]: type,
      [NotificationEnum.TextType]: 'mediumAdd',
    });
  }

  cancel(): void {
    this.modal.destroy(false);
  }

  public hideDisabledMarket(market: Market) {
    return market[MarketEnum.Disabled] === true;
  }
}
