/* Interfaces */

export interface GtColors {
  [GtColorsEnum.HeaderColor]: ColorDetails;
  [GtColorsEnum.DimColor]: ColorDetails;
  [GtColorsEnum.FactColor]: ColorDetails;
  [GtColorsEnum.ReferenceColor]: ColorDetails;
  [GtColorsEnum.CalcColor]: ColorDetails;
  [GtColorsEnum.RemarkColor]: ColorDetails;
  [GtColorsEnum.InstructionHeaderColor]: ColorDetails;
  [GtColorsEnum.InstructionSectionHeaderColor]: ColorDetails;
  [GtColorsEnum.InstructionSubsectionHeaderColor]: ColorDetails;
  [GtColorsEnum.InstructionStatementColor]: ColorDetails;
}

export interface ColorDetails {
  [ColorDetailsEnum.Fill]: string;
  [ColorDetailsEnum.Text]: string;
}


/* Enums */

export enum GtColorsEnum {
  HeaderColor = 'headerColor',
  DimColor = 'dimColor',
  FactColor = 'factColor',
  ReferenceColor = 'referenceColor',
  CalcColor = 'calcColor',
  RemarkColor = 'remarkColor',
  InstructionHeaderColor = 'instructionHeaderColor',
  InstructionSectionHeaderColor = 'instructionSectionHeaderColor',
  InstructionSubsectionHeaderColor = 'instructionSubsectionHeaderColor',
  InstructionStatementColor = 'instructionStatementColor',
}

export enum ColorDetailsEnum {
  Fill = 'fill',
  Text = 'text',
}

export enum GtColor {
  Black = 'black',
  White = 'white',
  Orange = 'orange',
  Purple = 'purple',
  Gray = 'gray',
  Gray2 = 'gray 2',
  LightGray = 'light gray',
  DarkGray = 'dark gray',
  DarkGray2 = 'dark gray 2',
  Blue = 'blue',
  Viola = 'viola',
}
