import { Project } from '@shared/interfaces';

export enum ProjectListPaginationEnum {
  TotalElements = 'totalElements',
  TotalPages = 'totalPages',
  Pageable = 'pageable',
  PageNumber = 'pageNumber',
  Number = 'number',
  PageSize = 'size',
  Direction = 'direction',
  SortProperty = 'sortProperty',
  Content = 'content',
}

export interface ProjectListPagination {
  [ProjectListPaginationEnum.Pageable]: {};
  [ProjectListPaginationEnum.PageNumber]: number;
  [ProjectListPaginationEnum.Number]: number;
  [ProjectListPaginationEnum.PageSize]: number;
  [ProjectListPaginationEnum.TotalElements]: number;
  [ProjectListPaginationEnum.TotalPages]: number;
  [ProjectListPaginationEnum.Direction]: string;
  [ProjectListPaginationEnum.SortProperty]: string;
  [ProjectListPaginationEnum.Content]: Project[];
}
