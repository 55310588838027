import { SortOrderEnum } from './sort.interface';

export interface Sortable {
  changeSortStrategy(): void;

  sortStrategy: SortOrderEnum;
  SortOrderEnum: typeof SortOrderEnum;
}

export interface SortParams {
  [SortParamsEnum.Direction]: string;
  [SortParamsEnum.SortProperty]?: string;
}

export enum SortParamsEnum {
  Direction = 'direction',
  SortProperty = 'sortProperty',
}
