import { ProjectListPaginationEnum } from '@shared-project/modules/projects/interfaces/projects.interface';
import { ProjectTypeEnum } from '@shared/interfaces';
import { quadraFilesMock } from 'src/app/logged-in/modules/quadra-audit/modules/quadra-project/components/quadra-files/quadra-files.mock';
import { selectedMediumMock } from 'src/app/logged-in/modules/value-track/projects/project/modules/kpi/mocks/selected-media.mock';

export const quadraWithFiles = {
  id: 0,
  projectUuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  name: 'string',
  status: 'KPI_SETUP',
  projectStatusPercent: 0,
  year: '2023',
  invoiceNumber: 'string',
  evaluatedFrom: '2023-11-08T11:00:32.641Z',
  evaluatedTo: '2023-11-08T11:00:32.641Z',
  content: {
    kpiSetup: {},
    adLayout: {},
    gtLayout: {},
    validatedFiles: [{}],
    adProcess: {},
    engineProcess: {
      adCalculationProcess: {},
      adValidationProcess: {},
      gtImportProcess: {},
      gtExportProcess: {},
      gtScoringCalculationProcess: {},
      gtScoringValidationProcess: {},
      quadraCalculationProcess: {
        calculationResultSuccess: 'Quadra calculation successful',
        calculationResult: true,
      },
    },
    scoringLayout: {},
  },
  disabled: true,
  blocked: true,
  gtImported: true,
  engineRequestTime: '2023-11-08T11:00:32.641Z',
  reportFiles: [
    {
      reportFileId: 0,
      filename: 'string',
      disabledForSending: true,
      agencyNotified: true,
      isClientNotified: true,
      approved: true,
      uploadedByUser: {
        userId: 0,
        email: 'string',
        disabled: true,
      },
      relatedProject: 0,
      createdBy: 'string',
      modifiedBy: 'string',
      createdDate: '2023-11-08T11:00:32.641Z',
      modifiedDate: '2023-11-08T11:00:32.641Z',
      rejectAgencyUser: {
        userId: 0,
        email: 'string',
        disabled: true,
      },
      agencyApproved: true,
      rejectedByAgency: true,
    },
  ],
  quadraFiles: [...quadraFilesMock],
  organization: {
    organizationId: 0,
    type: 'AGENCY',
    name: 'string',
    disabled: true,
  },
  agencies: [
    {
      organizationId: 0,
      type: 'AGENCY',
      name: 'string',
      disabled: true,
    },
  ],
  market: {
    id: 0,
    name: 'string',
    code: 'string',
    disabled: true,
  },
  rfp: {},
  createdBy: 'string',
  modifiedBy: 'string',
  createdDate: '2023-11-08T11:00:32.641Z',
  modifiedDate: '2023-11-08T11:00:32.641Z',
  client: {
    organizationId: 0,
    type: 'AGENCY',
    name: 'string',
    disabled: true,
  },
  userAccesses: [
    {
      userId: 0,
      email: 'string',
      disabled: true,
    },
  ],
  agencyUserAccesses: [
    {
      userId: 0,
      email: 'string',
      disabled: true,
    },
  ],
  responsibleUser: {
    userId: 0,
    email: 'string',
    disabled: true,
  },
  currencies: ['string'],
  version: 0,
  hasAgencyUsers: true,
  closingReport: 'string',
  atFilePromoted: null,
  scoringFilePromoted: null,
  type: 'QUADRA',
  currentStageNumber: null,
  stages: null,
  readOnly: false,
};
export const projectMock = {
  id: 220,
  projectUuid: '07074893-e4e6-44f4-902d-813a09b3980d',
  name: 'Wolverine',
  status: 'PROJECT_CREATED',
  projectStatusPercent: 10,
  year: '2023',
  invoiceNumber: null,
  evaluatedFrom: '2023-02-12T06:28:44.716+00:00',
  evaluatedTo: '2023-02-17T06:28:44.716+00:00',
  selectedMedia: [{ ...selectedMediumMock }],
  content: {
    kpiSetup: {
      selectedMedia: [{ ...selectedMediumMock }],
    },
    adLayout: {
      name: 'ValueTrack_Stars_Client_Poland_TV_2023_20230220_AT.xlsx',
      layoutHeader: {
        client: 'Stars_Client',
        agency: 'Default Agency',
        country: 'Poland',
        currency: 'EUR,PLN,GBA,USD',
        evaluationPeriod: '2023',
        version: null,
        logo: {
          clientLogo: null,
          companyLogo: null,
        },
        sendDate: null,
      },
      projectId: 220,
      colors: {
        agencydataColor: {
          fill: 'f79646',
          text: 'FFFFFF',
        },
        starsdataColor: {
          fill: '8064a2',
          text: 'FFFFFF',
        },
        calculationColor: {
          fill: '1f497d',
          text: 'FFFFFF',
        },
        validationColor: {
          fill: 'bf819e',
          text: 'FFFFFF',
        },
        columnTypeColor: {
          fill: 'd8d8d8',
          text: '000000',
        },
        headerColor: null,
        dimColor: null,
        factColor: null,
        referenceColor: null,
        calcColor: null,
        remarkColor: null,
        instructionHeaderColor: null,
        instructionSectionHeaderColor: null,
        instructionSubsectionHeaderColor: null,
        instructionStatementColor: null,
      },
      worksheets: [
        {
          sheetName: 'Custom Worksheet 1',
          sheetIndex: 1,
          sheetUid: '86584374-2541-4323-aaa0-0bc7841b2732',
          sheetHeader: {
            requestedData: '',
            instruction: '',
            medium: null,
          },
          companyLogo: true,
          clientLogo: true,
          media: 'TV',
          market: 'Poland',
          sumBy: null,
          agregatedBy: null,
          primaryKeys: [],
          columns: [
            {
              genericType: 'dimension',
              columnUid: 'D47',
              columnName: 'Auto Dimension 410152e',
              columnIndex: 1,
              columnType: 'AgencyData',
              dataType: 'integer',
              format: '#,##0.00',
              columnWidth: 15,
              enumValues: ['123', '321'],
              dimensionNames: [],
              isRequired: false,
              calculationsType: null,
              calculations: null,
              relatedDimensions: null,
              equalityColumnName: null,
            },
            {
              genericType: 'kpi',
              columnUid: 'K55',
              columnName: 'Cristof',
              columnIndex: 2,
              columnType: 'AgencyData',
              dataType: 'text',
              format: '',
              columnWidth: 15,
              enumValues: [],
              dimensionNames: [],
              isRequired: false,
              calculationsType: null,
              calculations: null,
              relatedDimensions: null,
              equalityColumnName: null,
            },
            {
              genericType: 'dimension',
              columnUid: 'D23',
              columnName: 'Auto Dimension 614792e',
              columnIndex: 3,
              columnType: 'AgencyData',
              dataType: 'integer',
              format: '#,##0.00',
              columnWidth: 15,
              enumValues: ['123', '321'],
              dimensionNames: [],
              isRequired: false,
              calculationsType: null,
              calculations: null,
              relatedDimensions: null,
              equalityColumnName: null,
            },
            {
              genericType: 'dimension',
              columnUid: 'D24',
              columnName: 'Auto Dimension 306888e',
              columnIndex: 4,
              columnType: 'AgencyData',
              dataType: 'integer',
              format: '#,##0.00',
              columnWidth: 15,
              enumValues: ['123', '321'],
              dimensionNames: [],
              isRequired: false,
              calculationsType: null,
              calculations: null,
              relatedDimensions: null,
              equalityColumnName: null,
            },
          ],
        },
        {
          sheetName: 'Wolverine',
          sheetIndex: 2,
          sheetUid: '2e724ecc-9284-4da7-a516-b8bf7a98dafe',
          sheetHeader: {
            requestedData: '',
            instruction: '',
            medium: null,
          },
          companyLogo: true,
          clientLogo: true,
          media: 'TV',
          market: 'Poland',
          sumBy: null,
          agregatedBy: null,
          primaryKeys: [],
          columns: [],
        },
      ],
    },
    gtLayout: {
      sheetList: [
        {
          sheetName: '220 - 1',
          sliceList: [
            {
              orientation: 'horizontal',
              padding: 1,
              sliceRemarks: [''],
              kpiList: [
                {
                  measure: 'Premium Position Reference',
                  kpiRemarks: null,
                  format: null,
                  referenceKpi: null,
                  calculations: null,
                  filteredDimensions: [],
                },
              ],
              isCompatible: false,
              columnCompatibility: [],
              rowCompatibility: [],
              compatible: false,
            },
            {
              orientation: 'horizontal',
              padding: 1,
              sliceRemarks: [''],
              kpiList: [],
              isCompatible: false,
              columnCompatibility: [],
              rowCompatibility: [],
              compatible: false,
            },
          ],
          sheetHeader: {
            requestedData: null,
            instruction: null,
            medium: 'TV',
          },
          signatureBox: null,
          protected: false,
        },
        {
          sheetName: '220 - 2 - pusto',
          sliceList: [
            {
              orientation: 'horizontal',
              padding: 1,
              sliceRemarks: [''],
              kpiList: [
                {
                  measure: 'Premium Position Index',
                  kpiRemarks: null,
                  format: null,
                  referenceKpi: null,
                  calculations: null,
                  filteredDimensions: [],
                },
              ],
              isCompatible: false,
              columnCompatibility: [],
              rowCompatibility: [],
              compatible: false,
            },
            {
              orientation: 'horizontal',
              padding: 1,
              sliceRemarks: [''],
              kpiList: [
                {
                  measure: 'Investment Net Net',
                  kpiRemarks: null,
                  format: null,
                  referenceKpi: null,
                  calculations: null,
                  filteredDimensions: [],
                },
              ],
              isCompatible: false,
              columnCompatibility: [
                'CPP 30" contract',
                'Cristof',
                'Budget variation index',
                'CPP 30" contract',
                'Cristof',
                'Budget variation index',
              ],
              rowCompatibility: [
                'Cristof',
                'Budget variation index',
                'Premium Position Reference',
                'Cristof',
                'Budget variation index',
              ],
              compatible: false,
            },
          ],
          sheetHeader: {
            requestedData: null,
            instruction: null,
            medium: 'TV',
          },
          signatureBox: null,
          protected: false,
        },
        {
          sheetName: 'Sheet 2203',
          sliceList: [
            {
              orientation: 'horizontal',
              padding: 1,
              sliceRemarks: [''],
              kpiList: [],
              isCompatible: false,
              columnCompatibility: [],
              rowCompatibility: [],
              compatible: false,
            },
          ],
          sheetHeader: {
            requestedData: null,
            instruction: null,
            medium: 'TV',
          },
          signatureBox: null,
          protected: false,
        },
      ],
      colors: {
        agencydataColor: null,
        starsdataColor: null,
        calculationColor: null,
        validationColor: null,
        columnTypeColor: null,
        headerColor: {
          fill: '808080',
          text: 'FFFFFF',
        },
        dimColor: {
          fill: '909090',
          text: 'FFFFFF',
        },
        factColor: {
          fill: 'f2f2f2',
          text: '000000',
        },
        referenceColor: {
          fill: 'b2b2b2',
          text: 'FFFFFF',
        },
        calcColor: {
          fill: 'f79646',
          text: 'FFFFFF',
        },
        remarkColor: {
          fill: 'FFFFFF',
          text: '000000',
        },
        instructionHeaderColor: {
          fill: 'FFFFFF',
          text: '000000',
        },
        instructionSectionHeaderColor: {
          fill: '909090',
          text: '000000',
        },
        instructionSubsectionHeaderColor: {
          fill: 'FFFFFF',
          text: '000000',
        },
        instructionStatementColor: {
          fill: 'FFFFFF',
          text: '000000',
        },
      },
      layoutHeader: {
        logo: {
          clientLogo: null,
          companyLogo: null,
        },
      },
      instructionWorksheet: {
        logoS3Path: null,
        sheetName: 'Instructions',
        instructionHeader: '',
        instructionDescription: '',
        sectionList: [],
      },
    },
    adProcess: {
      filename: 'Queen_AT.xlsx',
      processPercentage: 100,
      validationResultSuccess: false,
      validationResult: 'Validation failed',
      calculationResultSuccess: true,
      calculationResult: 'Calculations applied successfully',
      calculationVersion: 1,
    },
  },
  disabled: false,
  projectFiles: [
    {
      filename: 'Queen_AT (calculated).xlsx',
      extension: 'xlsx',
      uploadDate: '2023-02-21T10:35:50.000+00:00',
      projectFileType: 'AT_CALCULATED',
      deleted: false,
      accessibleByAgency: false,
      user: null,
      organization: null,
    },
    {
      filename: 'Queen_AT.xlsx',
      extension: 'xlsx',
      uploadDate: '2023-02-21T10:35:45.000+00:00',
      projectFileType: 'AT_VALIDATED',
      deleted: false,
      accessibleByAgency: false,
      user: null,
      organization: null,
    },
    {
      filename: 'Wolverine_AT (1).xlsx',
      extension: 'xlsx',
      uploadDate: '2023-02-21T10:35:23.000+00:00',
      projectFileType: 'AT_VALIDATED',
      deleted: false,
      accessibleByAgency: false,
      user: null,
      organization: null,
    },
    {
      filename: 'Wolverine_AT.xlsx',
      extension: 'xlsx',
      uploadDate: '2023-02-20T10:35:50.000+00:00',
      projectFileType: 'AT_TEMPLATE',
      deleted: false,
      accessibleByAgency: true,
      user: null,
      organization: null,
    },
  ],
  organization: {
    organizationId: 1,
    type: 'STARS',
    name: 'STARS',
    disabled: false,
  },
  agency: {
    organizationId: 3,
    type: 'AGENCY',
    name: 'Default Agency',
    disabled: false,
  },
  market: {
    id: 31,
    name: 'Poland',
    code: 'PL',
    disabled: false,
  },
  rfp: {
    rfpId: 1,
    rfpUuid: '2b252765-7221-44b0-b232-a8e6199811ff',
    name: 'Dummy RFP',
    client: {
      organizationId: 4,
      type: 'CLIENT',
      name: 'Stars_Client',
      disabled: false,
    },
    userAccesses: [],
    finished: true,
    projectCreated: true,
    clientNotified: true,
    readOnly: false,
  },
  createdBy: 'r.lejman@webellian.com',
  modifiedBy: 'r.lejman@webellian.com',
  createdDate: '2023-02-20T06:27:55.823+00:00',
  modifiedDate: '2023-02-22T08:47:22.953+00:00',
  client: {
    organizationId: 4,
    type: 'CLIENT',
    name: 'Stars_Client',
    disabled: false,
  },
  userAccesses: [
    {
      userId: 6,
      email: 'r.lejman@webellian.com',
    },
  ],
  agencyUserAccesses: [],
  responsibleUser: {
    userId: 6,
    email: 'r.lejman@webellian.com',
  },
  currencies: ['PLN', 'EUR', 'GBA', 'USD'],
  version: null,
  readOnly: false,
  type: ProjectTypeEnum.Quadra,
};
export const projectPaginationMock = {
  size: 1,
  empty: false,
  numberOfElements: 1,
  sort: {
    sorted: true,
    unsorted: false,
    empty: false,
  },
  [ProjectListPaginationEnum.Pageable]: {
    sort: {
      sorted: true,
      unsorted: false,
      empty: false,
    },
    pageNumber: 0,
    pageSize: 30,
    offset: 0,
    paged: true,
    unpaged: false,
  },
  last: false,
  [ProjectListPaginationEnum.TotalElements]: 1,
  [ProjectListPaginationEnum.TotalPages]: 1,
  first: true,
  [ProjectListPaginationEnum.Number]: 0,
  [ProjectListPaginationEnum.Content]: [quadraWithFiles],
} as any;
