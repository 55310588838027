import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ModalConfirmationStatuses } from '@shared/interfaces/modal-confirmation.interface';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-confirmation',
  templateUrl: './modal-confirmation.component.html',
  styleUrls: ['./modal-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalConfirmationComponent {
  @Input() type!: string;
  @Input() status: ModalConfirmationStatuses.Default | ModalConfirmationStatuses.Warning | ModalConfirmationStatuses.Success =
    ModalConfirmationStatuses.Default;
  @Input() descriptionParam!: number | string;

  constructor(private modal: NzModalRef) {}

  confirm(event: Event): void {
    event.preventDefault();
    this.modal.destroy(true);
  }

  cancel(event: Event): void {
    event.preventDefault();
    this.modal.destroy(false);
  }
}
