import { Medium, MediumEnum } from '@shared/interfaces/medium.interface';
import { Market } from '@shared/interfaces/market.interface';
import { Dimension } from '../../../../../../shared/interfaces/dimension.interface';
import { Kpi, KpiEnum } from '@shared/interfaces/kpi.interface';
import { DataFormats } from '@shared/interfaces/data-formats.interface';
import { SelectedKpi } from './selected-kpi.interface';
import { SelectedMedia, KpisForMedia } from './selected-medium.interface';
import { SelectedMediaEnum } from '@shared/interfaces/kpi-setup.interface';

export enum KpiFormEnum {
  Kpi = 'kpi',
}

export enum KpiStatesEnum {
  IsDisabled = 'isDisabled',
}

export enum CreateKpiSelectedItemsEnum {
  SelectedMarkets = 'selectedMarkets',
  SelectedMedia = 'selectedMedia',
}

export interface SelectedKpiItems {
  [CreateKpiSelectedItemsEnum.SelectedMarkets]?: Market[];
  [CreateKpiSelectedItemsEnum.SelectedMedia]?: Medium[];
}

export interface SelectsKpiData extends SelectedKpiItems {
  [KpiEnum.Media]: Medium[];
  [KpiEnum.Markets]: Market[];
  [SelectedMediaEnum.SelectedKpis]?: SelectedKpi[];
}

export type KpiForm = Omit<Kpi, KpiEnum.Id | KpiEnum.DimensionSetup>;

export type SelectableKpi = Kpi & Record<KpiStatesEnum.IsDisabled, boolean>;
export enum FormatEnum {
  Text = 'text',
  List = 'list',
}

export enum KpiListsData {
  KpiId = 'kpiId',
  Id = 'id',
  PrevKpiId = 'prevKpiId',
  SuggestedKpi = 'suggestedKpi',
  SelectedKpi = 'selectedKpi',
  SelectedKpis = 'selectedKpis',
}

export interface KpiData {
  [KpiListsData.KpiId]: SelectableKpi[KpiEnum.Id];
  [KpiListsData.Id]: Medium[MediumEnum.Id];
}

export interface SuggestedKpisData {
  [KpiListsData.Id]: Medium[MediumEnum.Id];
  [KpiListsData.SuggestedKpi]: SelectableKpi;
}
export interface SelectedKpiData {
  [KpiListsData.Id]: Medium[MediumEnum.Id];
  [KpiListsData.PrevKpiId]?: number;
  [KpiListsData.SelectedKpi]: SelectedKpi;
}

export enum KpiStateEnum {
  CurrTabMediaIndex = 'currTabMediaIndex',
  Dimensions = 'dimensions',
  SelectedKpis = 'selectedKpis',
  SelectedMedia = 'selectedMedia',
}

export interface KpiState {
  [KpiStateEnum.CurrTabMediaIndex]: number;
  [KpiStateEnum.Dimensions]: Dimension[];
  [KpiStateEnum.SelectedMedia]: (SelectedMedia & KpisForMedia)[];
}

export type KpiFilters = {
  [KpiFilterEnum.Name]: string;
  [KpiFilterEnum.Description]: string;
  [KpiFilterEnum.Disabled]: boolean;
  [KpiFilterEnum.Market]: Market;
  [KpiFilterEnum.Media]: Medium;
  [KpiFilterEnum.Formats]: string;
  [KpiFilterEnum.SearchText]: string;
};

export enum KpiFilterEnum {
  Name = 'name',
  Description = 'description',
  Disabled = 'disabled',
  Market = 'market',
  Media = 'media',
  Formats = 'formats',
  SearchText = 'searchText',
}

export interface KpiFilterValues {
  [KpiFilterEnum.Formats]: DataFormats[];
}
