import { RoleType } from '@auth/interfaces/user.interface';
import { AuditData } from './audit-data.interface';
import { Organization } from "@shared/interfaces/organization.interface";

export interface User extends UserAdd, Partial<AuditData> {
  [UserEnum.Id]: number;
  [UserEnum.Disabled]: boolean;
  [UserEnum.Blocked]: boolean;
  [UserEnum.Active]: boolean;
  [UserEnum.NotificationType]: string;
  [UserEnum.Organization]: Organization;
}

export enum UserEnum {
  Id = 'userId',
  Email = 'email',
  Role = 'role',
  Disabled = 'disabled',
  Blocked = 'blocked',
  Active = 'active',
  Organization = 'organization',
  OrganizationId = 'organizationId',
  NotificationType = 'notificationType',
}

export interface UserAdd {
  [UserEnum.Email]: string;
  [UserEnum.Role]: string;
  [UserEnum.OrganizationId]?: number;
}

export interface UserEdit {
  [UserEnum.Id]: number;
  [UserEnum.Email]: string;
  [UserEnum.Role]: string;
}

export type UserFilters = {
  [UserFilterEnum.Email]: string;
  [UserFilterEnum.Role]: string;
  [UserFilterEnum.Active]: boolean;
  [UserFilterEnum.Blocked]: boolean;
  [UserFilterEnum.SearchText]: string;
};

export enum UserFilterEnum {
  Email = 'email',
  Role = 'role',
  Active = 'active',
  Blocked = 'blocked',
  SearchText = 'searchText',
}

export type UserFilterValues = {
  [UserFilterEnum.Role]: RoleType[];
  [UserFilterEnum.Active]: boolean[];
  [UserFilterEnum.Blocked]: boolean[];
};

export type UserForm = {
  [UserEnum.Email]: string;
  [UserEnum.Role]: string;
};
