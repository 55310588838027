<div *nzModalTitle>
  <div nz-row nzJustify="center">
    <h3>
      <i *ngIf="!editModeData" nz-icon nzType="plus" nzTheme="outline"></i>
      <i *ngIf="editModeData" nz-icon nzType="edit" nzTheme="outline"></i>
      {{ title | uppercase }}
    </h3>
  </div>
</div>

<form
  (ngSubmit)="save($event)"
  id="medium-add-form"
  [formGroup]="mediumForm"
  [nzAutoTips]="autoTips"
  nz-form
  nzLayout="horizontal"
>
  <div nz-row>
    <div [nzSpan]="12" nz-col>
      <nz-form-item>
        <nz-form-label [nzFor]="MediumEnum.Name" [nzRequired]="true" [nzSpan]="8">{{
          "ADMIN.MEDIUM.form." + MediumEnum.Name | translate
        }}</nz-form-label>
        <nz-form-control [nzSpan]="14">
          <input
            [formControlName]="MediumEnum.Name"
            [id]="MediumEnum.Name"
            [placeholder]="'ADMIN.MEDIUM.form.placeholders.typeNameForNewMedium' | translate"
            nz-input
          />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row>
    <div [nzSpan]="12" nz-col>
      <nz-form-item>
        <nz-form-label [nzFor]="MediumEnum.Markets" [nzRequired]="true" [nzSpan]="8">{{
          "ADMIN.MEDIUM.form." + MediumEnum.Markets | translate
        }}</nz-form-label>
        <nz-form-control [nzSpan]="14">
          <nz-select
            nzMode="multiple"
            [formControlName]="MediumEnum.Markets"
            [nzPlaceHolder]="'ADMIN.MEDIUM.form.placeholders.pleaseSelect' | translate"
            [id]="MediumEnum.Markets"
          >
            <nz-option
              *ngFor="let market of markets$ | async"
              [nzValue]="market[MarketEnum.Id]"
              [nzLabel]="market[MarketEnum.Name]"
              [nzHide]="hideDisabledMarket(market)"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row nzJustify="end">
    <nz-space class="footer mt-4" nz-row>
      <button *nzSpaceItem nz-button type="button" id="medium-add-button-cancel" (click)="cancel()">
        <i nz-icon nzType="close" nzTheme="outline"></i>
        {{ "COMMON.cancel" | translate }}
      </button>
      <button *nzSpaceItem nz-button type="submit" id="medium-add-button-save" nzType="primary" (click)="save($event)">
        <i nz-icon nzType="check" nzTheme="outline"></i>
        {{ "COMMON.save" | translate }}
      </button>
    </nz-space>
  </div>
</form>
