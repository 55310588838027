import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Medium, MediumEnum, MediumSave } from '../../interfaces/medium.interface';
import { media } from '../../mocks/medium.mock';
import { RestApiService } from '@shared/services/rest-api/rest-api.service';
import { Market, MarketEnum } from '@shared/interfaces/market.interface';

@Injectable({
  providedIn: 'root',
})
export class MediumService {
  constructor(private restApiService: RestApiService) {}

  getAll(): Observable<Medium[]> {
    return this.restApiService.get('media/');
  }

  save(payload: MediumSave): Observable<Medium> {
    return this.restApiService.post('media/', payload);
  }

  edit(id: Medium[MediumEnum.Id], payload: MediumSave): Observable<Medium> {
    return this.restApiService.put(`media/${id}`, payload);
  }

  enable(id: Medium[MediumEnum.Id]): Observable<Medium> {
    return this.restApiService.post(`media/enable/${id}/`);
  }
  disable(id: Medium[MediumEnum.Id]): Observable<Medium> {
    return this.restApiService.post(`media/disable/${id}/`);
  }

  getMarketMedia(id: Market[MarketEnum.Id]): Observable<Medium[]> {
    return this.restApiService.get(`media/market/${id}`);
  }
}
