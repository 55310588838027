import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ClientService } from '../client.service';
import { DefaultValidatorsService } from '@shared/services/default-validators/default-validators.service';
import { CustomErrorEnum, CustomErrorType } from '@shared/interfaces/custom-error.interface';
import { NotificationEnum, NotificationType } from '@shared/components/notification/notification.const';
import { NotificationService } from '@shared/components/notification/notification.facade';
import { ClientFacade } from '../client.facade';
import { Client, ClientAdd, ClientEnum, ClientForm } from '@shared/interfaces/clients.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getClientFormInit, prepareClientFromBackend } from './create-client.helper';
import { Observable } from 'rxjs';
import { Market, MarketEnum } from '@shared/interfaces/market.interface';
import { MarketFacade } from '@shared/services/markets/market.facade';
import { ApplicationConf, ApplicationConfEnum } from '@shared/consts/application-conf.const';

@UntilDestroy()
@Component({
  selector: 'app-client-add',
  templateUrl: './client-add.component.html',
  styleUrls: ['./client-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientAddComponent implements OnInit {
  @Input() clientId: number = 0;
  public errorCreate$ = this.clientFacade.errorCreate$;
  public markets$ = this.marketFacade.markets$;
  public MarketEnum = MarketEnum;
  public form!: FormGroup;
  public ClientEnum = ClientEnum;
  public clients$: Observable<Client[]> = this.clientFacade.clients$;
  autoTips!: Record<string, Record<string, string>>;
  public title = this.translateService.instant('CLIENT.CREATE.title');
  private initFormValues: ClientForm = getClientFormInit();

  constructor(
    private readonly defaultValidatorsService: DefaultValidatorsService,
    private readonly modal: NzModalRef,
    private readonly translateService: TranslateService,
    private readonly clientFacade: ClientFacade,
    private readonly notificationService: NotificationService,
    private readonly clientService: ClientService,
    private readonly fb: FormBuilder,
    private readonly marketFacade: MarketFacade
  ) {
    this.autoTips = this.defaultValidatorsService.get();
  }

  private _editModeData!: Client;

  public get editModeData(): Client {
    return this._editModeData;
  }

  @Input() set editModeData(client: Client) {
    this._editModeData = client;
    if (!!client) {
      this.title = this.translateService.instant('CLIENT.CREATE.editClient');
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  ngOnInit(): void {
    if (!!this.editModeData) {
      this.initFormValues = prepareClientFromBackend(this.editModeData);
    }
    this.initForm();
    this.handleErrors();
  }

  initForm(): void {
    this.form = this.fb.group({
      [ClientEnum.Name]: [
        this.initFormValues[ClientEnum.Name],
        [Validators.required, Validators.maxLength(ApplicationConf[ApplicationConfEnum.DefaultInputLength])],
      ],
      [ClientEnum.Parent]: [this.initFormValues[ClientEnum.Parent]],
      [ClientEnum.Markets]: [this.initFormValues[ClientEnum.Markets]],
    });
  }

  cancel(): void {
    this.modal.destroy({ data: undefined });
  }

  submit(): void {
    if (this.form.valid) {
      const payload: ClientAdd = {
        [ClientEnum.Name]: this.form.controls[ClientEnum.Name].value,
        [ClientEnum.ParentId]: this.form.controls[ClientEnum.Parent].value,
        [ClientEnum.Markets]: this.form.controls[ClientEnum.Markets].value,
      };
      if (!!this._editModeData) {
        this.clientFacade.editClient(this._editModeData.clientId, payload, this.modal);
      } else {
        this.clientFacade.createClient(payload, this.modal);
      }
    } else {
      this.displayFormErrors();
    }
  }

  handleErrors(): void {
    this.errorCreate$.pipe(untilDestroyed(this)).subscribe({
      next: (error) => {
        if (error && error[CustomErrorEnum.Type] === CustomErrorType.Form) {
          if (error[CustomErrorEnum.MessageCode] === 'client_already_exists') {
            this.form.controls[ClientEnum.Name].setErrors({
              clientAlreadyExists: true,
            });
          }
          if (error[CustomErrorEnum.MessageCode] === 'client_circular_reference') {
            this.form.controls[ClientEnum.Parent].setErrors({
              clientCircularReference: true,
            });
            this.showNotification(NotificationType.Error, 'clientCircularReference');
          }
        } else {
          this.showNotification(NotificationType.Error, 'createClient');
        }
      },
    });
  }

  showNotification(type: NotificationType, text: string) {
    this.notificationService.create({
      [NotificationEnum.Type]: type,
      [NotificationEnum.TextType]: text,
    });
  }

  private displayFormErrors(): void {
    Object.values(this.form.controls).forEach((control) => {
      this.checkControl(control);
    });
  }

  private checkControl(control: AbstractControl): void {
    if (control.invalid) {
      control.markAsDirty();
      control.updateValueAndValidity({ onlySelf: true });
    }
  }

  public hideDisabledMarket(market: Market) {
    return market[MarketEnum.Disabled] === true;
  }
}
