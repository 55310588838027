import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SpinnerService } from '@shared/services/spinner/spinner.service';
import { ApplicationConfEnum } from '@shared/consts/application-conf.const';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(private loaderService: SpinnerService) {}

  clearRequests(): void {
    this.requests = [];
    this.loaderService.hide();
  }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.requests.length > 0 ? this.loaderService.show() : this.loaderService.hide();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(ApplicationConfEnum.LoaderSkipHeaderKey)) {
      return next.handle(req);
    }
    this.requests.push(req);
    this.loaderService.show();

    return new Observable((observer) => {
      const subscription = next.handle(req).subscribe({
        next: (event) => {
          if (event instanceof HttpResponse) {
            this.removeRequest(req);
            observer.next(event);
          }
        },
        error: (err) => {
          this.removeRequest(req);
          // if token refresh fails, remove all requests
          if (req.url.includes('api/refreshToken')) {
            this.clearRequests();
          }
          observer.error(err);
        },
        complete: () => {
          this.removeRequest(req);
          observer.complete();
        },
      });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
