import { RoleType, RoleTypeEnum } from '@auth/interfaces/user.interface';

export enum MenuItemEnum {
  Label = 'label',
  Link = 'link',
  Icon = 'icon',
  Visibility = 'visibility',
  Children = 'children',
  Module = 'module',
}

export interface MenuItem {
  [MenuItemEnum.Label]: string;
  [MenuItemEnum.Link]: string;
  [MenuItemEnum.Icon]: string;
  [MenuItemEnum.Visibility]: RoleType[];
  [MenuItemEnum.Children]?: MenuItem[];
}

export enum MenuContentEnum {
  ValueTrack = 'valueTrack',
  Pitching = 'pitching',
  Quadra = 'quadra',
  Market = 'market',
  Medium = 'medium',
  Dimension = 'dimension',
  Kpi = 'kpi',
  Manage = 'manage',
  QuadraTemplates = 'quadraTemplates',
  User = 'user',
  Client = 'client',
  Organization = 'organization',
  DefaultColumns = 'defaultColumns',
  RFP = 'rfp',
  ReportTool = 'reportTool',
}

export const Menu: MenuItem[] = [
  {
    [MenuItemEnum.Label]: MenuContentEnum.ValueTrack,
    [MenuItemEnum.Link]: '/value-track/projects',
    [MenuItemEnum.Icon]: 'dashboard',
    [MenuItemEnum.Visibility]: [
      RoleTypeEnum.Admin,
      RoleTypeEnum.StarsUser,
      RoleTypeEnum.PartnerUser,
      RoleTypeEnum.AgencyUser,
      RoleTypeEnum.ClientUser,
    ],
  },
  {
    [MenuItemEnum.Label]: MenuContentEnum.Pitching,
    [MenuItemEnum.Link]: '/pitching/projects',
    [MenuItemEnum.Icon]: 'dashboard',
    [MenuItemEnum.Visibility]: [
      RoleTypeEnum.Admin,
      RoleTypeEnum.StarsUser,
      RoleTypeEnum.PartnerUser,
      RoleTypeEnum.AgencyUser,
      RoleTypeEnum.ClientUser,
    ],
  },
  {
    [MenuItemEnum.Label]: MenuContentEnum.Quadra,
    [MenuItemEnum.Link]: '/quadra/projects',
    [MenuItemEnum.Icon]: 'dashboard',
    [MenuItemEnum.Visibility]: [
      RoleTypeEnum.Admin,
      RoleTypeEnum.StarsUser,
      RoleTypeEnum.PartnerUser,
      RoleTypeEnum.AgencyUser,
      RoleTypeEnum.ClientUser,
    ],
  },
  {
    [MenuItemEnum.Label]: MenuContentEnum.Market,
    [MenuItemEnum.Link]: '/admin/market/list',
    [MenuItemEnum.Icon]: 'global',
    [MenuItemEnum.Visibility]: [RoleTypeEnum.Admin],
  },
  {
    [MenuItemEnum.Label]: MenuContentEnum.Medium,
    [MenuItemEnum.Link]: '/admin/medium/list',
    [MenuItemEnum.Icon]: 'medium-workmark',
    [MenuItemEnum.Visibility]: [RoleTypeEnum.Admin],
  },
  {
    [MenuItemEnum.Label]: MenuContentEnum.Kpi,
    [MenuItemEnum.Link]: '/admin/kpi/list',
    [MenuItemEnum.Icon]: 'reconciliation',
    [MenuItemEnum.Visibility]: [RoleTypeEnum.Admin],
  },
  {
    [MenuItemEnum.Label]: MenuContentEnum.Dimension,
    [MenuItemEnum.Link]: '/admin/dimension/list',
    [MenuItemEnum.Icon]: 'reconciliation',
    [MenuItemEnum.Visibility]: [RoleTypeEnum.Admin],
  },
  {
    [MenuItemEnum.Label]: MenuContentEnum.DefaultColumns,
    [MenuItemEnum.Link]: '/admin/default-columns/',
    [MenuItemEnum.Icon]: 'insert-row-left',
    [MenuItemEnum.Visibility]: [RoleTypeEnum.Admin],
  },
  {
    [MenuItemEnum.Label]: MenuContentEnum.QuadraTemplates,
    [MenuItemEnum.Link]: '/admin/quadra/templates',
    [MenuItemEnum.Icon]: 'file',
    [MenuItemEnum.Visibility]: [RoleTypeEnum.Admin],
  },
  {
    [MenuItemEnum.Label]: MenuContentEnum.User,
    [MenuItemEnum.Link]: '/admin/user/list',
    [MenuItemEnum.Icon]: 'user',
    [MenuItemEnum.Visibility]: [RoleTypeEnum.Admin],
  },
  // {
  //   [MenuItemEnum.Label]: MenuContentEnum.Client,
  //   [MenuItemEnum.Link]: '/admin/client/list',
  //   [MenuItemEnum.Icon]: 'user-switch',
  //   [MenuItemEnum.Visibility]: [RoleTypeEnum.Admin],
  // },
  {
    [MenuItemEnum.Label]: MenuContentEnum.Organization,
    [MenuItemEnum.Link]: '/admin/organization/list',
    [MenuItemEnum.Icon]: 'team', // insert proper icon
    [MenuItemEnum.Visibility]: [RoleTypeEnum.Admin],
  },
  {
    [MenuItemEnum.Label]: MenuContentEnum.RFP,
    [MenuItemEnum.Link]: '/value-track/rfp',
    [MenuItemEnum.Icon]: 'reconciliation',
    [MenuItemEnum.Visibility]: [RoleTypeEnum.Admin, RoleTypeEnum.StarsUser, RoleTypeEnum.PartnerUser, RoleTypeEnum.ClientUser],
  },
  {
    [MenuItemEnum.Label]: MenuContentEnum.ReportTool,
    [MenuItemEnum.Link]: '/value-track/reports',
    [MenuItemEnum.Icon]: 'bar-chart',
    [MenuItemEnum.Visibility]: [RoleTypeEnum.Admin, RoleTypeEnum.StarsUser, RoleTypeEnum.PartnerUser],
  },
];
