import { Component, OnInit } from '@angular/core';
import { RestApiService } from '@shared/services/rest-api/rest-api.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'app-developer',
  templateUrl: './developer.component.html',
  styleUrls: ['./developer.component.scss'],
})
export class DeveloperComponent {
  constructor(private readonly fileSaverService: FileSaverService, private readonly http: HttpClient) {}

  getApiDocs(event: Event): void {
    event.preventDefault();
    this.http.get(`${environment.apiUrl}/api-docs`, { responseType: 'arraybuffer' }).subscribe((res: ArrayBuffer) => {
      const blob = new Blob([res]);
      this.fileSaverService.save(blob, 'api-docs.json');
    });
  }

  getApiReportingDocs(event: Event): void {
    event.preventDefault();
    this.http.get(`${environment.workspaceUrl}/api-docs`, { responseType: 'arraybuffer' }).subscribe((res: ArrayBuffer) => {
      const blob = new Blob([res]);
      this.fileSaverService.save(blob, 'api-docs.json');
    });
  }
}
