import { Injectable } from '@angular/core';
import { LoginResponseEnum, UserAuth, UserObject, UserObjectEnum } from '@auth/interfaces/user.interface';
import { Observable, of } from 'rxjs';
import { Vault } from '@ultimate/vault';
import { AuthService } from '@auth/services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AppLoadService {
  constructor(private readonly authService: AuthService) {}

  initApp(): Observable<UserObject | null> {
    const localStorage = new Vault({ type: 'local' });
    const user: UserAuth | null = <UserAuth | null>localStorage.get(UserObjectEnum.UserData);
    if (user) {
      const userData = localStorage.get(UserObjectEnum.UserData);
      if (!userData) {
        this.authService.logout();
        return of(null);
      }
      const loginResponse: UserObject = {
        [UserObjectEnum.LoginResponse]: {
          [LoginResponseEnum.AccessToken]: localStorage.get(LoginResponseEnum.AccessToken)!,
          [LoginResponseEnum.RefreshToken]: localStorage.get(LoginResponseEnum.RefreshToken)!,
        },
        [UserObjectEnum.UserData]: localStorage.get(UserObjectEnum.UserData) as UserAuth,
      };

      this.authService.setUserValue(loginResponse);
      return of(loginResponse);
    }
    return of(null);
  }
}
