import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ErrorModalData, ErrorModalEnum } from '@shared/interfaces/error-modal.interface';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorModalComponent {
  @Input() data!: ErrorModalData;
  @Input() code!: number;

  public ErrorModalEnum = ErrorModalEnum;

  constructor(private modal: NzModalRef) {}

  public back() {
    this.modal.destroy(null);
  }

  public isObject(value: any): boolean {
    return typeof value === 'object';
  }
}
