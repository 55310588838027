export interface CustomErrorObject {
  [CustomErrorEnum.Message]: string;
  [CustomErrorEnum.MessageCode]: string;
  [CustomErrorEnum.Type]: CustomErrorType;
}

export enum CustomErrorEnum {
  Message = 'message',
  MessageCode = 'messageCode',
  Type = 'type',
}

export enum CustomErrorType {
  Default = 'default',
  Form = 'form',
}

export enum ErrorOrigin {
  FE = 'FE',
  BE = 'BE',
}
