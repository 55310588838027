import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, catchError, fromEvent, Observable, of, take, throwError } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import {
  ACCESS_ERROR,
  AccessErrorEnum,
  Endpoints_Without_Token,
  TOKEN_ERROR,
  TokenErrorEnum,
} from '@auth/services/token/token-interceptor.const';
import { LoginResponse, LoginResponseEnum, UserObject, UserObjectEnum } from '@auth/interfaces/user.interface';
import { SpinnerService } from '@shared/services/spinner/spinner.service';
import { AuthService } from '@auth/services/auth/auth.service';
import { NotificationService } from '@shared/components/notification/notification.facade';
import { JwtTokenService } from '@auth/services/jwt-token/jwt-token.service';
import { Vault } from '@ultimate/vault';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NotificationEnum, NotificationType } from '@shared/components/notification/notification.const';
import { TokenErrorsHandleInsideComponent } from '@auth/services/token/token.const';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isTokenRefreshing: boolean = false;
  private $refresh: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private readonly authService: AuthService,
    private readonly notificationService: NotificationService,
    private readonly modalService: NzModalService,
    private readonly spinnerService: SpinnerService,
    private readonly tokenService: JwtTokenService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // This retrieves a token from local storage
    const localStorage = new Vault({ type: 'local' });
    const token: string = localStorage.get<string>(LoginResponseEnum.AccessToken)!;
    const addToken = Endpoints_Without_Token.filter((item: string) => request.url.includes(item)).length === 0;

    // This clones Http request and Authorization header with Bearer token added
    if (token && addToken) {
      request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
    }

    // @ts-ignore
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => this.parseErrorBlob(error)),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if (
            error.error === TOKEN_ERROR[TokenErrorEnum.TokenExpired] ||
            error.error.errorCode === TOKEN_ERROR[TokenErrorEnum.TokenExpired]
          ) {
            const currentUser: UserObject = <UserObject>this.authService.currentUserValue;
            // refresh token if expired
            if (this.isTokenRefreshing) {
              return this.$refresh.pipe(
                filter((e) => e !== null),
                take(1),
                switchMap((token: string) => next.handle(this.addAuthToken(request, token)))
              );
            } else {
              this.isTokenRefreshing = true;
              this.$refresh.next(null);
              return this.tokenService.refresh(currentUser[UserObjectEnum.LoginResponse][LoginResponseEnum.RefreshToken]).pipe(
                switchMap((loginResponse: LoginResponse) => {
                  this.isTokenRefreshing = false;
                  localStorage.set<string>(LoginResponseEnum.AccessToken, loginResponse[LoginResponseEnum.AccessToken]);
                  this.$refresh.next(loginResponse[LoginResponseEnum.AccessToken]);
                  return next.handle(this.addAuthToken(request, loginResponse[LoginResponseEnum.AccessToken]));
                }),
                catchError((err) => {
                  this.closeAllAndClearData();
                  this.isTokenRefreshing = false;
                  this.notificationService.create({
                    [NotificationEnum.Type]: NotificationType.Error,
                    [NotificationEnum.TextType]: 'sessionExpired',
                  });
                  return of(err);
                })
              );
            }
          } else if (
            error.error === TOKEN_ERROR[TokenErrorEnum.TokenNotFound] ||
            error.error.errorCode === TOKEN_ERROR[TokenErrorEnum.TokenNotFound]
          ) {
            this.closeAllAndLogout();
          } else if (
            error.error === TOKEN_ERROR[TokenErrorEnum.AuthenticationException] ||
            error.error.errorCode === TOKEN_ERROR[TokenErrorEnum.AuthenticationException]
          ) {
            this.closeAllAndLogout();
          } else if (
            error.error === ACCESS_ERROR[AccessErrorEnum.InsufficientAccessRights] ||
            error.error.errorCode === ACCESS_ERROR[AccessErrorEnum.InsufficientAccessRights]
          ) {
            this.notificationService.create({
              [NotificationEnum.Type]: NotificationType.Error,
              [NotificationEnum.TextType]: 'insufficientAccessRights',
            });
            this.modalService.closeAll();

            this.router.navigate(['/value-track/projects'], { relativeTo: this.route });
            return of(null);
          } else if (
            TokenErrorsHandleInsideComponent.includes(error.error) ||
            TokenErrorsHandleInsideComponent.includes(error.error.errorCode)
          ) {
            // do nothing, handle error inside component
          } else {
            this.closeAllAndLogout();
          }
        }
        return throwError(error); // any further errors are returned to frontend
      })
    );
  }

  private parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    if (!(err.error instanceof Blob)) {
      return throwError(() => err);
    }

    const reader: FileReader = new FileReader();
    reader.readAsText(err.error);

    return fromEvent(reader, 'loadend').pipe(
      switchMap((event: any) => {
        const result = event.target.result;
        if (typeof result === 'string') {
          try {
            const parsedError = JSON.parse(result);
            const updatedError = new HttpErrorResponse({
              error: parsedError,
              headers: err.headers,
              status: err.status,
              statusText: err.statusText,
              url: err.url || undefined,
            });
            return throwError(updatedError);
          } catch (error) {
            // translate - Nie można przetworzyć błędu
            return throwError(() => new Error('The error could not be processed'));
          }
        } else {
          return throwError(() => new Error('The error could not be processed'));
        }
      })
    );
  }

  private closeAllAndLogout(): void {
    if (this.authService.currentUserValue) {
      this.notificationService.create({
        [NotificationEnum.Type]: NotificationType.Error,
        [NotificationEnum.TextType]: 'sessionExpired',
      });
      this.authService.logout();
    }
    this.closeAllAndClearData();
  }

  private closeAllAndClearData(): void {
    this.authService.logoutWithoutRequest();
    this.modalService.closeAll();
    this.spinnerService.hide();
  }

  private addAuthToken(request: HttpRequest<unknown>, accessToken: string) {
    if (!accessToken) {
      return request;
    }
    return request.clone({
      setHeaders: { Authorization: `Bearer ${accessToken}` },
    });
  }
}
