import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[appElementInactive]',
})
export class ElementInactiveDirective {
  @Input() set appElementInactive(value: boolean | undefined | null) {
    this.disabled = !!value;
    this.readonly = !!value;
  }

  @HostBinding('disabled') disabled!: boolean;
  @HostBinding('readonly') readonly!: boolean;

  constructor() {}
}
