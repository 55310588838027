import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { KpiFilterFormInit, KpiFilterValuesInit } from './kpi-filter.const';
import { ApplicationConf, ApplicationConfEnum } from '@shared/consts/application-conf.const';
import { Observable } from 'rxjs';
import { KpiSelects, KpiSelectsEnum } from './kpi-filter.interface';
import { MediumEnum } from '@shared/interfaces/medium.interface';
import { MarketEnum } from '@shared/interfaces/market.interface';
import { KpiFilters, KpiFilterValues, KpiFilterEnum } from '@shared-project/modules/kpis/interfaces/kpi-setup-handlers.interface';

@Component({
  selector: 'app-kpi-filter',
  templateUrl: './kpi-filter.component.html',
  styleUrls: ['./kpi-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpiFilterComponent implements OnInit {
  @Output() submitted = new EventEmitter<KpiFilters>();
  @Input() filterValuesData!: KpiFilterValues;
  @Input() selectsData$!: Observable<Partial<KpiSelects>>;
  @Input() kpiWaiting!: boolean;

  public kpiFilterForm = this.formBuilder.group(KpiFilterFormInit);
  public KpiFilterEnum = KpiFilterEnum;

  public ApplicationConf = ApplicationConf;
  public ApplicationConfEnum = ApplicationConfEnum;
  public KpiSelectsEnum = KpiSelectsEnum;
  public MediumEnum = MediumEnum;
  public MarketEnum = MarketEnum;

  constructor(public formBuilder: FormBuilder) {}

  ngOnInit() {
    if (this.kpiWaiting) {
      this.kpiFilterForm.patchValue({ [KpiFilterEnum.Disabled]: null });
    }
  }

  filter(): void {
    const filterValues: KpiFilters = this.kpiFilterForm.getRawValue();
    this.submitted.emit(filterValues);
  }

  search(event: Event): void {
    event.preventDefault();
    this.filter();
  }

  clear(event: Event): void {
    event.preventDefault();
    this.kpiFilterForm.reset(KpiFilterValuesInit);
    this.filter();
  }
}
