import { Pipe, PipeTransform } from '@angular/core';
import { Organization, OrganizationEnum, OrganizationTypeEnum } from '@shared/interfaces/organization.interface';

@Pipe({
  name: 'organizationType',
})
export class OrganizationTypePipe implements PipeTransform {
  transform(organizations: Organization[] | null, type: OrganizationTypeEnum): Organization[] {
    if (organizations === null) return [];
    return organizations.filter((org: Organization) => org[OrganizationEnum.Type] === type);
  }
}
