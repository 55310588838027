import {
  Column,
  ColumnEnum,
} from 'src/app/logged-in/modules/value-track/projects/project/modules/agency/components/table/interfaces/columns.interface';

export enum CalculationsTypes {
  LookupTable = 'lookupTable',
  SlidingScale = 'slidingScale',
  Vlookup = 'vlookup',
  Autopopulated = 'autopopulated',
  Formula = 'formula',
  DefaultValue = 'defaultValue',
}

export enum SlidingScaleType {
  Linear = 'linear',
  Lower = 'lower',
  Higher = 'higher',
}
export type Formula = string | undefined;

export enum AutopopulatedEnum {
  Dimension = 'dimension',
}
export enum LookupTableEnum {
  Measure = 'measure',
  Dimensions = 'dimensions',
}
export enum VlookupEnum {
  Key = 'keys',
  Value = 'value',
}
export enum SlidingScaleEnum {
  Measure = 'measure',
  Dimensions = 'dimensions',
  SsDimensions = 'ssDimensions',
  Name = 'name',
  SsType = 'ssType',
}

export interface Calculations {
  [CalculationsTypes.LookupTable]: LookupTable;
  [CalculationsTypes.Vlookup]: Vlookup;
  [CalculationsTypes.Autopopulated]: Autopopulated;
  [CalculationsTypes.SlidingScale]: SlidingScale;
  [CalculationsTypes.Formula]: Formula;
  [CalculationsTypes.DefaultValue]: string | number;
}
export interface LookupTable {
  [LookupTableEnum.Measure]: string;
  [LookupTableEnum.Dimensions]: string[];
}
export interface Vlookup {
  [VlookupEnum.Key]: string[];
  [VlookupEnum.Value]: string;
}
export interface Autopopulated {
  [AutopopulatedEnum.Dimension]?: Column[ColumnEnum.ColumnName] | false;
}

export type DefaultValue = string | number;

export interface SlidingScale {
  [SlidingScaleEnum.Measure]: string;
  [SlidingScaleEnum.Dimensions]: string[];
  [SlidingScaleEnum.SsDimensions]: SlidingScaleDetails[];
}
export interface SlidingScaleDetails {
  [SlidingScaleEnum.Name]: string;
  [SlidingScaleEnum.SsType]: string;
}
