export enum QuestionEnum {
  Id = 'id',
  Content = 'content',
}

export interface Question {
  [QuestionEnum.Id]: number;
  [QuestionEnum.Content]: string;
}

export interface Questions {
  [key: string]: string[];
}
