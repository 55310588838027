import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import {OrganizationEnum, ProjectData, User, UserEnum} from '@shared/interfaces';
import { UserService } from '../../../logged-in/modules/admin/user/user.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DefaultValidatorsService, ToolsService } from '@shared/services';

@Component({
  selector: 'app-modal-add-users',
  templateUrl: './modal-add-users.component.html',
  styleUrls: ['./modal-add-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalAddUsersComponent implements OnInit {
  @Input() organizationId!:number;
  public usersForm: FormGroup = new FormGroup({
    [ProjectData.USER_ACCESSES]: new FormControl([], Validators.required),
  });
  public users: User[] = [];
  public autoTips!: Record<string, Record<string, string>>;
  readonly ProjectData = ProjectData;
  readonly UserEnum = UserEnum;

  constructor(
    private readonly defaultValidatorsService: DefaultValidatorsService,
    private modal: NzModalRef,
    private readonly userService: UserService
  ) {}

  private _editModeData!: number[];

  public get editModeData(): number[] {
    return this._editModeData;
  }

  @Input() set editModeData(userIds: number[]) {
    this._editModeData = userIds;
  }

  ngOnInit(): void {
    this.autoTips = this.defaultValidatorsService.get();
    this.userService.getUsersFromOrganization(this.organizationId).subscribe((users: User[]): void => {
      this.users = users.filter((user: User) => !user[UserEnum.Disabled] && !user[UserEnum.Blocked] && user[UserEnum.Active]);
      this._editModeData = this._editModeData.filter((userId: number) =>
        this.users.some((user: User): boolean => user[UserEnum.Id] === userId)
      );
      this.setInitialFormData();
    });
  }

  submit(event: Event): void {
    event.preventDefault();
    ToolsService.validForm(this.usersForm);
    if (this.usersForm.valid) {
      this.modal.destroy(this.usersForm.controls[ProjectData.USER_ACCESSES].value);
    }
  }

  cancel(event: Event): void {
    event.preventDefault();
    this.modal.destroy(false);
  }

  private setInitialFormData(): void {
    this.usersForm.controls[ProjectData.USER_ACCESSES].setValue(this.editModeData);
  }
}
