import { FormGroup } from '@angular/forms';
import { Medium, MediumEnum } from '@shared/interfaces/medium.interface';
import { Kpi } from '@shared/interfaces/kpi.interface';
import { DataFormats } from '@shared/interfaces/data-formats.interface';
import { Market } from '@shared/interfaces/market.interface';
import { AuditData } from '@shared/interfaces/audit-data.interface';

export enum DimensionEnum {
  Id = 'id',
  Name = 'name',
  Media = 'media',
  Description = 'description',
  Kpis = 'kpis',
  Type = 'type',
  Branches = 'branches',
  ParentDimension = 'parentDimension',
  Disabled = 'disabled',
  WaitingForApproval = 'waitingForApproval',
  ActivationPending = 'activationPending',
  Markets = 'markets',
}

export interface Dimension extends Partial<AuditData> {
  [DimensionEnum.Id]: number;
  [DimensionEnum.Name]: string;
  [DimensionEnum.Description]: string;
  [DimensionEnum.Type]: DataFormats;
  [DimensionEnum.Media]: Medium[];
  [DimensionEnum.Markets]: Market[];
  [DimensionEnum.Kpis]: Kpi[];
  [DimensionEnum.Branches]: BranchesForm[];
  [DimensionEnum.Disabled]: boolean;
  [DimensionEnum.WaitingForApproval]?: boolean;
  [DimensionEnum.ParentDimension]: Dimension | null;
}

export interface DimensionSave {
  [DimensionEnum.Name]: string;
  [DimensionEnum.Description]: string;
  [DimensionEnum.Type]: DataFormats;
  [DimensionEnum.Media]: number[];
  [DimensionEnum.Markets]: number[];
  [DimensionEnum.Branches]: BranchesForm[];
  [DimensionEnum.WaitingForApproval]?: boolean;
  [DimensionEnum.ParentDimension]?: Dimension[DimensionEnum.Id];
}

export type DimensionForm = {
  [DimensionEnum.Name]: string;
  [DimensionEnum.Description]: string;
  [DimensionEnum.Type]: DataFormats;
  [DimensionEnum.Media]: number[];
  [DimensionEnum.Markets]: number[];
  [DimensionEnum.Branches]: BranchesForm[];
  [DimensionEnum.WaitingForApproval]?: boolean;
  [DimensionEnum.ParentDimension]?: number;
};
export type ProjectDimensionForm = {
  [DimensionEnum.Name]: string;
  [DimensionEnum.Description]: string;
  [DimensionEnum.Type]: DataFormats;
  [DimensionEnum.Branches]: BranchesForm[];
  [DimensionEnum.WaitingForApproval]?: boolean;
  [DimensionEnum.ParentDimension]?: number;
};

export interface Branches {
  [key: string]: string[];
}

export enum BranchFormEnum {
  Name = 'name',
  ParentBranch = 'parentBranch',
  Children = 'children',
}

export interface BranchesForm {
  [BranchFormEnum.Name]: string;
  [BranchFormEnum.ParentBranch]: string;
  [BranchFormEnum.Children]?: BranchesForm[];
}

export enum CreateDimensionSelectedItemsEnum {
  SelectedKpis = 'selectedKpis',
  SelectedMedium = 'selectedMedium',
  SelectedMarkets = 'selectedMarkets',
  SelectedParentDimension = 'selectedParentDimension',
}

export interface SelectsDimensionData {
  [DimensionEnum.Media]: Medium[];
  [DimensionEnum.Markets]: Market[];
  [DimensionEnum.ParentDimension]: Dimension[];
  [CreateDimensionSelectedItemsEnum.SelectedMedium]?: Medium[];
  [CreateDimensionSelectedItemsEnum.SelectedMarkets]?: Market[];
  [CreateDimensionSelectedItemsEnum.SelectedParentDimension]?: Dimension[DimensionEnum.Id];
}

export type BranchesValidityChecker = (group: FormGroup) => void;

export enum DimensionSetupSectionEnum {
  Id = 'id',
  Name = 'name',
  FilteredRelations = 'filteredRelations',
  Branches = 'branches',
  Columns = 'Columns',
  Type = 'type',
}

export type DimensionFilters = {
  [DimensionFilterEnum.Name]: string;
  [DimensionFilterEnum.Description]: string;
  [DimensionFilterEnum.Markets]: Market;
  [DimensionFilterEnum.Media]: Medium;
  [DimensionFilterEnum.Type]: DataFormats;
  [DimensionFilterEnum.Disabled]: boolean;
  [DimensionFilterEnum.SearchText]: string;
};

export enum DimensionFilterEnum {
  Name = 'name',
  Description = 'description',
  Markets = 'markets',
  Media = 'media',
  Type = 'type',
  Disabled = 'disabled',
  SearchText = 'searchText',
}

export type DimensionFilterValues = {
  [DimensionFilterEnum.Type]: DataFormats[];
  [DimensionFilterEnum.Disabled]: boolean[];
};
