import { Injectable } from '@angular/core';
import { RestApiService } from '@shared/services/rest-api/rest-api.service';
import { User, UserAdd } from '@shared/interfaces/user.interface';
import { Observable } from 'rxjs';
import { Organization, OrganizationTypeEnum } from '@shared/interfaces/organization.interface';
import { NotificationsSettings } from '@shared/interfaces/notification-messages';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private readonly restApiService: RestApiService) {}

  save(payload: UserAdd): Observable<User> {
    return this.restApiService.post('users/', payload);
  }

  update(id: number, payload: UserAdd | NotificationsSettings): Observable<User> {
    return this.restApiService.put(`users/${id}`, payload);
  }

  getUsers(): Observable<User[]> {
    return this.restApiService.get('users/');
  }

  enable(id: number): Observable<User> {
    return this.restApiService.post(`users/enable/${id}/`);
  }

  disable(id: number): Observable<User> {
    return this.restApiService.post(`users/disable/${id}/`);
  }

  unblock(id: number): Observable<User> {
    return this.restApiService.post(`users/unblock/${id}/`);
  }

  getUsersFromMyOrganization(): Observable<User[]> {
    return this.restApiService.get(`users/organization/me`);
  }

  getUsersFromOrganization(id: number): Observable<User[]> {
    return this.restApiService.get(`users/organization/${id}`);
  }

  getClients(): Observable<Organization[]> {
    return this.restApiService.get(`organization/type/${OrganizationTypeEnum.Client}`);
  }

  getClientsByUser(): Observable<Organization[]> {
    return this.restApiService.get(`users/clients`);
  }

  resendRegistrationEmail(userId: number): Observable<User> {
    return this.restApiService.post(`users/resend/registration/${userId}`);
  }
}
