import { Pipe, PipeTransform } from '@angular/core';
import { Organization, OrganizationEnum } from '@shared/interfaces/organization.interface';
import { Market, MarketEnum } from '@shared/interfaces/market.interface';

@Pipe({
  name: 'organizationByMarket',
})
export class OrganizationByMarketPipe implements PipeTransform {
  transform(organizations: Organization[] | null, marketId: number): Organization[] {
    if (organizations === null) return [];
    return organizations.filter(
      (org: Organization) =>
        org[OrganizationEnum.Markets].filter((orgMarket: Market) => orgMarket[MarketEnum.Id] === marketId).length > 0
    );
  }
}
