import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { RoleType } from '@auth/interfaces/user.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserRolesService } from '@shared/services/user-roles/user-roles.service';

@UntilDestroy()
@Directive({
  selector: '[notAllowedByRole]',
})
export class NotAllowedByRoleDirective implements OnInit {
  @Input() set notAllowedByRole(value: RoleType | RoleType[]) {
    this._notAllowedRoles = Array.isArray(value) ? value : [value];
  }
  private _notAllowedRoles: RoleType[] = [];

  public userRole$ = this.userRoleFacade.userRole$;

  constructor(private element: ElementRef, private userRoleFacade: UserRolesService) {}

  ngOnInit() {
    this.userRole$.pipe(untilDestroyed(this)).subscribe((currRole) => {
      if (!!currRole && this.isRoleNotAllowed(currRole)) {
        this.element.nativeElement.remove();
      }
    });
  }

  private isRoleNotAllowed(currRole: RoleType): boolean {
    return this._notAllowedRoles.includes(currRole);
  }
}
