import {
  Client,
  ClientEnum,
  DataFormats,
  MarketEnum,
  Project,
  ProjectData,
  RfpEnum,
  SortClient,
  SortCode,
  SortCreatedBy,
  SortCreatedDate,
  SortDataTypes,
  SortDescription,
  SortEmail,
  SortLastUpdate,
  SortMarket,
  SortModifiedBy,
  SortName,
  SortResponsibleUser,
  SortRfp,
  SortStatus,
  SortYear,
  UserEnum,
} from '@shared/interfaces';

export const sortByName = (a: SortName<string>, b: SortName<string>) => a.name.localeCompare(b.name);

export const sortByEmail = (a: SortEmail<string>, b: SortEmail<string>) => a.email.localeCompare(b.email);

export const sortByCode = (a: SortCode<string>, b: SortCode<string>) => a.code.localeCompare(b.code);

export const sortByDescription = (a: SortDescription<string>, b: SortDescription<string>) =>
  a.description.localeCompare(b.description);

export const sortByDataType = (a: SortDataTypes<DataFormats>, b: SortDataTypes<DataFormats>) =>
  a.dataType.localeCompare(b.dataType);

export const sortByMarket = (a: SortMarket<Project>, b: SortMarket<Project>) =>
  a.market[MarketEnum.Name].localeCompare(b.market[MarketEnum.Name]);

export const sortByYear = (a: SortYear<string>, b: SortYear<string>) => a[ProjectData.YEAR] - b[ProjectData.YEAR];

export const sortByStatus = (a: SortStatus<string>, b: SortStatus<string>) => a.status.localeCompare(b.status);

export const sortByCreatedBy = (a: SortCreatedBy<string>, b: SortCreatedBy<string>) => a.createdBy.localeCompare(b.createdBy);

export const sortByModifiedBy = (a: SortModifiedBy<string>, b: SortModifiedBy<string>) =>
  a.modifiedBy.localeCompare(b.modifiedBy);

export const sortByLastUpdate = (a: SortLastUpdate<string>, b: SortLastUpdate<string>) =>
  new Date(a.modifiedDate).getTime() - new Date(b.modifiedDate).getTime();

export const sortByCreatedDate = (a: SortCreatedDate<string>, b: SortCreatedDate<string>) =>
  new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime();

export const sortByRfp = (a: SortRfp<Project>, b: SortRfp<Project>) => a.rfp[RfpEnum.Name].localeCompare(b.rfp[RfpEnum.Name]);

export const sortByClient = (a: SortClient<Client>, b: SortClient<Client>) =>
  a.client[ClientEnum.Name].localeCompare(b.client[ClientEnum.Name]);

export const sortByResponsibleUser = (a: SortResponsibleUser<string>, b: SortResponsibleUser<string>) =>
  a.responsibleUser[UserEnum.Email].localeCompare(b.responsibleUser[UserEnum.Email]);
