import * as packageInfo from '../../package.json';

export const environment = {
  production: false,
  apiUrl: 'https://api-gateway.dev.aws.netscore.eu/netscore/api',
  workspaceUrl: 'https://api-gateway.dev.aws.netscore.eu/report-bfr/api',
  reportUrl: 'https://reporting.dev.aws.netscore.eu',
  version: packageInfo,
  environment: 'DEV',
};
