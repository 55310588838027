import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentage',
})
export class PercentagePipe implements PipeTransform {
  transform(value: number): string | null {
    if (value == null) {
      return null;
    }

    if (value < 0 || value > 1) {
      console.warn('Value out of bounds', value);
      return null;
    }

    return `${(value * 100).toFixed(0)}%`;
  }
}
