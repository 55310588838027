export const generateFormulaPreview = (formula: string): string | null => {
  if (formula) {
    const sign = '$';
    const regex = getRegExp(sign);
    const previewValue = formula.replace(
      regex,
      (match: any) =>
        `<span style="background-color: #d3d3d385; border-radius: 6px; padding: 3px 6px;">${match.split('_').join(' ')}</span>`
    );
    return previewValue;
  }
  return null;
};

export const getRegExp = (prefix: string | string[]): RegExp => {
  const prefixArray = Array.isArray(prefix) ? prefix : [prefix];
  let prefixToken = prefixArray.join('').replace(/(\$|\^)/g, '\\$1');

  if (prefixArray.length > 1) {
    prefixToken = `[${prefixToken}]`;
  }

  return new RegExp(`(\\s|^)(${prefixToken})[^\\s]*`, 'g');
};
