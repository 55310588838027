import { NzModalService } from 'ng-zorro-antd/modal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Client, ClientEnum, ClientFilters } from '@shared/interfaces/clients.interface';
import { ClientFacade } from '../client.facade';
import { CreateClientModalConf, DisableClientModalConf, EnableClientModalConf } from '../client.const';
import { LAYOUT_MIN_WIDTH } from '@shared/consts/consts';
import { ClientAddComponent } from '../client-add/client-add.component';
import { ApplicationConf, ApplicationConfEnum } from '@shared/consts/application-conf.const';
import { Observable } from 'rxjs';
import { MarketEnum } from '@shared/interfaces/market.interface';
import { ClientFiltersValue } from '../client-filter/client-filter.const';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientListComponent implements OnInit {
  public pending = false;
  public MarketEnum = MarketEnum;
  public clients$: Observable<Client[]> = this.clientFacade.clients$;
  public selectsData$ = this.clientFacade.selectsData$;
  public currentPageData: readonly Client[] = [];
  public ClientEnum = ClientEnum;
  public ApplicationConf = ApplicationConf;
  public ApplicationConfEnum = ApplicationConfEnum;

  public clientFiltersValue = ClientFiltersValue;

  private readonly EditClientModalConf = {
    nzWidth: LAYOUT_MIN_WIDTH,
    nzCentered: true,
    nzContent: ClientAddComponent,
  };

  constructor(
    private readonly clientFacade: ClientFacade,
    private readonly translateService: TranslateService,
    private readonly modalService: NzModalService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // this.clientFacade.getEnabledClients();
  }

  public createClient(): void {
    this.modalService.create({ ...CreateClientModalConf });
  }

  public disableClient(event: Event, id: number): void {
    event.preventDefault();
    const modal = this.modalService.create({ ...DisableClientModalConf });
    modal.afterClose.subscribe((result: boolean) => (result ? this.clientFacade.disableClient(id) : ''));
  }

  public enableClient(event: Event, id: number): void {
    event.preventDefault();
    const modal = this.modalService.create({ ...EnableClientModalConf });
    modal.afterClose.subscribe((result: boolean) => (result ? this.clientFacade.enableClient(id) : ''));
  }

  public editClient(data: Client): void {
    this.modalService.create({
      ...this.EditClientModalConf,
      nzComponentParams: {
        editModeData: data,
      },
    });
  }

  public currentPageDataChange($event: readonly Client[]): void {
    this.currentPageData = $event;
    this.changeDetector.detectChanges();
  }

  public search(criteria: ClientFilters) {
    this.clientFacade.filterClient(criteria);
  }

  public sortName = (a: Client, b: Client) => a[ClientEnum.Name].localeCompare(b[ClientEnum.Name]);

  public sortParent = (a: Client, b: Client) => {
    if (a[ClientEnum.Parent] === null) {
      return 1;
    }
    if (b[ClientEnum.Parent] === null) {
      return -1;
    }
    return a[ClientEnum.Parent]![ClientEnum.Name].localeCompare(b[ClientEnum.Parent]![ClientEnum.Name]);
  };
}
