import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ClientFacade } from '../client.facade';

@Component({
  selector: 'app-client-add',
  templateUrl: './client-delete.component.html',
  styleUrls: ['./client-delete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientDeleteComponent {
  @Input() clientId: number = 0;
  @Input() name: string = '';

  constructor(private readonly clientFacade: ClientFacade, private readonly modal: NzModalRef) {}

  disableClient() {
    this.clientFacade.disableClient(this.clientId);
    this.modal.destroy({ data: undefined });
  }

  cancel(): void {
    this.modal.destroy({ data: undefined });
  }
}
