type FormDataInput = any;
export const formDataMapper = (data: FormDataInput | FormDataInput[]) => {
  const formData = new FormData();
  if (Array.isArray(data)) {
    data.forEach((file) => {
      formData.append('file', file);
    });
  } else {
    formData.append('filename', data);
  }

  return formData;
};
