import { Market } from '@shared/interfaces/market.interface';
import { Medium } from '@shared/interfaces/medium.interface';
import { AuditData } from './audit-data.interface';
import { DataFormats } from './data-formats.interface';
import { DimensionSetup } from '@shared-project/modules/dimensions/setup/interfaces/dimension-setup.interface';
import { KpiScoreValues } from '@shared-project/modules/kpis/interfaces/selected-kpi.interface';

export enum KpiEnum {
  Id = 'id',
  Name = 'name',
  Description = 'description',
  Formats = 'formats',
  Markets = 'markets',
  Media = 'media',
  Dimensions = 'dimensions',
  DimensionSetup = 'dimensionSetup',
  KpiUuid = 'kpiUuid',
  WaitingForApproval = 'waitingForApproval',
  Disabled = 'disabled',
}

export interface Kpi extends Partial<AuditData> {
  [KpiEnum.Id]: number;
  [KpiEnum.Name]: string;
  [KpiEnum.Disabled]: boolean;
  [KpiEnum.Description]: string;
  [KpiEnum.Formats]: DataFormats[];
  [KpiEnum.Markets]: Market[];
  [KpiEnum.Media]: Medium[];
  [KpiEnum.WaitingForApproval]: boolean;
}

export interface KpiSave {
  [KpiEnum.Name]: string;
  [KpiEnum.Description]: string;
  [KpiEnum.Formats]: string[];
  [KpiEnum.Markets]: number[];
  [KpiEnum.Media]: number[];
  [KpiEnum.WaitingForApproval]: boolean;
}

export interface KpiAfterReset extends Omit<KpiSave, KpiEnum.Formats> {
  [KpiEnum.Formats]: string;
}
