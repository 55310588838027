import { Market } from '@shared/interfaces/market.interface';
import { Rfp } from '@shared/interfaces/rfp.interface';
import { User } from '@shared/interfaces/user.interface';
import { DataFormats } from '@shared/interfaces/data-formats.interface';
import { Client } from '@shared/interfaces/clients.interface';

export enum SortOrderEnum {
  Asc = 'asc',
  Desc = 'desc',
}

export type SortName<T> = {
  name: string;
};

export type SortEmail<T> = {
  email: string;
};

export type SortCode<T> = {
  code: string;
};

export type SortDescription<T> = {
  description: string;
};

export type SortDataTypes<T> = {
  dataType: DataFormats;
};

export type SortMarket<T> = {
  market: Market;
};

export type SortYear<T> = {
  year: number;
};

export type SortStatus<T> = {
  status: string;
};

export type SortRfp<T> = {
  rfp: Rfp;
};

export type SortClient<T> = {
  client: Client;
};

export type SortCreatedBy<T> = {
  createdBy: string;
};

export type SortModifiedBy<T> = {
  modifiedBy: string;
};

export type SortLastUpdate<T> = {
  modifiedDate: string;
};

export type SortCreatedDate<T> = {
  createdDate: string;
};

export type SortResponsibleUser<T> = {
  responsibleUser: User;
};
