import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationConfEnum } from '@shared/consts/application-conf.const';
import { NotificationMessage, NotificationResponse, NotificationResponseEmum } from '@shared/interfaces/notification-messages';
import { Observable } from 'rxjs';
import { RestApiService } from '../rest-api/rest-api.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationMessagesService {
  private _path = 'notifications';
  constructor(private readonly restApiService: RestApiService) {}

  getAllNotifications(): Observable<NotificationResponse[]> {
    return this.restApiService.get(`${this._path}/`, {
      headers: new HttpHeaders().set(ApplicationConfEnum.LoaderSkipHeaderKey, 'true'),
    });
  }

  sendUsersNotifications(message: NotificationMessage): Observable<void> {
    return this.restApiService.post(`${this._path}/users/`, { ...message });
  }

  markAllAsRead(ids: number[]): Observable<any> {
    return this.restApiService.post(`${this._path}/read/`, ids);
  }

  markAsRead(id: NotificationResponse[NotificationResponseEmum.NotificationId]): Observable<NotificationResponse> {
    return this.restApiService.post(`${this._path}/read/${id}/`);
  }

  sendOrganizationNotifications(message: NotificationMessage) {
    return this.restApiService.post(`${this._path}/organizations/`, { ...message });
  }
}
