import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest, map } from 'rxjs';
import CustomError from '@shared/classes/CustomError.class';
import { NotificationEnum, NotificationType } from '@shared/components/notification/notification.const';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomErrorEnum, CustomErrorType } from '@shared/interfaces/custom-error.interface';
import { ClientService } from './client.service';
import { NotificationService } from '@shared/components/notification/notification.facade';
import { Client, ClientAdd, ClientEnum, ClientFilters } from '@shared/interfaces/clients.interface';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { filterClientHelper } from './client.helper';
import { MarketFacade } from '@shared/services/markets/market.facade';

@Injectable()
export class ClientFacade {
  private _clients = new BehaviorSubject<Client[]>([]);
  readonly clients$ = this._clients.asObservable();

  private _errorCreate$ = new Subject<CustomError | null>();
  errorCreate$ = this._errorCreate$.asObservable();

  private _errorEdit$ = new Subject<CustomError | null>();
  errorEdit$ = this._errorEdit$.asObservable();

  private _errorDisable$ = new Subject<CustomError | null>();
  errorDisable$ = this._errorDisable$.asObservable();

  private clientsAll: Client[] = [];

  public readonly markets$ = this.marketFacade.enabledMarkets$;

  constructor(
    private readonly clientService: ClientService,
    private readonly notificationService: NotificationService,
    private readonly marketFacade: MarketFacade
  ) {}

  get currentValueClient(): Client[] {
    return this._clients.value;
  }

  createClient(payload: ClientAdd, modal: NzModalRef): void {
    this.clientService.createClient(payload).subscribe({
      next: (client: Client) => {
        this._clients.next([...this._clients.value, ...[client]]);
        this.showNotification(NotificationType.Success, 'createClient');
        modal.destroy({ data: undefined });
      },
      error: (error: HttpErrorResponse) => {
        let customError: CustomError;
        if (error.error.errorCode === 'client_already_exists') {
          customError = new CustomError({
            [CustomErrorEnum.Message]: '',
            [CustomErrorEnum.MessageCode]: 'client_already_exists',
            [CustomErrorEnum.Type]: CustomErrorType.Form,
          });
        } else {
          customError = new CustomError({
            [CustomErrorEnum.Message]: '',
            [CustomErrorEnum.MessageCode]: 'unexpected_error',
            [CustomErrorEnum.Type]: CustomErrorType.Default,
          });
        }
        this._errorCreate$.next(customError);
      },
    });
  }

  editClient(id: number, payload: ClientAdd, modal: NzModalRef): void {
    this.clientService.editClient(id, payload).subscribe({
      next: (client: Client) => {
        this._clients.value.map((item: Client, index: number) =>
          item[ClientEnum.Id] === id ? (this._clients.value[index] = client) : ''
        );
        this._clients.next([...this._clients.value]);
        this.showNotification(NotificationType.Success, 'editClient');
        modal.destroy({ data: undefined });
      },
      error: (error: HttpErrorResponse) => {
        let customError: CustomError;
        if (error.error.errorCode === 'client_circular_reference') {
          customError = new CustomError({
            [CustomErrorEnum.Message]: '',
            [CustomErrorEnum.MessageCode]: 'client_circular_reference',
            [CustomErrorEnum.Type]: CustomErrorType.Form,
          });
        } else {
          customError = new CustomError({
            [CustomErrorEnum.Message]: '',
            [CustomErrorEnum.MessageCode]: 'unexpected_error',
            [CustomErrorEnum.Type]: CustomErrorType.Default,
          });
        }
        this._errorCreate$.next(customError);
      },
    });
  }

  disableClient(id: number): void {
    this.clientService.disableClient(id).subscribe({
      next: (client: Client) => {
        this._clients.value.map((item: Client, index: number) =>
          item[ClientEnum.Id] === id ? (this._clients.value[index] = client) : ''
        );
        this._clients.next([...this._clients.value]);
        this.showNotification(NotificationType.Success, 'disableClient');
      },
      error: () => {
        this.showNotification(NotificationType.Error, 'unexpectedError');
      },
    });
  }

  enableClient(id: number): void {
    this.clientService.enableClient(id).subscribe({
      next: (client: Client) => {
        this._clients.value.map((item: Client, index: number) =>
          item[ClientEnum.Id] === id ? (this._clients.value[index] = client) : ''
        );
        this._clients.next([...this._clients.value]);
        this.showNotification(NotificationType.Success, 'enableClient');
      },
      error: () => {
        this.showNotification(NotificationType.Error, 'unexpectedError');
      },
    });
  }

  getEnabledClients(): void {
    this.clientService.getClients().subscribe((clients: Client[]) => {
      this._clients.next(clients);
      this.clientsAll = clients;
    });
    //.pipe(map((clients: Client[]) => clients.filter((client: Client) => !client[ClientEnum.Disabled])))
  }

  private showNotification(type: NotificationType, textType: string) {
    this.notificationService.create({
      [NotificationEnum.Type]: type,
      [NotificationEnum.TextType]: textType,
    });
  }

  filterClient(filters: ClientFilters) {
    const filteredClients = filterClientHelper(this.clientsAll, filters);
    this._clients.next(filteredClients);
  }

  public selectsData$ = combineLatest([this.markets$, this.clients$]).pipe(map(([market, clients]) => ({ market, clients })));
}
