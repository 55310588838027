import { Pipe, PipeTransform } from '@angular/core';
import { SortOrderEnum } from '@shared/interfaces/sort.interface';
@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  transform(value: any[], sortOrder: SortOrderEnum = SortOrderEnum.Asc, sortKey?: string): any {
    if (!value || (sortOrder !== SortOrderEnum.Asc && sortOrder !== SortOrderEnum.Desc)) return value;

    // let numberArray = [];
    let sorted = [];

    if (!sortKey) {
      // numberArray = value.filter((item) => typeof item === 'number').sort();
      sorted = value.filter((item) => typeof item === 'string').sort() as string[];
    } else {
      // numberArray = value.filter((item) => typeof item[sortKey] === 'number').sort((a, b) => a[sortKey] - b[sortKey]);
      sorted = value
        .filter((item) => typeof item[sortKey] === 'string')
        .sort((a, b) => {
          if (a[sortKey] < b[sortKey]) return -1;
          else if (a[sortKey] > b[sortKey]) return 1;
          else return 0;
        });
    }
    // const sorted = numberArray.concat(sorted);
    return sortOrder === SortOrderEnum.Asc ? sorted : sorted.reverse();
  }
}
