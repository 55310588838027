import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subject, takeUntil } from 'rxjs';
import { NotificationEnum, NotificationType } from './notification.const';
import { NotificationService } from './notification.facade';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {
  @ViewChild(TemplateRef, { static: false }) template!: TemplateRef<{}>;
  private _destroy$ = new Subject<void>();
  public NotificationType = NotificationType;
  public NotificationEnum = NotificationEnum;
  constructor(private readonly notificationService: NotificationService, private notification: NzNotificationService) {}

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  ngOnInit(): void {
    this.notificationService.notification$
      .pipe(takeUntil(this._destroy$))
      .subscribe((data) => this.notification.template(this.template, data));
  }
}
