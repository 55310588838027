import {
  BranchesForm,
  BranchFormEnum,
  Dimension,
  DimensionEnum,
  DimensionForm,
} from '../../../value-track/projects/project/modules/kpi/interfaces/dimension.interface';
import { Market, MarketEnum, Medium, MediumEnum } from '@shared/interfaces';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicationConf, ApplicationConfEnum } from '@shared/consts/application-conf.const';

const getDimensionInitFormValues = () =>
  <DimensionForm>{
    [DimensionEnum.Name]: '',
    [DimensionEnum.Media]: [],
    [DimensionEnum.Markets]: [],
    [DimensionEnum.Description]: '',
    [DimensionEnum.Type]: null!,
    [DimensionEnum.ParentDimension]: undefined,
    [DimensionEnum.WaitingForApproval]: false,
    [DimensionEnum.Branches]: [],
  };

const prepareDimensionFromBackend = (dimension: Dimension): DimensionForm =>
  <DimensionForm>{
    [DimensionEnum.Name]: dimension[DimensionEnum.Name],
    [DimensionEnum.Media]: dimension[DimensionEnum.Media].map((medium: Medium) => medium[MediumEnum.Id]),
    [DimensionEnum.Markets]: dimension[DimensionEnum.Markets].map((market: Market) => market[MarketEnum.Id]),
    [DimensionEnum.Description]: dimension[DimensionEnum.Description],
    [DimensionEnum.Type]: dimension[DimensionEnum.Type],
    [DimensionEnum.ParentDimension]: dimension[DimensionEnum.ParentDimension]
      ? dimension[DimensionEnum.ParentDimension]![DimensionEnum.Id]
      : null,
    [DimensionEnum.Branches]: dimension[DimensionEnum.Branches],
  };

const setMediaData = (mediaIds: number[], media?: Medium[]): Medium[MediumEnum.Id][] => {
  if (!media) {
    return mediaIds;
  }
  return media.length > 0 ? media.map((medium: Medium) => medium[MediumEnum.Id]) : mediaIds;
};

const setMarketData = (marketsIds: number[], markets?: Market[]): Market[MarketEnum.Id][] => {
  if (!markets) {
    return marketsIds;
  }
  return markets.length > 0 ? markets.map((market: Medium) => market[MarketEnum.Id]) : marketsIds;
};

const branchDimensionBuilder = (branch: BranchesForm, fb: FormBuilder, readonlyMode: boolean) => {
  const { name, parentBranch } = branch;
  return fb.group({
    [BranchFormEnum.Name]: [
      { value: name, disabled: readonlyMode },
      [
        Validators.required,
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        Validators.maxLength(ApplicationConf[ApplicationConfEnum.DefaultInputLength]),
      ],
    ],
    [BranchFormEnum.ParentBranch]: [parentBranch],
  });
};

export const handleBooleanType = (formArray: FormArray, fb: FormBuilder, readonlyMode: boolean): void => {
  const booleanBranches = [
    { [BranchFormEnum.Name]: 'Yes', [BranchFormEnum.ParentBranch]: '' },
    {
      [BranchFormEnum.Name]: 'No',
      [BranchFormEnum.ParentBranch]: '',
    },
  ];
  booleanBranches.forEach((branch): void => {
    formArray.push(branchDimensionBuilder(branch, fb, readonlyMode));
  });

  formArray.controls.forEach((control: AbstractControl): void => {
    control.disable();
  });
  formArray.updateValueAndValidity();
};

const addParentToBranch = (form: FormGroup): FormArray => {
  const branchFormArray: FormArray = form.get([DimensionEnum.Branches]) as FormArray;
  branchFormArray.controls.map((item: AbstractControl) => {
    (<FormGroup>item).addControl(BranchFormEnum.ParentBranch, new FormControl('', [Validators.required]));
  });
  return branchFormArray;
};

export {
  getDimensionInitFormValues,
  prepareDimensionFromBackend,
  setMediaData,
  setMarketData,
  branchDimensionBuilder,
  addParentToBranch,
};
