import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  BranchFormEnum,
  Dimension,
  DimensionEnum,
} from '../../../logged-in/modules/value-track/projects/project/modules/kpi/interfaces/dimension.interface';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  branchDimensionBuilder,
  handleBooleanType,
} from '../../../logged-in/modules/admin/dimension/dimension-add/dimension-add.helper';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DataFormats } from '@shared/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-branches-form-array',
  templateUrl: './branches-form-array.component.html',
  styleUrls: ['./branches-form-array.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BranchesFormArrayComponent implements OnInit, OnChanges, OnDestroy {
  @Output() validationFailed: EventEmitter<BranchFormEnum.Name> = new EventEmitter<BranchFormEnum.Name>();
  @Input() form!: FormGroup;
  @Input() parentValue!: Dimension | null;
  @Input() readonlyMode: boolean = false;
  @ViewChild('inputElement', { static: false }) inputElement?: ElementRef;

  public dimensionType$: BehaviorSubject<DataFormats | null> = new BehaviorSubject<DataFormats | null>(null);

  public BranchFormEnum = BranchFormEnum;
  public DimensionEnum = DimensionEnum;
  public DataFormats = DataFormats;
  public formatterDollar = (value: number): string => `$ ${value}`;
  private readonly _destroy: Subject<void> = new Subject<void>();

  constructor(private readonly fb: FormBuilder) {}

  ngOnInit(): void {
    this.onTypeChange();
    this.initTypeControl();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.parentValue && changes.parentValue.currentValue !== changes.parentValue.previousValue) {
      (<FormArray>this.form.get(DimensionEnum.Branches))!.controls.map((control: AbstractControl) => {
        changes.parentValue.firstChange ? null : control.get([BranchFormEnum.ParentBranch])!.patchValue('');

        if (!!changes.parentValue.currentValue) {
          control.get([BranchFormEnum.ParentBranch])!.addValidators([Validators.required]);
        } else {
          control.get([BranchFormEnum.ParentBranch])!.clearValidators();
          control.get([BranchFormEnum.ParentBranch])!.updateValueAndValidity();
        }
      });
    }
    if (changes.form && changes.form.currentValue.controls.branches && changes.form.currentValue.controls.branches.length === 0) {
      this.pushDimensionBranch();
    }
  }

  public initTypeControl(): void {
    const typeControl: AbstractControl | null = this.form.get(DimensionEnum.Type);
    if (!!typeControl?.value) {
      const valuesArray: FormArray = this.form.get(DimensionEnum.Branches) as FormArray;
      this.dimensionType$.next(typeControl.value);

      if (typeControl.value === DataFormats.Boolean) {
        valuesArray?.controls.forEach((control: AbstractControl): void => {
          control.disable();
        });
      }
    }
  }

  private onTypeChange(): void {
    const typeControl: AbstractControl | null = this.form.get(DimensionEnum.Type);
    const valuesArray: FormArray = this.form.get(DimensionEnum.Branches) as FormArray;

    typeControl?.valueChanges.pipe(untilDestroyed(this)).subscribe((value: DataFormats): void => {
      this.dimensionType$.next(value);
      valuesArray.clear();
      if (value === DataFormats.Boolean) {
        handleBooleanType(valuesArray, this.fb, this.readonlyMode);
      } else {
        this.branches.push(
          branchDimensionBuilder(
            {
              [BranchFormEnum.Name]: '',
              [BranchFormEnum.ParentBranch]: '',
            },
            this.fb,
            this.readonlyMode
          )
        );
      }
    });
  }

  get branches(): FormArray {
    return this.form.get(DimensionEnum.Branches) as FormArray;
  }

  public addBranch($event: MouseEvent | Event): void {
    $event?.preventDefault();
    this.pushDimensionBranch();
  }

  public removeBranch(dimensionIndex: number): void {
    this.branches.removeAt(dimensionIndex);
  }

  private pushDimensionBranch() {
    this.branches.push(
      branchDimensionBuilder(
        {
          [BranchFormEnum.Name]: '',
          [BranchFormEnum.ParentBranch]: '',
        },
        this.fb,
        this.readonlyMode
      )
    );
  }

  drop(event: CdkDragDrop<any>): void {
    moveItemInArray(this.branches.controls, event.previousIndex, event.currentIndex);
  }
}
