import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'check-close-icon',
  templateUrl: './check-close-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckCloseIconComponent {
  @Input() public check!: boolean;

  constructor() {}
}
