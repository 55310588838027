import { Injectable } from '@angular/core';
import { RestApiService } from '@shared/services/rest-api/rest-api.service';
import { Observable } from 'rxjs';
import { Client, ClientAdd } from '@shared/interfaces/clients.interface';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(private readonly restApiService: RestApiService) {}

  getClients(): Observable<Client[]> {
    return this.restApiService.get('clients/');
  }

  createClient(payload: ClientAdd): Observable<Client> {
    return this.restApiService.post('clients/', payload);
  }

  disableClient(id: number): Observable<Client> {
    return this.restApiService.post(`clients/disable/${id}`);
  }

  enableClient(id: number): Observable<Client> {
    return this.restApiService.post(`clients/enable/${id}`);
  }

  editClient(id: number, payload: ClientAdd): Observable<Client> {
    return this.restApiService.put(`clients/${id}`, payload);
  }
}
