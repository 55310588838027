export enum ErrorModalEnum {
  Detail = 'detail',
  Loc = 'loc',
  Msg = 'msg',
  Type = 'type',
  Error = 'error',
  ErrorId = 'error_id',
}

export interface ErrorModalData {
  [ErrorModalEnum.Detail]: {
    [ErrorModalEnum.Loc]: (string | number)[];
    [ErrorModalEnum.Msg]: string;
    [ErrorModalEnum.Type]: string;
  }[];
  [ErrorModalEnum.Error]: {
    [ErrorModalEnum.Detail]: string;
    [ErrorModalEnum.ErrorId]: number;
  };
}
