import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DeveloperComponent } from '@shared/components/developer/developer.component';
import { ClientListComponent } from './components/client/client-list/client-list.component';
import { UnderConstructionComponent } from '@shared/components/under-construction/under-construction.component';

const routes: Routes = [
  {
    path: 'developer',
    component: DeveloperComponent,
  },
  {
    path: 'client',
    children: [
      {
        path: 'list',
        component: ClientListComponent,
      },
    ],
  },
  {
    path: 'under-construction',
    component: UnderConstructionComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SharedRoutingModule {}
