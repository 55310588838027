export enum CurrencyEnum {
  Id = 'id',
  Name = 'name',
  FullName = 'fullName',
}

export interface Currency {
  [CurrencyEnum.Id]: number;
  [CurrencyEnum.Name]: string;
  [CurrencyEnum.FullName]: string;
}
