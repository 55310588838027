import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, filter, first, map, Observable } from 'rxjs';

interface Item {
  [item: string]: Details;
}
enum DetailsEnum {
  SaveTime = 'saveTime',
  ModifyTime = 'modifyTime',
}
interface Details {
  [DetailsEnum.SaveTime]: number;
  [DetailsEnum.ModifyTime]: number;
}

@Injectable()
export class TrackingChangesFacade {
  private _data$ = new BehaviorSubject<Item>({});

  constructor() {}

  public setItemsToTrack(names: string | string[]): void {
    this._data$.pipe(first()).subscribe((data) => {
      const duplicates = this.checkDuplicates(names, data);
      if (duplicates) {
        // new Error('Duplicates were detected in the table of treated elements. Please remove duplicates.');
        return;
      }
      this.setData(names);
    });
  }

  public addItem(name: string | string[]) {
    if (typeof name === 'string') {
      this.add(name);
    } else {
      name.forEach((value) => this.add(value));
    }
  }

  public resetItems() {
    this._data$.next({});
  }

  private add(name: string) {
    const item = { [name]: { ...this.initDetails() } };
    this._data$.pipe(first()).subscribe((data) => {
      const duplicates = this.checkDuplicates(name, data);
      if (duplicates) {
        // new Error('Duplicates were detected in the table of treated elements. Please remove duplicates.');
        return;
      }
      this.addData(data, item);
    });
  }

  public markItemAsSaved(key: string) {
    this.changeState(key, DetailsEnum.SaveTime);
  }

  public markItemAsModified(key: string) {
    this.changeState(key, DetailsEnum.ModifyTime);
  }

  private setData(names: string | string[]) {
    if (typeof names === 'string') {
      const target = {
        [names]: { ...this.initDetails() },
      };
      this._data$.next({ ...target });
    } else {
      const targets = names.reduce((acc, name) => ({ ...acc, [name]: { ...this.initDetails() } }), {});
      this._data$.next({ ...targets });
    }
  }

  private addData(data: Item, payload: Item) {
    this._data$.next({ ...data, ...payload });
  }

  private checkDuplicates(names: string | string[], data: Item): boolean {
    let isDuplicate = false;
    let duplicates = [];
    if (typeof names === 'string') {
      isDuplicate = !!data[names];
    } else {
      duplicates = names.filter((name) => !!data[name]);
    }

    return isDuplicate || duplicates.length > 0;
  }

  private changeState(key: string, action: DetailsEnum) {
    this._data$
      .pipe(
        first(),
        filter((data) => !!data[key])
      )
      .subscribe((data) => {
        const editedData = {
          ...data[key],
          [action]: new Date().getTime(),
        };
        this._data$.next({
          ...data,
          [key]: editedData,
        });
      });
  }
  public itemIsUnsaved$(key: string): Observable<boolean> {
    return this._data$.pipe(
      filter((data) => !!data[key]),
      map((data) => {
        const { saveTime, modifyTime } = data[key];
        return saveTime > modifyTime || (saveTime === 0 && modifyTime === 0);
      }),
      distinctUntilChanged()
    );
  }
  public areUnsavedItems$(): Observable<boolean> {
    return this._data$.pipe(
      map((data) => Object.values(data)),
      map((data) => {
        const unsavedData = data.filter(
          (details) =>
            details[DetailsEnum.ModifyTime] < details[DetailsEnum.SaveTime] ||
            (details[DetailsEnum.ModifyTime] === 0 && details[DetailsEnum.SaveTime] === 0)
        );
        return unsavedData.length === data.length;
      }),
      distinctUntilChanged()
    );
  }

  private initDetails() {
    return {
      [DetailsEnum.SaveTime]: 0,
      [DetailsEnum.ModifyTime]: 0,
    };
  }
}
