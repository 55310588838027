import { AuditData } from './audit-data.interface';
import { Currency } from '@shared/interfaces/currency.interface';

export enum MarketEnum {
  Id = 'id',
  Name = 'name',
  Code = 'code',
  Currencies = 'currencies',
  Disabled = 'disabled',
}

export interface Market extends MarketSave, Partial<AuditData> {
  [MarketEnum.Id]: number;
  [MarketEnum.Currencies]: Currency[];
  [MarketEnum.Disabled]: boolean;
}

export interface MarketSave {
  [MarketEnum.Name]: string;
  [MarketEnum.Code]: string;
  [MarketEnum.Currencies]: Currency[];
}
export enum MarketFilterEnum {
  Disabled = 'disabled',
  SearchText = 'searchText',
}

export interface MarketFilters {
  [MarketFilterEnum.SearchText]: string;
  [MarketFilterEnum.Disabled]: boolean;
}

export type MarketFilterValues = {
  [MarketFilterEnum.Disabled]: boolean[];
};
