import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}
  transform(date: Date): string {
    const inputDate = new Date(date);
    let today = new Date();
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    let timeDiff = Math.abs(today.getTime() - inputDate.getTime());

    if (yesterday.toDateString() === inputDate.toDateString()) {
      return `1 ${this.translateService.instant('COMMON.dayAgo')}`;
    } else if (inputDate < yesterday) {
      let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      return `${diffDays} ${this.translateService.instant('COMMON.daysAgo')}`;
    } else {
      let diffHours = Math.floor(timeDiff / (1000 * 3600));
      let diffMinutes = Math.floor((timeDiff % (1000 * 3600)) / (1000 * 60));
      if (diffHours === 0) {
        return `${diffMinutes} ${this.translateService.instant('COMMON.minutesAgo')}`;
      } else {
        return `${diffHours} ${this.translateService.instant('COMMON.hoursAgo')}`;
      }
    }
  }
}
