import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export function atLeastOneLetterValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const containsLetters = /[a-zA-Z]/.test(control.value);
    return !containsLetters ? { containsLetters: { value: control.value } } : null;
  };
}

export function uniqueValueValidator(existingValues: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value === null || control.value === undefined || control.value.trim() === '') {
      return null;
    }

    const isDuplicate = existingValues.some((value) => value === control.value);

    return isDuplicate ? { alreadyExists: { value: control.value } } : null;
  };
}
