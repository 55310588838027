import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private _isOffline = new BehaviorSubject<boolean>(false);
  public readonly isOffline$ = this._isOffline.asObservable();

  constructor() {}

  change(status: boolean): void {
    this._isOffline.next(status);
  }
}
