<div *ngIf="isLoading" class="mat-spinner-background">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
</div>

<div class="no-internet-connection" *ngIf="isOffline$ | async">
  <nz-alert nzType="error" class="error-banner" [nzMessage]="noInternetConnection">
    <ng-template #noInternetConnection>
      <span class="error-message">
        {{ "COMMON.noInternetConnection" | translate }}
      </span>
    </ng-template>
  </nz-alert>
</div>

<nz-layout>
  <router-outlet></router-outlet>
</nz-layout>

<app-notification></app-notification>
