export enum AuditDateEnum {
  CreatedBy = 'createdBy',
  CreatedDate = 'createdDate',
  ModifiedBy = 'modifiedBy',
  ModifiedDate = 'modifiedDate',
}
export interface AuditData {
  [AuditDateEnum.CreatedBy]: string;
  [AuditDateEnum.CreatedDate]: string;
  [AuditDateEnum.ModifiedBy]: string;
  [AuditDateEnum.ModifiedDate]: string;
}
