import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconsProviderModule } from '@shared/icons-provider.module';
import { SharedModule } from '@shared/shared.module';
import { LoaderInterceptor } from '@shared/interceptors/loader.interceptor';
import { TokenInterceptor } from '@auth/services/token/token.interceptor';
import { UserObject } from '@auth/interfaces/user.interface';
import { Observable } from 'rxjs';
import { AppLoadService } from '@shared/services/app-load/app-load.service';
import { UserManagementModule } from './logged-in/modules/user/user-management.module';
import { GlobalErrorHandler } from '@shared/classes/GlobalErrorHandler .class';

registerLocaleData(en);

function initializeAppFactory(appLoadService: AppLoadService): () => Observable<UserObject | null> {
  return () => appLoadService.initApp();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    SharedModule,
    IconsProviderModule,
    ReactiveFormsModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: NZ_I18N, useValue: en_US },
    { provide: APP_INITIALIZER, useFactory: initializeAppFactory, deps: [AppLoadService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
