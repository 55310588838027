import { NzNotificationDataOptions } from 'ng-zorro-antd/notification';

export enum NotificationEnum {
  Type = 'type',
  TextType = 'textType',
  Options = 'options',
  IgnoreTranslation = 'ignoreTranslation',
}

export enum NotificationType {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

export type NotificationOptions<T = {}> = {
  [P in keyof NzNotificationDataOptions<T> extends `nz${infer R}`
    ? R extends `${infer FirstLetter}${infer Rest}`
      ? `${Lowercase<FirstLetter>}${Rest}`
      : never
    : never]?: NzNotificationDataOptions<T>[`nz${Capitalize<P>}`];
};

export interface Notification {
  [NotificationEnum.Type]: NotificationType;
  [NotificationEnum.TextType]: string;
  [NotificationEnum.IgnoreTranslation]?: boolean;
  [NotificationEnum.Options]?: NotificationOptions<{}>;
}
