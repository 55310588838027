import { Organization } from '@shared/interfaces';
import { AuditData } from '@shared/interfaces/audit-data.interface';

export enum UserObjectEnum {
  LoginResponse = 'loginResponse',
  UserData = 'userData',
}

export interface UserObject {
  [UserObjectEnum.LoginResponse]: LoginResponse;
  [UserObjectEnum.UserData]: UserAuth;
}

export enum UserAuthEnum {
  Active = 'active',
  Blocked = 'blocked',
  Email = 'email',
  Id = 'id',
  UserId = 'userId',
  SystemNotificationOn = 'systemNotificationOn',
  Role = 'role',
  EmailNotificationOn = 'emailNotificationOn',
  FirstLogin = 'firstLogin',
  Organization = 'organization',
}

export interface UserAuth extends Partial<AuditData> {
  [UserAuthEnum.Active]: boolean;
  [UserAuthEnum.Blocked]: boolean;
  [UserAuthEnum.Email]: string;
  [UserAuthEnum.Id]: number;
  [UserAuthEnum.Id]: number;
  [UserAuthEnum.UserId]: number;
  [UserAuthEnum.FirstLogin]: boolean;
  [UserAuthEnum.Role]: RoleType;
  [UserAuthEnum.SystemNotificationOn]: boolean;
  [UserAuthEnum.Organization]: Organization;

  [UserAuthEnum.EmailNotificationOn]: boolean;
}
export enum RoleTypeEnum {
  Admin = 'ROLE_ADMIN',
  StarsUser = 'ROLE_STARS_USER',
  PartnerUser = 'ROLE_PARTNER_USER',
  AgencyUser = 'ROLE_AGENCY',
  ClientUser = 'ROLE_CLIENT_USER',
}

export type RoleType =
  | RoleTypeEnum.Admin
  | RoleTypeEnum.StarsUser
  | RoleTypeEnum.PartnerUser
  | RoleTypeEnum.AgencyUser
  | RoleTypeEnum.ClientUser;

export enum LoginResponseEnum {
  AccessToken = 'access_token',
  RefreshToken = 'refresh_token',
}

export interface LoginResponse {
  [LoginResponseEnum.AccessToken]: string;
  [LoginResponseEnum.RefreshToken]: string;
}
