import { Injectable } from '@angular/core';
import { RestApiService } from '@shared/services/rest-api/rest-api.service';
import { Market, MarketSave } from '@shared/interfaces/market.interface';
import { Observable } from 'rxjs';
import { Currency } from '@shared/interfaces/currency.interface';

@Injectable({
  providedIn: 'root',
})
export class MarketService {
  constructor(private readonly restApiService: RestApiService) {}

  get(id: number): Observable<Market> {
    return this.restApiService.get(`market/${id}/`);
  }

  edit(id: number, payload: MarketSave): Observable<Market> {
    return this.restApiService.put(`markets/${id}`, payload);
  }

  save(market: MarketSave): Observable<Market> {
    return this.restApiService.post('markets/', market);
  }

  getAll(): Observable<Market[]> {
    return this.restApiService.get('markets/');
  }

  getCurrencies(): Observable<Currency[]> {
    return this.restApiService.get('currency/');
  }

  enable(id: number): Observable<Market> {
    return this.restApiService.post(`markets/enable/${id}/`);
  }

  disable(id: number): Observable<Market> {
    return this.restApiService.post(`markets/disable/${id}/`);
  }
}
