export enum KpiSetupEnum {
  SelectedMedia = 'selectedMedia',
  ProjectDimensions = 'projectDimensions',
}

export enum SelectedMediaEnum {
  SuggestedKpis = 'suggestedKpis',
  SelectedKpis = 'selectedKpis',
  MediumId = 'mediumId',
  MarketId = 'id',
  Name = 'name',
  Weight = 'weight',
}
