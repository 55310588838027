import { Validators } from '@angular/forms';
import { ApplicationConf, ApplicationConfEnum } from '@shared/consts/application-conf.const';
import { ClientFilterEnum, ClientFilterValues } from '@shared/interfaces/clients.interface';

export const ClientFiltersValue: ClientFilterValues = {
  [ClientFilterEnum.Disabled]: [true, false],
};

export const clientFilterForm = {
  [ClientFilterEnum.Disabled]: '',
  [ClientFilterEnum.Market]: [],
  [ClientFilterEnum.Parent]: [],
  [ClientFilterEnum.SearchText]: ['', Validators.maxLength(ApplicationConf[ApplicationConfEnum.DefaultInputLength])],
};
