<div *nzModalTitle>
  <div nz-row nzJustify="center">
    <h3>
      <i *ngIf="!editModeData" nz-icon nzType="plus" nzTheme="outline"></i>
      <i *ngIf="editModeData" nz-icon nzType="edit" nzTheme="outline"></i>
      {{ title | uppercase }}
    </h3>
  </div>
</div>

<form nz-form [formGroup]="form" id="client-add-form" nzLayout="horizontal" [nzAutoTips]="autoTips" (ngSubmit)="submit()">
  <div nz-row>
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label [nzSpan]="8" [nzFor]="ClientEnum.Name" [nzRequired]="true">
          {{ "CLIENT.CREATE.name" | translate }}
        </nz-form-label>
        <nz-form-control [nzSpan]="14" [nzErrorTip]="name">
          <input
            nz-input
            [formControlName]="ClientEnum.Name"
            [id]="ClientEnum.Name"
            [placeholder]="'CLIENT.CREATE.placeholders.nameField' | translate"
          />
        </nz-form-control>
        <ng-template #name let-control>
          <ng-container *ngIf="f[ClientEnum.Name].hasError('clientAlreadyExists')">{{
            "CLIENT.CREATE.validation.clientAlreadyExists" | translate
          }}</ng-container>
          <ng-container *ngIf="f[ClientEnum.Name].hasError('name')">{{
            "CLIENT.CREATE.validation.invalidName" | translate
          }}</ng-container>
        </ng-template>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="8" [nzFor]="ClientEnum.Markets">
          {{ "CLIENT.CREATE.markets" | translate }}
        </nz-form-label>
        <nz-form-control [nzSpan]="14">
          <nz-select
            nzMode="multiple"
            [formControlName]="ClientEnum.Markets"
            [nzPlaceHolder]="'CLIENT.CREATE.placeholders.pleaseSelect' | translate"
            [id]="ClientEnum.Markets"
          >
            <nz-option
              *ngFor="let market of markets$ | async"
              [nzValue]="market[MarketEnum.Id]"
              [nzLabel]="market[MarketEnum.Name]"
              [nzHide]="hideDisabledMarket(market)"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label [nzSpan]="8" [nzFor]="ClientEnum.Parent">
          {{ "CLIENT.CREATE.parent" | translate }}
        </nz-form-label>
        <nz-form-control [nzSpan]="14" [nzErrorTip]="parentErr">
          <nz-select
            nzMode="default"
            [formControlName]="ClientEnum.Parent"
            [nzPlaceHolder]="'CLIENT.CREATE.placeholders.pleaseSelect' | translate"
            [id]="ClientEnum.Parent"
          >
            <nz-option [nzLabel]="'COMMON.empty' | translate" [nzValue]="null"></nz-option>
            <nz-option
              *ngFor="let clients of clients$ | async"
              [nzValue]="clients[ClientEnum.Id]"
              [nzLabel]="clients[ClientEnum.Name]"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <ng-template #parentErr let-control>
        <ng-container *ngIf="f[ClientEnum.Parent].hasError('clientCircularReference')">{{
          "CLIENT.CREATE.validation.clientCircularReference" | translate
        }}</ng-container>
      </ng-template>
    </div>
  </div>

  <div *nzModalFooter>
    <div nz-row nzJustify="end">
      <div>
        <button (click)="cancel()" nz-button nzType="default" id="client-add-button-cancel">
          <i nz-icon nzType="close" nzTheme="outline"></i>
          {{ "COMMON.cancel" | translate }}
        </button>
        <button (click)="submit()" nz-button nzType="primary" id="client-add-button-save">
          <i nz-icon nzType="check" nzTheme="outline"></i>
          {{ "COMMON.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
