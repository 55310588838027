import { Injectable } from '@angular/core';
import { RestApiService } from '@shared/services/rest-api/rest-api.service';
import { Observable } from 'rxjs';
import { Market, MarketEnum, MarketSave } from '@shared/interfaces/market.interface';
import { Kpi, KpiEnum, KpiSave } from '@shared/interfaces/kpi.interface';
import { Medium, MediumEnum } from '@shared/interfaces/medium.interface';
import { SelectableKpi } from '@shared-project/modules/kpis/interfaces/kpi-setup-handlers.interface';

@Injectable({
  providedIn: 'root',
})
export class KpiService {
  constructor(private readonly restApiService: RestApiService) {}

  get(id: number): Observable<Kpi> {
    return this.restApiService.get(`kpis/${id}/`);
  }

  save(kpi: KpiSave): Observable<Kpi> {
    return this.restApiService.post('kpis/', kpi);
  }

  update(id: number, kpi: KpiSave): Observable<Kpi> {
    return this.restApiService.put(`kpis/${id}`, kpi);
  }

  enable(id: number): Observable<Kpi> {
    return this.restApiService.post(`kpis/enable/${id}`);
  }

  disable(id: number): Observable<Kpi> {
    return this.restApiService.post(`kpis/disable/${id}`);
  }

  getAllWaiting(): Observable<Kpi[]> {
    return this.restApiService.get('kpis/');
  }

  getWaitingKpis(): Observable<Kpi[]> {
    return this.restApiService.get('kpis/waiting');
  }

  getSuggestedKpi(marketId: Market[MarketEnum.Id], mediumId: Medium[MediumEnum.Id]): Observable<Kpi[]> {
    return this.restApiService.get(`kpis/suggested/${marketId}/${mediumId}/`);
  }

  getOtherKpi(marketId: Market[MarketEnum.Id], mediumId?: Medium[MediumEnum.Id]): Observable<Kpi[]> {
    return this.restApiService.get(`kpis/others/${marketId}/${mediumId}/`);
  }

  getAllKpi(marketId: Market[MarketEnum.Id], kpiName?: Kpi[KpiEnum.Name]): Observable<Kpi[]> {
    return this.restApiService.get('kpis/');
  }

  getKpi(kpiId: Kpi[KpiEnum.Id]): Observable<Kpi> {
    return this.restApiService.get(`kpis/${kpiId}`);
  }

  saveSelectedKpi(data: SelectableKpi[]): void {
    // return this.httpClient.get<Project[]>(`${environment.apiUrl}/kpi/${projectId}/${mediumId}/${suggested}/${kpiName}`);
  }

  createKpi(data: Kpi) {
    // return this.httpClient.get<Project[]>(`${environment.apiUrl}/kpi/${projectId}/${mediumId}/${suggested}/${kpiName}`);
  }

  approve(id: number) {
    return this.restApiService.put(`kpis/approve/${id}`);
  }

  disapprove(id: number) {
    return this.restApiService.put(`kpis/disapprove/${id}`);
  }
}
