import { Injectable } from '@angular/core';
import { EMPTY, Observable, catchError, map, of, tap, throwError } from 'rxjs';
import {
  EditProject,
  FileEnum,
  FileExtension,
  Market,
  Project,
  ProjectData,
  ProjectFile,
  ProjectFilters,
  ProjectTemplate,
  ProjectTypeEnum,
  ReportFile,
  SortParams,
  SortParamsEnum,
  ProjectStatuses,
  FileReportEnum,
} from '@shared/interfaces';
import { RestApiService } from '@shared/services/rest-api/rest-api.service';
import { config } from '@shared/config';
import { Organization } from '@shared/interfaces/organization.interface';
import { Currency } from '@shared/interfaces/currency.interface';
import { RfpData, RfpEnum } from '@shared/interfaces/rfp.interface';
import { AuthService } from '@auth/services/auth/auth.service';
import {
  ProjectUseTemplateEnum,
  ProjectUseTemplateFormValues,
} from '../../../logged-in/modules/value-track/projects/project/components/project-use-template-modal/project-use-template-modal.interface';
import { ProjectListPagination } from '@shared-project/modules/projects/interfaces/projects.interface';
import { ProjectsFilterService } from './projects-filter.service.ts/projects-filter.service';
import {
  UploadFileParams,
  UploadPitchingFileParams,
  UploadQuadraFileParams,
} from '@shared-project/modules/files-manager/files-manager.interface';
import { HandlingAgencyParams } from '@shared-project/modules/project-configuration/project-configuration.interface';
import { Stage } from '@shared/interfaces/stages.interface';
import { CreateQuadraProjectSave } from 'src/app/logged-in/modules/quadra-audit/projects/components/create-project/create-project.interface';
import {
  ChangeFileStateReq,
  FilesTypeToNuke,
} from 'src/app/logged-in/modules/quadra-audit/modules/quadra-project/components/quadra-files/quadra-files.interface';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  private _path = 'projects';
  private projectType = ProjectTypeEnum.ValueTrack;

  constructor(
    private readonly projectsFilterService: ProjectsFilterService,
    private readonly restApiService: RestApiService,
    private readonly authService: AuthService
  ) {}

  getProjects(
    filters: ProjectFilters,
    page: number,
    pageSize: number,
    sortParams: SortParams
  ): Observable<ProjectListPagination> {
    let newParams = { ...filters };
    const filteredObj: { [key: string]: any } = {};
    for (const [key, value] of Object.entries(newParams)) {
      if (
        !(Array.isArray(value) && value.length === 0) &&
        !(typeof value === 'string' && value.trim() === '') &&
        value !== null
      ) {
        filteredObj[key] = value;
      }
    }
    const encodedFilter: string = encodeURIComponent(JSON.stringify(filteredObj));
    let url: string = `projects/paging?filter=${encodedFilter}`;
    if (sortParams) {
      url = `${url}&direction=${sortParams[SortParamsEnum.Direction]}&sortProperty=${
        sortParams[SortParamsEnum.SortProperty]
      }&page=${page}&size=${pageSize}&projectType=${this.projectType}`;
    }
    return this.restApiService.get(url);
  }

  getProject(id: Project[ProjectData.ID]): Observable<Project | undefined> {
    return this.restApiService.get(`projects/${id}`);
  }

  createQuadraProjct(data: CreateQuadraProjectSave): Observable<Project | undefined> {
    return EMPTY;
  }

  uploadGt(formData: any, params: UploadPitchingFileParams): Observable<Project | undefined> {
    return EMPTY;
  }
  uploadQuadra(projectId: number, formData: any, params: UploadQuadraFileParams): Observable<Project | undefined> {
    return EMPTY;
  }
  changeFlowState(id: number, params: ChangeFileStateReq): Observable<Project> {
    return EMPTY;
  }

  startNewStage(projectId: number): Observable<Project> {
    return EMPTY;
  }

  nukeFile(id: number, type: FilesTypeToNuke): Observable<Project> {
    return EMPTY;
  }

  getProjectForUseTemplate(id: Project[ProjectData.ID]): Observable<ProjectTemplate[]> {
    return this.restApiService.get(`projects/copySource/${id}`);
  }

  createSavingCalcProject(id: Project[ProjectData.ID], agencies: number[]) {
    return EMPTY;
  }

  copyProjectUseTemplate(
    id: Project[ProjectData.ID],
    sourceId: number,
    formValues: ProjectUseTemplateFormValues
  ): Observable<Project> {
    Reflect.deleteProperty(formValues, ProjectUseTemplateEnum.Source);
    return this.restApiService.put(`projects/content/copy/${id}?sourceId=${sourceId}`, formValues);
  }

  setProject(id: Project[ProjectData.ID], data: Partial<EditProject>): Observable<Project> {
    return this.restApiService.put(`projects/${id}`, data);
  }

  uploadFile(id: Project[ProjectData.ID], formData: any, params: UploadFileParams): Observable<Project> {
    return this.restApiService.post(`projects/upload/${id}`, formData, { params });
  }

  uploadFileReport(id: Project[ProjectData.ID], formData: any, params: UploadFileParams): Observable<Project> {
    return this.restApiService.post(`files/${id}/upload`, formData, { params }, 'workspaceUrl');
  }

  validateAt(id: Project[ProjectData.ID], formData: any, params: UploadFileParams): Observable<Project> {
    return this.restApiService.post(`projects/validateAT/${id}`, formData, { params });
  }

  generateAt(id: Project[ProjectData.ID]): Observable<Project> {
    return this.restApiService.post(`projects/generateAT/${id}`);
  }

  calculateAt(id: Project[ProjectData.ID], filename: ProjectFile[FileEnum.FileName]): Observable<Project> {
    return this.restApiService.post(`projects/calculateAT/${id}/filename/${filename}`);
  }

  getCurrencies(): Observable<Currency[]> {
    return this.restApiService.get('currency/');
  }

  downloadFile(
    id: Project[ProjectData.ID],
    formData: any,
    extension: FileExtension,
    fileName: ProjectFile[FileEnum.FileName],
    fieldId?: number
  ): Observable<Blob> {
    return this.restApiService.post(`projects/download/${id}`, formData, { responseType: 'blob' as 'json' }).pipe(
      catchError((error) => {
        return throwError(() => new Error(error));
      }),
      tap((data) => {
        const blob = new Blob([data], { type: config.fileExtensions[extension] });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.download = fileName;
        anchor.href = url;
        anchor.click();
      })
    );
  }

  shareQadWithAgency(id: number) {
    return of({} as Project);
  }

  downloadQuadraFile(
    id: Project[ProjectData.ID],
    extension: FileExtension,
    fileName: ProjectFile[FileEnum.FileName],
    type: string
  ): Observable<Blob> {
    return of(new Blob());
  }

  downloadReportFile(file: ReportFile, projectId: number, formData: any): Observable<Blob> {
    return this.restApiService.get(`report/project/${projectId}/file/${file[FileReportEnum.ReportFileId]}`, formData).pipe(
      tap((data: Blob): void => {
        const blob: Blob = new Blob([data], { type: config.fileExtensions[FileExtension.PPTX] });
        const url: string = window.URL.createObjectURL(blob);
        const anchor: HTMLAnchorElement = document.createElement('a');
        anchor.download = file.filename;
        anchor.href = url;
        anchor.click();
      })
    );
  }

  notifyAgencyAboutAT(id: Project[ProjectData.ID], fileName: ProjectFile[FileEnum.FileName]): Observable<any> {
    return this.restApiService.post(`/projects/shareAT/${id}/filename/${fileName}`);
  }

  notifyAgencyAboutReportFile(id: Project[ProjectData.ID], fileName: string): Observable<any> {
    return this.restApiService.post(`report/reportNotification/${id}/filename/${fileName}`);
  }

  notifyClientAboutReportFile(id: Project[ProjectData.ID], fileId: number): Observable<any> {
    return this.restApiService.post(`report/reportClientNotification/${id}/fileId/${fileId}`);
  }

  deleteReportFile(reportFileId: number, projectId: number): Observable<any> {
    return this.restApiService.delete(`report/${reportFileId}?projectId=${projectId}`);
  }

  promoteAT(id: Project[ProjectData.ID], fileName: ProjectFile[FileEnum.FileName]): Observable<any> {
    return this.restApiService.post(`report/promote/${id}/filename/${fileName}`);
  }

  approveReport(id: Project[ProjectData.ID], fileName: ProjectFile[FileEnum.FileName]): Observable<any> {
    return this.restApiService.post(`report/approve/${id}/filename/${fileName}`);
  }

  rejectReport(id: Project[ProjectData.ID], fileName: ReportFile[FileEnum.FileName]): Observable<Project> {
    return this.restApiService.put(`projects/${id}/filename/${fileName}/reject`);
  }

  generateReport(id: Project[ProjectData.ID]): Observable<Project> {
    return of({} as Project);
  }

  approveAtFile(projectId: Project[ProjectData.ID], fileName: ProjectFile[FileEnum.FileName]): Observable<Project> {
    return this.restApiService.post(`projects/approveAT/${projectId}/filename/${fileName}`);
  }

  shareATForApprovalWithAgency(
    projectId: Project[ProjectData.ID],
    fileName: ProjectFile[FileEnum.FileName]
  ): Observable<Project> {
    return this.restApiService.post(`projects/shareATForApproval/${projectId}/filename/${fileName}`);
  }

  shareGTTemplateWithAgencies(projectId: Project[ProjectData.ID], fileName: ProjectFile[FileEnum.FileName]): Observable<Project> {
    return this.restApiService.post(`projects/shareGT/${projectId}/filename/${fileName}`);
  }

  updateProjectContent(id: Project[ProjectData.ID], body: any, operation: string = ''): Observable<Project | undefined> {
    const url = operation ? `${this._path}/content/${id}?operation=${operation}` : `${this._path}/content/${id}`;
    return this.restApiService.put(url, body);
  }

  saveKpiTree(id: Project[ProjectData.ID], body: any, operation = 'KPI_TREE'): Observable<Project | undefined> {
    const url = `${this._path}/content/${id}?operation=${operation}`;
    return this.restApiService.put(url, body);
  }

  editProjectPermissions(userAccesses: EditProject, id: Project[ProjectData.ID]): Observable<any> {
    return this.restApiService.put(`${this._path}/updateDetails/${id}`, userAccesses);
  }

  saveProject(project: EditProject): Observable<Project> {
    return this.restApiService.post(`${this._path}/`, project);
  }

  copyProject(id: Project[ProjectData.ID]): Observable<Project> {
    return this.restApiService.post(`${this._path}/clone/${id}`);
  }

  getClients(): Observable<Organization[]> {
    return this.restApiService.get(`organization/type/CLIENT`);
    // return this.restApiService.get(`organization/recursive/me`);
  }

  deleteProject(id: Project[ProjectData.ID]): Observable<Project> {
    return this.restApiService.post(`${this._path}/disable/${id}`);
  }

  reopenProject(id: Project[ProjectData.ID]): Observable<Project> {
    return this.restApiService.post(`${this._path}/reopen/${id}`);
  }

  closeProject(id: Project[ProjectData.ID], closingReportId: ReportFile[FileEnum.ReportFileId] | null): Observable<any> {
    if (closingReportId == null) {
      return this.restApiService.put(`${this._path}/close/${id}`);
    }
    return this.restApiService.put(`${this._path}/close/${id}?closingReportId=${closingReportId}`);
  }
  public getYears() {
    return this.projectsFilterService.getYears();
  }

  disableAgency(payload: HandlingAgencyParams): Observable<Stage | undefined> {
    return throwError(() => new Error('Please use pitching project service to handle the action'));
  }
  enableAgency(payload: HandlingAgencyParams): Observable<Stage | undefined> {
    return throwError(() => new Error('Please use pitching project service to handle the action'));
  }

  exportGT(id: number): Observable<Project> {
    return this.restApiService.post(`${this._path}/exportGT/${id}`);
  }

  importGT(id: number, formData: any): Observable<Project> {
    return this.restApiService.post(`${this._path}/importGT/${id}`, formData);
  }

  public getMarkets(): Observable<Market[]> {
    return this.projectsFilterService.getMarkets();
  }

  public getRfps(): Observable<RfpData[]> {
    return this.projectsFilterService
      .getRfps()
      .pipe(map((rfps) => rfps.filter((rfp) => rfp[RfpEnum.Type] === ProjectTypeEnum.ValueTrack)));
  }

  public getStatuses(): Observable<ProjectStatuses[]> {
    return this.projectsFilterService.getStatuses(this.projectType);
  }
}
