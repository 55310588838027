import { Injectable } from '@angular/core';
import { LoginResponse } from '@auth/interfaces/user.interface';
import { Observable } from 'rxjs';
import { RestApiService } from '@shared/services/rest-api/rest-api.service';

@Injectable({
  providedIn: 'root',
})
export class JwtTokenService {
  constructor(private restApiService: RestApiService) {}

  public refresh(token: string): Observable<LoginResponse> {
    return this.restApiService.post(`refreshToken`, { refresh_token: token });
  }
}
