import { Injectable } from '@angular/core';
import { Menu, MenuContentEnum, MenuItem, MenuItemEnum } from '@shared/interfaces/menu.interface';
import { UserAuthEnum, UserObject, UserObjectEnum } from '@auth/interfaces/user.interface';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor() {}

  getContent(currentUser: UserObject): MenuItem[] {
    return Menu.filter(
      (item: MenuItem) => item[MenuItemEnum.Visibility].indexOf(currentUser[UserObjectEnum.UserData][UserAuthEnum.Role]) > -1
    );
  }
}
