export const sortActiveFn = <T extends Record<'active', boolean>>(a: T, b: T): number => {
  if (a.active < b.active) {
    return 1;
  } else if (a.active === b.active) {
    return 0;
  } else if (a.active > b.active) {
    return -1;
  } else {
    return 0;
  }
};
