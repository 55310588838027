import { MarketFilterEnum, MarketFilters } from '@shared/interfaces';
import { Validators } from '@angular/forms';
import { ApplicationConf, ApplicationConfEnum } from '@shared/consts/application-conf.const';

export const MarketFieldsInitValues: MarketFilters = {
  [MarketFilterEnum.SearchText]: '',
  [MarketFilterEnum.Disabled]: false,
};

export const MarketFilterFormInit = {
  [MarketFilterEnum.SearchText]: [
    MarketFieldsInitValues[MarketFilterEnum.SearchText],
    Validators.maxLength(ApplicationConf[ApplicationConfEnum.DefaultInputLength]),
  ],
  [MarketFilterEnum.Disabled]: [MarketFieldsInitValues[MarketFilterEnum.Disabled]],
};

export const MarketFilterValuesInit: MarketFilters = {
  [MarketFilterEnum.SearchText]: MarketFieldsInitValues[MarketFilterEnum.SearchText],
  [MarketFilterEnum.Disabled]: MarketFieldsInitValues[MarketFilterEnum.Disabled],
};
