import { CustomErrorEnum, CustomErrorObject, ErrorOrigin, CustomErrorType } from '@shared/interfaces/custom-error.interface';

class CustomError {
  message!: string;
  messageCode!: string;
  type!: CustomErrorType;

  constructor(error: CustomErrorObject) {
    this.message = error[CustomErrorEnum.Message];
    this.messageCode = error[CustomErrorEnum.MessageCode];
    this.type = error[CustomErrorEnum.Type];
  }
}
export const BE_ERROR_ORIGIN_PREFIX = `${ErrorOrigin.BE}-`;
export default CustomError;
