import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'searchPipe' })
export class SearchPipe implements PipeTransform {
  transform(courses: any[], prop: string, searchText: string): any {
    if (!courses) {
      return [];
    }
    if (!searchText) {
      return courses;
    }
    searchText = searchText.toLowerCase();

    return courses.filter((course) => {
      return course[prop].toLowerCase().includes(searchText);
    });
  }
}
