import { Market } from '@shared/interfaces/market.interface';
import { Medium } from './medium.interface';
import { Organization } from '@shared/interfaces/organization.interface';
import { Question } from '@shared/interfaces/rfp-question.interface';
import { User } from '@shared/interfaces/user.interface';
import { RfpDefaultQuestionsInterface } from '../../logged-in/modules/value-track/rfp/rfp-add/rfp-default-questions/rfp-default-questions.interface';
import { ProjectTypeEnum } from '@shared/interfaces';

export enum RfpEnum {
  Id = 'id',
  Name = 'name',
  Type = 'type',
  Year = 'year',
  Markets = 'markets',
  Media = 'media',
  Content = 'content',
  Questions = 'questions',
  DefaultQuestions = 'defaultQuestions',
  OrganizationId = 'organizationId',
  Organization = 'organization',
  Client = 'client',
  ClientId = 'clientId',
  AssignedUsers = 'userAccesses',
  ReadOnly = 'readOnly',
  CreatedBy = 'createdBy',
  CreatedDate = 'createdDate',
  ModifiedBy = 'modifiedBy',
  ModifiedDate = 'modifiedDate',
  RfpId = 'rfpId',
  RfpUuid = 'rfpUuid',
  Finished = 'finished',
  ClientNotified = 'clientNotified',
  ProjectCreated = 'projectCreated',
}

export interface Rfp {
  [RfpEnum.Id]: number;
  [RfpEnum.RfpId]?: number;
  [RfpEnum.Type]: ProjectTypeEnum;
  [RfpEnum.RfpUuid]?: string;
  [RfpEnum.Name]: string;
  [RfpEnum.Year]: number;
  [RfpEnum.ReadOnly]: boolean;
  [RfpEnum.Content]?: RfpTemplateContent;
  [RfpEnum.Markets]?: Market[];
  [RfpEnum.Media]?: Medium[];
  [RfpEnum.DefaultQuestions]: any;
  [RfpEnum.Questions]: Question[];
  [RfpEnum.Organization]?: Organization;
  [RfpEnum.Client]?: Organization;
  [RfpEnum.ClientId]?: number;
  [RfpEnum.AssignedUsers]?: number[] | User[];
}

export enum RfpTemplateEnum {
  DefaultFields = 'defaultFields',
  RfpTemplateFields = 'rfpTemplateFields',
  RfpFields = 'rfpFields',
  DefaultQuestions = 'defaultQuestions',
}

export enum RfpTemplateFieldEnum {
  Name = 'name',
  Value = 'value',
}

export interface RfpField {
  [RfpTemplateFieldEnum.Name]: string;
  [RfpTemplateFieldEnum.Value]: string[];
}

export interface RfpData extends Rfp {
  [RfpEnum.Finished]: boolean;
  [RfpEnum.ClientNotified]: boolean;
  [RfpEnum.ProjectCreated]: boolean;
  [RfpEnum.Client]: Organization;
}

export interface RfpSave {
  [RfpEnum.Name]: string;
  [RfpEnum.ClientId]: number;
}

export interface RfpTemplateSave {
  [RfpEnum.Id]?: number;
  [RfpEnum.RfpId]?: number;
  [RfpEnum.Name]: string;
  [RfpEnum.Type]: ProjectTypeEnum;
  [RfpEnum.Year]: number;
  [RfpEnum.ClientId]: number;
  [RfpEnum.Content]: RfpTemplateContent;
  [RfpEnum.Client]?: Organization;
  [RfpEnum.Organization]?: Organization;
  [RfpEnum.AssignedUsers]?: number[] | User[];
}

export interface RfpSave extends RfpTemplateSave {}

export interface RfpTemplateContent {
  [RfpTemplateEnum.DefaultFields]: RfpField[];
  [RfpTemplateEnum.RfpTemplateFields]: RfpField[];
  [RfpTemplateEnum.RfpFields]: RfpField[];
  [RfpTemplateEnum.DefaultQuestions]: RfpDefaultQuestionsInterface;
}

export enum DefaultFieldsWithOtherEnum {
  Type = 'type',
  Value = 'value',
}

export interface DefaultFieldsWithOther {
  [DefaultFieldsWithOtherEnum.Type]: string;
  [DefaultFieldsWithOtherEnum.Value]: string;
}

export type RfpType = ProjectTypeEnum.ValueTrack | ProjectTypeEnum.Pitching;
