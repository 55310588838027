<div [formGroup]="formGroup">
  <nz-form-item>
    <nz-form-label [nzSpan]="5" [nzFor]="nameOfFormControl"> {{ label | translate }}</nz-form-label>
    <nz-form-control [nzSpan]="14">
      <nz-select [id]="nameOfFormControl" [nzDisabled]="editMode" [formControlName]="nameOfFormControl">
        <nz-option [nzLabel]="'COMMON.yes' | translate" [nzValue]="true"></nz-option>
        <nz-option [nzLabel]="'COMMON.no' | translate" [nzValue]="false"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
</div>
